import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

const LoaderFotoProfile = (props) => {
  return (
    <ContentLoader
      animate={false}
      speed={0.5}
      width={100}
      height={100}
      viewBox="0 0 120 120"
      backgroundColor="#cacace"
      foregroundColor="#fdfdfc"
      {...props}
    >
      <Circle cx="60" cy="59" r="58" />
    </ContentLoader>
  );
};

export default LoaderFotoProfile;
