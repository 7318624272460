import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  ScrollView,
  Icon,
  AspectRatio,
  Image,
  Text,
  Center,
  HStack,
  Stack,
  NativeBaseProvider,
  FlatList,
  StatusBar,
  IconButton,
  Input,
  VStack,
  Avatar,
  Spacer,
  useToast,
  Modal,
} from "native-base";
import { useRoute, useNavigation } from "@react-navigation/core";
import {
  View,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
  Keyboard,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { InsertarDetalleQuodom } from "../../api";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { QuodomDetalle, StyleSelectCategoria } from "../../styles/StyleQuodom";
import { Buscar, ObtenerCategorias } from "../../api";
import NodoCategoriaBuscar from "../../components/Home/NodoCategoriaBuscar";

const SHA256crypt = require("js-sha256");
const auth = require("../../config/auth.config");

import DetalleProducto from "../../components/Home/DetalleProducto";
import { wp } from "../../styles/responsive";
//import { Modal } from "react-native-paper";

const BusquedaScreen = (props) => {
  ////console.log('SOY EL PROPS', props.codigoQuodom)
  const mounted = useRef(true);
  const navigation = useNavigation();
  const route = useRoute();
  const toast = useToast();
  const [scrollPosition, setScrollPosition] = useState(0);
  const searchInput = useRef(null);
  const flatListRef = useRef();
  const viewRef = useRef();
  const [input, setInput] = useState("");
  const [nombreProductoID, setNombreProducto] = useState("");
  const [idItem, setidItem] = useState("");
  const [results, setResults] = useState([]);
  const [searchTimer, setSearchTimer] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [quodomID, setquodomID] = useState("");
  const [mostrarQuodom, setmostrarQuodom] = useState(true);
  const [mostrarCategorias, setMostrarCategorias] = useState(true);
  const [posts, setPosts] = useState([]);

  const [visible, setVisible] = useState(false);

  const hideModal = () => setVisible(false);
  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: "37%",
    marginRight: "37%",
    borderRadius: 5,
  };

  useEffect(() => {
    mounted.current = true;
    searchInput.current.focus();
    LeerDatos();
    return () => (mounted.current = false);
  }, []);

  const handleScroll = (event) => {
    let yOffset = event.nativeEvent.contentOffset.y / 1;
    setScrollPosition(yOffset);
  };
  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() SitioInicial.js");
    const token = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerCategorias(token);

    if (mounted.current) {
      setPosts(postsData);
    }
  };

  async function fetchData(text) {
    const token = await AsyncStorage.getItem("userToken");
    const res = await Buscar(token, text);
    ////console.log('res', res)

    if (res.length === 0 || input === "") {
      setMostrarCategorias(true);
    } else {
      setMostrarCategorias(false);
      setResults(res);
    }
  }

  async function AbrirModal(id, nombre) {
    window.scrollTo(0, 0);
    Keyboard.dismiss();
    let quodom;
    if (route.params.codigoQuodom === "") {
      quodom = await AsyncStorage.getItem("UltimoQuodom");
      //let qName = await AsyncStorage.getItem('UltimoQuodomName');
      setquodomID(quodom);
    } else {
      quodom = route.params.codigoQuodom;
      setmostrarQuodom(false);
      setquodomID(route.params.codigoQuodom);
    }
    ////console.log('BusquedaScreen quodomID: ', quodom);
    setVisible(true);
    ////console.log('AbrirModal con id', id);
    //setModalVisible(true);
    setidItem(id);
    setNombreProducto(nombre);
  }

  const ActualizarLista = (Item, newquodomID) => {
    setVisible(false);
  };

  function Volver() {
    navigation.goBack();
  }

  return (
    <View style={{ flex: 1 }}>
      {/*  <StatusBar backgroundColor={quodomTheme.COLORS['DEFAULT']} color={quodomTheme.COLORS['LABEL']} /> */}

      {/*SL  OJO ACA CON EL PUTO NOCTH DE LOS PUTOS IPHONES */}
      {/*  <Box safeAreaTop backgroundColor={quodomTheme.COLORS['DEFAULT']} /> */}

      <HStack
        bg={quodomTheme.COLORS["DEFAULT"]}
        px={1}
        py={3}
        justifyContent="center"
        alignItems="center"
      >
        <HStack space={4} alignItems="center">
          {/* retorno a busqueda de home */}
          {/* {route.params.codigoQuodom === '' ? (<Ionicons name="arrow-back-sharp" size={25} color="black" onPress={() => navigation.navigate('Home')} />) : null} */}
          {/* retorno a detalle quodom */}
          {/* {route.params.codigoQuodom !== '' ? (<Ionicons name="arrow-back-sharp" size={25} color="black" onPress={() => Volver()} />) : null} */}
          <Ionicons
            name="arrow-back-sharp"
            size={25}
            color="black"
            onPress={() => Volver()}
          />
          <Input
            ref={searchInput}
            //autoFocus={true}
            placeholder="¿Qué necesitas?"
            onChangeText={(text) => {
              if (searchTimer) {
                clearTimeout(searchTimer);
              }
              setInput(text);
              setSearchTimer(
                setTimeout(() => {
                  ////console.log('soy el valor de busc', text);
                  if (text !== "") {
                    ////console.log('entro a buscar', text);
                    fetchData(text);
                  }
                }, 400)
              );
            }}
            value={input}
            variant="outline"
            width="87%"
            height="10"
            borderRadius={5}
            backgroundColor="#fff"
            py="1"
            fontSize={15}
            px="2"
            placeholderTextColor="gray.500"
            bg="#FFF"
            borderWidth="0"
            InputRightElement={
              <Icon
                ml="7"
                size="7"
                color="gray.500"
                as={<Ionicons name="ios-close" onPress={() => setInput("")} />}
              />
            }
          />
        </HStack>
      </HStack>
      {mostrarCategorias || input === "" ? (
        <Center pt="5">
          <ScrollView horizontal={true}>
            <FlatList
              contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}
              data={posts}
              keyExtractor={({ id }, index) => id}
              renderItem={({ item }) => (
                <NodoCategoriaBuscar
                  titulo={item.nombrecategoria}
                  id={item.id}
                  imagenSearch={item.imagenSearch}
                  refreshImageSearch={item.refreshImageSearch}
                >
                  {" "}
                </NodoCategoriaBuscar>
              )}
              numColumns="3"
              snapToAlignment="center"
            />
          </ScrollView>
        </Center>
      ) : (
        <FlatList
          keyboardShouldPersistTaps="handled"
          data={results}
          nestedScrollEnabled={true}
          contentContainerStyle={{ flexGrow: 1 }}
          onScroll={(event) => handleScroll(event)}
          ref={flatListRef}
          //ItemSeparatorComponent={ItemSeparatorView}
          renderItem={({ item }) => (
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                onPress={() => AbrirModal(item.id, item.nombre)}
              >
                <Box
                  borderBottomWidth="1"
                  _dark={{
                    borderColor: "gray.600",
                  }}
                  borderColor="coolGray.200"
                  pl="4"
                  pr="5"
                  py="2"
                >
                  <HStack
                    space={3}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <VStack w="15%" borderWidth={0} pl="1">
                      <Image
                        source={{
                          uri: item.imagen,
                        }}
                        style={{ width: 50, height: 50 }}
                      />
                    </VStack>
                    <VStack w="80%" borderWidth={0}>
                      <Text
                        _dark={{
                          color: "warmGray.50",
                        }}
                        color="coolGray.800"
                      >
                        {item.nombre}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
              </TouchableOpacity>
            </View>
          )}
          keyExtractor={(item) => "" + item.id}
        />
      )}

      <Modal isOpen={visible} onClose={() => setVisible(false)} size="lg">
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Body>
            <HStack
              pl={5}
              pr={5}
              pt={3}
              pb={4}
              w="100%"
              bg="#706F9A"
              justifyContent="space-between"
              roundedTop={5}
            >
              <VStack space={1}>
                <Text style={StyleSelectCategoria.TituloModal}>Agregar</Text>
                <Text style={StyleSelectCategoria.itemTextBlanco}>
                  {nombreProductoID}
                </Text>
              </VStack>
              <TouchableOpacity onPress={hideModal}>
                <VStack alignItems="flex-start" borderWidth={0}>
                  <Ionicons name="close-outline" size={25} color="#fff" />
                </VStack>
              </TouchableOpacity>
            </HStack>

            <DetalleProducto
              idproducto={idItem}
              quodomID={quodomID}
              nombreProductoID={nombreProductoID}
              actualizarLista={ActualizarLista}
              mostrarQuodom={mostrarQuodom}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
    marginTop: 5,
    alignItems: "center", //Centered vertically
  },
  item: {
    padding: 10,
    fontSize: 18,
    height: 44,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});
export default BusquedaScreen;
