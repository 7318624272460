import React from "react"

import {
    Select,
    VStack,
    CheckIcon,
    Center
} from "native-base"
import { StylePerfil } from '../../../styles/StyleQuodom';

const SelectCondicionFiscal = (props) => {   

    let [service, setService] = React.useState(props.condfiscal)

    function onSelected(selected) {
        setService(selected);

        props.valorSeleccionado(selected);
    }

    return (

        <VStack style={{ alignItems: "flex-start" }} borderWidth={0} >
            <Select
                selectedValue={service}
                bg='#fff'
                w='100%'
                //h='8'
                borderRadius='0'
                pt='1'
                mt='0'
                pl='0'
                pb='2'
                borderColor='#ccc'
                borderBottomWidth={0}
                borderLeftWidth={0}
                borderRightWidth={0}
                borderTopWidth={0}
                style={StylePerfil.textbox}
                accessibilityLabel="1"
                placeholder="Seleccionar"
                _selectedItem={{
                    bg: "#706F9A",
                    endIcon: <CheckIcon size="5" />,
                }}

                onValueChange={(itemValue) => onSelected(itemValue)}
            >
                <Select.Item label="" value="" />
                <Select.Item label="Resp. Inscripto" value="Resp. Inscripto" />
                <Select.Item label="Exento" value="Exento" />
                <Select.Item label="Consumidor final" value="Consumidor final" />

            </Select>
        </VStack>

    )
}

export default SelectCondicionFiscal;
