import * as React from "react";
import { View, Image } from "react-native";
import { NativeBaseProvider, Divider, Box, Center, VStack } from "native-base";
import { wp } from "../styles/responsive";
import * as Progress from "react-native-progress";

const Loader = () => {
  return (
    <NativeBaseProvider>
      {/* <View style={{ alignItems: 'center', backgroundColor: "#F6EE5D", color: '#45444C' }}>
                <Box w="80%" >
                    <Divider my="2" />
                </Box>
            </View> */}
      <Center
        alignItems="center"
        justifyItems="center"
        flex={1}
        style={{ backgroundColor: "#F6EE5D", color: "#45444C" }}
      >
        <VStack space={0}>
          <Progress.CircleSnail
            size={0}
            indeterminate={true}
            borderWidth={0}
            borderColor="#706f9a"
            spinDuration={2500}
            borderRadius={45}
            color="#45444C"
          >
            <Image
              style={{ width: wp(15), height: wp(15), zIndex: 110 }}
              source={require("../assets/adaptative-icon.png")}
              alt="Alternate Text"
              alignItems="center"
            />
          </Progress.CircleSnail>
        </VStack>
      </Center>
    </NativeBaseProvider>
  );
};

export default () => {
  return <Loader />;
};
