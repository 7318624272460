import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Box,
  useToast,
  ScrollView,
  FlatList,
  Input,
  Button,
  Divider,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ObtenerDireccionesUsuario } from "../../api";

import { wp, hp, moderateScale } from "../../styles/responsive";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";
import MenuAcciones from "../../components/MisQuodoms/MenuAcciones";
import AppBar from "../../components/AppBar";
const DireccionesScreen = () => {
  const mounted = useRef(true);
  const route = useRoute();

  const isFocused = useIsFocused();
  const [user, setUser] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isGuardando, setisGuandando] = useState(false);
  const navigation = useNavigation();
  const [postData, setpostData] = useState([]);
  const [isLoadingDir, setisLoadingDir] = useState(true);
  const toast = useToast();
  const id = "id_toast";

  const [refreshPage, setRefreshPage] = useState(false);

  const obtenerDirecciones = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const direcciones = await ObtenerDireccionesUsuario(value);
    if (mounted.current) {
      setpostData(direcciones);
      setisLoadingDir(false);
      setisLoading(false);
    }
  };

  const Actualizar = async () => {
    //setRefreshPage(Math.random().toString());
    setisLoadingDir(true);
    await obtenerDirecciones();
    //console.log("Refresh cambiarState DetalleUsuarioScreen.js");
    setisLoadingDir(false);
  };

  useEffect(() => {
    mounted.current = true;
    obtenerDirecciones();
    return () => (mounted.current = false);
  }, [isFocused, refreshPage]);

  const obtenerEstilo = (item) => {
    //if (item) return QuodomDetalle.textoItemUbicacionBold;
    //else
    return QuodomDetalle.textoItemUbicacionBold;
  };

  const footerlist = () => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("AgregarDireccion", { updateScreen: Actualizar })
        }
      >
        <VStack pl="6" pr="5" py="2">
          <HStack space={3}>
            <Text style={QuodomDetalle.textoAgregraDireccionPerfil}>
              Agrega otra dirección
            </Text>
            <MaterialIcons
              name="keyboard-arrow-right"
              size={moderateScale(12)}
              color="#235EFF"
            />
          </HStack>
        </VStack>
      </TouchableOpacity>
    );
  };

  return (
    <VStack flex="1" backgroundColor="#F2F2F2">
      <AppBar />

      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <>
          <HStack pt="7" justifyContent="center">
            <Center flex="1">
              <VStack>
                <Text style={StylePerfil.textoTitulo}>Direcciones</Text>
                {isLoadingDir ? (
                  <ActivityIndicator size="small" color="#706F9A" />
                ) : (
                  <ScrollView horizontal={true} paddingTop={5}>
                    <FlatList
                      style={{ marginLeft: "25%", marginRight: "25%" }}
                      bg="#fff"
                      shadow="0"
                      borderRadius="0"
                      space={0}
                      data={postData}
                      ListFooterComponent={footerlist}
                      renderItem={({ item, index }) => (
                        /*  <TouchableOpacity onPress={() => { Actualizar(item.id) }} > */
                        <Box
                          borderBottomWidth="1"
                          _dark={{
                            borderColor: "gray.600",
                          }}
                          borderColor="coolGray.200"
                          pl="6"
                          pr="5"
                          py="2"
                          bg="#fff"
                        >
                          <View
                            style={{
                              flex: 1,
                              position: "absolute",
                              top: 0,
                              right: 0,
                              zIndex: 100,
                            }}
                          >
                            <MenuAcciones
                              iddireccion={item.id}
                              alias={item.alias}
                              refresh={Actualizar}
                            ></MenuAcciones>
                          </View>

                          <VStack>
                            <Text style={QuodomDetalle.textoItemUbicacionBold}>
                              {item.alias}
                            </Text>
                            <Text style={QuodomDetalle.textoItemUbicacion}>
                              {item.direccion}
                            </Text>
                            <Text style={QuodomDetalle.textoItemUbicacion}>
                              {item.localidad}, {item.provincia} - CP {item.cp}
                            </Text>
                            {item.default ? (
                              <VStack justifyContent="center">
                                <HStack space={1} alignItems="center">
                                  <SimpleLineIcons
                                    name="location-pin"
                                    size={moderateScale(6)}
                                    color="#45444C"
                                  />
                                  <Text
                                    style={
                                      QuodomDetalle.textoItemUbicacionPrincipalPerfil
                                    }
                                  >
                                    Principal
                                  </Text>
                                </HStack>
                              </VStack>
                            ) : null}
                          </VStack>

                          {/* </HStack> */}
                        </Box>
                        /*  </TouchableOpacity> */
                      )}
                      keyExtractor={(item) => item.id.toString()}
                    />
                  </ScrollView>
                )}
              </VStack>
            </Center>
          </HStack>
        </>
      )}
    </VStack>
  );
};
export default DireccionesScreen;
