import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  LogBox,
} from "react-native";
import {
  Input,
  Center,
  NativeBaseProvider,
  Icon,
  Button,
  HStack,
  VStack,
  Divider,
} from "native-base";
import { Ionicons, SimpleLineIcons, MaterialIcons } from "@expo/vector-icons";
import { colorFondoGeneral } from "../../styles/general";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  CrearQuodom,
  ObtenerDireccionesDefault,
  ObtenerDatosDireccion,
} from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import {
  QuodomDetalle,
  StylePerfil,
  StyleSelectCategoria,
} from "../../styles/StyleQuodom";
import { moderateScale, wp, hp } from "../../styles/responsive";
import { Modal } from "react-native-paper";

import { AuthContext } from "../../components/context";
const QuodomNuevo = () => {
  const { signOut } = React.useContext(AuthContext);

  const mounted = useRef(true);
  const route = useRoute();
  const navigation = useNavigation();
  const [NombreQuodomNuevo, setNombreQuodomNuevo] = useState("");
  const [postsDireccion, setDireccion] = useState([]);
  const [iddireccionState, setiddireccionState] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [colorAviso, setColorAviso] = useState("#45444C");
  const [sinDireccion, setsinDireccion] = useState(false);

  const [visible, setVisible] = useState(route.params.userGuest);
  const hideModal = () => setVisible(false);

  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 0,
  };

  LogBox.ignoreLogs([
    "Non-serializable values were found in the navigation state",
  ]);

  const DatosDireccionDefault = async () => {
    const tokenDir = await AsyncStorage.getItem("userToken");
    const datosDireccion = await ObtenerDireccionesDefault(tokenDir);
    ////console.log('datoooo', datosDireccion)
    if (mounted.current) {
      if (datosDireccion === null) {
        ////console.log('enterrrr')
        //setDireccion('Debe seleccionar una direccion de entrega.');
        setsinDireccion(true);
        setColorAviso("#DC7633");
      } else {
        setsinDireccion(false);
        setDireccion(datosDireccion);
        setiddireccionState(datosDireccion.id);
      }
      setisLoading(false);
    }
  };

  const CrearQuodomCall = async () => {
    CrearQuodomBD();
    //cuando termine de crear el quodom, acá va el goBack para que vuelva a la lista
    //navigation.goBack();
  };

  const CrearQuodomBD = async () => {
    const token1 = await AsyncStorage.getItem("userToken");
    const retorno = CrearQuodom(token1, NombreQuodomNuevo, iddireccionState);

    if (typeof retorno.idquodom !== "undefined" && retorno.idquodom !== null) {
      await AsyncStorage.setItem("UltimoQuodom", retorno.idquodom);
    }

    route.params.toTop();
    route.params.estado();
    navigation.goBack();
  };

  const cambiariddireccion = async (newState) => {
    setisLoading(true);
    ////console.log('Entra a cambiariddireccion con ID', newState);
    navigation.setParams({
      iddireccion: newState,
    });
    setsinDireccion(false);
    setiddireccionState(newState);
    // cambiarState(newState)

    const value = await AsyncStorage.getItem("userToken");
    const datosDireccion = await ObtenerDatosDireccion(value, newState);
    setDireccion(datosDireccion);
    setiddireccionState(datosDireccion.id);
    setisLoading(false);
    return newState;
  };

  useEffect(() => {
    mounted.current = true;
    DatosDireccionDefault();
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack flex={1}>
      <View style={{ flex: 1, alignContent: "center" }}>
        <HStack
          bg={quodomTheme.COLORS["DEFAULT"]}
          px={2}
          py={5}
          mb="0"
          justifyContent="space-between"
          alignItems="center"
          shadow={2}
        >
          <HStack space={4} alignItems="center">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <HStack space={2} alignItems="center">
                <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
                <Text style={QuodomDetalle.textoNavigate}>Nuevo Quodom</Text>
              </HStack>
            </TouchableOpacity>
          </HStack>
        </HStack>

        <VStack
          bg="#fff"
          shadow="1"
          pl="3"
          pr="3"
          pt="5"
          mt="0"
          borderRadius="0"
          space={2}
          justifyContent="flex-start"
        >
          <Text style={QuodomDetalle.textoOtraUbicacion}>
            Nombre del Quodom
          </Text>
          <VStack space={1} alignItems="center">
            {/* <SimpleLineIcons name="location-pin" size={moderateScale(16)} color='#706F9A' />
                        <Text style={QuodomDetalle.textoAgregarUbicacion} onPress={() => navigation.goBack()}>Agrega otra ubicación</Text>
                        <MaterialIcons name="keyboard-arrow-right" size={moderateScale(20)} color='#706F9A' /> */}
            <Input
              w={wp(95)}
              onChangeText={(text) => setNombreQuodomNuevo(text)}
              style={QuodomDetalle.textoEnviarANuevoQuodomInput}
            />
          </VStack>

          <Text style={QuodomDetalle.itemTextoTipNuevoQuodom}>
            Tip: él Quodom se creará vacío! Luego vas a poder insertar todos tus
            pedidos en el Quodom creado. ¡Adelante!
          </Text>

          <Divider my={2} mt="3" mb="3" />

          <Text style={QuodomDetalle.textoOtraUbicacion}>
            Elegí donde recibir tus productos
          </Text>

          <VStack space={1} alignItems="center" pb="3">
            {isLoading ? (
              <Center
                style={{ padding: 15 }}
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
                borderWidth={1}
                borderRadius={5}
                w={wp(95)}
                borderColor="#E5E5E5"
              >
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            ) : (
              <HStack
                style={{ padding: 10 }}
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
                borderWidth={1}
                borderRadius={5}
                w={wp(95)}
                borderColor="#E5E5E5"
              >
                <TouchableOpacity
                  onPress={() =>
                    navigation.navigate("DireccionEnvioQuodomScreen", {
                      cambiarId: cambiariddireccion,
                      iddireccion: iddireccionState,
                    })
                  }
                >
                  <HStack space={2} alignItems="center">
                    <SimpleLineIcons
                      name="location-pin"
                      size={moderateScale(16)}
                      color={colorAviso}
                    />
                    <VStack space={1}>
                      {sinDireccion ? (
                        <View>
                          <Text
                            adjustsFontSizeToFit={true}
                            numberOfLines={1}
                            style={QuodomDetalle.textoEnviarANuevoQuodomR}
                          >
                            No tienes direcciones de entrega.{" "}
                          </Text>
                          <Text
                            adjustsFontSizeToFit={true}
                            numberOfLines={2}
                            style={QuodomDetalle.textoEnviarANuevoQuodomR}
                          >
                            Recuerda crear una cuando envies tu Quodom a cotizar
                          </Text>
                        </View>
                      ) : (
                        <View>
                          <Text style={QuodomDetalle.textoEnviarANuevoQuodom}>
                            {postsDireccion.alias}
                          </Text>
                          <Text
                            style={QuodomDetalle.textoEnviarANuevoQuodomChico}
                          >
                            {postsDireccion.direccion}{" "}
                          </Text>
                          <Text
                            style={QuodomDetalle.textoEnviarANuevoQuodomChico}
                          >
                            {postsDireccion.localidad},{" "}
                            {postsDireccion.provincia} - CP: {postsDireccion.cp}
                          </Text>
                        </View>
                      )}
                    </VStack>
                    <MaterialIcons
                      name="keyboard-arrow-right"
                      size={moderateScale(18)}
                      color={colorAviso}
                    />
                  </HStack>
                </TouchableOpacity>
              </HStack>
            )}
            <Text style={QuodomDetalle.itemTextoTipNuevoQuodom}>
              Tip: se creara con la direccion principal, puedes cambiarla ahora
              o despues dentro del Quodom.
            </Text>
          </VStack>
        </VStack>
      </View>

      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="2"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="center"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "90%",
          }}
          onPress={() => {
            CrearQuodomCall();
          }}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>¡Crear Quodom YA!</Text>
        </TouchableOpacity>
      </HStack>

      <Modal
        visible={visible}
        //onDismiss={hideModal}
        contentContainerStyle={containerStyle}
      >
        <HStack
          pl={5}
          pr={5}
          pt={4}
          pb={4}
          w="100%"
          bg="#706F9A"
          justifyContent="space-between"
          roundedTop={5}
        >
          <VStack space={1}>
            <Text style={StyleSelectCategoria.TituloModal15}>
              Para continuar debes ingresar a QUODOM.
            </Text>
          </VStack>
          {/* <TouchableOpacity onPress={hideModal}>
                        <VStack alignItems="flex-start" borderWidth={0}>
                            <Ionicons name="close-outline" size={25} color="#fff" />
                        </VStack>
                    </TouchableOpacity> */}
        </HStack>
        <VStack
          bg="#fff"
          p="10"
          roundedBottom={5}
          justifyContent="center"
          alignItems="center"
          space={5}
          borderWidth="0"
        >
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              signOut();
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico}>Ingresar</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              props.navigation.navigate("SignUpScreen");
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico}>Crear Cuenta</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "trasparent",
              borderColor: "#706F9A",
              borderWidth: 1,
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              navigation.goBack();
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico2}>Cerrar</Text>
          </TouchableOpacity>
        </VStack>
      </Modal>
    </VStack>
  );
};
export default QuodomNuevo;
