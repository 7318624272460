import { Animated, FlatList, StyleSheet, Text, View } from "react-native";
import React, { useRef, useState } from "react";
import SlideItem from "./SlideItem";
import PaginationComprador from "./PaginationComprador";

const SliderComprador = () => {
  const Slides = [
    {
      id: 1,
      img: require("../../../assets/slidesnew/slideComprador1.jpg"),
    },
    {
      id: 2,
      img: require("../../../assets/slidesnew/slideComprador2.jpg"),
    },
    {
      id: 3,
      img: require("../../../assets/slidesnew/slideComprador3.jpg"),
    },
    {
      id: 4,
      img: require("../../../assets/slidesnew/slideComprador4.jpg"),
    },
  ];
  const [index, setIndex] = useState(0);
  const scrollX = useRef(new Animated.Value(0)).current;

  const handleOnScroll = (event) => {
    Animated.event(
      [
        {
          nativeEvent: {
            contentOffset: {
              x: scrollX,
            },
          },
        },
      ],
      {
        useNativeDriver: false,
      }
    )(event);
  };

  const handleOnViewableItemsChanged = useRef(({ viewableItems }) => {
    // //console.log('viewableItems', viewableItems);
    setIndex(viewableItems[0].index);
  }).current;

  const viewabilityConfig = useRef({
    itemVisiblePercentThreshold: 50,
  }).current;

  return (
    <View>
      <FlatList
        data={Slides}
        renderItem={({ item }) => <SlideItem item={item} />}
        horizontal
        pagingEnabled
        snapToAlignment="center"
        showsHorizontalScrollIndicator={false}
        onScroll={handleOnScroll}
        onViewableItemsChanged={handleOnViewableItemsChanged}
        viewabilityConfig={viewabilityConfig}
      />
      <PaginationComprador data={Slides} scrollX={scrollX} index={index} />
    </View>
  );
};

export default SliderComprador;

const styles = StyleSheet.create({});
