import React, { useEffect, useState, useRef } from "react";
import {
  FlatList,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import NodoCategoria from "../../components/Home/NodoCategoria";
//import BarraQuodomInferiorPrincipal from "../../components/BarraQuodomInferior";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerCategorias,
  ObtenerQuodom,
  getQuodomCreados,
  ObtenerCotizacion,
  GuardarUsuario,
  ObtenerDataUsuario,
} from "../../api";
import {
  Input,
  Icon,
  NativeBaseProvider,
  Stack,
  Divider,
  Box,
  HStack,
  ScrollView,
  Center,
  VStack,
  CheckIcon,
  useToast,
  Image,
} from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useIsFocused } from "@react-navigation/native";

import {
  StyleSelectCategoria,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  Jaldi_400Regular,
  Montserrat_400Regular_Italic,
  WorkSans_300Light,
  WorkSans_400Regular,
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_600SemiBold,
} from "../../styles/StyleQuodom";

import { SitioInicialStyle } from "../../styles/general";
import BannerPrincipal from "../../components/BannerPrincipal";
import Loader from "../../components/Loader";
import { moderateScale, wp } from "../../styles/responsive";
import DetalleProducto from "../../components/Home/DetalleProducto";
import { Modal } from "react-native-paper";
import ListaMisQuodomsInicial from "../MisQuodoms/ListaMisQuodomsInicial";
import quodomTheme from "../../constants/Theme";
import CustomSlider from "../../components/slider/custom.slider";
import { AuthContext } from "../../components/context";
const imagesVendedor = [
  {
    imgURL: "https://img.quodom.com.ar/slides/slideVendedor1.jpg",
    imgAlt: "img-1",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideVendedor2.jpg",
    imgAlt: "img-2",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideVendedor3.jpg",
    imgAlt: "img-3",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideVendedor4.jpg",
    imgAlt: "img-4",
  },
];

const imagesComprador = [
  {
    imgURL: "https://img.quodom.com.ar/slides/slideComprador1.jpg",
    imgAlt: "img-1",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideComprador2.jpg",
    imgAlt: "img-2",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideComprador3.jpg",
    imgAlt: "img-3",
  },
  {
    imgURL: "https://img.quodom.com.ar/slides/slideComprador4.jpg",
    imgAlt: "img-4",
  },
];

const SitioInicial = () => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);
  //const isCancelled = useRef(false);
  const navigation = useNavigation();

  const toast = useToast();
  const id = "id_toast";

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
    Jaldi_400Regular,
    WorkSans_300Light,
    WorkSans_400Regular,
    Montserrat_300Light,
    Montserrat_400Regular,
    Montserrat_400Regular_Italic,
    Montserrat_600SemiBold,
  });

  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  const [visible, setVisible] = useState(false);
  const [visibleVendedor, setVisibleVendedor] = useState(false);
  const [visibleComprador, setVisibleComprador] = useState(false);
  const [nombreProductoID, setNombreProducto] = useState("");
  const [quodomID, setquodomID] = useState("");
  const [idItem, setidItem] = useState("");
  const responseListener = useRef();
  const [refreshList, setrefreshList] = useState();

  const hideModal = () => setVisible(false);
  const hideModalVendedor = () => setVisibleVendedor(false);
  const hideModalComprador = () => setVisibleComprador(false);

  const { signOut } = React.useContext(AuthContext);

  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: "37%",
    marginRight: "37%",
    borderRadius: 5,
  };

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() SitioInicial.js");
    const value = await AsyncStorage.getItem("userToken");

    if (mounted.current) {
      let quodom = await AsyncStorage.getItem("UltimoQuodom");
      //console.log("quodomID - SitioINICIAL: ", quodom);

      if (quodom !== null) {
        const quodomData = await ObtenerQuodom(value, quodom);
        //console.log("quodomID - quodomData ESTADO?", quodomData.estado);

        if (quodomData.estado === "CREADO") {
          ////console.log("quodomID - quodomData ESTADO OK", quodomData.estado);
          if (mounted.current) {
            setquodomID(quodom);
          }
        } else {
          const Qs = await getQuodomCreados(value);
          if (Qs.res) {
            ////console.log('SOY Q', Qs);
            setquodomID(Qs.data[0].id);
            await AsyncStorage.setItem("UltimoQuodom", Qs.data[0].id);
          } else {
            ////console.log("quodomID - sin estado creado, tengo que crear");
            setquodomID("");
            await AsyncStorage.removeItem("UltimoQuodom");
          }
        }
      } else {
        ////console.log("quodomID - es null asi que busco el ultimo creado", quodom);
        const Qs = await getQuodomCreados(value);
        ////console.log('Qs', Qs);
        if (Qs.res) {
          setquodomID(Qs.data[0].id);
          await AsyncStorage.setItem("UltimoQuodom", Qs.data[0].id);
        } else {
          ////console.log("quodomID - sin estado creado, tengo que crear");
          setquodomID("");
          await AsyncStorage.removeItem("UltimoQuodom");
        }
      }
    }
    await AsyncStorage.setItem("volverA", "SitioInicial");
    await AsyncStorage.setItem("idcategoriaLP", "0");
    await AsyncStorage.setItem("nombrecategoriaLP", "nada");

    const postsData = await ObtenerCategorias(value);

    if (mounted.current) {
      setPosts(postsData);
      setisLoading(false);
    }
  };

  const ActualizarLista = (Item, newquodomID) => {
    ////console.log("aca", quodomID, newquodomID);
    // LeerDatos();
    //console.log("actualizo List");
    setrefreshList(Math.random().toString());
    // setrefreshList(false);
    setVisible(false);
  };

  const chequearSlide = async () => {
    const token1 = await AsyncStorage.getItem("userToken");
    // console.log("tokennn", token1);
    if (token1 === null) {
      // console.log("tokennn", token1);
      navigation.navigate("SignInScreen");
    }
    const showSlide = await AsyncStorage.getItem("ShowSlides");
    const esVendedor = await AsyncStorage.getItem("esVendedor");
    if (mounted.current) {
      if (showSlide === "SI") {
        const token = await AsyncStorage.getItem("userToken");
        const data = await ObtenerDataUsuario(token);
        data.ShowSlides = 0;
        const UserRes = await GuardarUsuario(token, data);
        await AsyncStorage.setItem("ShowSlides", "NO");
        if (esVendedor === "SI") {
          setVisibleVendedor(true);
          //  navigation.navigate("SlidesNewVendedor1");
        } else {
          navigation.navigate("SlidesNew1");
        }
      }
    }
  };

  useEffect(() => {
    mounted.current = true;
    LeerDatos();
    //chequarNotif();
    chequearSlide();

    return () => (mounted.current = false);
  }, [isFocused]);

  if (isLoading || !fontsLoaded) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <TouchableOpacity
            style={{ marginLeft: "10px", marginTop: "10px" }}
            onPress={() => navigation.toggleDrawer()}
          >
            <Icon
              size="8"
              as={<MaterialIcons name="menu" />}
              color={quodomTheme.COLORS["COLORVIOLETA"]}
            />
          </TouchableOpacity>

          <VStack flex={0.6} alignItems={"center"} marginTop={"3%"}>
            <ScrollView>
              <Box w="100%" mx="auto" backgroundColor="#F6EE5D">
                <Center>
                  <Image
                    source={require("../../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"2%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </Center>
              </Box>
              <View
                style={{
                  flex: 1,
                  backgroundColor: "#F6EE5D",
                  color: "#45444C",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{ width: "80%" }}
                  onPress={() =>
                    navigation.navigate("Busqueda", {
                      estado: "",
                      codigoQuodom: "",
                      nombreQuodom: "",
                    })
                  }
                >
                  <Box w="100%" bg="#FFF" borderRadius="25px" p="1">
                    <HStack space={2}>
                      <Icon
                        mt={1.5}
                        ml="2"
                        size="6"
                        color="#706F9A"
                        as={<Ionicons name="ios-search" />}
                      />
                      <Text
                        style={{
                          fontSize: moderateScale(9),
                          fontFamily: "Jaldi_400Regular",
                          color: "#808080",
                        }}
                      >
                        ¿Qué necesitás?
                      </Text>
                    </HStack>
                  </Box>
                </TouchableOpacity>

                <HStack>
                  <VStack>
                    <Center>
                      <ScrollView horizontal={true}>
                        <FlatList
                          contentContainerStyle={{
                            flexGrow: 1,
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                          data={posts}
                          keyExtractor={({ id }, index) => id}
                          renderItem={({ item }) => (
                            <NodoCategoria
                              titulo={item.nombrecategoria}
                              id={item.id}
                              imagen={item.imagen}
                              refreshImage={item.refreshImage}
                            >
                              {" "}
                            </NodoCategoria>
                          )}
                          numColumns="4"
                          snapToAlignment="center"
                        />
                      </ScrollView>
                    </Center>
                  </VStack>
                </HStack>
              </View>

              <Stack direction="row" space={2} mt={2}>
                <BannerPrincipal
                  visible={setVisible}
                  setNombreProducto={setNombreProducto}
                  setidItem={setidItem}
                />
              </Stack>
            </ScrollView>
          </VStack>

          <VStack flex={0.4}>
            <ListaMisQuodomsInicial
              actualizarLista={refreshList}
            ></ListaMisQuodomsInicial>
          </VStack>
        </HStack>

        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
        >
          <HStack
            pl={5}
            pr={5}
            pt={3}
            pb={4}
            w="100%"
            bg="#706F9A"
            justifyContent="space-between"
            roundedTop={5}
          >
            <VStack space={1}>
              <Text style={StyleSelectCategoria.TituloModal}>Agregar</Text>
              <Text style={StyleSelectCategoria.itemTextBlanco}>
                {nombreProductoID}
              </Text>
            </VStack>
            <TouchableOpacity onPress={hideModal}>
              <VStack alignItems="flex-start" borderWidth={0}>
                <Ionicons name="close-outline" size={25} color="#fff" />
              </VStack>
            </TouchableOpacity>
          </HStack>

          <DetalleProducto
            idproducto={idItem}
            quodomID={quodomID}
            nombreProductoID={nombreProductoID}
            actualizarLista={ActualizarLista}
            mostrarQuodom={true}
          />
        </Modal>

        <Modal
          visible={visibleVendedor}
          onDismiss={hideModalVendedor}
          contentContainerStyle={containerStyle}
        >
          <CustomSlider>
            {imagesVendedor.map((image, index) => {
              return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
            })}
          </CustomSlider>
        </Modal>

        <Modal
          visible={visibleComprador}
          onDismiss={hideModalComprador}
          contentContainerStyle={containerStyle}
        >
          <CustomSlider>
            {imagesComprador.map((image, index) => {
              return <img key={index} src={image.imgURL} alt={image.imgAlt} />;
            })}
          </CustomSlider>
        </Modal>
      </View>
    );
  }
};
//export  default SitioInicial
export default () => {
  return (
    <NativeBaseProvider>
      <SitioInicial />
    </NativeBaseProvider>
  );
};
