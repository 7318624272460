import React, { useState, useEffect, useRef } from "react";
import { TouchableOpacity, ActivityIndicator } from "react-native";
import {
  Actionsheet,
  useDisclose,
  Icon,
  Center,
  Text,
  View,
  VStack,
  Modal,
  Button,
  HStack,
  Divider,
  useToast,
  Box,
  Menu,
  Pressable,
} from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo,
  Ionicons,
} from "@expo/vector-icons";
import { QuodomDetalle, StyleProfileScreen } from "../../styles/StyleQuodom";
import { HEIGHT, hp, wp } from "../../styles/responsive";
import {
  EliminarQuodom,
  SendQuodom,
  ObtenerDireccionesUsuarioQ,
} from "../../api";
import { useNavigation } from "@react-navigation/native";
import SelectDirecciones from "../Direcciones/SelectDirecciones";

const MenuAcciones3 = (props) => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const [showModal, setShowModal] = useState(false);
  const [showModalEnviar, setShowModalEnviar] = useState(false);
  const [showModalEnviadoOK, setShowModalEnviadoOK] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [postsDireccion, setDireccion] = useState("");
  const { isOpen, onOpen, onClose } = useDisclose();
  const [iddireccionState, setiddireccionState] = useState(props.iddireccion);

  const toast = useToast();
  const id = "id_toast";

  const colorDot = (estado) => {
    if (estado == "CREADO") {
      return "#FFFFFF";
    }
    if (estado == "ENVIADO") {
      return "#4E4D4D";
    }
    if (estado == "COTIZADO") {
      return "#FFFFFF";
    }
    if (estado == "FINALIZADO") {
      return "#FFFFFF";
    }
    if (estado == "CERRADO") {
      return "#4E4D4D";
    }
    if (estado == "VENCIDO") {
      return "#4E4D4D";
    }
  };

  const DatosDireccion = async () => {
    ////console.log('Entre al DatosDireccion() AccionesDetalleQuodom.js nombre:', props.nombre, 'estado', estadoQuodom);

    const token = await AsyncStorage.getItem("userToken");
    //const datosDireccion = await ObtenerDatosDireccion(tokenDir, iddireccion);
    const datosDireccion = await ObtenerDireccionesUsuarioQ(token);
    ////console.log('datoooo', datosDireccion)
    if (mounted.current) {
      setDireccion(datosDireccion);
      setisLoading(false);
    }
  };

  const Eliminar = async (idquodom) => {
    const token = await AsyncStorage.getItem("userToken");
    EliminarQuodom(token, idquodom);
    if (mounted.current) {
      setShowModal(false);
    }
    props.actualizarestado();
  };

  const AbrirEnviar = () => {
    setisLoading(false);

    if (parseInt(props.cantproductos) === 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={2}
                  style={QuodomDetalle.TextoToast}
                >
                  Este Quodom no tiene productos para enviar a cotizar.
                </Text>
              </Box>
            );
          },
        });
      }
      return;
    }

    if (props.completado !== 100) {
      onClose(true);
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Este Quodom tiene detalles para completar. Revíselo y vuelva a
                  intentar.
                </Text>
              </Box>
            );
          },
        });
      }
      return;
    }

    setShowModalEnviar(true);
    onClose(true);
  };

  const Archivar = async (idquodom) => {
    //console.log("Archivar Quodoms", idquodom);
  };

  function handleSelected(e) {
    setiddireccionState(e);
  }

  const EnviarQuodom = async (idquodom) => {
    if (iddireccionState === null) {
      //console.log("El quodom no tiene direcciones de envio");
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "¡Atención!",
          status: "message",
          description: "Debe seleccionar una dirección de envió para continuar",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return false;
    }

    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");

    if (iddireccionState === null) {
      //console.log("El quodom no tiene direcciones de envio");
    }

    let quodomData = {
      iddireccion: iddireccionState,
    };

    const respuesta = await SendQuodom(token, idquodom, quodomData);
    ////console.log(respuesta);

    if (!respuesta.res) {
      //console.log("ERROR");
    } else {
      setisLoading(false);
      setShowModalEnviar(false);
      setShowModalEnviadoOK(false);
      props.actualizarestado(true);
      props.actualizarestado(false);
      navigation.navigate("SuccessScreen");
    }
  };

  /*  const Actualizar = () => {
         props.actualizarestado(true);
         props.actualizarestado(false);
         setShowModalEnviadoOK(false);
     } */

  useEffect(() => {
    mounted.current = true;
    if (props.estado === "CREADO") {
      DatosDireccion();
    }

    return () => (mounted.current = false);
  }, []);

  return (
    <View>
      <Center flex={1}>
        <Box alignItems="flex-start">
          <Menu
            p="0"
            borderWidth="0"
            borderColor="#52575D"
            shadow={5}
            //w={wp(50)}
            placement="left top"
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  {({ isHovered, isFocused, isPressed }) => {
                    return (
                      <Box
                        rounded="8"
                        style={{
                          transform: [
                            {
                              scale: isPressed ? 0.9 : 1,
                            },
                          ],
                        }}
                      >
                        <Entypo
                          name="dots-three-horizontal"
                          size={17}
                          color="#45444C"
                        />
                      </Box>
                    );
                  }}
                </Pressable>
              );
            }}
          >
            {props.estado === "CREADO" ? (
              <Menu.Item onPress={() => AbrirEnviar} p="3" borderRadius="3">
                <HStack space={2} alignItems="center">
                  <Icon
                    as={<MaterialCommunityIcons name="cube-send" />}
                    color="muted.500"
                    size="7"
                  />

                  <Text style={StyleProfileScreen.textMenuFiltros}>
                    Enviar a cotizar
                  </Text>
                </HStack>
              </Menu.Item>
            ) : null}

            <Divider></Divider>

            <Menu.Item
              onPress={() => setShowModal(true)}
              p="3"
              borderRadius="3"
            >
              <HStack space={2} alignItems="center">
                <Ionicons name="ios-trash-outline" size={23} color="#52575D" />
                <Text style={StyleProfileScreen.textMenuFiltros}>Eliminar</Text>
              </HStack>
            </Menu.Item>
          </Menu>
        </Box>
      </Center>

      <View>
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          hardwareAccelerated={true}
          statusBarTranslucent={true}
          animationType="none"
          transparent={true}
        >
          <Modal.Content width={wp(95)}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                Atención!
              </Text>
            </Modal.Header>
            <Modal.Body pt="5" pb="5">
              <Text style={QuodomDetalle.TextoModalEliminar}>
                Esta seguro de eliminar el Quodom{" "}
                <Text style={QuodomDetalle.TextoModalEliminarBold}>
                  {props.nombre}
                </Text>{" "}
                ?
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    padding: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                  }}
                  onPress={() => Eliminar(props.idquodom)}
                >
                  <Text style={QuodomDetalle.TextoModalButtonOK}>Eliminar</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                    Cancelar
                  </Text>
                </TouchableOpacity>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        <Modal
          isOpen={showModalEnviar}
          onClose={() => setShowModalEnviar(false)}
          size="lg"
        >
          <Modal.Content width={wp(95)}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                Resumen {props.nombre}
              </Text>
            </Modal.Header>
            <Modal.Body>
              {isLoading ? (
                <Center flex="1" h={hp(18)}>
                  <ActivityIndicator size="large" color="#706F9A" />
                </Center>
              ) : (
                <VStack space={3} w="100%" flex={1} justifyContent="center">
                  <HStack
                    pt="1"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Text style={QuodomDetalle.TextoModalResumenBold2}>
                      Cantidad de productos
                    </Text>
                    <Text pr="4" style={QuodomDetalle.TextoModalResumenBold}>
                      {props.cantproductos}
                    </Text>
                  </HStack>

                  <VStack w="100%" space={0} justifyContent="flex-start">
                    <Text style={QuodomDetalle.TextoModalResumenBold2}>
                      Enviar a
                    </Text>
                    <SelectDirecciones
                      data={postsDireccion}
                      valorSeleccionado={handleSelected}
                      valor={iddireccionState}
                    />
                  </VStack>

                  <Divider my={2} />
                  <VStack pl="2" pr="2" justifyContent="flex-start">
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      style={QuodomDetalle.TextoModalResumenTip}
                    >
                      Una vez que envié el Quodom a cotizar no podrá agregar más
                      productos.
                    </Text>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomDetalle.TextoModalResumenTip}
                    >
                      Ni modificar la dirección de entrega.
                    </Text>
                  </VStack>
                </VStack>
              )}
            </Modal.Body>
            <Modal.Footer>
              {isLoading ? null : (
                <Button.Group space={2}>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "transparent",
                      padding: 10,
                      borderRadius: 5,
                      borderWidth: 1,
                    }}
                    onPress={() => EnviarQuodom(props.idquodom)}
                  >
                    <Text style={QuodomDetalle.TextoModalButtonOK}>Enviar</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#706F9A",
                      padding: 10,
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      setShowModalEnviar(false);
                    }}
                  >
                    <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                      Cancelar
                    </Text>
                  </TouchableOpacity>
                </Button.Group>
              )}
            </Modal.Footer>
          </Modal.Content>
        </Modal>

        {/* <Modal isOpen={showModalEnviadoOK} flex={1} onClose={() => setShowModalEnviadoOK(false)} size="lg" animationPreset='slide'>
                    <Modal.Content width={wp(100)} h={hp(100)} bg='#2ECC71' >

                        <Modal.Body bg='#2ECC71'>

                            <VStack mt={hp(10)} space={3} bg='#2ECC71' alignItems="center" justifyContent="space-between">
                                <VStack alignItems="center" justifyContent="space-between" space={4}>
                                    <Ionicons name="ios-checkmark-circle-outline" size={100} color="#fff" />
                                    <Text style={QuodomDetalle.TextoModalSuccess}>¡El Quodom fue enviado con exito!</Text>
                                </VStack>

                                <Divider my={2} bg='#52BE80' />
                                <VStack pl='2' pr='2' justifyContent="flex-start" space={2}>
                                    <Text style={QuodomDetalle.TextoModalSuccessTip}>Recorda que el quodom tendra una validez de 72hs para que alguien con ganas te lo cotize. </Text>
                                    <Text style={QuodomDetalle.TextoModalSuccessTip}>Si algun proveedor te cotiza algun producto, veras este Quodom en tu lista de Quodoms en estado cotizado.</Text>
                                </VStack>
                            </VStack>

                        </Modal.Body>
                        <Modal.Footer bg='#2ECC71'>
                            <Button
                                size='lg'
                                bg='#706F9A'
                                flex="1"
                                onPress={() => { Actualizar() }}
                            >
                                ¡Listo!
                            </Button>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal> */}
      </View>
    </View>
  );
};

export default MenuAcciones3;
