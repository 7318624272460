import React, { useEffect, useState, useRef } from "react";
import { View, Text, TouchableOpacity, ActivityIndicator } from "react-native";
import { ObtenerDetalleProductoCotizado, ObtenerQuodom } from "../../api";
import {
  HStack,
  NativeBaseProvider,
  VStack,
  Center,
  FlatList,
  Box,
  Stack,
  Image,
  Modal,
  Button,
} from "native-base";
import { hp, wp } from "../../styles/responsive";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";

import { colorFondoGeneral, estilosCategorias } from "../../styles/general";
import {
  QuodomDetalle,
  DetalleProductoQuodomstyle,
  StyleSelectCategoria,
} from "../../styles/StyleQuodom";

import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { Ionicons } from "@expo/vector-icons";
import Loader from "../Loader";

import SeleccionDetalleProveedor from "./SeleccionDetalleProveedor";
import FiltrosSeleccionProveedor from "./FiltrosSeleccionProveedor";
import OrdenarSeleccionProveedor from "./OrdernarSeleccionProveedor";

const SeleccionarProveedor = (props) => {
  const mounted = useRef(true);
  const isFocused = useIsFocused();

  const route = useRoute();
  const navigation = useNavigation();
  const {
    idquodom,
    nombreQuodom,
    idquodomline,
    nombreProducto,
    imagen,
    refreshImagen,
    cantidadCotizado,
    marcarSeleccion,
    atributo1,
    atributo2,
    nombreAtributo1,
    nombreAtributo2,
    cantidadEnvio,
  } =
    route.params !== undefined
      ? route.params
      : ["", "", "", "", "", "", "", "", "", "", "", "", ""];

  ////console.log('SOY EL ROUTW', route.params)

  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsSin, setPostsSin] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingAdentro, setisLoadingAdentro] = useState(false);

  const [visible, setVisible] = useState(false);
  const [visibleFoto, setVisibleFoto] = useState(false);
  const [imagenProducto, setimagenProducto] = useState();
  const hideModal = () => setVisibleFoto(false);
  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 0,
  };

  const filtrar = (valor, tipo) => {
    ////console.log('valor:', valor, tipo);
    let lista = postsSin;

    if (tipo === "ENVIO") {
      if (valor === 0) {
        setPosts(lista);
      } else {
        const newData = lista.filter((item) => item.envio === valor);
        ////console.log('newData', newData);
        setPosts(newData);
      }
    }
  };

  const Ordenar = (valor, tipo) => {
    ////console.log('valor:', valor, tipo);
    let lista = postsSin;

    if (tipo === "PRE") {
      if (valor === 0) {
        const newData = [...lista].sort((a, b) =>
          parseFloat(b.preciounitario) > parseFloat(a.preciounitario)
            ? 1
            : parseFloat(b.preciounitario) < parseFloat(a.preciounitario)
            ? -1
            : 0
        );
        setPosts(newData);
      } else {
        const newData = [...lista].sort((a, b) =>
          parseFloat(a.preciounitario) > parseFloat(b.preciounitario)
            ? 1
            : parseFloat(a.preciounitario) < parseFloat(b.preciounitario)
            ? -1
            : 0
        );
        setPosts(newData);
      }
    }
    if (tipo === "CLAS") {
      const newData = [...lista].sort((a, b) =>
        parseFloat(b.calificacion) > parseFloat(a.calificacion)
          ? 1
          : parseFloat(b.calificacion) < parseFloat(a.calificacion)
          ? -1
          : 0
      );
      setPosts(newData);
    }

    if (tipo === "DIST") {
      if (valor === 0) {
        const newData = [...lista].sort((a, b) =>
          parseFloat(b.distancia.replace(" km", "")) >
          parseFloat(a.distancia.replace(" km", ""))
            ? 1
            : parseFloat(b.distancia.replace(" km", "")) <
              parseFloat(a.distancia.replace("km", ""))
            ? -1
            : 0
        );
        setPosts(newData);
      } else {
        const newData = [...lista].sort((a, b) =>
          parseFloat(a.distancia.replace(" km", "")) >
          parseFloat(b.distancia.replace(" km", ""))
            ? 1
            : parseFloat(a.distancia.replace(" km", "")) <
              parseFloat(b.distancia.replace(" km", ""))
            ? -1
            : 0
        );
        setPosts(newData);
      }
    }
  };

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const obtenerEstilo = (marca, unidad, cantidad) => {
    if (marca && unidad && cantidad) return "#2dab66";
    else return "#706f9a";
  };

  const setImagen = (image) => {
    setimagenProducto(image);
  };
  const modalFoto = (image) => {
    setVisibleFoto(true);
  };

  const LeerDatos = async () => {
    //console.log("LeerDatos SeleccionarProveedor.js");
    const token = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerDetalleProductoCotizado(
      token,
      idquodom,
      idquodomline
    );
    ////console.log('sssss', postsData);

    if (mounted.current) {
      setPosts(postsData);
      setPostsSin(postsData);
      setisLoading(false);
      setisLoadingAdentro(false);
      setisLoading(false);
      setisLoadingAdentro(false);
    }
  };
  const SetearImagen = (image) => {
    setimagenProducto(image);
  };

  const MarcarSeleccionado = (Item) => {
    ////console.log('entre con iD', id)
    let newData = posts.map((item) => {
      if (item.id !== Item) {
        item.seleccionComprador = false;
        return item;
      }
      return item;
    });

    ////console.log('posts', posts)
    newData = newData.map((item) => {
      if (item.id === Item) {
        if (item.seleccionComprador) {
          item.seleccionComprador = false;
        } else {
          item.seleccionComprador = true;
        }
        marcarSeleccion(item.idquodomline);
        return item;
      }
      //marcarSeleccion(item.idquodomline, '2');
      return item;
    });
    ////console.log('newData', newData)
    //marcarSeleccion(item.idquodomline);
    setPosts(newData);
    //marcarSeleccionado(Item);
  };
  const irCotizacion = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const Quodom = await ObtenerQuodom(token, idquodom);
    navigation.navigate("DetalleQuodomCotizado", {
      idquodom: Quodom.id,
      nombreQuodom: Quodom.descripcion,
      estadoQuodom: Quodom.estado,
      iddireccion: Quodom.iddireccion,
      fechaenvio: Quodom.fechaenvio,
    });
  };
  const cambiarState = () => {
    setisLoadingAdentro(true);
    //console.log("Refresh cambiarState SeleccionarProveedor.js");
    setRefreshing((state) => !state);
  };

  useEffect(() => {
    mounted.current = true;
    setisLoadingAdentro(true);
    //console.log("Entre al useEffect SeleccionarProveedor.js");
    LeerDatos();

    return () => (mounted.current = false);
  }, [isFocused, refreshing]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colorFondoGeneral,
          alignContent: "center",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        <HStack
          bg={quodomTheme.COLORS["DEFAULT"]}
          px={2}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space={4} alignItems="center">
            <TouchableOpacity onPress={() => irCotizacion()}>
              <HStack space={2} alignItems="center" pt="3">
                <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
                <Text style={QuodomDetalle.textoTituloChico}>
                  Seleccionar Proveedor
                </Text>
              </HStack>
            </TouchableOpacity>
          </HStack>
        </HStack>

        <Text
          style={{
            paddingLeft: 15,
            paddingTop: 3,
            backgroundColor: colorFondoGeneral,
          }}
        >
          <Text style={QuodomDetalle.textoTitulo}>{nombreQuodom} </Text>
          {/* <Ionicons name="create-outline" size={24} color="black" /> */}
        </Text>

        <View
          style={{
            flex: 1,
            backgroundColor: colorFondoGeneral,
            alignContent: "center",
          }}
        >
          <VStack flex={1} pr="3" pl="3" pt="0" space={2}>
            <Box
              rounded={8}
              /*  overflow="hidden" */
              width="100%"
              shadow={2}
              shadowRadius={8}
              marginTop={2}
              backgroundColor={obtenerEstilo(props.cantidadSeleccionados)}
              zIndex={1}
              //borderWidth={1}
              //borderColor='#fff'
            >
              <VStack space={0}>
                <HStack
                  justifyContent="space-between"
                  space={1}
                  w="100%"
                  borderWidth={0}
                >
                  <VStack
                    justifyContent="center"
                    bg="#fff"
                    borderWidth={0}
                    w="17%"
                    borderBottomRightRadius="10"
                    borderBottomLeftRadius="7"
                    borderTopLeftRadius={7}
                  >
                    <Image
                      source={{
                        uri: imagen,
                      }}
                      //uri={props.imagen} // image address
                      //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                      style={
                        estilosCategorias.ImagenDetalleQuodomCotizadoSeleccion
                      }
                    />
                  </VStack>

                  <VStack
                    justifyContent="center"
                    alignItems="flex-start"
                    w="45%"
                    borderWidth={0}
                  >
                    {/*  <Text style={DetalleProductoQuodomstyle.textoFechaCotizado}>#Fecha?</Text> */}
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={3}
                      style={DetalleProductoQuodomstyle.textoTituloCotizado}
                    >
                      {nombreProducto}
                    </Text>
                    {nombreAtributo1 !== "" ? (
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={DetalleProductoQuodomstyle.textoTituloCotizado2}
                      >
                        {capitalize(nombreAtributo1)}: {atributo1}
                      </Text>
                    ) : null}
                    {nombreAtributo2 !== "" ? (
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={DetalleProductoQuodomstyle.textoTituloCotizado2}
                      >
                        {capitalize(nombreAtributo2)}: {atributo2}
                      </Text>
                    ) : null}
                  </VStack>

                  <View
                    style={{
                      borderLeftWidth: 1,
                      borderLeftColor: "white",
                      marginTop: 8,
                      marginBottom: 8,
                    }}
                  />

                  <VStack
                    space={1}
                    justifyContent="center"
                    pr="2"
                    pl="1"
                    pt="3"
                    pb="3"
                    w="35%"
                    borderWidth={0}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomDetalle.TextoElegirProveedores}
                    >
                      {cantidadCotizado} proveedores
                    </Text>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomDetalle.TextoElegirProveedores}
                    >
                      {cantidadEnvio} con envio
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            </Box>
            <HStack space={2} h="10" justifyContent="flex-end">
              <FiltrosSeleccionProveedor filtrar={filtrar} />
              <OrdenarSeleccionProveedor Ordenar={Ordenar} />
            </HStack>

            <VStack>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={2}
                style={QuodomDetalle.textoTituloSeleccionarProveedor}
              >
                Seleccióna un proveedor para {nombreProducto}:
              </Text>
            </VStack>

            {isLoadingAdentro ? (
              <View
                style={{
                  flex: 1,
                  backgroundColor: colorFondoGeneral,
                  alignContent: "center",
                }}
              >
                <Center flex="1" mt="10">
                  <ActivityIndicator size="large" color="#706F9A" />
                </Center>
                {/*  <Center flex='1' mt='5'>
                                        <Text>Actuliz</Text>
                                    </Center> */}
              </View>
            ) : (
              <FlatList
                data={posts}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                ListFooterComponent={<Center h="50"></Center>}
                renderItem={({ item }) => (
                  <SeleccionDetalleProveedor
                    nombreProducto={nombreProducto}
                    atributo1={item.atributo1}
                    atributo2={item.atributo2}
                    nombreAtributo1={item.nombreAtributo1}
                    nombreAtributo2={item.nombreAtributo2}
                    preciounitario={item.preciounitario}
                    imagen={
                      item.imagendelvendedor === null
                        ? imagen
                        : item.imagendelvendedor
                    }
                    refreshImagen={
                      item.refreshImage !== "base64"
                        ? refreshImagen
                        : item.refreshImage
                    }
                    idquodom={item.idquodom}
                    actualizarestado={cambiarState}
                    envio={item.envio}
                    idcotizacionline={item.id}
                    idquodomline={item.idquodomline}
                    seleccionComprador={item.seleccionComprador}
                    //isLoading={setisLoadingAdentro}
                    cantidad={item.cantidad}
                    calificacion={item.calificacion}
                    cantcalificaciones={item.cantcalificaciones}
                    fechaentrega={item.fechaentrega}
                    marcar={MarcarSeleccionado}
                    modal={setVisible}
                    modalFoto={modalFoto}
                    setImagen={SetearImagen}
                    distancia={item.distancia}
                    usuarioVendedor={item.usuarioVendedor}
                  />
                )}
                keyExtractor={(item) => item.id}
              />
            )}
          </VStack>
        </View>

        <Modal visible={visible} contentContainerStyle={containerStyle}>
          <Modal.Content width={wp(95)}>
            <Modal.Body>
              <VStack flex={1}>
                <Center>
                  <ActivityIndicator size="large" color="#fff" />
                </Center>
              </VStack>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      </View>
    );
  }
};
export default () => {
  return (
    <NativeBaseProvider>
      <SeleccionarProveedor />
    </NativeBaseProvider>
  );
};
