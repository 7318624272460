import React, { useEffect, useState, useRef } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { useNavigation, useIsFocused } from "@react-navigation/native";

import {
  NativeBaseProvider,
  VStack,
  Text,
  Center,
  HStack,
  Divider,
  Icon,
  IconButton,
  Pressable,
} from "native-base";
import { estilosCategorias } from "../../styles/general";
import {
  deleteCategoria,
  ObtenerCategoriasUsuario,
  AsociarCategoria,
} from "../../api";
import { Ionicons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";

const NodoCategoriaProfile = (props) => {
  const navigation = useNavigation();
  const [refreshing, setRefreshing] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const isFocused = useIsFocused();
  ////console.log("estas son las props", props);
  async function checkCategoriaUser(e) {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const categoria = await ObtenerCategoriasUsuario(value);
      const userID = await AsyncStorage.getItem("userID");

      const Filtercategoria = categoria.filter((cat) => cat.idcategoria === e);
      //console.log("entre a check");
      if (Filtercategoria.length > 0) {
        setisChecked(true);
      } else {
        // //console.log(Filtercategoria);
        setisChecked(false);
      }
    } catch (error) {
      //console.log("errorr", error);
      setisChecked(false);
    }
  }

  const AgregarOEliminar = async (e) => {
    if (e != "") {
      const value = await AsyncStorage.getItem("userToken");
      const categoria = await ObtenerCategoriasUsuario(value);
      const userID = await AsyncStorage.getItem("userID");
      //  //console.log(categoria);
      const Filtercategoria = categoria.filter((cat) => cat.idcategoria === e);
      if (Filtercategoria.length > 0) {
        //  setIsLoading(true);
        //  //console.log(Filtercategoria);
        await deleteCategoria(value, Filtercategoria[0].id);
        //  setIsLoading(false);
        setRefreshing(true);
        props.refreshPage(true);
      } else {
        // setisLoading(true);

        let data = { idcategoria: e };
        const respuesta = await AsociarCategoria(value, data);
        setRefreshing(true);
        props.refreshPage(true);

        if (!respuesta.res) {
          if (!toast.isActive(id)) {
            toast.show({
              id,
              title: "Algo salió mal",
              status: "error",
              description: respuesta.message,
              placement: "bottom",
              style: { backgroundColor: "#2ECC71" },
            });
          }
          //setError(true);
        }
        //   setRefreshPage(refreshPage + 1);
        //   setAddCat("");
        // setisLoading(false);
      }
      checkCategoriaUser(props.id);
    }
  };

  useEffect(() => {
    checkCategoriaUser(props.id);
  }, [refreshing]);

  return (
    <View>
      <NativeBaseProvider>
        <VStack borderWidth={0} alignItems="center" padding={"15%"}>
          <Center>
            <Pressable onPress={() => AgregarOEliminar(props.id)}>
              {({ isHovered, isFocused, isPressed }) => {
                return (
                  <HStack>
                    {isChecked ? (
                      <HStack p="2">
                        <VStack>
                          <Center>
                            <Ionicons
                              name="checkmark-outline"
                              size={60}
                              style={{ position: "absolute" }}
                            ></Ionicons>

                            <View paddingRight={0} style={{ opacity: 0.2 }}>
                              <Center>
                                <Image
                                  source={{
                                    uri: props.imagen,
                                  }}
                                  //uri={props.imagen} // image address
                                  //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                  style={estilosCategorias.logo}
                                ></Image>
                              </Center>
                              <Text style={estilosCategorias.textoiconos}>
                                {props.titulo}
                              </Text>
                            </View>
                          </Center>
                        </VStack>
                      </HStack>
                    ) : (
                      <HStack p="2">
                        <VStack>
                          <Center>
                            <Image
                              source={{
                                uri: props.imagen,
                              }}
                              //uri={props.imagen} // image address
                              //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                              style={estilosCategorias.logo}
                            ></Image>
                          </Center>
                          <Text style={estilosCategorias.textoiconos}>
                            {props.titulo}
                          </Text>
                        </VStack>
                      </HStack>
                    )}
                  </HStack>
                );
              }}
            </Pressable>
          </Center>
        </VStack>
      </NativeBaseProvider>
    </View>
  );
};

export default NodoCategoriaProfile;
