import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  RefreshControl,
  Modal,
  Pressable,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { ObtenerDetalleQuodoms, ObtenerDatosDireccion } from "../../api";
import {
  HStack,
  NativeBaseProvider,
  Center,
  FlatList,
  Icon,
  Image,
  VStack,
  Box,
} from "native-base";
import DetalleProductoEnviado from "./DetalleProductoEnviado";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { basico, colorFondoGeneral, ModalStyle } from "../../styles/general";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { MaterialIcons, Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import Loader from "../Loader";
import { moderateScale, wp } from "../../styles/responsive";
import Moment from "moment";
import ListaMisQuodomsInicial from "../../screens/MisQuodoms/ListaMisQuodomsInicial";
const DetalleQuodomEnviado = (props) => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);

  const route = useRoute();
  const navigation = useNavigation();

  const [fechaenvio, setfechaenvio] = useState("");
  const [iddireccion, setiddireccion] = useState(0);
  const [nombreQuodom, setNombreQuodom] = useState("");
  const [idquodom, setidquodomState] = useState("");

  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [NombreQuodomNuevo, setNombreQuodomNuevo] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsDireccion, setDireccion] = useState("");
  const [colorAviso, setColorAviso] = useState("");
  const [isLoadingAdentro, setisLoadingAdentro] = useState(true);
  const [iddireccionState, setiddireccionState] = useState();

  const CambiarNombreQuodom = async () => {
    ////console.log("Entre " + NombreQuodomNuevo);
    const token = await AsyncStorage.getItem("userToken");
    //ModificarQuodom(idquodom, token, NombreQuodomNuevo);
    setModalVisible(false);
    //cambiarState(true);
    cambiarState();
  };
  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    //setIsExtended(currentScrollPosition <= 0);
  };
  const LeerDatos = async () => {
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    //console.log("Entre al LeerDatos() DetalleQuodomEnviado.js");
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerDetalleQuodoms(tokenLeer, q.id);

    if (mounted.current) {
      if (postsData.length === 0) {
        setSinProductos(false);
      } else {
        setPosts(postsData);
      }

      setisLoading(false);
    }
  };

  const DatosDireccion = async () => {
    //console.log("Entre al DatosDireccion() DetalleQuodomEnviado.js");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const tokenDir = await AsyncStorage.getItem("userToken");
    const datosDireccion = await ObtenerDatosDireccion(tokenDir, q.iddireccion);

    if (mounted.current) {
      if (!datosDireccion) {
        setDireccion("Debe seleccionar una direccion de entrega.");
        setColorAviso("#DC7633");
      } else {
        setDireccion(
          "Enviar a " +
            datosDireccion.alias +
            " " +
            datosDireccion.direccion +
            " " +
            datosDireccion.localidad
        );
        setColorAviso("#45444C");
        setiddireccionState(datosDireccion.id);
      }
    }
    setisLoadingAdentro(false);
  };

  useEffect(() => {
    //console.log("Entre al useEffect DetalleQuodomEnviado.js");
    setisLoadingAdentro(true);
    mounted.current = true;
    AsyncStorage.getItem("quodomSeleccionada").then((valor) => {
      const q = JSON.parse(valor);
      setidquodomState(q.id);
      // setEstadoQuodom(q.estado);
      setNombreQuodom(q.descripcion);
      setfechaenvio(q.cantproductos);
      setiddireccion(q.iddireccion);
      setisLoading(false);
    });
    if (isFocused) {
      LeerDatos();
      DatosDireccion();
    }
    return () => (mounted.current = false);
  }, [route]);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <TouchableOpacity
            style={{ marginLeft: "10px", marginTop: "10px" }}
            onPress={() => navigation.toggleDrawer()}
          >
            <Icon
              size="8"
              as={<MaterialIcons name="menu" />}
              color={quodomTheme.COLORS["COLORVIOLETA"]}
            />
          </TouchableOpacity>
          <VStack
            flex={0.6}
            marginTop={"3%"}
            borderWidth={0}
            alignItems="center"
          >
            <Box w="100%" mx="auto" backgroundColor="#F6EE5D">
              <Center>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Image
                    source={require("../../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"2%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </TouchableOpacity>
              </Center>
            </Box>

            <FlatList
              onScroll={onScroll}
              contentContainerStyle={{
                flexGrow: 1,
              }}
              style={{
                backgroundColor: "#F6EE5D",
                marginLeft: "5%",
                marginRight: "4%",
                width: "90%",
              }}
              numColumns="2"
              data={posts}
              ListHeaderComponent={
                <VStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingTop: 5,
                      paddingRight: "6%",
                    }}
                    justifyContent={"space-between"}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <VStack justifyContent={"space-between"}>
                      <Text
                        style={{
                          paddingLeft: 10,
                          paddingTop: 5,
                          backgroundColor: colorFondoGeneral,
                        }}
                      >
                        <Text style={QuodomDetalle.textoTitulo}>
                          {nombreQuodom}{" "}
                        </Text>
                      </Text>
                    </VStack>
                  </HStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingBottom: "2%",

                      paddingRight: "6%",
                    }}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <View>
                      <HStack space={1} alignItems="center">
                        <SimpleLineIcons
                          name="location-pin"
                          size={moderateScale(13)}
                          color={colorAviso}
                        />
                        <Text style={QuodomDetalle.textoEnviarA}>
                          {postsDireccion}
                        </Text>
                      </HStack>
                      <HStack
                        style={{ paddingLeft: 15, paddingTop: 10 }}
                        justifyContent="space-between"
                        alignItems="center"
                        overflow="hidden"
                      >
                        <Text style={QuodomDetalle.textoEnviarA}>
                          Pedido enviado el{" "}
                          {Moment(fechaenvio).format("DD/MM/YYYY")}{" "}
                        </Text>
                      </HStack>
                    </View>
                  </HStack>
                </VStack>
              }
              ListFooterComponent={null}
              renderItem={({ item }) => (
                <DetalleProductoEnviado
                  detalleProducto={item.detalleProducto}
                  nombreProducto={item.nombreProducto}
                  atributo1={item.atributo1}
                  atributo2={item.atributo2}
                  atributoNombre1={item.nombreAtributo1}
                  atributoNombre2={item.nombreAtributo2}
                  cantidad={item.cantidad}
                  imagen={item.imagen}
                  refreshImagen={item.refreshImagen}
                  atributosFaltantes={item.atributosFaltantes}
                  idproducto={item.idproducto}
                  nombreCategoria={item.nombreCategoria}
                  id={item.id}
                  idquodom={item.idquodom}
                  cantProveedores={item.cantProveedores}
                  cantEnZona={item.cantEnZona}
                ></DetalleProductoEnviado>
              )}
              keyExtractor={(item) => item.id}
            />
          </VStack>

          <VStack flex={0.4}>
            <ListaMisQuodomsInicial
              actualizarLista={visible}
            ></ListaMisQuodomsInicial>
          </VStack>
        </HStack>
      </View>
    );
  }
};
export default DetalleQuodomEnviado;
