import React, { useEffect, useState, useRef } from 'react';
import { VStack, HStack, Text, Center, Image } from "native-base";
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";

import { QuodomDetalle, StylePerfil } from '../../styles/StyleQuodom';
import { wp } from '../../styles/responsive';


const WizardVendedorScreenSuccess = () => {
    const mounted = useRef(true);
    const navigation = useNavigation();


    const SiguentePaso = async () => {
        navigation.navigate('Home');
    };


    useEffect(() => {
        mounted.current = true;
        return () => mounted.current = false;
    }, []);


    return (
        <VStack flex={1} >


            <View style={{ flex: 1, backgroundColor: '#fff', alignContent: 'center' }}>

                <HStack bg={quodomTheme.COLORS['COLORVIOLETA']} px={2} py={2} justifyContent='space-between' alignItems='center'>
                    <HStack space={4} alignItems='center'>
                        <TouchableOpacity onPress={() => navigation.goBack()}>
                            <HStack space={2} alignItems='center' pt='3'>
                                <Ionicons name="arrow-back-sharp" size={25} color="white" />
                            </HStack>
                        </TouchableOpacity>
                    </HStack>
                </HStack>

                <HStack bg={quodomTheme.COLORS['COLORVIOLETA']} space={2} pl='15' pr='15' pb='5' mb='2' justifyContent='space-between' alignItems='center'>
                    <VStack w={wp(90)}>
                        <Text adjustsFontSizeToFit={true} numberOfLines={1} style={QuodomDetalle.textoTituloMisCotizacion}>Completá el wizard y comenzá a vender.</Text>
                    </VStack>
                </HStack>

               

                <View style={{ padding: 5, alignItems: 'center', borderWidth: 0 }}>
                    <VStack p='5' space={4}>
                        <Center h='24' justifyContent='center' alignItems='center' mt='5' mb='1' borderWidth={0}>
                        <Text style={QuodomDetalle.textoTituloMisCotizacionNGM2}>¡Listo!</Text>
                        </Center>
                       
                        <Text  adjustsFontSizeToFit={true} numberOfLines={2} style={QuodomDetalle.textoTituloMediano}>¡Ya puedes comenzar a vender en Quodom!</Text>
                     
                        <Text adjustsFontSizeToFit={true} numberOfLines={3} style={QuodomDetalle.textoTituloSeleccionarProveedor}>Puedes agregar mas tarde nuevas categorias o zonas desde tu pefil de usuario.</Text>

                        <Text adjustsFontSizeToFit={true} numberOfLines={4} style={QuodomDetalle.textoAgregraDireccionPerfil}>Nota: Para habilitar las funciones de vendedor debes cerrar la aplicacion y volver a ingresar.</Text>
                    </VStack>
                </View>
            </View>

            <HStack pb='2' pl='2' pr='2' safeAreaBottom bg='#fff' shadow="3" p="3" mt="0"
                borderRadius="0" space={1} alignItem='center' justifyContent='center'>

                <TouchableOpacity
                    style={{
                        alignItems: "center",
                        backgroundColor: "#706F9A",
                        padding: 10,
                        borderRadius: 5,
                        width: '90%'
                    }}
                    onPress={() => SiguentePaso()}
                >
                    <Text style={StylePerfil.TextoButtonGenerico}>Volver</Text>
                </TouchableOpacity>
            </HStack>

        </VStack>

    );
};


export default WizardVendedorScreenSuccess