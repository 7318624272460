import React, { useEffect, useState, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerProductosAtributosVendedor,
  ModificarDetalleQuodom,
} from "../../../api";
import { Select, VStack, CheckIcon } from "native-base";

const SelectAtributoVendedor = (props) => {
  ////console.log('PROPS', props);
  const mounted = useRef(true);
  const [data, setData] = useState([]);
  let [service, setService] = useState(props.atributovalue);

  const colorFondo = (estado) => {
    if (estado) return "#2DAB66";
    else return "#fff";
  };

  const colorBordes = (estado) => {
    if (estado) return "#fff";
    else return "transparent";
  };

  const obtenerColor = (estado) => {
    if (estado) return "#FFFFFF";
    else return "#000000";
  };
  /* 
        const [dataInfo, setDataInfo] = React.useState({
            selectedItem: {},
            seleccionado: props.atributoNombre,
            Atributo: []
        }); */

  async function onSelected(selected) {
    setService(selected);
    props.valordelpicker(selected);
  }

  const loadAtributo = async () => {
    ////console.log('soy los props de SelectAtributo',props);
    const value = await AsyncStorage.getItem("userToken");
    //const atrData = await ObtenerProductosAtributos(value, props.idproducto, props.atributoNombre);
    const atrData = await ObtenerProductosAtributosVendedor(
      value,
      props.idproducto,
      props.atributoNombre
    );

    ////console.log(atrData);
    if (!atrData || atrData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      atrData.push(generico);
    }
    if (mounted.current) {
      setData(atrData);
    }
  };

  useEffect(() => {
    mounted.current = true;
    setService(props.atributovalue);
    loadAtributo();
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack style={{ alignItems: "flex-end", flex: 1 }} borderWidth={0}>
      <Select
        selectedValue={service}
        bg={colorFondo(props.detallequodomcompleto)}
        w="90%"
        h="10"
        borderRadius="20"
        //p='2'
        borderColor={colorBordes(props.detallequodomcompleto)}
        color={obtenerColor(props.detallequodomcompleto)}
        accessibilityLabel="Seleccionar"
        placeholder="Seleccionar"
        _selectedItem={{
          bg: "#706F9A",
          endIcon: <CheckIcon size="5" color="#000" />,
          color: "#fff",
        }}
        customDropdownIconProps={{
          color: obtenerColor(props.detallequodomcompleto),
          mr: "3",
        }}
        onValueChange={(itemValue) => onSelected(itemValue)}
      >
        {data.map((x) => {
          return (
            <Select.Item
              label={x.valoratributo}
              value={x.valoratributo}
              key={x.id}
            />
          );
        })}
      </Select>
    </VStack>
  );
};

export default SelectAtributoVendedor;
