import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias } from "../../styles/general";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  Ionicons,
} from "@expo/vector-icons";
import { moderateScale, wp } from "../../styles/responsive";

import { Box, HStack, VStack, Center } from "native-base";

const OfertasRenderItemAceptada = (props) => {
  const [nombreAtributo1_comprador, setnombreAtributo1_comprador] = useState(
    props.atributoNombre1
  );
  const [nombreAtributo2_comprador, setnombreAtributo2_comprador] = useState(
    props.atributoNombre2
  );

  const [nombreAtributo1_vendor, setvalorNameAtributo1] = useState(
    props.vendedor_nombreAtributo1
  );
  const [nombreAtributo2_vendor, setvalorNameAtributo2] = useState(
    props.vendedor_nombreAtributo2
  );

  const atr1_comprador =
    nombreAtributo1_comprador !== "" && nombreAtributo1_comprador !== null;
  const atr2_comprador =
    nombreAtributo2_comprador !== "" && nombreAtributo2_comprador !== null;

  const atr1_vendor =
    nombreAtributo1_vendor !== "" && nombreAtributo1_vendor !== null;
  const atr2_vendor =
    nombreAtributo2_vendor !== "" && nombreAtributo2_vendor !== null;

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  useEffect(() => {
    // //console.log(props);
  }, []);

  return (
    <HStack flex={1} mb="2" ml="1" mr="1" justifyContent="center">
      <Box
        w="52%"
        roundedTopLeft={8}
        roundedBottomLeft={8}
        bgColor="#2dab66"
        shadow={2}
        shadowRadius={8}
        elevation={5}
      >
        <HStack space={0} alignItems="center">
          <VStack>
            {props.imagendelvendedor !== null ? (
              <Image
                source={{
                  uri: "data:image/png;base64," + props.imagendelvendedor,
                }}
                alt="."
                style={estilosCategorias.ImagenDetalleQuodom}
              />
            ) : (
              <Image
                source={{
                  uri: props.imagen,
                }}
                style={estilosCategorias.ImagenDetalleQuodom}
              />
            )}
          </VStack>
          <VStack
            style={{ paddingTop: 1, paddingLeft: 5, paddingRight: 8 }}
            w="65%"
          >
            <Text style={DetalleProductoQuodomstyle.textoTituloCotizado}>
              {props.nombreproducto}
            </Text>
          </VStack>
        </HStack>

        <HStack pt="2" pl="3" pb="4">
          <VStack space={2.5}>
            {props.envio ? (
              <HStack
                space={1}
                bg="#2dab66"
                alignItems="center"
                style={{
                  textAlign: "center",
                  padding: 3,
                  borderWidth: 1,
                  borderColor: "#fff",
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 5,
                }}
              >
                <MaterialCommunityIcons
                  name="truck-outline"
                  size={18}
                  color="#fff"
                />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  Enviar
                </Text>
              </HStack>
            ) : (
              <HStack
                space={1}
                bg="#2dab66"
                alignItems="center"
                style={{
                  textAlign: "center",
                  padding: 3,
                  borderWidth: 1,
                  borderColor: "#fff",
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 5,
                }}
              >
                <MaterialIcons name="storefront" size={18} color="#fff" />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  Retira en tienda
                </Text>
              </HStack>
            )}

            <Text style={DetalleProductoQuodomstyle.textoPrecio}>
              Precio{" "}
              {props.preciounitario.toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </Text>
          </VStack>
        </HStack>
      </Box>

      <Box
        w="48%"
        bg="#fff"
        roundedTopRight={8}
        roundedBottomRight={8}
        shadow={2}
        shadowRadius={8}
        justifyContent="center"
      >
        <VStack alignItems="center" space={0.2} pl="3">
          <HStack w="95%" pb="1">
            <Text
              style={{ fontSize: 13, fontFamily: "Montserrat_600SemiBold" }}
            >
              Caracteristicas
            </Text>
          </HStack>

          <HStack w="95%">
            <Text style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}>
              Cantidad:
              <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                {" "}
                {props.cantidad}
              </Text>
            </Text>
          </HStack>

          {atr1_comprador ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.atributoNombre1)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.atributo1}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr2_comprador ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.atributoNombre2)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.atributo2}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr1_vendor ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.vendedor_nombreAtributo1)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.vendedor_atributo1}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr2_vendor ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.vendedor_nombreAtributo2)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.vendedor_atributo2}
                </Text>
              </Text>
            </HStack>
          ) : null}
        </VStack>
      </Box>
    </HStack>
  );
};
export default OfertasRenderItemAceptada;
