import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Alert,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  HStack,
  Text,
  useToast,
  Center,
  VStack,
  ScrollView,
} from "native-base";
import {
  QuodomDetalle,
  StyleSigInScreen,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { useNavigation } from "@react-navigation/core";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsociarCategoria, ObtenerCategorias } from "../../api";
import { moderateScale } from "../../styles/responsive";
import SelectCategoria from "../../components/MisQuodoms/Pickers/SelectCategoria";
import CategoriaList from "../../components/SettingsUser/CategoriaList";
import AppBar from "../../components/AppBar";
import NodoCategoriaProfile from "../../components/SettingsUser/NodoCategoriaProfile";

const CategoriasScreen = () => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const toast = useToast();
  const id = "id_toast";

  const [refreshPage, setRefreshPage] = useState("");
  const [refreshing, setRefreshing] = React.useState(false);
  const [addCat, setAddCat] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState("Agregar categoría");
  const [posts, setPosts] = useState([]);

  const LeerDatos = async () => {
    const value = await AsyncStorage.getItem("userToken");

    const postsData = await ObtenerCategorias(value);

    if (mounted.current) {
      setPosts(postsData);
      setisLoading(false);
    }
  };

  useEffect(() => {
    //setisLoading(true);
    LeerDatos();
  }, [refreshing]);

  return (
    <View style={{ flex: 1, backgroundColor: "#F2F2F2" }}>
      <AppBar />

      <View
        style={{ flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 0 }}
      >
        <Text style={StylePerfil.textoTitulo}>Categorias</Text>

        <VStack paddingTop={10}>
          <Center>
            <Text style={StyleSigInScreen.textoLoginyPass}>
              Seleccioná las categorias en las que quieras participar.
            </Text>
            <ScrollView horizontal={true}>
              <FlatList
                contentContainerStyle={{
                  flexGrow: 1,
                  alignItems: "center",
                  overflow: "hidden",
                }}
                data={posts}
                keyExtractor={({ id }, index) => id}
                renderItem={({ item }) => (
                  <NodoCategoriaProfile
                    titulo={item.nombrecategoria}
                    id={item.id}
                    imagen={item.imagen}
                    refreshImage={item.refreshImage}
                    refreshPage={setRefreshing}
                  >
                    {" "}
                  </NodoCategoriaProfile>
                )}
                numColumns="4"
                snapToAlignment="center"
              />
            </ScrollView>
          </Center>
        </VStack>
      </View>
    </View>
  );
};

export default CategoriasScreen;
