import React, { useEffect, useState, useRef } from "react";
import {
  View,
  ActivityIndicator,
  TouchableOpacity,
  BackHandler,
  WebView,
} from "react-native";
import { HStack, VStack, Center, Text, useToast, Box } from "native-base";
//import { WebView } from 'react-native-webview';
import { QuodomDetalle } from "../../styles/StyleQuodom";
import { useRoute, useNavigation, useIsFocused } from "@react-navigation/core";
import { Ionicons } from "@expo/vector-icons";
import { Modal } from "react-native-paper";
import { Checkpayment, CheckTransactionId } from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import alert from "./alert";
const WebMobbex = () => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const route = useRoute();

  const toast = useToast();
  const id_toast = "id_toast";

  const { idquodom, nombreQuodom, url, tokenId, vengode } =
    route.params !== undefined ? route.params : ["", "", "", "", ""];
  const [isLoading, setisLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const fetchData = async () => {
    //console.log("--CHECK", tokenId);
    const data = await Checkpayment(tokenId);

    //console.log("-- Estado pago", data.pago.estado_code);

    if (data.pago.estado_code !== 1) {
      setisLoading(true);
      clearInterval(intervalId);

      //SL aca tengo que hacer el switch del estado del pago
      switch (data.pago.estado_code) {
        case 200: {
          //SL 200 - Paga
          //SL aca tengo que finalizar el QUODOM y despues enviar a ResumenQuodomFinalizado.
          //SL 20230303 Ahora lo finalizo desde el API para los pagos que se hacen por el link de pago
          const token = await AsyncStorage.getItem("userToken");
          //let data = { idquodom: idquodom }
          const res = await CheckTransactionId(token, data.pago.transactionId);

          if (res) {
            setisLoading(false);
          }

          navigation.navigate("ResumenQuodomFinalizado", {
            idquodom: idquodom,
            nombreQuodom: nombreQuodom,
            vengode: vengode,
          });
        }

        default: {
          //console.log("ENVIAR A OTRO LADO");
        }
      }
    }
  };

  const backAction = () => {
    if (!isLoading) {
      //console.log("entreeee");

      alert("Atención!", "Esta seguro de cancelar el proceso de pago?", [
        {
          text: "NO",
          onPress: () => null,
          style: "cancel",
        },
        { text: "SI, cancelar", onPress: () => navigation.goBack() },
      ]);
      return true;
    } else {
      if (!toast.isActive(id_toast)) {
        toast.show({
          id_toast,
          placement: "top",
          render: () => {
            return (
              <Box bg="gray.700" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Espere unos segundos.
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Se esta terminando de procesar el pago.
                </Text>
              </Box>
            );
          },
        });
      }
      return true;
    }
  };

  let intervalId;

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect() WebMobbex.js");

    intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    wait(3000).then(() => setVisible(false));

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => {
      mounted.current = false;
      clearInterval(intervalId);
      backHandler.remove();
    };
  }, []);

  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 0,
  };

  return (
    <>
      <View
        style={{
          flex: 1,
          backgroundColor: "#706F9A",
          alignContent: "center",
          paddingLeft: "25%",
          paddingRight: "25%",
        }}
      >
        {!isLoading ? (
          <HStack
            bg="#706F9A"
            px={2}
            py={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack space={4} alignItems="center">
              <TouchableOpacity onPress={() => backAction()}>
                <HStack space={2} alignItems="center" pt="3">
                  <Ionicons name="arrow-back-sharp" size={25} color="white" />
                  <Text style={QuodomDetalle.textoTituloChicoFinalizado}>
                    Volver al Quodom
                  </Text>
                </HStack>
              </TouchableOpacity>
            </HStack>
          </HStack>
        ) : null}
        {isLoading ? (
          <Center flex={1}>
            <ActivityIndicator size="large" color="#fff" />
            <VStack alignItems="center" justifyContent="center" p="3" space={2}>
              <Text style={QuodomDetalle.TextoModalSuccess}>
                Procesando el pago...
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoRedireccion}
              >
                Aguarde unos segundos.
              </Text>
            </VStack>
          </Center>
        ) : (
          <iframe src={url} height={"100%"} width={"100%"} />
        )}
      </View>
      <Modal visible={visible} contentContainerStyle={containerStyle}>
        <VStack flex={1}>
          <Center>
            <ActivityIndicator size="large" color="#fff" />
          </Center>
        </VStack>
      </Modal>
    </>
  );
};

export default WebMobbex;
