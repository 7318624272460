import React, { useEffect, useState } from "react";
import {
  View,
  Image,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";

import {
  QuodomDetalle,
  StyleSelectCategoria,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { basico } from "../../styles/general";
import {
  ObtenerProductosID,
  InsertarDetalleQuodom,
  CrearQuodom,
} from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MaterialIcons } from "@expo/vector-icons";
import { Modal } from "react-native-paper";
//SL MODAL!!!!!
import { Center, HStack, VStack, Input, Divider, useToast } from "native-base";

import SelectQuodom from "../MisQuodoms/Pickers/SelectQuodom";
import SelectAtributoModal from "../MisQuodoms/Pickers/SelectAtributosModal";

import { AuthContext } from "../../components/context";
const SHA256crypt = require("js-sha256");
const auth = require("../../config/auth.config");

const DetalleProducto = (props) => {
  ////console.log('PROPSDeat', props.quodomID)
  const { signOut } = React.useContext(AuthContext);

  const [isLoading, setisLoading] = useState(true);
  //const [refreshing, setRefreshing] = useState(false);
  const [valorAtributo1, setvalorAtributo1] = useState("");
  const [valorAtributo2, setvalorAtributo2] = useState("");
  const [valorQuodomID, setvalorQuodomID] = useState(props.quodomID);
  const [valorNameAtributo1, setvalorNameAtributo1] = useState(null);
  const [valorNameAtributo2, setvalorNameAtributo2] = useState(null);
  const [valorCantidad, setvalorCantidad] = useState(1);
  const [item, setItem] = useState([]);
  const [cant, setcant] = useState(1);
  const [quodomID, setquodomID] = useState(props.quodomID);
  const [mostrarQuodom, setmostrarQuodom] = useState(props.mostrarQuodom);
  const toast = useToast();
  const id = "id_toast";
  const atr1 = valorNameAtributo1 !== "" && valorNameAtributo1 != null;
  const atr2 = valorNameAtributo2 !== "" && valorNameAtributo2 != null;

  const [visible, setVisible] = useState(false);
  const hideModal = () => setVisible(false);
  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 0,
  };

  async function cambiarCantidad(valor) {
    ////console.log('valor', valor)
    if (valor === "" || valor === null) {
      valor = 0;
      setcant(0);
    }
    setcant(valor);
  }

  async function funcionRestar() {
    if (parseInt(cant) <= 1) {
      ////console.log(parseInt(cant))
    } else {
      let resta = parseInt(cant) - 1;
      setcant(resta);
      cambiarCantidad(resta);
    }
  }

  async function funcionSumar() {
    ////console.log('Cant', parseInt(cant));
    let suma = parseInt(cant) + 1;
    setcant(suma);

    cambiarCantidad(suma);
  }
  const LeerDatos = async () => {
    /*   if (quodomID !== null) {
        const value = await AsyncStorage.getItem('userToken');
        const quodomData = await ObtenerQuodom(value, quodomID);
        //console.log('quodomData DetalleProducto', quodomData);
  
        if (quodomData.estado === 'CREADO') {
          setquodomID(quodomID);
          setvalorQuodomID(quodomID);
        } else {
          setquodomID(null);
          setvalorQuodomID('0')
          await AsyncStorage.removeItem('UltimoQuodom');
          //props.actualizarLista(props.idproducto, quodomID);
        }
      } */
  };

  const ObtenerProducto = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const producto = await ObtenerProductosID(value, props.idproducto);

    setvalorNameAtributo1(capitalize(producto.atributo1));
    setvalorNameAtributo2(capitalize(producto.atributo2));
    setisLoading(false);
  };

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const CerrarSession = async () => {
    const keys = await AsyncStorage.getAllKeys();
    await AsyncStorage.multiRemove(keys);
    signOut();
  };
  useEffect(() => {
    //console.log("Entre al useEffect DetalleProductoModal.js");
    ////console.log('con id producto', props.idproducto);
    if (props.idproducto !== "") {
      LeerDatos();
      ObtenerProducto();
    } else {
      //console.log("id producto no hago nada");
    }
  }, [props.idproducto]);

  const agregarProductoID = async () => {
    const userGuest = await AsyncStorage.getItem("userGuest");
    if (userGuest === "SI") {
      setVisible(true);
      return;
    }
    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");
    let IDQuodom = valorQuodomID;

    if (
      IDQuodom === "0" ||
      IDQuodom === "" ||
      IDQuodom === null ||
      IDQuodom === undefined
    ) {
      //SL Va a un QUODOM nuevo a crearlo!
      const retorno = await CrearQuodom(token, "Nuevo Quodom", null);
      //console.log("retorno", retorno);
      if (retorno.res) {
        IDQuodom = retorno.idquodom;
        await AsyncStorage.setItem("UltimoQuodom", IDQuodom);
      } else {
        setisLoading(false);
        toast.show({
          id,
          title: "Producto Agregado",
          variant: "solid",
          description: "No se pudo crear el Quodom",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
        return;
      }
    } else {
      await AsyncStorage.setItem("UltimoQuodom", IDQuodom);
    }

    //Agrego el item
    let codigoProducto = parseInt(props.idproducto);
    let signCreado = SHA256crypt.sha256(
      IDQuodom + codigoProducto + parseInt(cant) + auth.claveprivada
    );
    let datainsercion = {
      idquodom: IDQuodom,
      idproducto: codigoProducto,
      cantidad: parseInt(cant),
      nombreProducto: props.nombreProductoID,
      atributo1: valorAtributo1,
      atributo2: valorAtributo2,
      appSign: signCreado,
    };
    ////console.log('DATA', datainsercion);

    const postsData = await InsertarDetalleQuodom(token, datainsercion);
    ////console.log('RES InsertarDetalleQuodom: ', postsData);
    if (postsData.res) {
    } else {
      toast.show({
        id,
        title: "Producto Agregado",
        variant: "solid",
        description: "El producto no se pudo agregar al quodom " + IDQuodom,
        placement: "bottom",
        style: { backgroundColor: "#2ECC71" },
      });
      //console.log("ERROR".postsData.message);
      setisLoading(false);
      return;
    }

    setisLoading(false);
    props.actualizarLista(props.idproducto, IDQuodom);
    //setModalVisible(false);
    toast.show({
      id,
      title: "Producto Agregado",
      variant: "solid",
      description: "El producto fue cargado correctamente",
      placement: "bottom",
      style: { backgroundColor: "#2ECC71" },
    });
  };

  function getvalorAtributo1(valor) {
    ////console.log(valor);
    setvalorAtributo1(valor);
    //props.selectedatr1(valor);
  }

  function getvalorAtributo2(valor) {
    ////console.log(valor);
    setvalorAtributo2(valor);
    //props.selectedatr2(valor);
  }

  function getvalorQuodom(valor) {
    ////console.log('Select Quodom', valor);
    setvalorQuodomID(valor);
    //props.selectedatr2(valor);
  }

  return (
    <View style={basico}>
      {isLoading ? (
        <VStack bg="#fff" h="20" roundedBottom={5}>
          <Center flex="1">
            <ActivityIndicator size="small" color="#706F9A" />
          </Center>
        </VStack>
      ) : (
        <VStack space={3} bg="#fff" roundedBottom={5} borderWidth={0}>
          <HStack
            pl={5}
            pr={5}
            pt={4}
            pb={0}
            alignItems="center"
            space={0}
            borderWidth="0"
          >
            <VStack borderWidth="0" w="50%">
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={QuodomDetalle.itemValorCampoCat}
              >
                Cantidad:
              </Text>
            </VStack>

            <VStack style={{ alignItems: "flex-end", flex: 1 }} borderWidth={0}>
              <Input
                w="90%"
                rounded={20}
                h="10"
                //borderColor='transparent'
                bg="#fff"
                borderColor="#45444C"
                color="#45444C"
                _focus={{
                  backgroundColor: "gray.200",
                  borderColor: "#6604c9",
                }}
                selectionColor="gray.200"
                textAlign="center"
                value={cant}
                onChangeText={(text) => {
                  setcant(text);
                }}
              ></Input>
            </VStack>
          </HStack>

          {atr1 ? (
            <HStack pl={5} pr={5} alignItems="center" space={0} borderWidth="0">
              <VStack borderWidth="0" w="25%">
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.itemValorCampoCat}
                >
                  {valorNameAtributo1}:
                </Text>
              </VStack>
              <SelectAtributoModal
                DP={true}
                idproducto={props.idproducto}
                atributoNombre={valorNameAtributo1}
                valordelpicker={getvalorAtributo1}
              />
            </HStack>
          ) : null}

          {atr2 ? (
            <HStack pl={5} pr={5} alignItems="center" space={0} borderWidth="0">
              <VStack borderWidth="0" w="25%">
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.itemValorCampoCat}
                >
                  {valorNameAtributo2}:
                </Text>
              </VStack>
              <SelectAtributoModal
                DP={true}
                idproducto={props.idproducto}
                atributoNombre={valorNameAtributo2}
                valordelpicker={getvalorAtributo2}
              />
            </HStack>
          ) : null}
          <HStack pl={5} pr={5} pt={0} pb={0}>
            <Divider></Divider>
          </HStack>

          <HStack bg="#fff" pl={5} pr={5} pt={0} pb={0}>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={3}
              style={StyleSelectCategoria.TextoModalItalic}
            >
              Podés completar las caractarísticas ahora o agregar el producto
              vacio y después completar todo junto.
            </Text>
          </HStack>
          {mostrarQuodom ? (
            <View>
              <HStack
                pl={5}
                pr={5}
                pt={0}
                pb={0}
                bg="#fff"
                alignItems="center"
                space={0}
                borderWidth="0"
              >
                <VStack borderWidth="0" w="25%">
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.itemValorCampoCat}
                  >
                    Agregar en:
                  </Text>
                </VStack>
                <SelectQuodom
                  Default={quodomID}
                  valordelpicker={getvalorQuodom}
                />
              </HStack>

              {quodomID === "" ||
              quodomID === null ||
              quodomID === undefined ? (
                <HStack bg="#fff" pl={5} pr={5} pt={0} pb={0}>
                  <Text style={StyleSelectCategoria.TextoModalItalic}>
                    Se te creará un Quodom nuevo para agregar los productos.
                  </Text>
                </HStack>
              ) : null}
            </View>
          ) : null}
          <HStack
            bg="#706F9A"
            pl={5}
            pr={5}
            pt={4}
            pb={4}
            roundedBottom={5}
            justifyContent="center"
            alignItems="center"
            space={0}
            borderWidth="0"
          >
            <TouchableOpacity
              style={{
                alignItems: "center",
                backgroundColor: "#fff",
                padding: 10,
                borderRadius: 5,
                width: "90%",
              }}
              onPress={() => {
                agregarProductoID();
              }}
            >
              <Text style={StylePerfil.TextoButtonGenerico2}>AGREGAR</Text>
            </TouchableOpacity>
          </HStack>
        </VStack>
      )}

      <Modal
        visible={visible}
        //onDismiss={hideModal}
        contentContainerStyle={containerStyle}
      >
        <HStack
          pl={5}
          pr={5}
          pt={4}
          pb={4}
          w="100%"
          bg="#706F9A"
          justifyContent="space-between"
          roundedTop={5}
        >
          <VStack space={1}>
            <Text style={StyleSelectCategoria.TituloModal15}>
              Para continuar debes ingresar a QUODOM.
            </Text>
          </VStack>
          {/* <TouchableOpacity onPress={hideModal}>
                        <VStack alignItems="flex-start" borderWidth={0}>
                            <Ionicons name="close-outline" size={25} color="#fff" />
                        </VStack>
                    </TouchableOpacity> */}
        </HStack>
        <VStack
          bg="#fff"
          p="10"
          roundedBottom={5}
          justifyContent="center"
          alignItems="center"
          space={5}
          borderWidth="0"
        >
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              signOut();
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico}>Ingresar</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              props.navigation.navigate("SignUpScreen");
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico}>Crear Cuenta</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "trasparent",
              borderColor: "#706F9A",
              borderWidth: 1,
              padding: 15,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              setVisible(false);
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico2}>Cerrar</Text>
          </TouchableOpacity>
        </VStack>
      </Modal>
    </View>
  );
};
export default DetalleProducto;
