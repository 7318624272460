import React, { useState } from "react";
import { View, Text } from "react-native";
//import { useNavigation } from "@react-navigation/native";
import { HStack, VStack, Divider } from "native-base";
import { QuodomDetalle, DetalleProductoQuodomstyle } from '../../styles/StyleQuodom';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import NumberFormat from 'react-number-format';


const ResumenRenderItem = (props) => {


    const [nombreAtributo1_comprador, setnombreAtributo1_comprador] = useState(props.nombreAtributo1);
    const [nombreAtributo2_comprador, setnombreAtributo2_comprador] = useState(props.nombreAtributo2);

    const [nombreAtributo1_vendor, setvalorNameAtributo1] = useState(props.vendedor_nombreAtributo1);
    const [nombreAtributo2_vendor, setvalorNameAtributo2] = useState(props.vendedor_nombreAtributo2);

    const atr1_comprador = ((nombreAtributo1_comprador !== '') && (nombreAtributo1_comprador !== null));
    const atr2_comprador = ((nombreAtributo2_comprador !== '') && (nombreAtributo2_comprador !== null));

    const atr1_vendor = ((nombreAtributo1_vendor !== '') && (nombreAtributo1_vendor !== null));
    const atr2_vendor = ((nombreAtributo2_vendor !== '') && (nombreAtributo2_vendor !== null));

    function capitalize(string) {
        if (string !== '' && string !== null)
            try {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            }
            catch (err) {
                return
            }
    }


    const completo = (atributo1, atributo2, valorUnitario) => {
        if (valorUnitario) {
            valorUnitario = parseFloat(valorUnitario);
        }

        if (nombreAtributo1_vendor && nombreAtributo2_vendor) {
            if ((atributo1) && (atributo2) && ((valorUnitario !== 0 && valorUnitario !== null))) {
                return true
            }
            else {
                return false
            }
        }

        if (!nombreAtributo2_vendor && nombreAtributo1_vendor) {
            if ((atributo1) && (valorUnitario !== 0 && valorUnitario !== null)) {
                return true
            }
            else {
                return false
            }
        }

        if (!nombreAtributo2_vendor && !nombreAtributo1_vendor) {
            if (valorUnitario !== 0 && valorUnitario !== null) {
                return true
            }
            else {
                return false
            }
        }
    }

    if (completo(props.vendedor_atributo1, props.vendedor_atributo2, props.preciounitario)) {
        return (
            <View>
                <HStack pt='0' pb='1' borderWidth={0}>
                    <HStack w='100%' justifyContent='flex-start' space={0} borderWidth='0'>

                        <VStack justifyContent='center' w='52%' borderWidth={0}>
                            <Text style={DetalleProductoQuodomstyle.textoTituloCotizadoResumen2}>{props.nombreproducto}</Text>
                            {atr1_comprador ?
                                <HStack w='95%'>
                                    <Text textAlign='center' style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}>{capitalize(props.nombreAtributo1)}: <Text style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}> {props.atributo1}</Text></Text>
                                </HStack>
                                : null
                            }
                            {atr2_comprador ?
                                <HStack w='95%'>
                                    <Text textAlign='center' style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}>{capitalize(props.nombreAtributo2)}:  <Text style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}> {props.atributo2}</Text></Text>
                                </HStack>
                                : null
                            }

                            {atr1_vendor ?
                                <HStack w='95%'>
                                    <Text textAlign='center' style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}>{capitalize(props.vendedor_nombreAtributo1)}: <Text style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}> {props.vendedor_atributo1}</Text></Text>
                                </HStack>
                                : null
                            }

                            {atr2_vendor ?
                                <HStack w='95%'>
                                    <Text textAlign='center' style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}>{capitalize(props.vendedor_nombreAtributo2)}: <Text style={{ fontSize: 13, fontFamily: 'Montserrat_300Light' }}> {props.vendedor_atributo2}</Text></Text>
                                </HStack>
                                : null
                            }
                        </VStack>

                        <Divider orientation="vertical" mx="2" bg="muted.300" />

                        <VStack justifyContent='center' borderWidth={0} w='42%' >
                            {props.enviovendedor ?
                                <HStack alignItems='center' space={1} justifyContent='space-between'>
                                    {/* <Text style={QuodomDetalle.TextoPrecioResumen2}>${props.preciounitario}</Text> */}

                                    <NumberFormat
                                        value={props.preciounitario}
                                        displayType="text"
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        decimalSeparator={","}
                                        prefix="$"
                                        renderText={(value) => <Text style={QuodomDetalle.TextoPrecioResumen2}>{value}</Text>}
                                    />

                                    <HStack space={1} w='50%' bg='#fff' justifyContent='center' alignItems='center' style={{ textAlign: 'center', padding: 5, borderWidth: 1, borderColor: '#4D4D4D', shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5 }}>
                                        <MaterialCommunityIcons name="truck-outline" size={15} color='#4D4D4D' />
                                        <Text style={{ color: '#4D4D4D', fontSize: 11, fontFamily: 'Prompt_300Light' }} >Envio</Text>
                                    </HStack>
                                </HStack>
                                :
                                <HStack alignItems='center' space={1} justifyContent='space-between'>
                                    {/*  <Text style={QuodomDetalle.TextoPrecioResumen2}>${props.preciounitario}</Text> */}
                                    <NumberFormat
                                        value={props.preciounitario}
                                        displayType="text"
                                        thousandSeparator={"."}
                                        decimalScale={2}
                                        decimalSeparator={","}
                                        prefix="$"
                                        renderText={(value) => <Text style={QuodomDetalle.TextoPrecioResumen2}>{value}</Text>}
                                    />

                                    <HStack space={1} w='50%' bg='#fff' justifyContent='center' alignItems='center' style={{ textAlign: 'center', padding: 5, borderWidth: 1, borderColor: '#4D4D4D', shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5 }}>
                                        <MaterialIcons name="storefront" size={15} color='#4D4D4D' />
                                        <Text style={{ color: '#4D4D4D', fontSize: 11, fontFamily: 'Prompt_300Light' }}>Retira</Text>
                                    </HStack>
                                </HStack>
                            }
                        </VStack>
                    </HStack>
                </HStack>
                <Divider my={2} />
            </View>
        );
    }
    else {
        return null;
    }
};
export default ResumenRenderItem;
