import React, { useEffect } from "react";
import { View, TouchableOpacity, BackHandler } from "react-native";
import { Alert, VStack, Divider, Text } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import { hp } from "../../styles/responsive";
import { useNavigation, useRoute } from "@react-navigation/native";

export const SuccessScreenOferta = () => {
  const navigation = useNavigation();
  const route = useRoute();

  const { vengode } = route.params !== undefined ? route.params : [""];

  const volver = () => {
    //console.log("vengo de ", vengode);
    if (vengode === "NOT") {
      navigation.navigate("Notif");
    } else {
      navigation.navigate("OfertasList");
    }
    return true;
  };

  useEffect(() => {
    const backAction = () => {
      volver();
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  return (
    <VStack flex={1} bg="#2ECC71">
      <View style={{ flex: 1, alignContent: "center" }}>
        <VStack
          mt={hp(20)}
          space={3}
          bg="#2ECC71"
          alignItems="center"
          justifyContent="space-between"
        >
          <VStack alignItems="center" space={2}>
            <Ionicons
              name="ios-checkmark-circle-outline"
              size={120}
              color="#fff"
            />
            <Text style={QuodomDetalle.TextoModalSuccess}>
              ¡Cotización enviada con éxito!
            </Text>
          </VStack>

          <Divider my={2} bg="#52BE80" />
          <VStack pl="5" pr="5" justifyContent="flex-start" space={2}>
            <Text style={QuodomDetalle.TextoModalSuccessTip}>
              Recibirás una notificación inmediata cuando elijan tus productos.
            </Text>
          </VStack>
        </VStack>
      </View>
      <VStack
        pb="5"
        pl="2"
        pr="2"
        safeAreaBottom
        space={2}
        justifyContent="flex-start"
      >
        <VStack>
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 20,
              borderRadius: 5,
            }}
            onPress={() => volver()}
          >
            <Text style={QuodomDetalle.TextoModalSuccessButton}>¡Listo!</Text>
          </TouchableOpacity>
        </VStack>
      </VStack>
    </VStack>
  );
};

export default () => {
  return <SuccessScreenOferta />;
};
