import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Box,
  useToast,
  ScrollView,
  FlatList,
  Input,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  LogBox,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getMisCalificaciones } from "../../api";

import { wp, hp, moderateScale } from "../../styles/responsive";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";
import { Snackbar } from "react-native-paper";
import Moment from "moment";
import "moment/locale/es";
import { Rating, AirbnbRating } from "react-native-ratings";

const CalificacionesScreen = () => {
  const mounted = useRef(true);
  const route = useRoute();

  const isFocused = useIsFocused();
  const [postData, setpostData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const navigation = useNavigation();
  const [refreshing, setRefreshing] = useState(false);

  //const [refreshPage, setRefreshPage] = useState(false);

  LogBox.ignoreLogs([
    "Non-serializable values were found in the navigation state",
  ]);

  const loadCalificaciones = async () => {
    //console.log('Entre al loadCalificaciones CalificacionesScreen.js');
    const token = await AsyncStorage.getItem("userToken");
    const data = await getMisCalificaciones(token);
    ////console.log(data);
    if (mounted.current) {
      setpostData(data);
      setisLoading(false);
    }
    //SL Aca las marco como leidas todas
    //UpdateNotificacionesAll(token);
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(500).then(() => {
      loadNotificaciones();
      setRefreshing(false);
    });
  }, []);

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  function desde(fecha) {
    Moment.locale("es");
    return Moment(fecha, "YYYY-MM-DD h:mm:ss").fromNow();
  }

  useEffect(() => {
    mounted.current = true;
    //console.log('Entre al useEffect CalificacionesScreen.js');
    loadCalificaciones();
    return () => (mounted.current = false);
  }, [isFocused]);

  const footerlist = () => {
    return <Center h="40"></Center>;
  };

  return (
    <View style={{ flex: 1 }}>
      <HStack
        bg={quodomTheme.COLORS["DEFAULT"]}
        px={2}
        py={5}
        mb="0"
        justifyContent="space-between"
        alignItems="center"
        shadow={2}
      >
        <VStack space={4} borderWidth={0}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack space={2} alignItems="center">
              <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
              <Text style={QuodomDetalle.textoNavigate}>
                Mis calificaciones
              </Text>
            </HStack>
          </TouchableOpacity>
        </VStack>
      </HStack>

      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <VStack w={wp(100)} style={{ flex: 1 }} backgroundColor="#F2F2F2">
          <VStack
            justifyContent="center"
            backgroundColor="#fff"
            h="24"
            borderWidth={0}
          >
            <HStack alignItems="center" justifyItems="center" space={1}>
              <Text
                h="20"
                borderWidth={0}
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={StylePerfil.textoRating}
              >
                {" "}
                {route.params.calificacion.calificacion}
              </Text>
              <VStack>
                <Rating
                  showRating={false}
                  //style={{ paddingTop: 2 }}
                  //tintColor={'#f2f2f2'}
                  ratingCount={5}
                  imageSize={40}
                  isDisabled={true}
                  startingValue={route.params.calificacion.calificacion}
                  readonly={true}
                />
                {/*  <AirbnbRating
                                        showRating={false}
                                        tintColor='#fff'
                                        ratingCount={5}
                                        size={30}
                                        isDisabled={true}
                                        defaultRating={route.params.calificacion.calificacion}
                                        readonly={true}
                                    /> */}
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.textoItemNotificacion}
                >
                  {" "}
                  Promedio entre {
                    route.params.calificacion.cantcalificaciones
                  }{" "}
                  calificaciones.
                </Text>
              </VStack>
            </HStack>
          </VStack>

          <HStack style={StylePerfil.infoContainer} space={2}>
            <HStack flex={1} w={wp(100)}>
              <FlatList
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                }
                data={postData}
                //style={{  borderRadius: 2, shadow: 2}}
                //contentContainerStyle={{ flexGrow: 1, paddingBottom: 5 }}
                ListFooterComponent={footerlist}
                renderItem={({ item, index }) => (
                  <Box
                    borderBottomWidth="1"
                    _dark={{
                      borderColor: "gray.600",
                    }}
                    borderColor="coolGray.200"
                    pl="5"
                    pr="5"
                    py="3"
                    bg="#fff"
                  >
                    <HStack space={2} alignItems="center">
                      {/*  <Ionicons name="information-circle-outline" size={30} color="black" /> */}
                      <VStack w="95%" borderWidth={0}>
                        {/* <HStack justifyContent='space-between'> */}
                        <Text style={QuodomDetalle.textoItemFecha}>
                          {desde(item.createdAt)}
                        </Text>
                        <HStack
                          alignItems="center"
                          justifyItems="center"
                          space={0}
                        >
                          <AirbnbRating
                            showRating={false}
                            tintColor="#fff"
                            ratingCount={5}
                            size={10}
                            isDisabled={true}
                            defaultRating={item.valor}
                            readonly={true}
                          />
                          <Text
                            adjustsFontSizeToFit={true}
                            numberOfLines={1}
                            style={QuodomDetalle.textoItemValor}
                          >
                            {" "}
                            ({item.valor})
                          </Text>
                        </HStack>

                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={2}
                          style={QuodomDetalle.textoItemUbicacionBold}
                        >
                          {item.obs}
                        </Text>
                      </VStack>
                    </HStack>
                  </Box>
                )}
                keyExtractor={(item) => item.id.toString()}
              />

              {/*   } */}
            </HStack>
          </HStack>
        </VStack>
      )}
    </View>
  );
};

export default CalificacionesScreen;
