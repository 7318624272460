import React, { useState, useEffect, useRef } from "react"
import { TouchableOpacity, ActivityIndicator } from 'react-native';
import {
    Actionsheet,
    useDisclose,
    Icon,
    Center,
    Text,
    View,
    VStack,
    Modal,
    Button,
    HStack,
    Divider,
    useToast
} from "native-base"
import AsyncStorage from '@react-native-async-storage/async-storage';
import { MaterialIcons, MaterialCommunityIcons, Entypo, Ionicons } from "@expo/vector-icons";
import { QuodomDetalle } from '../../styles/StyleQuodom';
import { HEIGHT, hp, wp } from "../../styles/responsive";
import { useNavigation } from '@react-navigation/native';

import { EliminarDetalleQuodom, InsertarDetalleQuodom } from '../../api';

const SHA256crypt = require('js-sha256');
const auth = require('../../config/auth.config')

const MenuAcciones4 = (props) => {

    const { isOpen, onOpen, onClose } = useDisclose();
    const [modalVisibleDuplicar, setModalVisibleDuplicar] = useState(false);
    const [modalVisibleEliminar, setModalVisibleEliminar] = useState(false);


    const toast = useToast();
    const id = "id_toast"

    const colorDot = (estado) => {
        if (estado == 'CREADO') {
            return "#FFFFFF"

        }
        if (estado == 'ENVIADO') {
            return "#4E4D4D"
        }
        if (estado == 'COTIZADO') {
            return "#FFFFFF"
        }
        if (estado == 'FINALIZADO') {
            return "#FFFFFF"
        }
    }

    const Eliminar = async () => {
        setModalVisibleEliminar(false);
        const token = await AsyncStorage.getItem('userToken');
        EliminarDetalleQuodom(token, props.props.id);
        props.actualizarestado();
    }

    const DuplicarProducto = async () => { 
        props.actualizarestado();
        const value = await AsyncStorage.getItem('userToken');
        let signCreado = SHA256crypt.sha256(props.props.idquodom + props.props.idproducto + '1' + auth.claveprivada);
        let datainsercion = { idquodom: props.props.idquodom, idproducto: props.props.idproducto, cantidad: 1, nombreProducto: props.props.nombreProducto, appSign: signCreado }
        await InsertarDetalleQuodom(value, datainsercion);
        setModalVisibleDuplicar(false);
        props.actualizarestado();
    }


    return (
        <VStack flex={1}>
            <TouchableOpacity onPress={onOpen} style={{ padding: 5 }}>
                <Entypo name="dots-three-horizontal" size={24} color={colorDot(props.estado)} />
            </TouchableOpacity>

            <Actionsheet isOpen={isOpen} onClose={onClose} size="full">

                <Actionsheet.Content>
                    <Center>
                        <Text style={QuodomDetalle.textoTituloSeleccionarProveedor}>{props.nombre}</Text>
                    </Center>

                    <Actionsheet.Item onPress={() => setModalVisibleDuplicar(true)}>
                        <HStack alignItems='center' space={3}>
                            <Ionicons name="duplicate-outline" size={23} color='#45444C' />
                            <Text style={QuodomDetalle.textoMenuAcciones}>Duplicar</Text>
                        </HStack>
                    </Actionsheet.Item>

                    <Actionsheet.Item onPress={() => setModalVisibleEliminar(true)}>
                        <HStack alignItems='center' space={3}>
                            <Ionicons name="ios-trash-outline" size={23} color="#B03A2E" />
                            <Text style={QuodomDetalle.textoMenuAccionesEliminar}>Eliminar</Text>
                        </HStack>
                    </Actionsheet.Item>
                </Actionsheet.Content>
            </Actionsheet>


            <View>
                {/* MODAL CONFIRMACION DUPLICAR */}
                <Modal isOpen={modalVisibleDuplicar} onClose={() => setModalVisibleDuplicar(false)}>
                    <Modal.Content width={wp(95)}>
                        <Modal.CloseButton />
                        <Modal.Header>
                            <Text style={QuodomDetalle.TextoTituloModalEliminar}>Duplicar Producto</Text>
                        </Modal.Header>
                        <Modal.Body pt='5' pb='5'>
                            <Text style={QuodomDetalle.TextoModalEliminar}>Esta seguro de duplicar el producto <Text style={QuodomDetalle.TextoModalEliminarBold}>{props.nombreProducto}</Text> ?</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        backgroundColor: "transparent",
                                        padding: 10,
                                        borderRadius: 5,
                                        borderWidth: 1
                                    }}
                                    onPress={() => DuplicarProducto()}
                                >
                                    <Text style={QuodomDetalle.TextoModalButtonOK}>Si, duplicar</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        backgroundColor: "#706F9A",
                                        padding: 10,
                                        borderRadius: 5
                                    }}
                                    onPress={() => setModalVisibleDuplicar(!modalVisibleDuplicar)}
                                >
                                    <Text style={QuodomDetalle.TextoModalButtonCancelar}>Cancelar</Text>
                                </TouchableOpacity>

                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>

                {/* MODAL CONFIRMACION ELIMINAR */}
                <Modal isOpen={modalVisibleEliminar} onClose={() => setModalVisibleEliminar(false)}>
                    <Modal.Content width={wp(95)}>
                        <Modal.CloseButton />
                        <Modal.Header>
                            <Text style={QuodomDetalle.TextoTituloModalEliminar}>Atención!</Text>
                        </Modal.Header>
                        <Modal.Body pt='5' pb='5'>
                            <Text style={QuodomDetalle.TextoModalEliminar}>Esta seguro de eliminar el producto <Text style={QuodomDetalle.TextoModalEliminarBold}>{props.nombreProducto}</Text> ?</Text>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button.Group space={2}>
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        backgroundColor: "transparent",
                                        padding: 10,
                                        borderRadius: 5,
                                        borderWidth: 1
                                    }}
                                    onPress={() => Eliminar()}
                                >
                                    <Text style={QuodomDetalle.TextoModalButtonOK}>Eliminar</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    style={{
                                        alignItems: "center",
                                        backgroundColor: "#706F9A",
                                        padding: 10,
                                        borderRadius: 5
                                    }}
                                    onPress={() => setModalVisibleEliminar(!modalVisibleEliminar)}
                                >
                                    <Text style={QuodomDetalle.TextoModalButtonCancelar}>Cancelar</Text>
                                </TouchableOpacity>

                            </Button.Group>
                        </Modal.Footer>
                    </Modal.Content>
                </Modal>
            </View>
        </VStack>
    )
}

export default MenuAcciones4