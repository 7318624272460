import React from "react";
import {
  NativeBaseProvider,
  Box,
  Text,
  Heading,
  VStack,
  Input,
  Link,
  Button,
  HStack,
  useToast,
  Center,
} from "native-base";

import { ForgotPassApi } from "../api";

const ForgotPassword = () => {
  const [data, setData] = React.useState({
    email: "",
  });

  const toast = useToast();
  const id = "id_toast";

  const textInputChange = (val) => {
    if (val.trim().length >= 4) {
      setData({
        ...data,
        email: val,
        check_textInputChange: true,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        email: val,
        check_textInputChange: false,
        isValidUser: false,
      });
    }
  };

  const handleValidEmail = (val) => {
    if (val.trim().length >= 4) {
      setData({
        ...data,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        isValidUser: false,
      });
    }
  };

  const forgotHandle = async (email) => {
    let userInfo = {
      email: email,
    };

    if (data.email.length == 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description:
            "Por favor, introduce una dirección de correo electrónico válida",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return;
    }

    const foundUser = await ForgotPassApi(userInfo);

    //SL res == false es error muestro el message
    if (!foundUser.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: foundUser.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return;
    } else {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Cuenta verificada",
          status: "success",
          description: "El correo fue enviado, revise su casilla.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return;
    }
  };

  return (
    <NativeBaseProvider>
      <Center
        safeArea
        flex={1}
        p={2}
        w="100%"
        mx="auto"
        backgroundColor="#F6EE5D"
      >
        <Box flex={1} w="50%">
          <VStack space={3} mt={5} alignItems="center">
            <Text fontSize="lg" bold="true" color="#45444C">
              Recuperar clave
            </Text>
            <Input
              placeholder="Correo electronico"
              width="75%"
              fontSize="md"
              variant="filled"
              onChangeText={(val) => textInputChange(val)}
              onEndEditing={(e) => handleValidEmail(e.nativeEvent.text)}
            />

            <Button
              backgroundColor="#706F9A"
              width="75%"
              _text={{ color: "#F6EE5D", fontSize: "md" }}
              onPress={() => {
                forgotHandle(data.email);
              }}
            >
              Enviar
            </Button>

            <VStack space={2}></VStack>

            <HStack>
              <Text fontSize="xs" color="#45444C" fontWeight={400}>
                Protegido por re CAPTCHA -{" "}
              </Text>
              <Link
                _text={{ color: "#45444C", fontSize: "xs" }}
                justifyContent="center"
                href="#"
              >
                Privacidad
              </Link>
            </HStack>
          </VStack>
        </Box>
      </Center>
    </NativeBaseProvider>
  );
};

//export default ForgotPassword;

export default ForgotPassword;
