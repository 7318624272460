import React, { useState } from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  QuodomList_Card,
  QuodomDetalle,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  normalize,
} from "../../styles/StyleQuodom";
import * as Progress from "react-native-progress";
import { FontAwesome, Ionicons, Entypo } from "@expo/vector-icons";
import { HStack, VStack, Box, Center, Divider } from "native-base";
import MenuAcciones3 from "./MenuAcciones3";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { wp } from "../../styles/responsive";
import NodoQuodomCrear from "./NodoQuodomCrear";
import { UltimoQuodom } from "./../../api";

const NodoQuodomLinea = (props) => {
  const [esCardCreacion] = useState(props.id == undefined);
  const navigation = useNavigation();

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
  });

  var imagen = require("../../assets/line_zigzag_vertical.png");
  var iamgegris = require("../../assets/line_zigzag_gris_vertical.png");
  //const nombreQuodom = ''

  function nombreQuodomEdit(texto) {
    if (texto.length > 20) {
      return texto.slice(0, 24) + " ...";
    } else {
      return texto;
    }
  }

  function desde(fecha) {
    switch (fecha) {
      case 3: {
        return "72hs";
      }
      case 2: {
        return "48hs";
      }
      case 1: {
        return "24hs";
      }
      default: {
        return fecha + " días";
      }
    }
  }

  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  const obtenerEstilo = (estado) => {
    switch (estado) {
      case "CREADO": {
        if (mostrarPorcentaje(props.porccompletado) === 100) {
          return QuodomList_Card.itemContainerCompleto;
        }
        return QuodomList_Card.itemContainer;
      }

      case "ENVIADO": {
        return QuodomList_Card.itemContainerEnviado;
      }

      case "COTIZADO": {
        return QuodomList_Card.itemContainerCotizado;
      }

      case "CERRADO": {
        return QuodomList_Card.itemContainerCerrado;
      }

      case "FINALIZADO": {
        return QuodomList_Card.itemContainerComprado;
      }

      case "VENCIDO": {
        return QuodomList_Card.itemContainerVencido;
      }
    }
  };

  const obtenerColor = () => {
    if (mostrarPorcentaje(props.porccompletado) === 100) {
      return "#2dab66";
    }
    return "#F6EE5D";
  };

  const mostrarPorcentaje = (porcentaje) => {
    return parseInt(porcentaje * 100);
  };

  var Estado = "";
  var Icono = "";

  switch (props.estado) {
    case "CREADO": {
      if (mostrarPorcentaje(props.porccompletado) === 100) {
        Estado = "COMPLETO";
        Icono = "checkmark-outline";
        break;
      }
      Estado = "INCOMPLETO";
      Icono = "close";
      break;
    }
    case "ENVIADO": {
      Estado = "ENVIADO";
      break;
    }
    case "COTIZADO": {
      Estado = "LISTO";
      break;
    }
    case "COMPRADO": {
      Estado = "FINALIZADO";
      break;
    }
    case "CERRADO": {
      Estado = "CERRADO";
      break;
    }
  }

  const Navegar = async (estado) => {
    ////console.log("CAAAAAA", cotizacion);
    const jsonValue = JSON.stringify(props);
    await AsyncStorage.setItem("quodomSeleccionada", jsonValue);
    switch (estado) {
      case "CREADO": {
        navigation.navigate("DetalleQuodom", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          estadoQuodom: props.estado,
          iddireccion: props.iddireccion,
          cantproductos: props.cantproductos,
        });
        break;
      }
      case "ENVIADO": {
        navigation.navigate("DetalleQuodomEnviado", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          estadoQuodom: props.estado,
          iddireccion: props.iddireccion,
          fechaenvio: props.fechaenvio,
        });
        break;
      }
      case "COTIZADO": {
        navigation.navigate("DetalleQuodomCotizado", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          estadoQuodom: props.estado,
          iddireccion: props.iddireccion,
          fechaenvio: props.fechaenvio,
        });
        break;
      }
      case "FINALIZADO": {
        navigation.navigate("ResumenQuodomFinalizado", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          nro: props.nro,
          iddireccion: props.iddireccion,
          fechapago: props.fechapago,
        });
        break;
      }
      case "CERRADO": {
        navigation.navigate("ResumenQuodomFinalizado", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          nro: props.nro,
          iddireccion: props.iddireccion,
          fechapago: props.fechapago,
        });
        break;
      }

      case "VENCIDO": {
        navigation.navigate("DetalleQuodomVencido", {
          idquodom: props.id,
          nombreQuodom: props.descripcion,
          estadoQuodom: props.estado,
          iddireccion: props.iddireccion,
          actualizarestado: props.actualizarestado,
          toTop: props.toTop,
        });
        break;
      }
    }
  };

  if (!fontsLoaded) {
    //return <AppLoading />;
    return null;
  } else {
    return (
      <View style={{ paddingTop: 10, width: "100%" }}>
        {esCardCreacion ? null : (
          <HStack style={obtenerEstilo(props.estadoCalc)} pr="5" pl="5">
            <View
              style={{
                flex: 1,
                position: "absolute",
                top: 1,
                right: 9,
                zIndex: 9999999,
              }}
            >
              <MenuAcciones3
                idquodom={props.id}
                estado={props.estado}
                actualizarestado={props.actualizarestado}
                nombre={props.descripcion}
                iddireccion={props.iddireccion}
                cantproductos={props.cantproductos}
                completado={mostrarPorcentaje(props.porccompletado)}
                estadoCalc={props.estadoCalc}
              />
            </View>

            <TouchableOpacity
              style={{ width: "100%" }}
              onPress={() => Navegar(props.estadoCalc)}
            >
              {props.estado === "CREADO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text style={QuodomList_Card.itemfechavencimientoenvio}>
                        {CortarFecha(props.createdAt)}{" "}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      //  w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcion}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={imagen}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack justifyContent="flex-start" alignItems="center">
                      <Ionicons name={Icono} size={18} color="white" />
                      <Text style={QuodomDetalle.itemTextoEstado}>
                        {Estado}
                      </Text>
                    </HStack>
                    <VStack justifyContent="flex-start">
                      <HStack space={2} marginBottom={"2%"}>
                        <Progress.Bar
                          progress={parseFloat(props.porccompletado)}
                          animated={true}
                          color={obtenerColor()}
                          unfilledColor="#B1AFAF"
                          height={"100%"}
                          width={wp(7)}
                        />

                        <Text style={QuodomList_Card.itemTextoBarraProgreso}>
                          {mostrarPorcentaje(props.porccompletado)}%{" "}
                        </Text>
                      </HStack>
                      {mostrarPorcentaje(props.porccompletado) === 100 ? (
                        <HStack borderWidth={0}>
                          <Text>
                            <Text
                              adjustsFontSizeToFit={true}
                              numberOfLines={1}
                              style={QuodomList_Card.itemListosDetallesAmarillo}
                            >
                              Listo para enviar
                            </Text>
                          </Text>
                        </HStack>
                      ) : (
                        <HStack borderWidth={0}>
                          <Text>
                            <Text
                              adjustsFontSizeToFit={true}
                              numberOfLines={1}
                              style={QuodomList_Card.itemFaltanDetalles}
                            >
                              {" "}
                              Faltan detalles ¡completalos!
                            </Text>
                          </Text>
                        </HStack>
                      )}
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}

              {props.estadoCalc === "ENVIADO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text
                        style={QuodomList_Card.itemfechavencimientoenvioEnviado}
                      >
                        {CortarFecha(props.createdAt)}{" "}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      //   w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcionEnviado}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={iamgegris}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack
                      pt="1"
                      space={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Text style={QuodomDetalle.itemTextoEstadoEnviado}>
                        ¡{Estado}!
                      </Text>
                    </HStack>

                    <VStack justifyContent="flex-start">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={QuodomList_Card.itemTextoEnviado}
                      >
                        Preparando tus presupuestos.
                      </Text>
                      <HStack pt="1" space={2}>
                        <Entypo name="stopwatch" size={18} color="#4E4D4D" />
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomList_Card.itemTextoEnviado}
                        >
                          Quedan: {desde(props.diasparavencimientoenvio)}. |
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomList_Card.itemTextoEnviadoCantidad}
                        >
                          Productos: {props.cantproductos}
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}

              {props.estadoCalc === "COTIZADO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text style={QuodomList_Card.itemfechavencimientoenvio}>
                        {CortarFecha(props.createdAt)}{" "}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      //  w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcion}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={imagen}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack
                      pt="1"
                      space={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Text style={QuodomDetalle.itemTextoEstadoCotizado}>
                        ¡LISTO!
                      </Text>
                      <Text style={QuodomList_Card.itemTextoFinalizado}>
                        {props.nro}
                      </Text>
                    </HStack>
                    <VStack justifyContent="flex-start">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={QuodomList_Card.itemTextoCotizado}
                      >
                        Cotizaciones disponibles
                      </Text>
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomList_Card.itemTextoCotizado}
                      >
                        ¡Seleccioná y comprá!
                      </Text>
                      <HStack
                        pt="1"
                        space={2}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Entypo name="stopwatch" size={20} color="#FFFFFF" />
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomList_Card.itemTextoCotizadoChico}
                        >
                          Tenés {desde(props.diasparavencimientoaceptacion)}{" "}
                          para finalizar tu compra
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}

              {props.estadoCalc === "FINALIZADO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text style={QuodomList_Card.itemfechavencimientoenvio}>
                        {CortarFecha(props.createdAt)}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      //  w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcion}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={imagen}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack
                      pt="1"
                      space={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Text style={QuodomDetalle.itemTextoEstado}>
                        <Ionicons
                          name="checkmark-outline"
                          size={18}
                          color="#fff"
                        />
                        FINALIZADO
                      </Text>
                      <Text style={QuodomList_Card.itemTextoFinalizado}>
                        {props.nro}
                      </Text>
                    </HStack>
                    <VStack justifyContent="flex-start">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomList_Card.itemTextoFinalizado2}
                      >
                        Compra realizada con exito!{" "}
                      </Text>
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomList_Card.itemTextoFinalizado}
                      >
                        Mira los estados de pedidos.
                      </Text>
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}

              {props.estadoCalc === "CERRADO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text
                        style={QuodomList_Card.itemfechavencimientoenvioCerrado}
                      >
                        {CortarFecha(props.createdAt)}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      // w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcionCerrado}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={imagen}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack
                      pt="1"
                      space={2}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Text style={QuodomDetalle.itemTextoCerrado}>
                        <Ionicons
                          name="checkmark-outline"
                          size={18}
                          color="#4E4D4D"
                        />
                        FINALIZADO
                      </Text>
                      <Text style={QuodomList_Card.itemTextoCerradoGrande}>
                        {props.nro}
                      </Text>
                    </HStack>

                    <VStack justifyContent="flex-start">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomList_Card.itemTextoFinalizado}
                      >
                        Compra realizada el {props.fechapago}{" "}
                      </Text>

                      <HStack>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={2}
                          style={{
                            textAlign: "center",
                            padding: 5,
                            color: "#fff",
                            fontSize: 14,

                            borderWidth: 1,
                            borderColor: "#fff",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 5,
                          }}
                        >
                          Repetir
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}

              {props.estadoCalc === "VENCIDO" ? (
                <HStack
                  style={{
                    borderWidth: 0,
                    height: wp(8),
                    justifyContent: "space-between",
                  }}
                >
                  <VStack
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      marginLeft: "5px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "1.5%",
                    }}
                  >
                    <HStack w={wp(35)}>
                      <Text
                        style={QuodomList_Card.itemfechavencimientoenvioCerrado}
                      >
                        {CortarFecha(props.createdAt)}
                      </Text>
                    </HStack>

                    <HStack
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      overflow="hidden"
                      //   w={wp(34)}
                      h="16"
                      maxH="16"
                    >
                      <Text style={QuodomList_Card.itemDescripcionCerrado}>
                        {nombreQuodomEdit(props.descripcion)}
                      </Text>
                    </HStack>
                  </VStack>
                  <View
                    style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }}
                  >
                    <Image
                      source={imagen}
                      style={{ width: 10, height: "80%", marginTop: 10 }}
                      resizeMode="contain"
                    ></Image>
                  </View>
                  <VStack
                    style={{
                      width: "55%",
                      marginTop: "15px",
                      paddingLeft: "2%",
                      paddingBottom: "0.5%",
                      paddingTop: "0.5%",
                    }}
                  >
                    <HStack justifyContent="flex-start" alignItems="center">
                      <Ionicons name="close" size={18} color="white" />
                      <Text style={QuodomDetalle.itemTextoEstado}>VENCIDO</Text>
                    </HStack>

                    <VStack justifyContent="flex-start">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={QuodomList_Card.itemTextoAmarillo}
                      >
                        Pasaron las 72hs y se venció tu pedido.
                      </Text>
                      <HStack>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={2}
                          style={{
                            textAlign: "center",
                            padding: 5,
                            color: "#F6EE5D",
                            fontSize: 11,

                            borderWidth: 1,
                            borderColor: "#fff",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 5,
                          }}
                        >
                          ¡Repetilo!
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
              ) : null}
            </TouchableOpacity>
          </HStack>
        )}
      </View>
    );
  }
};

export default NodoQuodomLinea;
