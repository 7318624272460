import React, { useState } from "react";
import { Switch } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  View,
  Divider,
  HStack,
  Input,
} from "native-base";

import { StyleProfileScreen } from "../../styles/StyleQuodom";

import { MaterialCommunityIcons } from "@expo/vector-icons";
import { hp, moderateScale, wp } from "../../styles/responsive";

const FiltrosCotizaciones = (props) => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  return (
    <View>
      <Center flex={1}>
        <Box alignItems="flex-end">
          <Menu
            borderRadius="8"
            closeOnSelect={false}
            //bg='#fff'

            p="0"
            borderWidth="0"
            borderColor="#52575D"
            shadow={5}
            //w={wp(50)}
            placement="bottom left"
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  {({ isPressed }) => {
                    return (
                      <Box
                        p="0"
                        rounded="0"
                        width={"150px"}
                        style={{
                          transform: [
                            {
                              scale: isPressed ? 0.95 : 1,
                            },
                          ],
                        }}
                      >
                        <HStack
                          space={0}
                          alignItems="center"
                          style={{
                            textAlign: "center",
                            padding: 5,
                            borderWidth: 1,
                            borderColor: "#fff",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 4,
                          }}
                        >
                          <MaterialCommunityIcons
                            name="filter"
                            size={moderateScale(6)}
                            color="#fff"
                          />
                          <Text
                            style={{
                              fontSize: 18,
                              color: "#fff",
                              paddingLeft: "30px",
                            }}
                          >
                            Filtros
                          </Text>
                        </HStack>
                      </Box>
                    );
                  }}
                </Pressable>
              );
            }}
          >
            <Menu.OptionGroup
              defaultValue="todos"
              title="Vencimientos"
              type="radio"
            >
              <Menu.ItemOption
                value="todos"
                onPress={() => props.filtrar(4, "VENC")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>Todos</Text>
              </Menu.ItemOption>
              <Menu.ItemOption
                value="venc"
                onPress={() => props.filtrar(1, "VENC")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Vence 24hs
                </Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="venc1"
                onPress={() => props.filtrar(2, "VENC")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Vence 48hs
                </Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="venc2"
                onPress={() => props.filtrar(3, "VENC")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Vence 72hs
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>

            <Divider></Divider>

            <Menu.OptionGroup defaultValue="venc3" title="Ordenar" type="radio">
              <Menu.ItemOption
                value="venc3"
                onPress={() => props.filtrar(1, "ORDEN")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Fecha de vencimiento
                </Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="venc4"
                onPress={() => props.filtrar(2, "ORDEN")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Fecha de creación
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>

            <Divider></Divider>

            <Menu.OptionGroup title="Estados" type="radio">
              <Menu.ItemOption
                value="es"
                onPress={() => props.filtrar("TODOS", "ESTADO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>Todos</Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="es2"
                onPress={() => props.filtrar("CREADO", "ESTADO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Para cotizar
                </Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="es3"
                onPress={() => props.filtrar("COTIZADO", "ESTADO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>Cotizado</Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="es4"
                onPress={() => props.filtrar("VENDISTE", "ESTADO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>Vendido</Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="es5"
                onPress={() => props.filtrar("CERRADO", "ESTADO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Finalizado
                </Text>
              </Menu.ItemOption>

              {/*     <Menu.ItemOption value='ENVIO' onPress={() => //console.log("Eliminar()")} p='2' borderRadius='3'>
                                <HStack space={1} alignItems='center'>

                                    <Text style={StyleProfileScreen.textMenuFiltros}>Para enviar</Text>
                                    <Switch
                                        style={{ transform: [{ scaleX: 0.5 }, { scaleY: 0.5 }] }}
                                        trackColor={{ false: "#fff", true: "#81b0ff" }}
                                        //thumbColor={toggleCheckBox ? "#F6EE5D" : "#fff"}
                                        ios_backgroundColor="#ccc"
                                    //onValueChange={toggleSwitch}
                                    //value={toggleCheckBox}
                                    />
                                </HStack>
                            </Menu.ItemOption>

                            <Menu.ItemOption value='OTRO' onPress={() => //console.log("Eliminar()")} p='2' borderRadius='3'>
                                <HStack space={1} alignItems='center'>
                                    <Text style={StyleProfileScreen.textMenuFiltros}>Nombre</Text>
                                    <Input w={20}></Input>
                                </HStack>
                            </Menu.ItemOption>

                            <Menu.ItemOption value='OTRO2' onPress={() => //console.log("Eliminar()")} p='2' borderRadius='3'>
                                <Text style={StyleProfileScreen.textMenuFiltros}>Opcion 4</Text></Menu.ItemOption>

                            <Menu.ItemOption value='OTRO3' onPress={() => //console.log("Eliminar()")} p='2' borderRadius='3'>
                                <Text style={StyleProfileScreen.textMenuFiltros}>Opcion 5</Text></Menu.ItemOption> */}
            </Menu.OptionGroup>
          </Menu>
        </Box>
      </Center>
    </View>
  );
};

export default FiltrosCotizaciones;
