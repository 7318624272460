import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Input,
  useToast,
  FormControl,
  ScrollView,
  Image,
  Box,
} from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerProvincias,
  ObtenerLocalidad,
  ObtenerZonas,
  GuardarUsuario,
  ObtenerDataUsuario,
  ObtenerDireccionesUsuario,
} from "../../api";

import {
  StyleSigInScreen,
  QuodomDetalle,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { wp } from "../../styles/responsive";

import SelectProvinciaWizard from "../../components/MisQuodoms/Pickers/SelectProvinciaWizard";
import SelecLocalidadWizard from "../../components/MisQuodoms/Pickers/SelectLocalidadWizard";
import SelectZonaWizard from "../../components/MisQuodoms/Pickers/SelectZonasWizard";
import SelectDireccionesWizard from "./SelectDireccionesWizard";
import SelectBancosWizard from "./SelectBancosWizard";

const WizardVendedorScreen1 = () => {
  const mounted = useRef(true);

  const toast = useToast();
  const id = "id_toast";
  const navigation = useNavigation();

  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [postsDireccion, setDireccion] = useState("");
  const [iddireccionState, setiddireccionState] = useState();
  const [nuevaDireccion, setNuevaDireccion] = useState(false);
  const [mostrarDirecciones, setMostrarDirecciones] = useState(false);

  const [dataProv, setDataProv] = useState([]);
  const [dataLoc, setDataLoc] = useState([]);
  const [dataZonas, setDataZonas] = useState([]);

  const [addZona, setAddZona] = useState([]);

  const [prov, setProv] = useState("");
  const [loc, setLoc] = useState();

  const loadProfile = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const data = await ObtenerDataUsuario(value);

    const datosDireccion = await ObtenerDireccionesUsuario(value);
    ////console.log(datosDirecciony)
    if (datosDireccion.length === 0) {
      setMostrarDirecciones(false);
      setNuevaDireccion(true);
    } else {
      setMostrarDirecciones(true);
      let generico = {
        alias: "",
        direccion: "",
        localidad: "",
        id: 0,
      };
      datosDireccion.push(generico);
      if (mounted.current) {
        setDireccion(datosDireccion);
        setiddireccionState(datosDireccion[0].id);
      }
    }

    ////console.log(data);
    if (mounted.current) {
      setUser(data);
      setIsLoading(false);
    }
  };

  const loadProvincia = async () => {
    //const token = await AsyncStorage.getItem('userToken');
    const ProvData = await await ObtenerProvincias();

    if (mounted.current) {
      setDataProv(ProvData);
    }
  };

  function handleSelected(e) {
    if (e === 0) {
      setNuevaDireccion(true);
      setiddireccionState(e);
    } else {
      setiddireccionState(e);
      setNuevaDireccion(false);
    }
  }

  const loadLocalidad = async (prov) => {
    //const token = await AsyncStorage.getItem('userToken');
    const LocData = await ObtenerLocalidad(prov, "VENDEDOR");
    ////console.log(LocData)
    if (mounted.current) {
      setDataLoc(LocData);
    }
  };

  const loadZonas = async (loc) => {
    //const token = await AsyncStorage.getItem('userToken');
    const ZonasData = await ObtenerZonas(loc);

    /*    let generico = {
               nombrezona: 'Todos',
               id: 0,
           }
           ZonasData.push(generico); */

    if (mounted.current) {
      setDataZonas(ZonasData);
    }
  };

  const CpChange = (e) => {
    setAddZona({
      ...addZona,
      cp: e,
    });
  };

  const CalleChange = (e) => {
    setAddZona({
      ...addZona,
      calle: e,
    });
  };

  const NumeroChange = (e) => {
    setAddZona({
      ...addZona,
      numero: e,
    });
  };

  function handleSelectedProvincia(e) {
    setAddZona({
      ...addZona,
      idprovincia: e,
    });
    setProv(e);
    loadLocalidad(e);
  }

  function handleSelectedLocalidad(e) {
    //console.log(e);
    setAddZona({
      ...addZona,
      idlocalidad: e,
    });
    setLoc(e);
    loadZonas(e);
  }

  function handleSelectedZona(e) {
    ////console.log('zona', e);
    setAddZona({
      ...addZona,
      idzona: e,
    });
    //handleAddZona(parseInt(e));
  }

  const RazonSocialChange = (val) => {
    setUser({
      ...user,
      RazonSocial: val,
      ShowSlides: 1,
    });
  };

  const CuitChange = (val) => {
    setUser({
      ...user,
      cuit: val,
    });
  };

  const CodareaChange = (val) => {
    setUser({
      ...user,
      codArea: val,
    });
  };

  const TelefonoChange = (val) => {
    setUser({
      ...user,
      telefono: val,
    });
  };

  const BANK_CBUChange = (val) => {
    setUser({
      ...user,
      BANK_CBU: val,
    });
  };

  const BANK_NombreChange = (val) => {
    setUser({
      ...user,
      BANK_Nombre: val,
    });
  };

  const BANK_CuitChange = (val) => {
    setUser({
      ...user,
      BANK_CUIT: val,
    });
  };

  function handleSelectedBanco(e) {
    setUser({
      ...user,
      BANK_Banco: e,
    });
  }

  const SiguentePaso = async () => {
    toast.closeAll();
    if (
      user.cuit === "" ||
      user.cuit === null ||
      user.cuit === undefined ||
      user.RazonSocial === "" ||
      user.RazonSocial === null ||
      user.RazonSocial === undefined ||
      user.BANK_CBU === "" ||
      user.BANK_CBU === undefined ||
      user.BANK_Nombre === "" ||
      user.BANK_Nombre === undefined ||
      user.BANK_CUIT === "" ||
      user.BANK_CUIT === undefined ||
      user.BANK_Banco === "" ||
      user.BANK_Banco === undefined
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Atención
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Por favor, complete todos los campos del formulario.
                </Text>
              </Box>
            );
          },
        });
      }
      //setError(true);
      return;
    }
    if (user.cuit.length < 11) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Atención
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Por favor, revise el numero de CUIT.
                </Text>
              </Box>
            );
          },
        });
      }
      //setError(true);
      return;
    }
    ////console.log('ADDZONA', addZona.cp)
    if (nuevaDireccion) {
      if (
        addZona.cp === undefined ||
        addZona.calle === undefined ||
        addZona.numero === undefined ||
        addZona.idprovincia === undefined ||
        addZona.idlocalidad === undefined ||
        addZona.idzona === undefined
      ) {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            //placement: "bottom",
            render: () => {
              return (
                <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.TextoToastTitulo}
                  >
                    Atención
                  </Text>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={3}
                    style={QuodomDetalle.TextoToast}
                  >
                    Por favor, completar todos los campos de Dirección.
                  </Text>
                </Box>
              );
            },
          });
        }
        //setError(true);
        return;
      }
    }

    //SL Aca lo guardo sin habilitar el vendedor en  1
    setIsLoading(true);

    const token = await AsyncStorage.getItem("userToken");
    const UserRes = await GuardarUsuario(token, user);
    setIsLoading(false);
    if (!UserRes.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Algo salió mal
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  UserRes.message
                </Text>
              </Box>
            );
          },
        });
      }
      setIsLoading(false);
      return;
    }

    navigation.navigate("WizardVendedor2", { addZona: addZona });
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect del WizardVendedor1.js");
    loadProfile();
    loadProvincia();
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack flex={1}>
      {isLoading ? (
        <Center flex="1">
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <View
          style={{ flex: 1, backgroundColor: "#fff", alignContent: "center" }}
        >
          <HStack
            bg={quodomTheme.COLORS["COLORVIOLETA"]}
            px={2}
            py={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack space={4} alignItems="center">
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <HStack space={2} alignItems="center" pt="3">
                  <Ionicons name="arrow-back-sharp" size={25} color="white" />
                </HStack>
              </TouchableOpacity>
            </HStack>
          </HStack>

          <HStack
            bg={quodomTheme.COLORS["COLORVIOLETA"]}
            space={2}
            pl="15"
            pr="15"
            pb="3"
            mb="3"
            justifyContent="space-between"
            alignItems="center"
          >
            <VStack w={wp(90)}>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={QuodomDetalle.textoTituloMisCotizacion}
              >
                Completá el wizard y comenzá a vender.
              </Text>
              {/* <Text style={QuodomDetalle.textoTituloMisCotizacion}>Paso 1/3</Text> */}
            </VStack>
          </HStack>

          <Center>
            <VStack justifyContent="center">
              <HStack space={10} alignItems="flex-start">
                <Center borderWidth={0} w="24">
                  <Image
                    source={require("../../assets/Wizard/1activo.png")}
                    size={wp(15)}
                    alt="Datos de la empresa"
                  ></Image>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.textoChico2Bold}
                  >
                    Datos empresa
                  </Text>
                </Center>

                <Center borderWidth={0} w="24">
                  <Image
                    source={require("../../assets/Wizard/2noactivo.png")}
                    size={wp(15)}
                    alt="Categorias"
                  ></Image>
                  <Text style={QuodomDetalle.textoChico2}>Categorias</Text>
                </Center>

                <Center borderWidth={0} w="24">
                  <Image
                    source={require("../../assets/Wizard/3noactivo.png")}
                    size={wp(15)}
                    alt="Zonas"
                  ></Image>
                  <Text style={QuodomDetalle.textoChico2}>Zonas</Text>
                </Center>
              </HStack>
            </VStack>
          </Center>

          <ScrollView w="100%" borderWidth={0}>
            <View style={{ alignItems: "center" }}>
              <VStack space={4} pt={5} pb={3} alignItems="center">
                <HStack>
                  <Text style={StylePerfil.labelMon15A}>
                    Datos de la empresa
                  </Text>
                </HStack>

                <FormControl alignItems="flex-start">
                  <Text style={StylePerfil.labelMon13L}>
                    CUIT<Text style={StylePerfil.labelObligatorio}> *</Text>
                  </Text>
                  <Input
                    keyboardType="decimal-pad"
                    placeholder="Sin guiones, ni puntos."
                    width="90%"
                    style={StyleSigInScreen.textoLoginyPass}
                    rounded="8"
                    bgColor="#fff"
                    onChangeText={(val) => CuitChange(val)}
                    value={user.cuit}
                  />
                </FormControl>

                <FormControl alignItems="flex-start">
                  <Text style={StylePerfil.labelMon13L}>
                    Razón Social
                    <Text style={StylePerfil.labelObligatorio}> *</Text>
                  </Text>
                  <Input
                    placeholder="Como figura en la AFIP."
                    width="90%"
                    style={StyleSigInScreen.textoLoginyPass}
                    rounded="8"
                    bgColor="#fff"
                    onChangeText={(val) => RazonSocialChange(val)}
                    value={user.RazonSocial}
                  />
                </FormControl>

                <FormControl alignItems="flex-start">
                  <Text style={StylePerfil.labelMon13L}>
                    Teléfono<Text style={StylePerfil.labelObligatorio}> *</Text>
                  </Text>
                  <HStack space={1}>
                    <Input
                      placeholder="Cod. Area"
                      width="29%"
                      style={StyleSigInScreen.textoLoginyPass}
                      rounded="8"
                      bgColor="#fff"
                      onChangeText={(val) => CodareaChange(val)}
                      value={user.codArea}
                    />
                    <Input
                      placeholder="Numero"
                      width="60%"
                      style={StyleSigInScreen.textoLoginyPass}
                      rounded="8"
                      bgColor="#fff"
                      onChangeText={(val) => TelefonoChange(val)}
                      value={user.telefono}
                    />
                  </HStack>
                </FormControl>

                {/*   <HStack>
                                    <Text style={StylePerfil.labelMon15A}>Datos bancarios</Text>
                                </HStack>

                                <FormControl alignItems="flex-start">
                                    <Text style={StylePerfil.labelMon13L}>CBU/CVU<Text style={StylePerfil.labelObligatorio}> *</Text></Text>
                                    <Input keyboardType="decimal-pad" placeholder='Sin guiones, ni puntos.' width='90%'
                                        style={StyleSigInScreen.textoLoginyPass} rounded='8' bgColor='#fff'
                                        value={user.BANK_CBU}
                                        onChangeText={(val) => BANK_CBUChange(val)} />
                                </FormControl>



                                <FormControl alignItems="flex-start">
                                    <Text style={StylePerfil.labelMon13L}>Nombre y Apellido Titular<Text style={StylePerfil.labelObligatorio}> *</Text></Text>
                                    <Input keyboardType="decimal-pad"  width='90%'
                                        style={StyleSigInScreen.textoLoginyPass} rounded='8' bgColor='#fff'
                                        value={user.BANK_Nombre}
                                        onChangeText={(val) => BANK_NombreChange(val)} />
                                </FormControl>

                                <FormControl alignItems="flex-start">
                                    <Text style={StylePerfil.labelMon13L}>CUIT/CUIL<Text style={StylePerfil.labelObligatorio}> *</Text></Text>
                                    <Input keyboardType="decimal-pad" placeholder='Sin guiones, ni puntos.' width='90%'
                                        style={StyleSigInScreen.textoLoginyPass} rounded='8' bgColor='#fff'
                                        value={user.BANK_CUIT}
                                        onChangeText={(val) => BANK_CuitChange(val)} />
                                </FormControl>


                                <FormControl alignItems="flex-start" w='90%'>
                                    <Text style={StylePerfil.labelMon13L}>Banco<Text style={StylePerfil.labelObligatorio}> *</Text></Text>
                                    <SelectBancosWizard banco={user.BANK_Banco} valorSeleccionado={handleSelectedBanco}></SelectBancosWizard>
            </FormControl> */}

                <VStack alignItems="center" mt="2">
                  <Text style={StylePerfil.labelMon15A}>
                    Dirección Comercial
                  </Text>
                  {/* <Text adjustsFontSizeToFit={true} numberOfLines={2} style={StyleSigInScreen.textoLegendaChicaB}>
                                        (Debe ser la dirección donde retiran los productos).
                                    </Text> */}
                </VStack>

                {mostrarDirecciones ? (
                  <FormControl alignItems="flex-start" w="90%">
                    <Text style={StylePerfil.labelMon13L}>
                      Direcciones del usuario
                    </Text>
                    <SelectDireccionesWizard
                      data={postsDireccion}
                      valorSeleccionado={handleSelected}
                      valor={iddireccionState}
                    />
                  </FormControl>
                ) : null}

                {nuevaDireccion ? (
                  <VStack space={4} alignItems="center">
                    <FormControl alignItems="flex-start">
                      <Text style={StylePerfil.labelMon13L}>
                        Codigo Postal
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <Input
                        placeholder=""
                        width="90%"
                        style={StyleSigInScreen.textoLoginyPass}
                        rounded="8"
                        bgColor="#fff"
                        onChangeText={(val) => CpChange(val)}
                        value={addZona.cp}
                      />
                    </FormControl>

                    <FormControl alignItems="flex-start" w="90%">
                      <Text style={StylePerfil.labelMon13}>
                        Provincia
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <SelectProvinciaWizard
                        data={dataProv}
                        valorSeleccionado={handleSelectedProvincia}
                        valor={prov}
                      />
                    </FormControl>

                    <FormControl alignItems="flex-start" w="90%">
                      <Text style={StylePerfil.labelMon13}>
                        Partido
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <SelecLocalidadWizard
                        data={dataLoc}
                        valorSeleccionado={handleSelectedLocalidad}
                        valor={loc}
                      />
                    </FormControl>

                    <FormControl alignItems="flex-start" w="90%">
                      <Text style={StylePerfil.labelMon13}>
                        Localidad/Barrio
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <SelectZonaWizard
                        data={dataZonas}
                        valorSeleccionado={handleSelectedZona}
                      />
                    </FormControl>

                    <FormControl alignItems="flex-start">
                      <Text style={StylePerfil.labelMon13L}>
                        Calle / Av.
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <Input
                        placeholder="Calle"
                        width="90%"
                        style={StyleSigInScreen.textoLoginyPass}
                        rounded="8"
                        bgColor="#fff"
                        onChangeText={(val) => CalleChange(val)}
                        value={addZona.calle}
                      />
                    </FormControl>

                    <FormControl alignItems="flex-start">
                      <Text style={StylePerfil.labelMon13L}>
                        Numero
                        <Text style={StylePerfil.labelObligatorio}> *</Text>
                      </Text>
                      <Input
                        placeholder="Numero"
                        width="90%"
                        style={StyleSigInScreen.textoLoginyPass}
                        rounded="8"
                        bgColor="#fff"
                        onChangeText={(val) => NumeroChange(val)}
                        value={addZona.numero}
                      />
                    </FormControl>
                  </VStack>
                ) : null}
              </VStack>
              <VStack h={12}></VStack>
            </View>
          </ScrollView>
        </View>
      )}
      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="0"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="flex-end"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "40%",
          }}
          onPress={() => SiguentePaso()}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Siguente</Text>
        </TouchableOpacity>
      </HStack>
    </VStack>
  );
};

export default WizardVendedorScreen1;
