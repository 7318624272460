import React, { useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    Select,
    VStack,
    CheckIcon,
    Center
} from "native-base"
import { hp, wp } from "../../../styles/responsive"
import { StyleSelectCategoria } from '../../../styles/StyleQuodom';
import { ObtenerCategorias } from '../../../api';

const SelectCategoria = ({ onSelectedValue, placeholder }) => {

    const [categoria, setCategoria] = useState([]);
    let [service, setService] = useState("")
    //let [placeholder, setPlaceholder] = useState('Agregar categoría');

    const loadCategorias = async () => {
        const token = await AsyncStorage.getItem('userToken');
        const CategoriasData = await ObtenerCategorias(token);
        setCategoria(CategoriasData)
    }

    useEffect(() => {
        loadCategorias();
    }, []);

    function onSelected(selected) {
        setService(selected);
        onSelectedValue(selected);
    }


    return (

        <VStack style={{ alignItems: "flex-start", flex: 1 }}>
            <Select
                w='100%'
                selectedValue=''
                bg='#fff'
                style={StyleSelectCategoria.defaultCategoria}
                accessibilityLabel={placeholder}
                placeholder={placeholder}
                _selectedItem={{
                    bg: "#706F9A",
                    endIcon: <CheckIcon size="5" />,
                }}

                onValueChange={(itemValue) => onSelected(itemValue)}
            >


                {categoria.map(x => {
                    return (
                        <Select.Item
                            label={x.nombrecategoria}
                            value={x.id}
                            key={x.id}
                        />
                    );
                })}

            </Select>
        </VStack>

    )
}

export default SelectCategoria
