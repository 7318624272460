import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  useDisclose,
  IconButton,
  Stagger,
  HStack,
  Icon,
  Text,
  NativeBaseProvider,
  useToast,
  Center,
  Actionsheet,
  View,
  VStack,
  Modal,
  Button,
  Divider,
} from "native-base";
import {
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Alert,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  SimpleLineIcons,
  Ionicons,
} from "@expo/vector-icons";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import { HEIGHT, hp, moderateScale, wp } from "../../styles/responsive";
import {
  ModificarQuodomACotizado,
  ModificarACotizado,
  CrearOperNotificacion,
  ObtenerOfertasItems,
  ObtenerDireccionesUsuario,
  ObtenerDataUsuario,
  getDistanciaChange,
} from "../../api";
import ResumenRenderItem from "./ResumenRenderItem";
//import DateTimePicker from '@react-native-community/datetimepicker';
import Moment from "moment";
import SelectDirecciones from "../../components/Direcciones/SelectDirecciones";

import { DatePickerModal } from "react-native-paper-dates";
const AccionesDetalleOfertas = ({
  isExtended,
  restan,
  vengode,
  cambiarDistancia,
}) => {
  const mounted = useRef(true);
  ////console.log('COTTTTT', cot)
  const navigation = useNavigation();
  const route = useRoute();
  const { idquodom, nombreQuodom, estadoQuodom, estado } =
    route.params !== undefined ? route.params : ["", "", "", ""];

  const toast = useToast();
  const id = "id_toast";

  const [showModalEnviar, setShowModalEnviar] = useState(false);
  const [dire, setdire] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setisLoading] = useState(true);
  //const [showModalEnviadoOK, setShowModalEnviadoOK] = useState(false);
  const [posts, setPosts] = useState([]);
  const [fechaEnvio, setfechaEnvio] = useState();
  const [fechaHoy, setFechaHoy] = useState(new Date());

  const fechaminina = new Date(
    new Date().setDate(new Date().getDate() + restan)
  );
  const fechamax = new Date(new Date().setDate(new Date().getDate() + 60));
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  ////console.log('Por props: ' + props.estadoQuodom);
  ////console.log('Por route: ' + estadoQuodom);
  const [mode, setMode] = useState("date");
  const [show, setShow] = useState(false);
  const [iddireccionState, setiddireccionState] = useState();
  const [postsDireccion, setDireccion] = useState("");
  const [colorAviso, setColorAviso] = useState("");
  const [puedeVender, setPuedeVender] = useState(false);
  const [date, setDate] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setDate(params.date);
    },
    [setOpen, setDate]
  );
  const Actualizar = () => {
    route.params.estado(true);
    route.params.estado(false);
    navigation.goBack();
    ////console.log(route.params);
    //estado(true);
    //navigation.goBack();
    ////console.log('entr');
    //navigation.navigate('MisQuodoms');
    ////console.log('salgo');
  };
  const showDatepicker = () => {
    showMode("date");
  };
  const showMode = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  };

  async function enviarCotizacion() {
    // var fechaEnvioActual = new Date(fechaEnvio);
    ////console.log('posts', fechaEnvio)
    const newData = posts.filter(
      (item) => item.preciounitario !== null && item.preciounitario !== "0"
    );
    ////console.log('newData', newData.length)
    if (newData.length === 0) {
      if (!toast.isActive(id)) {
        Alert.alert("Atención", "No has cotizado ningun producto.", [
          { text: "OK" },
        ]);
        /*  toast.show({
            id,
            placement: "bottom",
            render: () => {
              return (
                <Box bg="gray.700" p='5' rounded="sm" mb={5}  style={{zIndex:99999,elevation:99999}}>
                  <Text adjustsFontSizeToFit={true} numberOfLines={1} style={QuodomDetalle.TextoToastTitulo}>Atención</Text>
                  <Text adjustsFontSizeToFit={true} numberOfLines={2} style={QuodomDetalle.TextoToast}>No has cotizado ningun producto.</Text>
                </Box>
              );
            }
          });*/
      }
      return false;
    }
    if (date === undefined) {
      if (!toast.isActive(id)) {
        Alert.alert("Atención", "Seleccioná una fecha de envio.", [
          { text: "OK" },
        ]);
        /*   toast.show({
             id,
             placement: "bottom",
             render: () => {
               return (
                 <Box bg="gray.700" p='5' rounded="sm" mb={5} style={{zIndex:99999,elevation:99999}}>
                   <Text adjustsFontSizeToFit={true} numberOfLines={1} style={QuodomDetalle.TextoToastTitulo}>Atención</Text>
                   <Text adjustsFontSizeToFit={true} numberOfLines={2} style={QuodomDetalle.TextoToast}>Seleccioná una fecha de envio.</Text>
                 </Box>
               );
             }
           });*/
      }
      return false;
    }
    if (iddireccionState === null || iddireccionState === "") {
      if (!toast.isActive(id)) {
        Alert.alert("Atención", "Seleccioná una dirección.", [{ text: "OK" }]);
      }
      return false;
    }

    //SL TODO ESTO DEBERIA IR EN EL API !!!HACER!!!
    ////console.log('enviar cotización');
    //crear cabecera cotización
    //const value = await AsyncStorage.getItem('userToken');

    let Data = {
      estado: "COTIZADO",
    };

    const token = await AsyncStorage.getItem("userToken");
    const Q = ModificarQuodomACotizado(token, idquodom, Data);
    //SL Si Q es false, es porque ya se compro o finalizo, entonces no mando la notificacion.

    var fechaEnvioActual = new Date(date);
    var fechaEnvioFormat =
      fechaEnvioActual.getFullYear() +
      "/" +
      (fechaEnvioActual.getMonth() + 1) +
      "/" +
      fechaEnvioActual.getDate();

    var data = {
      estado: "COTIZADO",
      idquodom: idquodom,
      fechaentrega: fechaEnvioFormat,
      iddireccion: iddireccionState,
    };

    ////console.log('DATA ENVIO', data);
    const modifquodomacotizado = await ModificarACotizado(token, data);

    //al final, crear la notificación para el comprador AL COMPRADOR.
    //var fechaActual = new Date();
    //var fechaCreacion = fechaActual.getFullYear() + '/' + fechaActual.getMonth() + '/' + fechaActual.getDate();

    if (Q) {
      var dataNotificacion = {
        tiponotificacion: "ENVIOCOTIZACION",
        idquodom: idquodom,
        //fecha: fechaCreacion,
        titulo: "Te cotizaron uno de tus pedidos!",
        //texto: 'Te Cotizaron'
      };

      CrearOperNotificacion(token, dataNotificacion);
    }

    if (!modifquodomacotizado.res) {
      //console.log("ERROR");
      setisLoading(false);
    } else {
      setisLoading(false);
      setShowModalEnviar(false);
      navigation.navigate("SuccessScreenOferta", { vengode: vengode });
    }
  }

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate;
    setShow(false);
    setfechaEnvio(currentDate);
  };

  const enviar = async () => {
    if (puedeVender) {
      setisLoading(true);
      DatosCotizacion();
      setShowModalEnviar(true);
    } else {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.700" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Atención
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={2}
                  style={QuodomDetalle.TextoToast}
                >
                  Tu usuario aún no esta habilitado para vender.
                </Text>
              </Box>
            );
          },
        });
      }
      return false;
    }
  };

  const handleSelected = async (e) => {
    ////console.log('entre a cambiar la dir de entrega por ', e)
    const token = await AsyncStorage.getItem("userToken");

    //SL aca tengo que volver a calcular la distancia porque cambie la direccion de retiro
    let values = {
      idquodom: idquodom,
      iddireccion: e,
    };

    getDistanciaChange(token, values);

    setiddireccionState(e);
    var data = {
      idquodom: idquodom,
      iddireccion: e,
    };

    await ModificarACotizado(token, data);
    cambiarDistancia();
  };

  const DatosCotizacion = async () => {
    //console.log("Entre al DatosCotizacion() AccionesDetalleOfertas.js");

    const value = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerOfertasItems(value, idquodom);
    ////console.log('ESTO ES LO QUE TENGO', postsData)
    const datosDireccion = await ObtenerDireccionesUsuario(value);

    if (mounted.current) {
      setPosts(postsData);
      setDireccion(datosDireccion);
      //console.log("direee", postsData);
      //console.log("datosdiree", datosDireccion);

      if (
        postsData[0].iddireccion === null ||
        postsData[0].iddireccion === ""
      ) {
        setiddireccionState(datosDireccion[0].id);
      } else {
        setiddireccionState(postsData[0].iddireccion);
      }

      setisLoading(false);
    }
  };

  const DatosVendedor = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const res = await ObtenerDataUsuario(token);

    if (mounted.current) {
      ////console.log('--------------- PUEDE VENDER', res.VendedorHabilitado);
      setPuedeVender(res.VendedorHabilitado);
    }
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect AccionesDetalleOfertas.js");
    DatosCotizacion();
    DatosVendedor();
    //DatosDireccionDefault();
    return () => (mounted.current = false);
  }, []);

  function stringToBoolean(int) {
    switch (int) {
      case 1:
        return true;
      case 0:
      case null:
        return false;
      default:
        return Boolean(int);
    }
  }

  const showDatePicker = () => {
    setDatePickerVisible(true);
    setOpen(true);
  };

  const hideDatePicker = () => {
    setDatePickerVisible(false);
  };

  const handleConfirm = (date) => {
    hideDatePicker();
    ////console.log('entre', date)
    setFechaHoy(date);
    setfechaEnvio(date);
  };

  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 16 };

  return (
    <Box>
      <TouchableOpacity
        style={{ backgroundColor: "#F6EE5D" }}
        onPress={() => enviar()}
      >
        <HStack space={2} alignItems="center" style={styles.itemButtons}>
          <Icon
            as={<MaterialCommunityIcons name="cube-send" />}
            color="muted.500"
            size="7"
          />

          <Text style={styles.itemText}>Enviar cotización</Text>
        </HStack>
      </TouchableOpacity>

      {/* MODAL Envio */}
      <Modal
        isOpen={showModalEnviar}
        onClose={() => setShowModalEnviar(false)}
        size="lg"
      >
        <Modal.Content width={wp(96)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Resumen Cotización
            </Text>
          </Modal.Header>
          <Modal.Body borderWidth={0} p="4">
            {isLoading ? (
              <Center flex="1" h={hp(18)}>
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            ) : (
              <VStack space={3} w="100%" flex={1} justifyContent="center">
                <HStack
                  pt="1"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text style={QuodomDetalle.TextoModalResumenBold2}>
                    Fecha de entrega:
                  </Text>
                  <Text style={QuodomDetalle.TextoModalResumenBold}>
                    {date !== undefined
                      ? Moment(date).format("DD/MM/YYYY")
                      : "Seleccionar"}
                  </Text>
                  <DatePickerModal
                    locale="es"
                    mode="single"
                    visible={open}
                    onDismiss={onDismissSingle}
                    date={date}
                    onConfirm={onConfirmSingle}
                  />

                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "transparent",
                      padding: 10,
                      borderRadius: 5,
                      borderWidth: 1,
                    }}
                    onPress={showDatePicker}
                  >
                    <Icon
                      as={MaterialCommunityIcons}
                      size="6"
                      name="calendar"
                      _dark={{
                        color: "black",
                      }}
                      color="black"
                    />
                  </TouchableOpacity>
                </HStack>

                {/* <TouchableOpacity onPress={() => navigation.navigate('DireccionEnvioQuodomScreen', { cambiarId: cambiariddireccion, iddireccion: iddireccionState, idquodom: idquodom })}> */}
                <VStack w="100%" space={0} justifyContent="flex-start">
                  <Text style={QuodomDetalle.TextoModalResumenBold2}>
                    Dirección de retiro:
                  </Text>

                  {/* <Text style={QuodomDetalle.TextoModalResumen2}>{postsDireccion.alias}, {postsDireccion.direccion}, {postsDireccion.localidad}</Text>
                        <MaterialIcons name="keyboard-arrow-right" size={moderateScale(20)} color='#59584e' /> */}
                  <SelectDirecciones
                    data={postsDireccion}
                    valorSeleccionado={handleSelected}
                    valor={iddireccionState}
                  />
                </VStack>
                {/* </TouchableOpacity> */}

                <VStack w="90%" pt="2" justifyContent="space-between">
                  <Text style={QuodomDetalle.TextoModalResumenBold2}>
                    Productos Cotizados{" "}
                  </Text>
                  <Text style={QuodomDetalle.TextoModalItalic}>
                    (Solo los productos completos)
                  </Text>
                </VStack>

                <View style={{ flex: 1, alignContent: "center" }}>
                  <FlatList
                    //itemDimension={100}
                    data={posts}
                    //style={QuodomDetalle.gridView}
                    //spacing={20}

                    renderItem={({ item }) => (
                      <ResumenRenderItem
                        nombreproducto={item.nombreproducto}
                        enviovendedor={stringToBoolean(item.enviovendedor)}
                        cantidad={item.cantidad}
                        nombreAtributo1={item.nombreAtributo1}
                        nombreAtributo2={item.nombreAtributo2}
                        atributo1={item.atributo1}
                        atributo2={item.atributo2}
                        vendedor_atributo1={item.vendedor_atributo1}
                        vendedor_atributo2={item.vendedor_atributo2}
                        vendedor_nombreAtributo1={item.vendedor_nombreAtributo1}
                        vendedor_nombreAtributo2={item.vendedor_nombreAtributo2}
                        preciounitario={item.preciounitario}
                      ></ResumenRenderItem>
                    )}
                    keyExtractor={(item) => Math.random().toString()}
                  />
                </View>

                <VStack pl="2" pr="2" justifyContent="flex-start">
                  <Text style={QuodomDetalle.TextoModalResumenTip}>
                    Una vez que envié la cotización ya no podrá modificarla.
                  </Text>
                </VStack>
              </VStack>
            )}
          </Modal.Body>
          {isLoading ? null : (
            <Modal.Footer>
              <Button.Group space={2}>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    padding: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                  }}
                  onPress={() => enviarCotizacion(idquodom)}
                >
                  <Text style={QuodomDetalle.TextoModalButtonOK}>Enviar</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setShowModalEnviar(false);
                  }}
                >
                  <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                    Cancelar
                  </Text>
                </TouchableOpacity>
              </Button.Group>
            </Modal.Footer>
          )}
        </Modal.Content>
      </Modal>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 10,
    right: 10,
    position: "absolute",
  },
  itemButtons: {
    backgroundColor: "#F6EE5D",
    color: "#706F9A",
    marginRight: 0,
    textAlign: "center",
    padding: 4,
    //fontSize: 13,
    borderWidth: 1,
    borderColor: "#706F9A",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    //width: WIDTH * 0.25,
    fontSize: moderateScale(9),
  },
  itemText: {
    color: "#706F9A",
    marginRight: 0,
    textAlign: "center",
    padding: 4,
    fontSize: moderateScale(9),
  },
});

export default AccionesDetalleOfertas;
/* export default () => {
  return (

    <AccionesDetalleQuodom />

  )
} */
