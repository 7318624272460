import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  RefreshControl,
  TouchableOpacity,
  Keyboard,
  ActivityIndicator,
} from "react-native";
import {
  ObtenerOfertasItems,
  getDistancia,
  CrearCabCotizacionVenta2,
  ObtenerDataUsuario,
  ObtenerDistancia,
  ModificarACotizado,
  ObtenerCotizacion,
} from "../../api";
import {
  HStack,
  NativeBaseProvider,
  Box,
  Stack,
  Center,
  VStack,
  Divider,
  Icon,
  Spacer,
  Image,
} from "native-base";
import { useRoute, useNavigation } from "@react-navigation/core";
import { colorFondoGeneral } from "../../styles/general";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import OfertasRenderItem from "./OfertasRenderItem";
import { HEIGHT, WIDTH, moderateScale, hp, wp } from "../../styles/responsive";
import AccionesDetalleOfertas from "./AccionesDetalleOfertas";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { Rating } from "react-native-ratings";

const OfertasItemList = (props) => {
  const mounted = useRef(true);

  const route = useRoute();
  // const { vengode } = route.params;
  ////console.log("PROPS", cotizacion);
  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };
  const [cotizacion, setCotizacon] = useState([]);
  const [idquodom, setIdquodom] = useState("");
  const [nombreusuariocomprador, setnombre] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [restan, setrestan] = useState("");
  const [posts, setPosts] = useState([]);
  const [refreshing, setRefreshing] = useState("");
  const [distancia, setDistancia] = useState("0");
  const [isLoadingDistancia, setisLoadingDistancia] = useState(true);
  const [textProducto, setTextProducto] = useState("");
  const [isExtended, setIsExtended] = useState(true);
  const [puedeVender, setPuedeVender] = useState(false);
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const [coti, setCoti] = useState(cotizacion);

  const navigation = useNavigation();

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const LeerDatos = async () => {
    const valor = await AsyncStorage.getItem("cotizacionSeleccionada");
    const coti = JSON.parse(valor);
    //console.log("cotii", coti);
    const value = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerOfertasItems(value, coti.idquodom);
    const cotiData = await ObtenerCotizacion(value, coti.idquodom);
    //console.log("coti", cotiData);
    ////console.log('ESTE', postsData)

    if (mounted.current) {
      setCoti(cotiData);
      setPosts(postsData);
      setisLoading(false);
    }
  };

  const DatosVendedor = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const res = await ObtenerDataUsuario(token);

    if (mounted.current) {
      ////console.log('--------------- PUEDE VENDER', res.VendedorHabilitado);
      setPuedeVender(res.VendedorHabilitado);
    }
  };

  const loadDistancia = async () => {
    const token = await AsyncStorage.getItem("userToken");
    let data = {
      DireccionComprador: cotizacion.Direccion,
    };
    let postsData = await getDistancia(token, data);
    //SL Aca creo la cabecera y le clavo la distancia
    if (postsData === "ERROR") {
      postsData = "Sin Dato";
    }
    let dataCot = {
      idquodom: idquodom,
      distancia: postsData,
    };

    ModificarACotizado(token, dataCot);

    ////console.log("postsData", postsData);

    if (mounted.current) {
      setDistancia(postsData);
      setisLoadingDistancia(false);
    }
  };

  const cambiarState = (newState) => {
    setisLoading(true);
    setRefreshing(newState);
    return newState;
  };

  const cambiarDistancia = async (newState) => {
    setisLoadingDistancia(true);
    const token = await AsyncStorage.getItem("userToken");
    const CH = await ObtenerDistancia(token, idquodom);
    setDistancia(CH.distancia);
    setisLoadingDistancia(false);
    return newState;
  };

  useEffect(() => {
    mounted.current = true;
    AsyncStorage.getItem("cotizacionSeleccionada").then((valor) => {
      const coti = JSON.parse(valor);
      setCotizacon(coti);
      setIdquodom(coti.idquodom);
      setnombre(coti.nombreusuariocomprador);
      // setproductosEntregados(coti.productosEntregados);
      setisLoading(false);
    });
    if (cotizacion.diasparavencimientoaceptacion == "3") {
      setrestan("72Hs");
    }
    if (cotizacion.diasparavencimientoaceptacion == "2") {
      setrestan("48Hs");
    } else {
      setrestan("24Hs");
    }

    if (cotizacion.cantidadlineas <= 1) {
      setTextProducto("Producto");
    } else {
      setTextProducto("Productos");
    }

    LeerDatos();
    DatosVendedor();

    if (cotizacion.distancia === null) {
      loadDistancia();
    } else {
      setDistancia(cotizacion.distancia);
      setisLoadingDistancia(false);
    }

    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      () => {
        setKeyboardVisible(true); // or some other action
      }
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      () => {
        setKeyboardVisible(false); // or some other action
      }
    );

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
      mounted.current = false;
    };

    /*    return () =>  */
  }, [refreshing]);

  function stringToBoolean(int) {
    switch (int) {
      case 1:
        return true;
      case 0:
      case null:
        return false;
      default:
        return Boolean(int);
    }
  }

  const onScroll = ({ nativeEvent }) => {
    ////console.log('isExtended Detalle', isExtended)
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;

    setIsExtended(currentScrollPosition <= 0);
    ////console.log('isExtended Detalle', currentScrollPosition <= 0)
    //return (currentScrollPosition <= 0)
  };

  const Header = () => {
    return (
      <>
        <HStack borderWidth={0}>
          <Box bg="#FFF" rounded={5} width={"100%"}>
            <HStack
              style={{
                alignItems: "center",
                height: hp(13),
              }}
            >
              <VStack
                style={{
                  marginLeft: 0,
                  height: hp(13),
                  width: "3%",
                  backgroundColor: "#706F9A",
                  borderBottomLeftRadius: 5,
                  borderTopLeftRadius: 5,
                }}
              ></VStack>

              <VStack>
                <Image
                  style={{ borderColor: "#000000", borderWidth: 0 }}
                  source={require("../../assets/imgCotizacionPara.png")}
                  alt="texto alternativo"
                  size={50}
                />
              </VStack>

              <VStack
                style={{
                  width: "54%",
                  borderColor: "#000000",
                  borderWidth: 0,
                }}
              >
                <Text style={QuodomDetalle.textoVence}>
                  Vence el {CortarFecha(cotizacion.fechavencimientoenvio)}
                </Text>
                <Text style={QuodomDetalle.textoPedidoDe}>
                  Pedido de cotización de {cotizacion.nombreusuariocomprador}
                </Text>
              </VStack>

              <VStack
                style={{
                  alignItems: "center",
                  width: "43%",
                  backgroundColor: "#706F9A",
                  borderBottomStartRadius: 20,
                  borderTopEndRadius: 5,
                  borderBottomEndRadius: 5,
                }}
              >
                <VStack
                  style={{ justifyContent: "center", height: hp(13) }}
                  space={2}
                  p="3"
                >
                  <Text style={styles.itemButtons}>
                    {cotizacion.cantidadlineas} {textProducto}
                  </Text>
                  <Text style={styles.itemButtons}>Restan {restan}</Text>
                </VStack>
              </VStack>
            </HStack>
          </Box>
        </HStack>

        <Box
          style={{
            marginLeft: 0,
            marginRight: 0,
            paddingBottom: "1%",
            paddingTop: "1%",
          }}
        >
          <HStack space={3} mb={3} ml={0} mt={3}>
            <HStack
              space={1}
              style={{
                padding: 8,
                backgroundColor: "#706F9A",
                shadowRadius: 0,
                shadowOpacity: 0,
                elevation: 0,
                borderRadius: 8,
              }}
            >
              <HStack>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: moderateScale(8),
                  }}
                >
                  {cotizacion.localidad}
                </Text>
              </HStack>
              <HStack>
                {isLoadingDistancia ? (
                  <Center>
                    <ActivityIndicator size="small" color="#fff" />
                  </Center>
                ) : (
                  <Text
                    style={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: moderateScale(8),
                    }}
                  >
                    ({distancia})
                  </Text>
                )}
              </HStack>
            </HStack>
            <HStack
              style={{
                padding: 8,
                backgroundColor: "#706F9A",
                alignItems: "center",
                borderRadius: 8,
              }}
            >
              {cotizacion.calificacion === 0.0 ? (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#FFFFFF",
                    fontSize: moderateScale(8),
                    backgroundColor: "#706F9A",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 8,
                  }}
                >
                  Sin calificaciones aún
                </Text>
              ) : (
                <>
                  <Text
                    style={{
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: moderateScale(8),
                      backgroundColor: "#706F9A",
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 8,
                    }}
                  >
                    Calificación{" "}
                  </Text>

                  <Rating
                    readonly={true}
                    showRating={false}
                    style={{ paddingTop: 2 }}
                    tintColor="#706F9A"
                    ratingCount={5}
                    imageSize={12}
                    startingValue={cotizacion.calificacion}
                  />
                </>
              )}
            </HStack>
            <Spacer width={"10%"}></Spacer>
            <HStack>
              <AccionesDetalleOfertas
                codigoQuodom={cotizacion.idquodom}
                nombreQuodom={cotizacion.nombrequodom}
                usuarioVendedor={cotizacion.usuarioVendedor}
                estadoQuodom={cotizacion.estado}
                vengode={""}
                restan={cotizacion.diasparavencimientoaceptacion}
                isExtended={isExtended}
                puedeVender={puedeVender}
                cambiarDistancia={cambiarDistancia}
              />
            </HStack>
            {/*   <Text style={{ textAlign: 'center', padding: 3, color: '#FFFFFF', fontSize: moderateScale(9), fontFamily: 'Prompt_300Light', backgroundColor: '#706F9A', shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5 }}>...</Text> */}
          </HStack>
        </Box>

        <HStack mt="2" mb="1">
          <Text style={DetalleProductoQuodomstyle.textoCompleta}>
            Completá los detalles de cada producto:
          </Text>
        </HStack>
      </>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#F6EE5D", marginBottom: 0 }}>
      <TouchableOpacity
        style={{ marginLeft: "10px", marginTop: "10px" }}
        onPress={() => navigation.toggleDrawer()}
      >
        <Icon
          size="8"
          as={<MaterialIcons name="menu" />}
          color={quodomTheme.COLORS["COLORVIOLETA"]}
        />
      </TouchableOpacity>

      <Center>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
          <Image
            source={require("../../assets/INICIO-16.png")}
            alt="Quodom."
            alignItems="center"
            marginBottom={"2%"}
            style={{ width: wp(10), height: wp(3) }}
          />
        </TouchableOpacity>
      </Center>

      {isLoading ? (
        <View
          style={{
            Flex: 1,
            zIndex: 100,
            position: "absolute",
            top: 120,
            bottom: 0,
            width: "100%",
            backgroundColor: "#F6EE5D",
          }}
        >
          <Center flex="1">
            <ActivityIndicator size="large" color="#fff" />
          </Center>
        </View>
      ) : (
        <View
          style={{
            Flex: 1,
            zIndex: 100,
            position: "absolute",
            top: 120,
            bottom: 0,
            width: "100%",
            borderWidth: 0,
            backgroundColor: "#F6EE5D",
          }}
        >
          <VStack>
            <Center
              style={{
                backgroundColor: "#F6EE5D",
              }}
            >
              <HStack width={"60%"} paddingTop={"2%"} paddingBottom={"1%"}>
                <Text style={QuodomDetalle.textoTituloMisCotizacionWeb}>
                  Cotización para {nombreusuariocomprador}
                </Text>
                <Spacer width={"40%"}></Spacer>
              </HStack>

              <HStack width={"60%"}>
                <FlatList
                  //initialNumToRender={10}
                  ListHeaderComponent={Header}
                  onScroll={onScroll}
                  //itemDimension={100}
                  data={posts}
                  //style={QuodomDetalle.gridView}
                  //spacing={20}
                  showsVerticalScrollIndicator={false}
                  ListFooterComponent={<Center h={hp(10)}></Center>}
                  renderItem={({ item, index }) => (
                    <OfertasRenderItem
                      nombreproducto={item.nombreproducto}
                      marcavendedor={item.marcavendedor}
                      enviovendedor={stringToBoolean(item.enviovendedor)}
                      //marca={item.marca}
                      //unidad={item.unidad}
                      cantidad={item.cantidad}
                      imagen={item.imagen}
                      refreshImagen={item.refreshImagen}
                      atributosFaltantes={item.atributosFaltantes}
                      idproducto={item.idproducto}
                      id={item.id}
                      idquodom={item.idquodom}
                      actualizarestado={cambiarState}
                      incluirenvio={item.incluirenvio}
                      idcotizacion={item.idcotizacion}
                      preciounitario={item.preciounitario}
                      imagendelvendedor={item.imagendelvendedor}
                      atributo1={item.atributo1}
                      atributo2={item.atributo2}
                      atributoNombre1={item.nombreAtributo1}
                      atributoNombre2={item.nombreAtributo2}
                      nombrecategoria={item.nombrecategoria}
                      vendedor_atributo1={item.vendedor_atributo1}
                      vendedor_atributo2={item.vendedor_atributo2}
                      vendedor_atributoNombre1={item.vendedor_nombreAtributo1}
                      vendedor_atributoNombre2={item.vendedor_nombreAtributo2}
                      duplicado={item.duplicado}
                      puedeVender={puedeVender}
                      //modalLoading={setVisible}
                    ></OfertasRenderItem>
                  )}
                  keyExtractor={(item) => item.id.toString()}
                />
              </HStack>
            </Center>
          </VStack>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
  },
  itemContainerCreado: {
    backgroundColor: "#FFFFFF",
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    height: HEIGHT * 0.12,
    width: WIDTH * 0.4,
    borderTopEndRadius: 5,
    borderBottomEndRadius: 5,
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
    paddingTop: 0,
  },

  itemButtons: {
    color: "#fff",
    marginRight: 0,
    textAlign: "center",
    padding: 1,
    fontSize: moderateScale(7),
    borderWidth: 1,
    borderColor: "#fff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    width: wp(15),
  },
  TouchableOpacityStyle: {
    //Here is the trick
    position: "absolute",
    width: 50,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    right: 30,
    bottom: 30,
  },
  navBarLeftButton: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
});

export default OfertasItemList;
