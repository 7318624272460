import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  FlatList,
  RefreshControl,
  TouchableOpacity,
  Switch,
} from "react-native";
import {
  ObtenerOfertasItemsVendido,
  ObtenerInfoComprador,
  ObtenerFactura,
  UpdateFactura,
  ObtenerCotizacion,
} from "../../api";
import {
  Center,
  HStack,
  NativeBaseProvider,
  VStack,
  Button,
  Box,
  Divider,
  Popover,
  useToast,
  Icon,
  Image,
} from "native-base";
import { useRoute, useNavigation } from "@react-navigation/core";
import { colorFondoGeneral } from "../../styles/general";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import {
  Ionicons,
  Entypo,
  FontAwesome5,
  MaterialIcons,
} from "@expo/vector-icons";
import Loader from "../../components/Loader";
import OfertasRenderItemAceptada from "./OfertasRenderItemAceptada";
import { HEIGHT, WIDTH, moderateScale, wp } from "../../styles/responsive";
import DetalleImporteVenta from "./DetalleImporteVenta";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import ListaMisCotizacionesInicial from "./OfertasListInicial";
//import AccionesDetalleOfertas from './AccionesDetalleOfertas';
import FiltrosCotizaciones from "./FiltrosCotizaciones";
import { RotateOutUpLeft } from "react-native-reanimated";

const OfertasItemAceptada = (props) => {
  const mounted = useRef(true);

  const route = useRoute();

  const [visible, setVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [posts, setPosts] = useState([]);
  const navigation = useNavigation();
  const [toggleCheckBox, setToggleCheckBox] = useState(props.enviovendedor);
  const [productosEntregados, setproductosEntregados] = useState(0);

  const [ch, setCh] = useState([]);
  const [infoComprador, setInfoComprador] = useState([]);
  const [factura, setFactura] = useState(false);
  const [cotizacion, setCotizacon] = useState([]);
  const [idquodom, setIdquodom] = useState("");
  const [nombreusuariocomprador, setnombre] = useState("");
  const toast = useToast();
  const id = "id_toast";
  ////console.log('ROUTE de OfertasItemAceptada ', route.params)

  const toggleSwitch = async () => {
    // //console.log("cotizacionnn", cotizacion);
    if (productosEntregados === 0) {
      ////console.log("Comprador", ch.usuariocomprador);
      navigation.navigate("CalificacionComprador", {
        idcotizacion: ch.id,
        idComprador: ch.usuariocomprador,
        idquodom: idquodom,
        actualizarEstado: actualizarEstado,
      });
    } else {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "¡Atención!",
          status: "message",
          description:
            "Ya se calificó e indico que los productos fueron entregados",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
    }
  };

  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const LeerDatos = async () => {
    const token = await AsyncStorage.getItem("userToken");
    let q = idquodom;
    const valor = await AsyncStorage.getItem("cotizacionSeleccionada");
    const coti = JSON.parse(valor);
    //console.log("cotii", coti);
    const cotizacion = await ObtenerCotizacion(token, coti.idquodom);
    //console.log("cotibase", cotizacion[0]);
    setCotizacon(coti);
    setIdquodom(coti.idquodom);
    setnombre(coti.nombreusuariocomprador);
    setproductosEntregados(cotizacion[0].productosEntregados);
    setisLoading(false);
    const postsData = await ObtenerOfertasItemsVendido(token, coti.idquodom);
    const dataComprador = await ObtenerInfoComprador(token, coti.idquodom);
    const getFactura = await ObtenerFactura(
      token,
      postsData[0].idcotizacion,
      postsData[0].usuarioVendedor
    );

    // //console.log('jojo',postsData[0]);
    // //console.log('esto',getFactura.archivo);
    if (mounted.current) {
      if (getFactura !== null) {
        setFactura(true);
      }
      setPosts(postsData);
      ////console.log("dataComprador", dataComprador);
      setInfoComprador(dataComprador);
      setCh(postsData[0]);
      setisLoading(false);
    }
  };

  const cambiarState = (newState) => {
    if (mounted.current) {
      setRefreshing(newState);
    }
    return newState;
  };

  useEffect(() => {
    mounted.current = true;
    LeerDatos();
    return () => (mounted.current = false);
  }, [route, productosEntregados, factura]);

  function stringToBoolean(int) {
    switch (int) {
      case 1:
        return true;
      case 0:
      case null:
        return false;
      default:
        return Boolean(int);
    }
  }

  const actualizarEstado = (Item) => {
    ////console.log("actualizarEstado", Item);

    setproductosEntregados(true);
  };

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Obtén la parte codificada en base64 del resultado
        resolve(base64String);
      };

      reader.onerror = () => {
        reject(new Error("Error al leer el archivo"));
      };

      reader.readAsDataURL(file);
    });
  }

  const pickDoc = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        mediaTypes: "application/pdf",
      });
      //console.log(result);
      // var lastThree = result.name.substr(result.name.length - 3);
      if (result.mimeType === "application/pdf") {
        if (result.type == "success") {
          setisLoading(true);
          if (!result.cancelled) {
            const token = await AsyncStorage.getItem("userToken");
            // let docBase64 = await readFileAsync(result.uri);
            let base64result = result.uri.split(",")[1];
            let fact = {
              idcotizacion: ch.idcotizacion,
              usuariovendedor: ch.usuarioVendedor,
              archivo: base64result,
              nro_quodom: ch.nro,
              usuariocomprador: ch.usuariocomprador,
              id_quodom: ch.idquodom,
            };

            let saveDoc = await UpdateFactura(token, fact);

            if (!saveDoc.res) {
              if (!toast.isActive(id)) {
                toast.show({
                  id,
                  title: "Algo salió mal",
                  status: "error",
                  description: saveDoc.message,
                  placement: "bottom",
                  style: { backgroundColor: "#2ECC71" },
                });
              }
              setisLoading(false);
              return;
            } else {
              setFactura(true);
            }
          }
        }
      } else {
        alert("Seleccionar un PDF");
      }
    } catch (error) {
      alert(error);
    }

    setisLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <TouchableOpacity
            style={{ marginLeft: "10px", marginTop: "10px" }}
            onPress={() => navigation.toggleDrawer()}
          >
            <Icon
              size="8"
              as={<MaterialIcons name="menu" />}
              color={quodomTheme.COLORS["COLORVIOLETA"]}
            />
          </TouchableOpacity>
          <VStack
            flex={0.6}
            marginTop={"3%"}
            borderWidth={0}
            alignItems="center"
          >
            <Box
              w="100%"
              mx="auto"
              backgroundColor="#F6EE5D"
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              <Center>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Image
                    source={require("../../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"2%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </TouchableOpacity>
                <Text style={QuodomDetalle.textoTituloChicoVendiste}>
                  ¡Felicitaciones!
                </Text>
                <Text style={QuodomDetalle.textoTituloChicoEligio}>
                  {nombreusuariocomprador} eligió{" "}
                  {cotizacion.cantidadlineasCotizadas} productos
                </Text>
              </Center>
              <FlatList
                /* refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />} */
                //itemDimension={100}
                data={posts}
                //spacing={20}
                ListHeaderComponent={
                  <HStack
                    flex={1}
                    mb="2"
                    ml="1"
                    mr="1"
                    bg="#fff"
                    p="3"
                    space={0.7}
                    rounded={8}
                  >
                    <HStack>
                      <VStack justifyContent="left">
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomDetalle.textoInfoCompradorBold}
                        >
                          Datos del comprador
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomDetalle.textoInfoComprador}
                        >
                          Nombre:{" "}
                          <Text style={QuodomDetalle.textoInfoCompradorBold}>
                            {infoComprador.NombreComprador}
                          </Text>
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomDetalle.textoInfoComprador}
                        >
                          Telefono:{" "}
                          <Text style={QuodomDetalle.textoInfoCompradorBold}>
                            {infoComprador.telefono}
                          </Text>
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomDetalle.textoInfoComprador}
                        >
                          Email:{" "}
                          <Text style={QuodomDetalle.textoInfoCompradorBold}>
                            {infoComprador.email}
                          </Text>
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={2}
                          style={QuodomDetalle.textoInfoComprador}
                        >
                          Dirección:{" "}
                          <Text style={QuodomDetalle.textoInfoCompradorBold}>
                            {infoComprador.Direccion}, {infoComprador.Localidad}
                            , {infoComprador.cp}
                          </Text>
                        </Text>
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={2}
                          style={QuodomDetalle.textoInfoComprador}
                        >
                          Fecha Entrega:{" "}
                          <Text style={QuodomDetalle.textoInfoCompradorBold}>
                            {CortarFecha(ch.fechaentrega)}
                          </Text>
                        </Text>
                      </VStack>
                      <VStack
                        justifyContent="right"
                        style={{ paddingLeft: "40%" }}
                      >
                        <HStack>
                          <Text style={QuodomDetalle.textoPrecioTotalVendido2}>
                            Total:{" "}
                            {cotizacion.totalvendido.toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                            })}
                          </Text>
                          <DetalleImporteVenta
                            total={parseFloat(cotizacion.totalvendido) * 1}
                          ></DetalleImporteVenta>
                        </HStack>
                        {!productosEntregados ? (
                          <Button
                            borderColor="#000"
                            size="md"
                            variant="outline"
                            _text={{
                              color: "#000",
                            }}
                            //onPress={() => setModalVisible(true)}
                            //onPress={() => navigation.navigate('ResumenPago', { idquodom: idquodom, nombreQuodom: nombreQuodom })}
                            onPress={() => toggleSwitch()}
                          >
                            <HStack
                              space={2}
                              alignItems="center"
                              style={{ textAlign: "center", padding: 0 }}
                            >
                              <Text
                                style={{
                                  color: "#000",
                                  fontSize: 14,
                                }}
                              >
                                Productos entregados
                              </Text>
                            </HStack>
                          </Button>
                        ) : (
                          <HStack
                            space={1}
                            bg="#FFFFFF"
                            alignItems="center"
                            style={{
                              backgroundColor: "#FFFFFF",
                              textAlign: "center",
                              padding: 4,
                            }}
                          >
                            <Entypo
                              name="check"
                              size={moderateScale(15)}
                              color="#2DAB66"
                            />
                            <Text
                              style={{
                                color: "#2DAB66",
                                fontSize: 14,
                              }}
                            >
                              Productos Entregados
                            </Text>
                          </HStack>
                        )}
                        {!factura ? (
                          <Button
                            borderColor="#000"
                            size="md"
                            variant="outline"
                            _text={{
                              color: "#000",
                            }}
                            //onPress={() => setModalVisible(true)}
                            //onPress={() => navigation.navigate('ResumenPago', { idquodom: idquodom, nombreQuodom: nombreQuodom })}
                            onPress={() => pickDoc()}
                          >
                            <HStack
                              space={2}
                              alignItems="center"
                              style={{ textAlign: "center", padding: 0 }}
                            >
                              {/* <MaterialCommunityIcons name="credit-card-check-outline" size={18} color="#000"></MaterialCommunityIcons> */}
                              {/*  <FontAwesome5 name="file-invoice-dollar" size={18} color="black" /> */}
                              <Text
                                style={{
                                  color: "#000",
                                  fontSize: 14,
                                }}
                              >
                                Adjuntar Factura
                              </Text>
                            </HStack>
                          </Button>
                        ) : (
                          <HStack
                            space={1}
                            bg="#FFFFFF"
                            alignItems="center"
                            style={{
                              backgroundColor: "#FFFFFF",
                              textAlign: "center",
                              padding: 4,
                            }}
                          >
                            <Entypo
                              name="check"
                              size={moderateScale(15)}
                              color="#2DAB66"
                            />
                            <Text
                              style={{
                                color: "#2DAB66",
                                fontSize: 14,
                              }}
                            >
                              Factura adjuntada
                            </Text>
                          </HStack>
                        )}
                      </VStack>
                    </HStack>
                  </HStack>
                }
                ListFooterComponent={<Center h={20}></Center>}
                renderItem={({ item }) => (
                  <OfertasRenderItemAceptada
                    nombreproducto={item.nombreproducto}
                    marcavendedor={item.marcavendedor}
                    enviovendedor={stringToBoolean(item.enviovendedor)}
                    marca={item.marca}
                    unidad={item.unidad}
                    cantidad={item.cantidad}
                    imagen={item.imagen}
                    refreshImagen={item.refreshImagen}
                    atributosFaltantes={item.atributosFaltantes}
                    idproducto={item.idproducto}
                    id={item.id}
                    idquodom={item.idquodom}
                    actualizarestado={cambiarState}
                    incluirenvio={item.incluirenvio}
                    idcotizacion={item.idcotizacion}
                    preciounitario={item.preciounitario}
                    imagendelvendedor={item.imagendelvendedor}
                    atributo1={item.atributo1}
                    atributo2={item.atributo2}
                    atributoNombre1={item.nombreAtributo1}
                    atributoNombre2={item.nombreAtributo2}
                    nombrecategoria={item.nombrecategoria}
                    vendedor_atributo1={item.vendedor_atributo1}
                    vendedor_atributo2={item.vendedor_atributo2}
                    vendedor_nombreAtributo1={item.vendedor_nombreAtributo1}
                    vendedor_nombreAtributo2={item.vendedor_nombreAtributo2}
                  ></OfertasRenderItemAceptada>
                )}
                keyExtractor={(item) => item.id.toString()}
              />
            </Box>
          </VStack>
          <VStack flex={0.4}>
            <ListaMisCotizacionesInicial
              actualizarLista={visible}
            ></ListaMisCotizacionesInicial>
          </VStack>
        </HStack>
      </View>
    );
  }
};

export default OfertasItemAceptada;
