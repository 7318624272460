import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import {
  Box,
  Text,
  Center,
  HStack,
  FlatList,
  Button,
  Spacer,
  VStack,
  useToast,
} from "native-base";
import { Checkbox } from "react-native-paper";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { hp, moderateScale, wp } from "../../styles/responsive";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";

import {
  ObtenerDireccionesUsuario,
  ModificarQuodom,
  ObtenerLocalidadPorID,
} from "../../api";
import quodomTheme from "../../constants/Theme";
import { colorFondoGeneral } from "../../styles/general";
import { MaterialIcons, Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import ContentLoader, { Rect } from "react-content-loader/native";

const DireccionEnvioQuodom = (props) => {
  const toast = useToast();
  const id = "id_toast";

  /*const { iddireccion, idquodom } = ["", ""];
  if (props == !undefined) {
    iddireccion = props.iddireccion;
    idquodom = props.idquodom;
  }*/
  const loadingData = [{ id: "1" }, { id: "2" }, { id: "3" }];

  const [postData, setpostData] = useState([]);
  const [iddireccionState, setiddireccionState] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [isUpdating, setisUpdating] = useState(false);

  const obtenerDirecciones = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const direcciones = await ObtenerDireccionesUsuario(value);

    setpostData(direcciones);
    setisLoading(false);
  };

  const check = (id) => {
    if (iddireccionState === id) {
      return "checked";
    }
    return "unchecked";
  };

  const Actualizar = async (id, idpartido) => {
    const token = await AsyncStorage.getItem("userToken");
    const LocData = await ObtenerLocalidadPorID(token, idpartido);
    //console.log("diree", LocData);

    if (LocData.comprador) {
      setiddireccionState(id);
    } else {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Zona no habilitada",
          status: "message",
          description:
            "No puede utilizar esta direccion, esta zona aun no fue habilitada",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
    }
  };

  const ActualizarDireccionEnvio = async () => {
    setisUpdating(true);
    let Data = {
      iddireccion: iddireccionState,
    };
    //console.log("quodom a guardar", props.idquodom);
    if (props.idquodom != "" && props.idquodom !== undefined) {
      let value = await AsyncStorage.getItem("userToken");
      let ret = await ModificarQuodom(value, props.idquodom, Data);
      //console.log("dev", ret);
      /*   props.actualizarDetalle(true);
              props.actualizarDetalle(false);
              props.actualizarNodo(true);
              props.actualizarNodo(false); */
    }
    //console.log(iddireccionState);
    props.cambiarId(iddireccionState);
    setisUpdating(false);
    props.actualizarmodal(false);
    props.actualizarpage(true);
    //   navigation.goBack();
  };

  const ActualizarScreen = async () => {
    setisLoading(true);
    await obtenerDirecciones();
    //console.log("Entre ActualizarScreen DireccionEnvioQuodom.js");
    setisLoading(false);
  };

  useEffect(() => {
    setiddireccionState(props.iddireccion);
    obtenerDirecciones();
  }, []);

  return (
    <VStack flex={1}>
      <View style={{ flex: 1, alignContent: "center" }}>
        {isUpdating ? (
          <Center flex="1">
            <ActivityIndicator size="large" color="#706F9A" />
          </Center>
        ) : (
          <VStack pt="5">
            {isLoading ? (
              <FlatList
                maxH={hp(55)}
                bg="#fff"
                shadow="1"
                borderRadius="2"
                space={0}
                data={loadingData}
                renderItem={({ item }) => (
                  <Box
                    borderBottomWidth="1"
                    _dark={{
                      borderColor: "gray.600",
                    }}
                    borderColor="coolGray.200"
                    pl="6"
                    pr="5"
                    py="2"
                    bg="#fff"
                  >
                    <ContentLoader
                      animate={false}
                      speed={0.5}
                      width={500}
                      height={50}
                      viewBox="0 0 500 50"
                      backgroundColor="#cacace"
                      foregroundColor="#fdfdfc"
                    >
                      <Rect x="40" y="5" rx="2" ry="0" width="70" height="5" />
                      <Rect x="3" y="13" rx="2" ry="0" width="20" height="20" />
                      <Rect
                        x="40"
                        y="20"
                        rx="2"
                        ry="0"
                        width={wp(80)}
                        height="5"
                      />
                      <Rect
                        x="40"
                        y="35"
                        rx="2"
                        ry="0"
                        width={wp(80)}
                        height="5"
                      />
                    </ContentLoader>
                  </Box>
                )}
                keyExtractor={(item) => item.id}
              />
            ) : (
              <FlatList
                maxH={hp(60)}
                bg="#fff"
                shadow="1"
                borderRadius="2"
                space={0}
                //extraData={selectedId}

                data={postData}
                renderItem={({ item, index }) => (
                  <TouchableOpacity
                    onPress={() => {
                      Actualizar(item.id, item.idpartido);
                    }}
                  >
                    <Box
                      borderBottomWidth="1"
                      _dark={{
                        borderColor: "gray.600",
                      }}
                      borderColor="coolGray.200"
                      pl="6"
                      pr="5"
                      py="2"
                      bg="#fff"
                    >
                      <HStack space={3} justifyContent="space-between">
                        <VStack justifyContent="center">
                          <Checkbox
                            color="#706F9A"
                            status={check(item.id)}
                            onPress={() => {
                              Actualizar(item.id, item.idpartido);
                            }}
                          />
                        </VStack>
                        <VStack>
                          <Text style={QuodomDetalle.textoItemUbicacionBold}>
                            {item.alias}
                          </Text>
                          <Text style={QuodomDetalle.textoItemUbicacion}>
                            {item.direccion} - CP {item.cp}
                          </Text>
                          <Text style={QuodomDetalle.textoItemUbicacion}>
                            {item.localidad}, {item.provincia}
                          </Text>
                        </VStack>
                        <Spacer />
                        {item.default ? (
                          <VStack justifyContent="center">
                            <Text
                              style={QuodomDetalle.textoItemUbicacionPrincipal}
                            >
                              Principal
                            </Text>
                          </VStack>
                        ) : null}
                      </HStack>
                    </Box>
                  </TouchableOpacity>
                )}
                keyExtractor={(item) => item.id.toString()}
              />
            )}
          </VStack>
        )}
      </View>

      {/* <VStack pb='2' pl='2' pr='2' safeAreaBottom bg='#fff' shadow="3" p="3" mt="2" borderRadius="0" space={2} justifyContent='flex-start'>
                <VStack>
                    <Button bg='#706F9A' onPress={() => ActualizarDireccionEnvio()}>Aceptar</Button>
                </VStack>
            </VStack> */}

      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="2"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="center"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "90%",
          }}
          onPress={() => ActualizarDireccionEnvio()}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Aceptar</Text>
        </TouchableOpacity>
      </HStack>
    </VStack>
  );
};

export default DireccionEnvioQuodom;
