import React, { useEffect, useState, useRef } from 'react';
import { VStack, HStack, Text, Center, Input, useToast, FormControl, ScrollView, Image, Box } from "native-base";
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from '@react-native-async-storage/async-storage';

import { StyleSigInScreen, QuodomDetalle, StylePerfil } from '../../styles/StyleQuodom';
import { wp } from '../../styles/responsive';
import SliderComprador from './component/SliderComprador';

const SlidesNew1 = () => {
    const mounted = useRef(true);

    const toast = useToast();
    const id = "id_toast";
    const navigation = useNavigation();

    const [user, setUser] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const SiguentePaso = async () => {
        navigation.navigate('SlidesNew2');
    };


    useEffect(() => {
        setIsLoading(false);
        mounted.current = true;
        return () => mounted.current = false;
    }, []);


    return (
        <VStack flex={1} >

            {isLoading ?

                <Center flex='1'>
                    <ActivityIndicator size="large" color="#706F9A" />
                </Center>

                :
                <View style={{ flex: 1, backgroundColor: '#fff', alignContent: 'center' }}>
                       <SliderComprador   />
                </View>
            }
      

        </VStack>

    );
};


export default SlidesNew1