import React, { useState } from "react";
import { View, Image, Text, TouchableOpacity } from "react-native";
import {
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias } from "../../styles/general";
import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { moderateScale, wp } from "../../styles/responsive";

import {
  Box,
  Modal,
  Center,
  HStack,
  Stack,
  VStack,
  Button,
  Input,
} from "native-base";

const DetalleProductoEnviado = (props) => {
  var imagen = require("../../assets/line_zigzag.png");

  const [valorAtributo1, setvalorAtributo1] = useState(props.atributo1);
  const [valorAtributo2, setvalorAtributo2] = useState(props.atributo2);
  const [valorNameAtributo1, setvalorNameAtributo1] = useState(
    props.atributoNombre1
  );
  const [valorNameAtributo2, setvalorNameAtributo2] = useState(
    props.atributoNombre2
  );
  const [cant, setcant] = useState(props.cantidad);

  const [modalVisibleEliminar, setModalVisibleEliminar] = useState(false);
  const [minimizadoValue, setMininizadoValue] = useState(true);

  let detallequodomcompleto = false;
  const atr1 = valorNameAtributo1 !== "" && valorNameAtributo1 != null;
  const atr2 = valorNameAtributo2 !== "" && valorNameAtributo2 != null;

  const debugBorder = 0;
  const paddingAtr = atr1 ? (atr2 ? 24 : 16) : 8;

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const CardMinimizado = () => {
    return (
      <View
        style={{
          justifyContent: "center",
          width: "100%",
          marginLeft: 10,
          marginRight: 10,
          marginBottom: 2,
          marginTop: 0,
        }}
      >
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => setMininizadoValue(false)}
        >
          <Box
            rounded={11}
            /*  overflow="hidden" */
            width="100%"
            shadow={0}
            marginTop={4}
            backgroundColor="#2dab66"
            zIndex={1}
          >
            <HStack
              space={1}
              bg="#fff"
              borderWidth={debugBorder}
              borderTopLeftRadius="11"
              borderTopRightRadius="11"
              alignItems="center"
            >
              <Image
                source={{
                  uri: props.imagen,
                }}
                //uri={props.imagen} // image address
                //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                style={estilosCategorias.ImagenDetalleQuodom}
              />

              <Text style={DetalleProductoQuodomstyle.textoTituloNegro}>
                {props.nombreProducto}
              </Text>
              {/*  <Text style={DetalleProductoQuodomstyle.textoCategoria}>{props.nombreCategoria}</Text> */}
              <VStack pl={3} borderWidth={0}>
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={35}
                  color="#000"
                  onPress={() => setMininizadoValue(false)}
                />
              </VStack>
            </HStack>

            {/*     <View style={{ paddingTop: 30, paddingRight: 0, margin: 0, position: 'absolute', top: 0, right: 23, zIndex: 1000 }}>
                        <MaterialIcons name="keyboard-arrow-down" size={25} color='#FFFFFF'
                            onPress={() => setMininizadoValue(false)} />
                    </View>
 */}

            <HStack space={2} mb={3} ml={2} mt={2}>
              <HStack space={1} alignItems="center">
                <FontAwesome
                  name="check"
                  size={moderateScale(14)}
                  color="white"
                />
                {/* <Text style={DetalleProductoQuodomstyle.textoEstadoNodoQuodomCompletoMinimizado}>COMPLETO</Text> */}
              </HStack>
              {/*  <View
                            style={{
                                borderLeftWidth: 1,
                                borderLeftColor: 'white',
                            }}
                        ></View> */}
              <HStack space={2} pt="1" pb="1">
                <Text
                  style={{
                    textAlign: "center",
                    padding: 2,
                    color: "#FFFFFF",
                    fontSize: 12,

                    borderWidth: 1,
                    borderColor: "#FFFFFF",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 3,
                  }}
                >
                  Cant. {props.cantidad}{" "}
                </Text>
                {atr1 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      padding: 2,
                      color: "#FFFFFF",
                      fontSize: 12,

                      borderWidth: 1,
                      borderColor: "#FFFFFF",
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 3,
                    }}
                  >
                    {capitalize(props.atributoNombre1)} {props.atributo1}{" "}
                  </Text>
                ) : null}
                {atr2 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      padding: 2,
                      color: "#FFFFFF",
                      fontSize: 12,

                      borderWidth: 1,
                      borderColor: "#FFFFFF",
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 3,
                    }}
                  >
                    {capitalize(props.atributoNombre2)} {props.atributo2}{" "}
                  </Text>
                ) : null}
              </HStack>
            </HStack>
          </Box>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View
      style={{
        justifyContent: "center",
        width: "45%",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        marginTop: 0,
        zIndex: 10,
      }}
    >
      {minimizadoValue ? (
        <CardMinimizado />
      ) : (
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 2,
            marginTop: 0,
          }}
        >
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => setMininizadoValue(true)}
          >
            <Box
              rounded={11}
              /*  overflow="hidden" */
              width="100%"
              shadow={0}
              marginTop={4}
              backgroundColor="#2dab66"
              zIndex={1}
            >
              <HStack
                space={1}
                bg="#fff"
                borderWidth={debugBorder}
                borderTopLeftRadius="11"
                borderTopRightRadius="11"
                alignItems="center"
              >
                <VStack>
                  <Image
                    source={{
                      uri: props.imagen,
                    }}
                    //uri={props.imagen} // image address
                    //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                    style={estilosCategorias.ImagenDetalleQuodom}
                  />
                </VStack>
                <VStack>
                  <Text style={DetalleProductoQuodomstyle.textoTituloNegro}>
                    {props.nombreProducto}
                  </Text>
                  {/* <Text style={DetalleProductoQuodomstyle.textoCategoria}>{props.nombreCategoria}</Text> */}
                </VStack>

                <VStack pl={3} borderWidth={0}>
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={35}
                    color="#000"
                    onPress={() => setMininizadoValue(true)}
                  />
                </VStack>
              </HStack>

              <HStack
                space={2}
                marginLeft={5}
                marginTop={3}
                borderWidth={debugBorder}
              >
                <Text
                  style={{
                    textAlign: "center",
                    padding: 3,
                    color: "#FFFFFF",
                    fontSize: 13,

                    borderWidth: 1,
                    borderColor: "#FFFFFF",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 5,
                  }}
                >
                  {props.cantProveedores} proveedores
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    padding: 3,
                    color: "#FFFFFF",
                    fontSize: 13,

                    borderWidth: 1,
                    borderColor: "#FFFFFF",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 5,
                  }}
                >
                  {props.cantEnZona} en tu zona
                </Text>
              </HStack>

              <HStack
                space={2}
                marginLeft={2}
                marginRight={2}
                marginTop={3}
                marginBottom={2}
                borderWidth={debugBorder}
              >
                <Box w="40%" borderWidth={debugBorder}>
                  <VStack
                    space={1}
                    alignItems="center"
                    justifyContent="center"
                    borderWidth={debugBorder}
                  >
                    <HStack
                      space={1}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <FontAwesome
                        name="check"
                        size={moderateScale(17)}
                        color="white"
                        style={{ alignContent: "center", zIndex: 1 }}
                      />
                      <Text
                        style={
                          DetalleProductoQuodomstyle.textoEstadoNodoQuodomCompleto
                        }
                      >
                        COMPLETO
                      </Text>
                    </HStack>
                    <Image
                      source={imagen}
                      style={{ width: wp(30), height: 8 }}
                      resizeMode="contain"
                    ></Image>
                  </VStack>

                  <VStack pt={paddingAtr} pr={4} alignItems="flex-end">
                    <Text style={QuodomList_Card.itemSinFaltanDetalles}>
                      ¡LISTO!
                    </Text>
                    <Text style={QuodomList_Card.itemSinFaltanDetallesChico}>
                      Ingresado con éxito
                    </Text>
                  </VStack>
                </Box>

                <View
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "white",
                    marginBottom: 5,
                    marginTop: 5,
                  }}
                />

                <Box w="55%" marginBottom={0}>
                  <HStack w="100%" pb={2} space={0} alignItems="center">
                    <HStack
                      w="100%"
                      pb={2}
                      pt={2}
                      space={2}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text style={[QuodomDetalle.itemValorCampo]}>
                        Cantidad:
                      </Text>
                      <VStack
                        h="9"
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          textAlign: "center",
                          borderWidth: 1,
                          borderColor: "#FFFFFF",
                          shadowRadius: 0,
                          shadowOpacity: 0,
                          elevation: 0,
                          borderRadius: 20,
                        }}
                      >
                        <Text
                          style={{
                            textAlign: "center",
                            color: "#FFFFFF",
                            fontSize: 13,
                          }}
                        >
                          {cant}
                        </Text>
                      </VStack>
                    </HStack>
                  </HStack>

                  {atr1 ? (
                    <View>
                      <View
                        style={{
                          borderBottomColor: "#FFFFFF",
                          borderBottomWidth: 1,
                        }}
                      />

                      <HStack
                        w="100%"
                        pb={2}
                        pt={2}
                        space={2}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Text style={[QuodomDetalle.itemValorCampo]}>
                          {capitalize(valorNameAtributo1)}:
                        </Text>
                        <VStack
                          h="9"
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            textAlign: "center",
                            borderWidth: 1,
                            borderColor: "#FFFFFF",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 20,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "center",
                              color: "#FFFFFF",
                              fontSize: 13,
                            }}
                          >
                            {valorAtributo1}
                          </Text>
                        </VStack>
                      </HStack>
                    </View>
                  ) : null}

                  {atr2 ? (
                    <View>
                      <View
                        style={{
                          borderBottomColor: "#FFFFFF",
                          borderBottomWidth: 1,
                        }}
                      />
                      <HStack
                        w="100%"
                        pb={2}
                        pt={2}
                        space={0}
                        alignItems="center"
                      >
                        <HStack
                          w="100%"
                          pb={2}
                          pt={2}
                          space={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text style={[QuodomDetalle.itemValorCampo]}>
                            {capitalize(valorNameAtributo2)}:
                          </Text>
                          <VStack
                            h="9"
                            style={{
                              flex: 1,
                              justifyContent: "center",
                              textAlign: "center",
                              borderWidth: 1,
                              borderColor: "#FFFFFF",
                              shadowRadius: 0,
                              shadowOpacity: 0,
                              elevation: 0,
                              borderRadius: 20,
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "center",
                                color: "#FFFFFF",
                                fontSize: 13,
                              }}
                            >
                              {valorAtributo2}
                            </Text>
                          </VStack>
                        </HStack>
                      </HStack>
                    </View>
                  ) : null}
                </Box>
              </HStack>
            </Box>
          </TouchableOpacity>
        </View>
      )}

      {/* MODAL CONFIRMACION ELIMINAR */}
      <Modal
        isOpen={modalVisibleEliminar}
        onClose={() => setModalVisibleEliminar(false)}
      >
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Atención!
            </Text>
          </Modal.Header>
          <Modal.Body pt="5" pb="5">
            <Text style={QuodomDetalle.TextoModalEliminar}>
              Esta seguro de eliminar el producto{" "}
              <Text style={QuodomDetalle.TextoModalEliminarBold}>
                {props.nombreProducto}
              </Text>{" "}
              ?
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "transparent",
                  padding: 10,
                  borderRadius: 5,
                  borderWidth: 1,
                }}
                onPress={() => Eliminar(props)}
              >
                <Text style={QuodomDetalle.TextoModalButtonOK}>Eliminar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => setModalVisibleEliminar(!modalVisibleEliminar)}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Cancelar
                </Text>
              </TouchableOpacity>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  );
};
export default DetalleProductoEnviado;
