import React from 'react';
import { TouchableOpacity } from "react-native";
import { NativeBaseProvider, Stack, Box, Divider, VStack, HStack, Text, Center, Heading, View, ScrollView, Image } from 'native-base';
import { StyleSigInScreen, useFonts, Prompt_300Light, Prompt_400Regular, Prompt_500Medium } from '../styles/StyleQuodom';
import { useNavigation } from '@react-navigation/native';
import { Avatar, Button, Card, Title, Paragraph } from 'react-native-paper';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { hp, wp } from '../styles/responsive';

const ElegirCuenta = () => {

  const navigation = useNavigation();

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,

  });

  const LeftContent = props =>
    <Image source={require('../assets/4.png')}
      style={{ width: 60, height: 40 }}
      alt='Cuenta Comprador' />

  const LeftContentVendor = props =>
    <Image source={require('../assets/3i.png')}
      style={{ width: 60, height: 40 }}
      alt='Cuenta Vendedor' />


  return (
    <View flex={1} backgroundColor='#F6EE5D'>

      <HStack safeArea bg='#F6EE5D' px={2} py={2} justifyContent='space-between' alignItems='center'>
        <HStack space={4} alignItems='center'>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack space={2} alignItems='center' pt='3' pr='3' pb='3'>
              <Ionicons name="arrow-back-sharp" size={25} color="#000" />
            </HStack>
          </TouchableOpacity>
        </HStack>
      </HStack>



      <VStack>
        <Box alignItems="center" pt='1' pb='5' >
          <Text style={StyleSigInScreen.textIniciarSession}>Elegi que tipo de cuenta queres crear en Quodom</Text>
        </Box>
        <ScrollView
          _contentContainerStyle={{
            w: "100%",
          }}
          height="100%"
          backgroundColor='#F6EE5D'
        >
          <Box
            p={2}
            w="100%"
            backgroundColor='#F6EE5D'
          >
            <Card>
              <Card.Title titleStyle={StyleSigInScreen.textTituloCrearCuenta} title="Cuenta de Comprador" left={LeftContent} />
              <Card.Content>
                <Text style={StyleSigInScreen.textoLoginChico}>
                  Crea Quodoms y envialos a cotizar, elegi los mejores precios y compra!
                </Text>
                <Text style={StyleSigInScreen.textoLoginChico}>
                  Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500
                </Text>
              </Card.Content>

              <Card.Actions>
                <Box width='100%' alignItems='flex-end'>
                  <Button
                    onPress={() => navigation.navigate('SignUpScreen')}>
                    <Text style={StyleSigInScreen.textoContinuar}>Continuar</Text></Button>
                </Box>
              </Card.Actions>
            </Card>
          </Box>


          <Box
            p={2}
            w="100%"
            backgroundColor='#F6EE5D'
          >
            <Card>
              <Card.Title titleStyle={StyleSigInScreen.textTituloCrearCuenta} title="Cuenta de Vendedor" left={LeftContentVendor} />
              <Card.Content>
                <Text style={StyleSigInScreen.textoLoginChico}>
                  Cotiza productos, envialas cotizaciones y espera que alguien eliga tus productos!
                </Text>
                <Text style={StyleSigInScreen.textoLoginChico}>
                  Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias desde el año 1500
                </Text>
              </Card.Content>

              <Card.Actions>
                <Box width='100%' alignItems='flex-end'>
                  <Button
                    onPress={() => navigation.navigate('SignUpScreenVendor')}>
                    <Text style={StyleSigInScreen.textoContinuar}>Continuar</Text></Button>
                </Box>
              </Card.Actions>
            </Card>
          </Box>
          <Center h={hp(35)}>

          </Center>
        </ScrollView>
      </VStack>

    </View >

  );
}


export default () => {
  return (
    <NativeBaseProvider>
      <ElegirCuenta />
    </NativeBaseProvider>
  )
}