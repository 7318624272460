import React, { useEffect, useState, useRef } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico } from "../../styles/general";
import { useNavigation } from "@react-navigation/native";
import {
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome5,
  Entypo,
} from "@expo/vector-icons";
import { hp, wp, moderateScale } from "../../styles/responsive";
import {
  ObtenerResumenVendedor,
  ObtenerInfoVendedor,
  ObtenerCotQudomProv,
  ObtenerFactura,
} from "../../api";

import {
  Center,
  HStack,
  Stack,
  VStack,
  FlatList,
  Divider,
  Switch,
  useToast,
} from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader, { Rect } from "react-content-loader/native";
import Moment from "moment";

import {
  checkFileIsAvailable,
  downloadFileFromUri,
  openDownloadedFile,
} from "expo-downloads-manager";

const ResumenItem = (props) => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const [posts, setPosts] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const loadingData = [{ id: "1" }, { id: "2" }];
  const [ch, setCh] = useState([]);
  const [productosRecibidos, setproductosRecibidos] = useState(false);
  const toast = useToast();
  const id = "id_toast";
  const [downloadStatus, setDownloadStatus] = React.useState("NOTSTARTED");
  const [downloadProgress, setDownloadProgress] = React.useState(0);
  const [URL, setURL] = useState("");
  const callback = (downloadProgress) => {
    const progress =
      downloadProgress.totalBytesWritten /
      downloadProgress.totalBytesExpectedToWrite;
    setDownloadProgress(progress);
  };

  async function checkAvail() {
    const { isAvailable } = await checkFileIsAvailable(fileName);
    if (isAvailable) {
      setDownloadStatus("FINISHED");
    }
  }

  const Verfactura2 = async () => {
    //console.log("entre");
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const getFactura = await ObtenerFactura(tokenLeer, ch.id, props.idvendor);
    //console.log(getFactura);
    const pdfUrl = "data:application/pdf;base64," + getFactura.archivo;

    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "factura.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    /* if (getFactura) {
      ////console.log(' getFactura.archivo', getFactura.archivo);
      //setURL("data:application/pdf;base64," + getFactura.archivo);
      navigation.navigate("WebPdf", {
        idcotizacion: ch.id,
        usuariovendedor: props.idvendor,
        url: "data:application/pdf;base64," + getFactura.archivo,
      });
      // navigation.navigate('WebPdf', { url: 'https://img.quodom.com.ar/pdf/file.pdf'  })
    }*/
  };

  const toggleSwitch = () => {
    ////console.log("Vendedor", props.idvendor);
    if (productosRecibidos === false) {
      navigation.navigate("CalificacionVendedor", {
        idcotizacion: ch.id,
        idVendor: props.idvendor,
        idquodom: props.idquodom,
        actualizarEstado: actualizarEstado,
      });
    } else {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "¡Atención!",
          status: "message",
          description:
            "Ya se calificó e indico que los productos fueron recibidos",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
    }
  };

  const actualizarEstado = (Item) => {
    ////console.log("actualizarEstado", Item);
    /* const newData = ch.map(item => {
            if (item.id === Item) {
                item.productosEntregados = true
                return item
            }
            return item
        })
        setCh(newData); */
    setproductosRecibidos(true);
  };

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() ResumenItem.js");
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerResumenVendedor(
      tokenLeer,
      props.idquodom,
      props.idvendor
    );
    if (mounted.current) {
      ////console.log('Leer', postsData)
      setPosts(postsData);
    }
  };

  const InfoVendor = async () => {
    //console.log("Entre al InfoVendor() ResumenItem.js");
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const Data = await ObtenerInfoVendedor(
      tokenLeer,
      props.idvendor,
      props.idquodom
    );
    const cotHeader = await ObtenerCotQudomProv(
      tokenLeer,
      props.idquodom,
      props.idvendor
    );
    //console.log("cott", cotHeader);

    if (mounted.current) {
      setVendor(Data);
      setCh(cotHeader);
      //if (cotHeader.productosRecibidos === "true") {
      setproductosRecibidos(cotHeader.productosRecibidos);
      // } else {
      //   setproductosRecibidos(false);
      //}

      setisLoading(false);
    }
  };

  useEffect(() => {
    //console.log("Entre al useEffect ResumenItem.js");
    mounted.current = true;

    LeerDatos();
    InfoVendor();
    return () => (mounted.current = false);
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <View>
        {isLoading ? (
          <></>
        ) : (
          <FlatList
            showsVerticalScrollIndicator={false}
            data={posts}
            ListFooterComponent={
              <View>
                <HStack justifyContent="space-between" alignItems="center">
                  <VStack w="50%" borderWidth={0}>
                    <Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                          fontFamily: "Prompt_400Regular",
                        }}
                      >
                        Vendedor:
                      </Text>
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {vendor.RazonSocial}
                      </Text>
                    </Text>
                    <Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                          fontFamily: "Prompt_400Regular",
                        }}
                      >
                        Teléfono:
                      </Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {vendor.telefono}
                      </Text>
                    </Text>
                    <Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                          fontFamily: "Prompt_400Regular",
                        }}
                      >
                        Dirección:
                      </Text>
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {vendor.direccion}, {vendor.Localidad},{" "}
                        {vendor.provincia}
                      </Text>
                    </Text>
                    <Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                          fontFamily: "Prompt_400Regular",
                        }}
                      >
                        Fecha Entrega:
                      </Text>
                      <Text
                        style={{
                          color: "#4D4D4D",
                          fontSize: 16,
                        }}
                      >
                        {" "}
                        {Moment(ch.fechaentrega).format("DD/MM/YYYY")}
                      </Text>
                    </Text>
                  </VStack>
                </HStack>

                <HStack
                  pt="3"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <VStack>
                    <TouchableOpacity onPress={() => Verfactura2()}>
                      <HStack
                        space={1}
                        bg="#fff"
                        alignItems="center"
                        style={{
                          backgroundColor: "#F2F2F2",
                          textAlign: "center",
                          padding: 6,
                          borderWidth: 1,
                          borderColor: "#4D4D4D",
                          shadowRadius: 0,
                          shadowOpacity: 0,
                          elevation: 0,
                          borderRadius: 5,
                        }}
                      >
                        <FontAwesome5
                          name="file-invoice-dollar"
                          size={moderateScale(10)}
                          color="#4D4D4D"
                        />
                        <Text
                          style={{
                            color: "#4D4D4D",
                            fontSize: moderateScale(6),
                          }}
                        >
                          Ver Factura
                        </Text>
                      </HStack>
                    </TouchableOpacity>
                  </VStack>
                  <VStack>
                    {!productosRecibidos ? (
                      <TouchableOpacity onPress={() => toggleSwitch()}>
                        <HStack
                          space={1}
                          bg="#fff"
                          alignItems="center"
                          style={{
                            backgroundColor: "#F2F2F2",
                            textAlign: "center",
                            padding: 6,
                            borderWidth: 1,
                            borderColor: "#4D4D4D",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 5,
                          }}
                        >
                          <Text
                            style={{
                              color: "#4D4D4D",
                              fontSize: moderateScale(6),
                            }}
                          >
                            Recibi los productos
                          </Text>
                        </HStack>
                      </TouchableOpacity>
                    ) : (
                      <HStack
                        space={1}
                        bg="#fff"
                        alignItems="center"
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          padding: 4,
                        }}
                      >
                        <Entypo
                          name="check"
                          size={moderateScale(15)}
                          color="#2DAB66"
                        />
                        <Text
                          style={{
                            color: "#2DAB66",
                            fontSize: moderateScale(12),
                          }}
                        >
                          Productos Recibidos
                        </Text>
                      </HStack>
                    )}
                  </VStack>
                </HStack>
              </View>
            }
            renderItem={({ item, index }) => (
              <View>
                <HStack pt="1" pb="1" borderWidth={0}>
                  <HStack justifyContent="flex-start" space={2}>
                    <VStack justifyContent="center" w="50%" borderWidth={0}>
                      <Text
                        style={
                          DetalleProductoQuodomstyle.textoTituloCotizadoResumen
                        }
                      >
                        <Text
                          style={
                            DetalleProductoQuodomstyle.textoTituloCotizadoResumen2
                          }
                        >
                          {item.cantidad}x
                        </Text>{" "}
                        {item.nombreProducto}
                      </Text>
                    </VStack>

                    <View
                      style={{
                        borderLeftWidth: 1,
                        borderLeftColor: "#4D4D4D",
                      }}
                    />

                    <VStack justifyContent="center" borderWidth={0} w="50%">
                      {item.envio ? (
                        <HStack alignItems="center" space={1}>
                          <Text style={QuodomDetalle.TextoPrecioResumen}>
                            {item.preciounitario.toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                            })}
                          </Text>
                          <HStack
                            space={1}
                            w="30%"
                            bg="#fff"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              textAlign: "center",
                              padding: 3,
                              borderWidth: 1,
                              borderColor: "#4D4D4D",
                              shadowRadius: 0,
                              shadowOpacity: 0,
                              elevation: 0,
                              borderRadius: 5,
                            }}
                          >
                            <MaterialCommunityIcons
                              name="truck-outline"
                              size={17}
                              color="#4D4D4D"
                            />
                            <Text
                              style={{
                                color: "#4D4D4D",
                                fontSize: 15,
                              }}
                            >
                              Envio Incluido
                            </Text>
                          </HStack>
                        </HStack>
                      ) : (
                        <HStack alignItems="center" space={1}>
                          <Text style={QuodomDetalle.TextoPrecioResumen}>
                            {item.preciounitario.toLocaleString("es-AR", {
                              style: "currency",
                              currency: "ARS",
                            })}
                          </Text>
                          <HStack
                            space={1}
                            w="30%"
                            bg="#fff"
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              textAlign: "center",
                              padding: 3,
                              borderWidth: 1,
                              borderColor: "#4D4D4D",
                              shadowRadius: 0,
                              shadowOpacity: 0,
                              elevation: 0,
                              borderRadius: 5,
                            }}
                          >
                            <MaterialIcons
                              name="storefront"
                              size={17}
                              color="#4D4D4D"
                            />
                            <Text
                              style={{
                                color: "#4D4D4D",
                                fontSize: 15,
                              }}
                            >
                              Retiro en tienda
                            </Text>
                          </HStack>
                        </HStack>
                      )}
                    </VStack>
                  </HStack>
                </HStack>
                <Divider my={2} />
              </View>
            )}
            keyExtractor={(item) => item.id.toString()}
          />
        )}
      </View>
    </View>
  );
};
export default ResumenItem;
