import React, { useState, useEffect } from "react";
import {
  TouchableOpacity,
  View,
  Text,
  StyleSheet,
  Modal,
  Pressable,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { ModalStyle } from "../../styles/general";
import {
  MaterialIcons,
  Entypo,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import {
  Center,
  HStack,
  Image,
  VStack,
  Box,
  Icon,
  useToast,
} from "native-base";
import { HEIGHT, hp, moderateScale, WIDTH, wp } from "../../styles/responsive";
import { IgnorarQuodom, ArchivarCH } from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import MenuAcciones3 from "./../../components/MisQuodoms/MenuAcciones3";

const OfertasItem = ({ cotizacion, isLoadingItem }) => {
  const toast = useToast();
  const id = "id_toast";
  const navigation = useNavigation();
  const [modalVisibleEliminar, setModalVisibleEliminar] = useState(false);
  const [addIgnorar, setAddIgnorar] = useState([]);
  const [textProducto, setTextProducto] = useState("");
  const [textProductoCotizado, setTextProductoCotizado] = useState("");

  const navegaraOfertasDetalle = async () => {
    //console.log("CAAAAAA", cotizacion);
    const jsonValue = JSON.stringify(cotizacion);
    await AsyncStorage.removeItem("cotizacionSeleccionada");
    await AsyncStorage.setItem("cotizacionSeleccionada", jsonValue);
    switch (cotizacion.estado) {
      case "COTIZADO": {
        navigation.navigate("OfertasItemEnviado", {
          idcotizacion: cotizacion.idcotizacion,
          idquodom: cotizacion.idquodom,
          nombreusuariocomprador: cotizacion.nombreusuariocomprador,
          cotizacion: cotizacion,
          usuarioVendedor: cotizacion.usuarioVendedor,
        });
        break;
      }

      case "NOVENDIO": {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "No Participaste",
            status: "message",
            description:
              "El Quodom se encuentra finalizado y no participaste de la oferta.",
            placement: "bottom",
            style: { backgroundColor: "#2ECC71" },
          });
        }
        break;
      }

      case "VENDISTE": {
        navigation.navigate("OfertasItemAceptada", {
          idcotizacion: cotizacion.idcotizacion,
          idquodom: cotizacion.idquodom,
          nombreusuariocomprador: cotizacion.nombreusuariocomprador,
          cotizacion: cotizacion,
          usuarioVendedor: cotizacion.usuarioVendedor,
        });
        break;
      }

      case "CERRADO": {
        navigation.navigate("OfertasItemAceptada", {
          idcotizacion: cotizacion.idcotizacion,
          idquodom: cotizacion.idquodom,
          nombreusuariocomprador: cotizacion.nombreusuariocomprador,
          cotizacion: cotizacion,
          usuarioVendedor: cotizacion.usuarioVendedor,
        });
        break;
      }

      case "CREADO": {
        navigation.navigate("OfertasItemList", {
          idquodom: cotizacion.idquodom,
          nombreusuariocomprador: cotizacion.nombreusuariocomprador,
          cotizacion: cotizacion,
          usuarioVendedor: cotizacion.usuarioVendedor,
        });
        break;
      }
    }

    /*  if (cotizacion.estado == 'COTIZADO') {
       
     } else {
       if (cotizacion.estado == 'VENDISTE' && cotizacion.estado == 'CERRADO') {
         
       } else {
         
       }
     } */
  };
  async function Archivar(idquodom, archivar) {
    //clearInterval(interval)
    const token = await AsyncStorage.getItem("userToken");
    ArchivarCH(token, idquodom, archivar);
  }
  async function Ignorar(idquodom) {
    const token = await AsyncStorage.getItem("userToken");
    IgnorarQuodom(token, idquodom);
  }
  const HandleArchivar = (rowKey) => {
    setId(rowKey);
    setvisibleArchivar(true);
    //setoldcotizaciones(cotizaciones);
    Archivar(rowKey, 1);
    const newData = [...cotizaciones];
    const prevIndex = cotizaciones.findIndex(
      (item) => item.idquodom === rowKey
    );
    newData.splice(prevIndex, 1);
    setCotizaciones(newData);
  };

  const HandleIgnorar = (rowKey) => {
    setId(rowKey);
    setvisibleIgnorar(true);
    Ignorar(rowKey);
    const newData = [...cotizaciones];
    const prevIndex = cotizaciones.findIndex(
      (item) => item.idquodom === rowKey
    );
    newData.splice(prevIndex, 1);
    setCotizaciones(newData);
  };
  useEffect(() => {
    if (cotizacion.cantidadlineas <= 1) {
      setTextProducto("Producto");
    } else {
      setTextProducto("Productos");
    }
    if (cotizacion.cantidadlineasCotizadas <= 1) {
      setTextProductoCotizado("Producto");
    } else {
      setTextProductoCotizado("Productos");
    }
  }, []);

  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  return (
    <View>
      {isLoadingItem ? (
        <View style={styles.itemContainer}>
          <Box w="94%" bgColor="#EAEEF7" rounded={5}>
            <HStack
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: hp(13),
              }}
            ></HStack>
          </Box>
        </View>
      ) : (
        <View style={styles.itemContainer}>
          {cotizacion.estado === null || cotizacion.estado === "CREADO" ? (
            cotizacion.diasparavencimientoaceptacion === 3 ? (
              <Box ml="3" mr="3" bg="#FFF" rounded={5} width={"100%"}>
                <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                  <HStack
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: hp(13),
                    }}
                  >
                    <VStack
                      style={{
                        marginLeft: 0,
                        height: hp(13),
                        width: "3%",
                        backgroundColor: "#F6EE5D",
                        borderBottomLeftRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    ></VStack>

                    <VStack>
                      <Image
                        style={{ borderColor: "#000000", borderWidth: 0 }}
                        source={require("../../assets/imgCotizacionPara.png")}
                        alt="texto alternativo"
                        size={50}
                      />
                    </VStack>

                    <VStack
                      style={{
                        width: "48%",
                        borderColor: "#000000",
                        borderWidth: 0,
                      }}
                    >
                      <Text style={QuodomDetalle.textoVence}>
                        Vence el {CortarFecha(cotizacion.fechavencimientoenvio)}
                      </Text>
                      <Text style={QuodomDetalle.textoPedidoDe}>
                        Pedido de cotización de{" "}
                        {cotizacion.nombreusuariocomprador}
                      </Text>
                    </VStack>

                    <VStack
                      style={{
                        alignItems: "center",
                        width: "44%",
                        backgroundColor: "#F6EE5D",
                        borderBottomStartRadius: 20,
                        borderTopEndRadius: 5,
                        borderBottomEndRadius: 5,
                      }}
                    >
                      <VStack
                        style={{ justifyContent: "center", height: hp(13) }}
                        space={2}
                        p="3"
                      >
                        <Text style={styles.itemButtons}>
                          {cotizacion.cantidadlineas} {textProducto}
                        </Text>
                        <Text style={styles.itemButtons}>Restan 72hs</Text>
                      </VStack>
                      <TouchableOpacity
                        style={[styles.backRightBtn, styles.backRightBtnRight]}
                        onPress={() => Ignorar(cotizacion.idquodom)}
                      >
                        <Center>
                          <Icon
                            mb="1"
                            as={
                              <MaterialCommunityIcons
                                name="archive-clock"
                                color="#000"
                              />
                            }
                            color="#000"
                            size="6"
                          />
                          <Text style={styles.backTextWhite}>Ignorar</Text>
                        </Center>
                      </TouchableOpacity>
                    </VStack>
                  </HStack>
                </TouchableOpacity>
              </Box>
            ) : cotizacion.diasparavencimientoaceptacion === 2 ? (
              <Box ml="3" mr="3" bg="#FFF" rounded={5} width={"100%"}>
                <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                  <HStack
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: hp(13),
                    }}
                  >
                    <VStack
                      style={{
                        marginLeft: 0,
                        height: hp(13),
                        width: "3%",
                        backgroundColor: "#F8AE40",
                        borderBottomLeftRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    ></VStack>

                    <VStack>
                      <Image
                        style={{ borderColor: "#000000", borderWidth: 0 }}
                        source={require("../../assets/imgCotizacionPara.png")}
                        alt="texto alternativo"
                        size={50}
                      />
                    </VStack>

                    <VStack
                      style={{
                        width: "44%",
                        borderColor: "#000000",
                        borderWidth: 0,
                      }}
                    >
                      <Text style={QuodomDetalle.textoVence}>
                        Vence el {CortarFecha(cotizacion.fechavencimientoenvio)}
                      </Text>
                      <Text style={QuodomDetalle.textoPedidoDe}>
                        Pedido de cotización de{" "}
                        {cotizacion.nombreusuariocomprador}
                      </Text>
                    </VStack>

                    <VStack
                      style={{
                        alignItems: "center",
                        width: "48%",
                        backgroundColor: "#F8AE40",
                        borderBottomStartRadius: 20,
                        borderTopEndRadius: 5,
                        borderBottomEndRadius: 5,
                      }}
                    >
                      <VStack
                        style={{ justifyContent: "center", height: hp(13) }}
                        space={2}
                      >
                        <Text style={styles.itemButtons48}>
                          {cotizacion.cantidadlineas} {textProducto}
                        </Text>
                        <Text style={styles.itemButtons48}>Restan 48hs</Text>
                      </VStack>
                      <TouchableOpacity
                        style={[styles.backRightBtn, styles.backRightBtnRight]}
                        onPress={() => Ignorar(cotizacion.idquodom)}
                      >
                        <Center>
                          <Icon
                            mb="1"
                            as={
                              <MaterialCommunityIcons
                                name="archive-clock"
                                color="#000"
                              />
                            }
                            color="#000"
                            size="6"
                          />
                          <Text style={styles.backTextWhite}>Ignorar</Text>
                        </Center>
                      </TouchableOpacity>
                    </VStack>
                  </HStack>
                </TouchableOpacity>
              </Box>
            ) : (
              <Box ml="3" mr="3" bg="#FFF" rounded={5} width={"100%"}>
                <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                  <HStack
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: hp(13),
                    }}
                  >
                    <VStack
                      style={{
                        marginLeft: 0,
                        height: hp(13),
                        width: "3%",
                        backgroundColor: "#E96352",
                        borderBottomLeftRadius: 5,
                        borderTopLeftRadius: 5,
                      }}
                    ></VStack>

                    <VStack>
                      <Image
                        style={{ borderColor: "#000000", borderWidth: 0 }}
                        source={require("../../assets/imgCotizacionPara.png")}
                        alt="texto alternativo"
                        size={50}
                      />
                    </VStack>

                    <VStack
                      style={{
                        width: "44%",
                        borderColor: "#000000",
                        borderWidth: 0,
                      }}
                    >
                      <Text style={QuodomDetalle.textoVence}>
                        Vence el {CortarFecha(cotizacion.fechavencimientoenvio)}
                      </Text>
                      <Text style={QuodomDetalle.textoPedidoDe}>
                        Pedido de cotización de{" "}
                        {cotizacion.nombreusuariocomprador}
                      </Text>
                    </VStack>

                    <VStack
                      style={{
                        alignItems: "center",
                        width: "48%",
                        backgroundColor: "#E96352",
                        borderBottomStartRadius: 20,
                        borderTopEndRadius: 5,
                        borderBottomEndRadius: 5,
                      }}
                    >
                      <VStack
                        style={{ justifyContent: "center", height: hp(13) }}
                        space={2}
                        p="3"
                      >
                        <Text style={styles.itemButtons48}>
                          {cotizacion.cantidadlineas} {textProducto}
                        </Text>
                        <Text style={styles.itemButtons48}>Restan 24hs</Text>
                      </VStack>
                      <TouchableOpacity
                        style={[styles.backRightBtn, styles.backRightBtnRight]}
                        onPress={() => Ignorar(cotizacion.idquodom)}
                      >
                        <Center>
                          <Icon
                            mb="1"
                            as={
                              <MaterialCommunityIcons
                                name="archive-clock"
                                color="#000"
                              />
                            }
                            color="#000"
                            size="6"
                          />
                          <Text style={styles.backTextWhite}>Ignorar</Text>
                        </Center>
                      </TouchableOpacity>
                    </VStack>
                  </HStack>
                </TouchableOpacity>
              </Box>
            )
          ) : cotizacion.estado === "COTIZADO" ? (
            <Box ml="3" mr="3" bg="#2DAB66" rounded={5} width={"100%"}>
              <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                <HStack
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: hp(13),
                  }}
                >
                  <VStack
                    style={{
                      marginLeft: 0,
                      height: hp(13),
                      width: "3%",
                      backgroundColor: "#2DAB66",
                      borderBottomLeftRadius: 5,
                      borderTopLeftRadius: 5,
                    }}
                  ></VStack>

                  <VStack>
                    <Image
                      style={{ borderWidth: 0, backgroundColor: "#2DAB66" }}
                      source={require("../../assets/imgCotizacionPara.png")}
                      alt="Enviado"
                      size={50}
                    />
                  </VStack>

                  <VStack
                    style={{
                      width: "53%",
                      borderColor: "#000000",
                      borderWidth: 0,
                    }}
                  >
                    {/*   <Text style={{ color: "#FFFFFF" }} >Enviado el {CortarFecha(cotizacion.fechaenviocotizacion)} </Text>
                      <Text style={{ color: "#FFFFFF", fontWeight: 'bold' }}>Cotización para {cotizacion.nombreusuariocomprador}</Text> */}
                    <Text style={QuodomDetalle.textoVenceW}>
                      Enviado el {CortarFecha(cotizacion.fechaenviocotizacion)}
                    </Text>
                    <Text style={QuodomDetalle.textoPedidoDeW}>
                      Cotización para {cotizacion.nombreusuariocomprador}
                    </Text>
                  </VStack>

                  <VStack
                    style={{
                      alignItems: "center",
                      width: "39%",
                      backgroundColor: "#2DAB66",
                      borderBottomStartRadius: 20,
                      borderTopEndRadius: 5,
                      borderBottomEndRadius: 5,
                    }}
                  >
                    <VStack
                      style={{ justifyContent: "center", height: hp(13) }}
                      space={2}
                      p="3"
                    >
                      <HStack
                        space={2}
                        bg="#fff"
                        alignItems="center"
                        style={{
                          textAlign: "center",
                          padding: 5,
                          borderWidth: 1,
                          borderColor: "#2DAB66",
                          shadowRadius: 0,
                          shadowOpacity: 0,
                          elevation: 0,
                          borderRadius: 5,
                        }}
                      >
                        <MaterialIcons
                          name="check"
                          size={moderateScale(11)}
                          color="#2DAB66"
                        />
                        <Text
                          style={{
                            color: "#2DAB66",
                            fontSize: moderateScale(10),
                          }}
                        >
                          Enviado
                        </Text>
                      </HStack>
                    </VStack>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </Box>
          ) : cotizacion.estado == "VENDISTE" ? (
            <Box ml="3" mr="3" bg="#F6EE5D" rounded={5} width={"100%"}>
              <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                <HStack
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: hp(13),
                  }}
                >
                  <VStack>
                    <Image
                      style={{ borderWidth: 0 }}
                      source={require("../../assets/imgVendiste.png")}
                      alt="texto alternativo"
                      size={50}
                    />
                  </VStack>

                  <VStack
                    style={{
                      width: "90%",
                      borderColor: "#000000",
                      borderWidth: 0,
                      paddingLeft: 5,
                    }}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={styles.itemTitle}
                    >
                      {CortarFecha(cotizacion.fechaenviocotizacion)}
                      <Text style={QuodomDetalle.textoVendiste}>
                        {" "}
                        ¡Vendiste!
                      </Text>{" "}
                    </Text>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomDetalle.textoTituloChicoVendido}
                    >
                      {cotizacion.nombreusuariocomprador} eligió{" "}
                      {cotizacion.cantidadlineasCotizadas}{" "}
                      {textProductoCotizado}.
                    </Text>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      style={QuodomDetalle.textoTituloChicoVendido2}
                    >
                      !Comunícate! Utiliza este número como referencia{" "}
                      <Text style={QuodomDetalle.textoTituloChicoVendido}>
                        {cotizacion.nro}
                      </Text>
                    </Text>
                    <TouchableOpacity
                      style={[styles.backRightBtn, styles.backRightBtnLeft]}
                      onPress={() => HandleArchivar(cotizacion.idquodom)}
                    >
                      <Center>
                        <Icon
                          mb="1"
                          as={
                            <MaterialCommunityIcons name="archive-arrow-down-outline" />
                          }
                          color="#000"
                          size="6"
                        />
                        <Text style={styles.backTextWhite}>Archivar</Text>
                      </Center>
                    </TouchableOpacity>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </Box>
          ) : cotizacion.estado == "NOVENDIO" ? (
            <Box
              ml="3"
              mr="3"
              bg="#706F9A"
              rounded={5}
              borderWidth={1}
              borderColor="#fff"
              width={"100%"}
            >
              <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                <HStack
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: hp(13),
                  }}
                >
                  <VStack>
                    <Image
                      style={{ borderWidth: 0, backgroundColor: "#706F9A" }}
                      source={require("../../assets/imgCotizacionPara.png")}
                      alt="Enviado"
                      size={50}
                    />
                  </VStack>

                  <VStack
                    style={{
                      width: "90%",
                      borderColor: "#000000",
                      borderWidth: 0,
                      paddingLeft: 5,
                    }}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      style={styles.itemTitle2}
                    >
                      {CortarFecha(cotizacion.fechaenviocotizacion)}
                      <Text style={QuodomDetalle.textoVendisteC}>
                        Finalizado, tus productos no fueron seleccionados por el
                        comprador.
                      </Text>{" "}
                    </Text>
                    <Text style={QuodomDetalle.textoTituloChicoVendidoB}>
                      Cotización para {cotizacion.nombreusuariocomprador}
                    </Text>
                    <TouchableOpacity
                      style={[styles.backRightBtn, styles.backRightBtnLeft]}
                      onPress={() => HandleArchivar(cotizacion.idquodom)}
                    >
                      <Center>
                        <Icon
                          mb="1"
                          as={
                            <MaterialCommunityIcons name="archive-arrow-down-outline" />
                          }
                          color="#000"
                          size="6"
                        />
                        <Text style={styles.backTextWhite}>Archivar</Text>
                      </Center>
                    </TouchableOpacity>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </Box>
          ) : (
            <Box
              ml="3"
              mr="3"
              bg="#706F9A"
              rounded={5}
              borderWidth={1}
              borderColor="#fff"
              width={"100%"}
            >
              <TouchableOpacity onPress={() => navegaraOfertasDetalle()}>
                <HStack
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: hp(13),
                  }}
                >
                  <VStack>
                    <Image
                      style={{ borderWidth: 0, backgroundColor: "#706F9A" }}
                      source={require("../../assets/imgCotizacionPara.png")}
                      alt="Enviado"
                      size={50}
                    />
                  </VStack>

                  <VStack
                    style={{
                      width: "90%",
                      borderColor: "#000000",
                      borderWidth: 0,
                      paddingLeft: 5,
                    }}
                  >
                    <Text style={styles.itemTitle2}>
                      {CortarFecha(cotizacion.fechaenviocotizacion)}
                      <Text style={QuodomDetalle.textoVendisteB}>
                        {" "}
                        Venta completa!
                      </Text>{" "}
                    </Text>
                    <Text style={QuodomDetalle.textoTituloChicoVendidoB}>
                      Cotización para {cotizacion.nombreusuariocomprador}
                    </Text>
                    <TouchableOpacity
                      style={[styles.backRightBtn, styles.backRightBtnLeft]}
                      onPress={() => HandleArchivar(cotizacion.idquodom)}
                    >
                      <Center>
                        <Icon
                          mb="1"
                          as={
                            <MaterialCommunityIcons name="archive-arrow-down-outline" />
                          }
                          color="#000"
                          size="6"
                        />
                        <Text style={styles.backTextWhite}>Archivar</Text>
                      </Center>
                    </TouchableOpacity>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </Box>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    marginVertical: 5,
    alignItems: "center",
  },
  itemContainerCreado: {
    backgroundColor: "#FFFFFF",
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    //height: HEIGHT * 0.12,
    //width: WIDTH * 0.4,
    borderTopEndRadius: 5,
    borderBottomEndRadius: 5,
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
    paddingTop: 0,
  },

  itemContainerEnviado: {
    backgroundColor: "#2DAB66",
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    height: HEIGHT * 0.12,
    width: WIDTH * 0.4,
    borderTopEndRadius: 5,
    borderBottomEndRadius: 5,
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
  },
  itemContainerVendido: {
    backgroundColor: "#F6EE5D",
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 5,
    height: HEIGHT * 0.12,
    width: WIDTH * 0.4,
    borderTopEndRadius: 5,
    borderBottomEndRadius: 5,
    flex: 1,
    marginRight: 10,
    marginLeft: 10,
  },
  itemTitle: {
    marginRight: 0,
    //paddingTop: 15,
    color: "#4E4D4D",
    fontSize: 13,
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    //width: WIDTH * 0.25,
  },

  itemTitle2: {
    marginRight: 0,
    //paddingTop: 15,
    color: "#fff",
    fontSize: 13,
    borderWidth: 0,
    borderColor: "#ffffff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    //width: WIDTH * 0.25,
  },
  itemButtons: {
    marginRight: 0,
    textAlign: "center",
    padding: 1,
    color: "#4E4D4D",
    fontSize: moderateScale(7),
    borderWidth: 1,
    borderColor: "#908c53",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    width: wp(15),
  },
  itemButtons48: {
    color: "#fff",
    marginRight: 0,
    textAlign: "center",
    padding: 1,
    fontSize: moderateScale(7),
    borderWidth: 1,
    borderColor: "#fff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    width: wp(15),
  },
  itemTitle24: {
    marginRight: 3,
    textAlign: "center",
    padding: 2,
    color: "#E96352",
    backgroundColor: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#ffffff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 10,
    width: wp(15),
    justifyContent: "center",
  },
  itemTitle48: {
    marginRight: 3,
    textAlign: "center",
    padding: 2,
    color: "#F8AE40",
    backgroundColor: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#ffffff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 10,
    width: wp(15),
    justifyContent: "center",
  },
  itemTitle72: {
    marginRight: 3,
    textAlign: "center",
    padding: 2,
    color: "#F6EE5D",
    backgroundColor: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#ffffff",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 10,
    width: wp(15),
    justifyContent: "center",
  },
  itemTitleEnviado: {
    marginRight: 5,
    padding: 2,
    color: "#2DAB66",
    backgroundColor: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#2DAB66",
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 10,
    width: wp(15),
  },
  navBarLeftButton: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    alignContent: "flex-end",
  },
  backRightBtn: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 120,
  },
  backRightBtnLeft: {
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  backRightBtnRight: {
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },

  backTextWhite: {
    //color: '#FFF',
    color: "#000",
    fontSize: moderateScale(8),

    //borderWidth: 1,
    // height: hp(13)
  },
});
export default OfertasItem;
