import React, { useEffect, useState } from "react";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
  Image,
} from "react-native";
import {
  HStack,
  Input,
  Icon,
  FlatList,
  Box,
  Button,
  Flex,
  VStack,
  Center,
  Link,
  Divider,
  Heading,
  Spacer,
  Text,
  useToast,
  Stack,
  NativeBaseProvider,
  ScrollView,
} from "native-base";

import {
  QuodomDetalle,
  StylePerfil,
  StyleSelectCategoria,
  useFonts,
  Montserrat_400Regular,
  Montserrat_400Regular_Italic,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
} from "../../styles/StyleQuodom";
//import BarraQuodomInferior from "../BarraQuodomInferior";
import { useRoute, useNavigation, useIsFocused } from "@react-navigation/core";
import { Ionicons, FontAwesome, MaterialIcons } from "@expo/vector-icons";
import quodomTheme from "../../constants/Theme";
import Loader from "../Loader";
import BannerSubCategorias from "../BannerSubCategorias";
import {
  ObtenerProductosCatQuodomBuscar,
  ObtenerSubCategorias,
  getQuodomCreados,
  InsertarDetalleQuodom,
  ObtenerQuodom,
  ObtenerProductosCatQuodom,
} from "../../api";
import DetalleProducto from "./DetalleProducto";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";
import { hp, wp, moderateScale } from "../../styles/responsive";

import { Modal } from "react-native-paper";
import SelectCantidad from "../MisQuodoms/Pickers/SelectCantidad";
import SelectQuodom from "../MisQuodoms/Pickers/SelectQuodom";

const SHA256crypt = require("js-sha256");
const auth = require("../../config/auth.config");
import ListaMisQuodomsInicial from "../../screens/MisQuodoms/ListaMisQuodomsInicial";
const ListaProductosScreen = (props) => {
  ////console.log('entre LISTAPRODUCTOS');
  const isFocused = useIsFocused();
  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
    Montserrat_400Regular,
    Montserrat_400Regular_Italic,
  });

  const route = useRoute();
  const navigation = useNavigation();
  const toastIdRef = React.useRef();
  const toast = useToast();
  const [refreshList, setrefreshList] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [actListaSub, setactListaSub] = useState(999);
  const [actNombreSub, setactNombreSub] = useState("");
  const [catPadre, setCatPadre] = useState(999);
  const [catPadreNombre, setCatPadreNombre] = useState("");
  const [posts, setPosts] = useState([]);
  const [idItem, setidItem] = useState("");
  //const [CantidadItem, SetCantidadItem] = useState(1);
  //const [Atributo1, SetAtributo1] = useState('');
  //const [Atributo2, SetAtributo2] = useState('');
  const [nombreProductoID, setNombreProducto] = useState("");
  const [idLineaQuodom, setidLineaQuodom] = useState("");
  const [quodomID, setquodomID] = useState("");
  //const [quodomName, setquodomName] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [addproducto, setaddproducto] = useState(false);

  //const [modalVisible, setModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [txtbuscar, settxtBuscar] = useState("");
  const [searchTimer, setSearchTimer] = useState(null);
  const [actualizarQuodoms, setactualizarQuodoms] = useState(false);
  const { volver } = [""];
  if (route.params == !undefined) {
    volver = route.params.volver;
  }

  const hideModal = () => setVisible(false);
  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: "37%",
    marginRight: "37%",
    borderRadius: 5,
  };

  /* if (route.params.vengoDelNodo) {
   // route.params.vengoDelNodo = false;
    setCatPadre(route.params.catPadre);
    setCatPadreNombre(route.params.catNombre);
    setactNombreSub(route.params.catNombre);
  } */

  const buscaren =
    "Buscar productos en " + catPadreNombre.substring(0, 9) + "...";

  /*  const onRefresh = React.useCallback(() => {
     //console.log('entre al onRefresh de ListaMisQuodoms.js');
     setRefreshing(true);
     wait(1000).then(() => setRefreshing(false));
   }, []); */

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const SubCategoria = async (newState) => {
    //console.log("entre subcategoria");
    ////console.log("ALGUIEN ME LLAMO", newState);
    setactListaSub(newState);
    setisLoading(true);
    LeerDatos(newState);
    if (newState != undefined) {
      await AsyncStorage.setItem("idcategoriaLP", newState.toString());
    }
    return newState;
  };

  const actNombre = async (newState) => {
    //console.log("entre actnombre");
    setactNombreSub(newState);
    if (newState != undefined) {
      await AsyncStorage.setItem("nombrecategoriaLP", newState.toString());
    }
    return newState;
  };

  const checkQuodom = async () => {
    setPosts([]);
    //console.log("Entre al checkQuodom() ListaProductos.js");

    const value = await AsyncStorage.getItem("userToken");
    if (route.params.vengoDelNodo) {
      //route.params.vengoDelNodo = false;
      await AsyncStorage.setItem("volverA", "ListaProductos");
      setCatPadre(route.params.catPadre);
      setCatPadreNombre(route.params.catNombre);
      setactNombreSub(route.params.catNombre);
    } else {
      await AsyncStorage.setItem("volverA", "SitioInicial");
    }

    let quodom = await AsyncStorage.getItem("UltimoQuodom");
    //let qName = await AsyncStorage.getItem('UltimoQuodomName');

    ////console.log("quodomID: ", quodom);

    if (quodom !== null) {
      const quodomData = await ObtenerQuodom(value, quodom);
      ////console.log("quodomData ESTADO", quodomData);

      if (quodomData.estado === "CREADO") {
        ////console.log("quodomData ESTADO", quodomData.estado);
        setquodomID(quodom);
      } else {
        const Qs = await getQuodomCreados(value);
        if (Qs.res) {
          ////console.log('SOY Q', Qs);
          setquodomID(Qs.data[0].id);
          await AsyncStorage.setItem("UltimoQuodom", Qs.data[0].id);
        } else {
          ////console.log("NO TENGO NUNGUNO LO TENGO QUE CREAR");
          setquodomID("");
          await AsyncStorage.removeItem("UltimoQuodom");
        }
      }
    } else {
      ////console.log("quodomID: es null asi que busco el ultimo creado", quodom);
      const Qs = await getQuodomCreados(value);

      if (Qs.res) {
        setquodomID(Qs.data[0].id);
        await AsyncStorage.setItem("UltimoQuodom", Qs.data[0].id);
      } else {
        ////console.log("NO TENGO NUNGUNO LO TENGO QUE CREAR");
        setquodomID("");
        await AsyncStorage.removeItem("UltimoQuodom");
      }
    }

    let categoriapadre = 0;
    if (route.params.catPadre !== undefined) {
      categoriapadre = route.params.catPadre;
      await AsyncStorage.setItem(
        "catPadreNombre",
        route.params.catNombre.toString()
      );
      await AsyncStorage.setItem("catPadre", route.params.catPadre.toString());
    } else {
      categoriapadre = parseInt(await AsyncStorage.getItem("catPadre"));
      let categoriapadrenombre = await AsyncStorage.getItem("catPadreNombre");
      setCatPadre(categoriapadre);
      setCatPadreNombre(categoriapadrenombre);
    }
    setisLoading(false);
  };
  const LeerDatos = async (subcat) => {
    let SubCategoriaID = subcat;

    if (subcat === 0) {
      const SubCat = await ObtenerSubCategorias(value, categoriapadre);
      if (!SubCat || SubCat.length === 0) {
        //console.log("SubCat Vacia");
      } else {
        var d = SubCat[0];
        SubCategoriaID = d.id;
        await AsyncStorage.setItem("idcategoriaLP", SubCategoriaID.toString());
        setactNombreSub(d.nombrecategoria);
      }
    } else {
      await AsyncStorage.setItem("idcategoriaLP", SubCategoriaID.toString());
    }
    let quodom = await AsyncStorage.getItem("UltimoQuodom");
    const value = await AsyncStorage.getItem("userToken");
    if (!quodom) {
      quodom = null;
    }

    const productos = await ObtenerProductosCatQuodom(
      value,
      quodom,
      SubCategoriaID
    );
    ////console.log(productos)
    setPosts(productos);
    setisLoading(false);
  };

  const buscar = async (valor) => {
    ////console.log('valor.length', valor.length)
    const value = await AsyncStorage.getItem("userToken");
    if (valor.length === 0) {
      const productos = await ObtenerProductosCatQuodom(
        value,
        quodomID,
        actListaSub
      );
      setPosts(productos);
    } else {
      const productos = await ObtenerProductosCatQuodomBuscar(
        value,
        quodomID,
        catPadre,
        valor
      );
      //if (productos.length !== 0) {
      setPosts(productos);
      //} else
    }
  };

  const ActualizarLista = (Item, newquodomID) => {
    ////console.log("aca", quodomID, newquodomID);
    setVisible(false);

    if (quodomID !== newquodomID) {
      setisLoading(true);
      LeerDatos();
    } else {
      const newData = posts.map((item) => {
        if (item.id === Item) {
          item.existe = 1;
          return item;
        }
        return item;
      });
      setPosts(newData);
      setaddproducto(!addproducto);
    }
    setactualizarQuodoms(true);
    setrefreshList(Math.random().toString());
  };

  useEffect(() => {
    /*  //console.log(
      "--------------------------------------------------------Entre al useEffect ListaProductos.js",
      txtbuscar
    ); */

    /* const checkData = async () => {
      let quodom = await AsyncStorage.getItem('UltimoQuodom');
      if (quodom !== quodomID) {
        setisLoading(true);
        LeerDatos();
      }
    }
 
    checkData().catch(console.error); */
    //console.log("entre useeffect");
    settxtBuscar("");
    setPosts([]);
    setisLoading(true);
    checkQuodom();
  }, [actListaSub, isFocused]);

  function AbrirModal(id, nombre) {
    setVisible(true);
    ////console.log("AbrirModal con id", id);
    //setModalVisible(true);
    setidItem(id);
    setNombreProducto(nombre);
  }

  const EmptyPlaceHolder = () => {
    return (
      <HStack style={{ backgroundColor: "#F6EE5D" }}>
        <Box
          w={{
            base: "100%",
            md: "100%",
          }}
        >
          <FlatList
            style={{
              backgroundColor: "#F6EE5D",
              marginLeft: "35px",
              marginRight: "25px",
            }}
            data={loadingData}
            numColumns={3}
            contentContainerStyle={{ flexGrow: 1 }}
            renderItem={({ item }) => (
              <View
                style={{
                  justifyContent: "center",
                  width: "33%",
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 2,
                  marginTop: 0,
                }}
              >
                <Box
                  style={{ backgroundColor: "#FFF" }}
                  borderWidth="1"
                  borderRadius="10"
                  borderColor="coolGray.200"
                  pl="4"
                  pr="5"
                  py="2"
                  mb="2"
                  height="16"
                >
                  <ContentLoader
                    animate={false}
                    speed={0.5}
                    width={350}
                    height={80}
                    viewBox="0 0 470 105"
                    backgroundColor="#cacace"
                    foregroundColor="#fdfdfc"
                  >
                    <Rect x="82" y="7" rx="2" ry="3" width="70" height="7" />
                    <Rect x="0" y="0" rx="4" ry="4" width="66" height="58" />
                    <Rect
                      x="82"
                      y="47"
                      rx="2"
                      ry="3"
                      width={wp(90)}
                      height="7"
                    />
                    <Rect
                      x="82"
                      y="27"
                      rx="2"
                      ry="3"
                      width={wp(90)}
                      height="7"
                    />
                    {/*  <Rect x={wp(80)} y="13" rx="100" ry="100" width="40" height="40" /> */}
                  </ContentLoader>
                </Box>
              </View>
            )}
            keyExtractor={(item) => item.id}
          />
        </Box>
      </HStack>
    );
  };

  const loadingData = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
    { id: "6" },
    { id: "7" },
    { id: "8" },
  ];

  if (!fontsLoaded) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <VStack flex={0.7}>
            <TouchableOpacity
              style={{ marginLeft: "10px", marginTop: "10px" }}
              onPress={() => navigation.toggleDrawer()}
            >
              <Icon
                size="8"
                as={<MaterialIcons name="menu" />}
                color={quodomTheme.COLORS["COLORVIOLETA"]}
              />
            </TouchableOpacity>
            <Center marginTop={"1%"}>
              <Box
                w="100%"
                mx="auto"
                backgroundColor="#F6EE5D"
                marginBottom={"2%"}
              >
                <Center>
                  <TouchableOpacity onPress={() => navigation.goBack()}>
                    <Image
                      source={require("../../assets/INICIO-16.png")}
                      alt="Quodom."
                      alignItems="center"
                      marginBottom={"2%"}
                      style={{ width: wp(10), height: wp(3) }}
                    />
                  </TouchableOpacity>
                </Center>
              </Box>
              <HStack pl={3} pr={3} pb={3} pt={0} style={{ width: "70%" }}>
                <Input
                  placeholder={buscaren}
                  variant="filled"
                  width="100%"
                  height="42px"
                  bg="#FFF"
                  backgroundColor="#fff"
                  borderRadius="25px"
                  py="1"
                  px="2"
                  fontSize="15"
                  fontFamily="Montserrat_400Regular"
                  placeholderTextColor="gray.500"
                  _hover={{ bg: "gray.200", borderWidth: 0 }}
                  borderWidth="0"
                  //value={txtbuscar}
                  //onChangeText={(val) => buscar(val)}

                  onChangeText={(text) => {
                    if (searchTimer) {
                      clearTimeout(searchTimer);
                    }
                    settxtBuscar(text);
                    setSearchTimer(
                      setTimeout(() => {
                        ////console.log('soy el valor de busc', text);
                        buscar(text);
                      }, 400)
                    );
                  }}
                  value={txtbuscar}
                  InputLeftElement={
                    <Icon
                      ml="2"
                      size="6"
                      color="gray.500"
                      as={<Ionicons name="ios-search" />}
                    />
                  }
                />
              </HStack>
              {txtbuscar === "" ? (
                <Box w="100%" mx="auto" backgroundColor="#FFF">
                  <Center>
                    <BannerSubCategorias
                      catPadre={catPadre}
                      actListaSubCat={SubCategoria}
                      actNombreCat={actNombre}
                      isLoading={setisLoading}
                    />
                  </Center>
                </Box>
              ) : null}
              {isLoading ? (
                <HStack style={{ backgroundColor: "#F6EE5D" }}>
                  <Box
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                  >
                    <Text
                      pt="2"
                      pl="4"
                      pb="2"
                      style={StyleSelectCategoria.itemTextLarge}
                    ></Text>
                    <Center>
                      <FlatList
                        horizontal={false}
                        numColumns={3}
                        style={{
                          backgroundColor: "#F6EE5D",
                          marginLeft: "35px",
                          marginRight: "25px",
                        }}
                        data={loadingData}
                        contentContainerStyle={{ flexGrow: 1 }}
                        renderItem={({ item }) => (
                          <View
                            style={{
                              justifyContent: "center",
                              width: "30%",
                              marginLeft: 10,
                              marginRight: 10,
                              marginBottom: 2,
                              marginTop: 0,
                            }}
                          >
                            <Box
                              style={{ backgroundColor: "#FFF" }}
                              borderWidth="1"
                              borderRadius="10"
                              borderColor="coolGray.200"
                              pl="4"
                              pr="5"
                              py="2"
                              mb="2"
                              height="16"
                            >
                              <ContentLoader
                                animate={false}
                                speed={0.5}
                                width={"100%"}
                                viewBox="0 0 470 105"
                                backgroundColor="#cacace"
                                foregroundColor="#fdfdfc"
                              >
                                <Rect
                                  x="82"
                                  y="7"
                                  rx="2"
                                  ry="3"
                                  width="70"
                                  height="7"
                                />
                                <Rect
                                  x="0"
                                  y="0"
                                  rx="4"
                                  ry="4"
                                  width="66"
                                  height="58"
                                />
                                <Rect
                                  x="82"
                                  y="47"
                                  rx="2"
                                  ry="3"
                                  width={wp(90)}
                                  height="7"
                                />
                                <Rect
                                  x="82"
                                  y="27"
                                  rx="2"
                                  ry="3"
                                  width={wp(90)}
                                  height="7"
                                />
                                {/*  <Rect x={wp(80)} y="13" rx="100" ry="100" width="40" height="40" /> */}
                              </ContentLoader>
                            </Box>
                          </View>
                        )}
                        keyExtractor={(item) => item.id}
                      />
                    </Center>
                  </Box>
                </HStack>
              ) : (
                <HStack style={{ backgroundColor: "#F6EE5D" }}>
                  <Box
                    w={{
                      base: "100%",
                      md: "100%",
                    }}
                    zIndex={10}
                  >
                    {txtbuscar === "" ? (
                      <Text
                        pt="2"
                        pl="4"
                        pb="2"
                        style={StyleSelectCategoria.itemTextLarge}
                      ></Text>
                    ) : null}
                    <Center>
                      <ScrollView height={wp(25)}>
                        <FlatList
                          style={{
                            backgroundColor: "#F6EE5D",
                            zIndex: 10,
                            marginLeft: "35px",
                            marginRight: "25px",
                            flex: 1,
                          }}
                          horizontal={false}
                          numColumns={3}
                          data={posts}
                          // refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
                          ListEmptyComponent={<EmptyPlaceHolder />}
                          contentContainerStyle={{ flexGrow: 1 }}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          scrollToOverflowEnabled={true}
                          nestedScrollEnabled
                          renderItem={({ item }) => (
                            <View
                              style={{
                                justifyContent: "center",
                                width: "30%",
                                marginLeft: 10,
                                marginRight: 10,
                                marginBottom: 2,
                                marginTop: 0,
                                zIndex: 10,
                              }}
                            >
                              {item.existe === 1 ? (
                                <Box
                                  style={{ backgroundColor: "#706F9A" }}
                                  borderWidth="1"
                                  borderRadius="8"
                                  borderColor="#fff"
                                  p="0"
                                  py="1"
                                  mb="1.5"
                                  height="16"
                                  zIndex={10}
                                >
                                  <HStack
                                    space={0}
                                    zIndex={10}
                                    borderWidth={0}
                                    justifyItems="center"
                                    alignItems="center"
                                    w="100%"
                                  >
                                    <VStack w="20%" borderWidth={0}>
                                      <Image
                                        source={{
                                          uri: item.imagen,
                                        }}
                                        //uri={props.imagen} // image address
                                        //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                        style={{ width: 50, height: 50 }}
                                      />
                                    </VStack>

                                    <VStack
                                      justifyContent="flex-start"
                                      borderWidth={0}
                                      w="60%"
                                      marginLeft={"10px"}
                                    >
                                      <Text
                                        style={
                                          StyleSelectCategoria.itemTextBlanco
                                        }
                                      >
                                        {item.nombreproducto}
                                      </Text>
                                    </VStack>

                                    <VStack
                                      justifyContent="center"
                                      borderWidth={0}
                                      w="15%"
                                    >
                                      {/* <FontAwesome name="check" size={35} color="#F6EE5D" />    */}
                                      <Ionicons
                                        name="checkmark"
                                        size={30}
                                        color="#F6EE5D"
                                      />
                                    </VStack>
                                  </HStack>
                                </Box>
                              ) : (
                                <TouchableOpacity
                                  onPress={() =>
                                    AbrirModal(item.id, item.nombreproducto)
                                  }
                                >
                                  <Box
                                    style={{ backgroundColor: "#FFF" }}
                                    borderWidth="1"
                                    borderRadius="8"
                                    borderColor="coolGray.200"
                                    p="0"
                                    py="1"
                                    mb="1.5"
                                    height="16"
                                    zIndex={10}
                                  >
                                    <HStack
                                      space={1}
                                      zIndex={10}
                                      borderWidth={0}
                                      justifyItems="center"
                                      alignItems="center"
                                      w="100%"
                                    >
                                      <VStack w="20%" borderWidth={0} pl="1">
                                        <Image
                                          source={{
                                            uri: item.imagen,
                                          }}
                                          //uri={props.imagen} // image address
                                          //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                          style={{ width: 50, height: 50 }}
                                        />
                                      </VStack>

                                      <VStack
                                        justifyContent="flex-start"
                                        borderWidth={0}
                                        w="70%"
                                        marginLeft={"10px"}
                                      >
                                        <Text
                                          style={StyleSelectCategoria.itemText}
                                        >
                                          {item.nombreproducto}
                                        </Text>
                                      </VStack>
                                    </HStack>
                                  </Box>
                                </TouchableOpacity>
                              )}
                            </View>
                          )}
                          keyExtractor={(item) => item.id.toString()}
                        />
                      </ScrollView>
                    </Center>
                  </Box>
                </HStack>
              )}

              {volver !== "BUSCAR" ? null : null}
            </Center>
          </VStack>
          <VStack flex={0.3}>
            <ListaMisQuodomsInicial
              actualizarLista={refreshList}
            ></ListaMisQuodomsInicial>
          </VStack>
        </HStack>

        <Modal
          visible={visible}
          onDismiss={hideModal}
          contentContainerStyle={containerStyle}
        >
          <HStack
            pl={5}
            pr={5}
            pt={3}
            pb={4}
            w="100%"
            bg="#706F9A"
            justifyContent="space-between"
            roundedTop={5}
          >
            <VStack space={1}>
              <Text style={StyleSelectCategoria.TituloModal}>Agregar</Text>
              <Text style={StyleSelectCategoria.itemTextBlanco}>
                {nombreProductoID}
              </Text>
            </VStack>
            <TouchableOpacity onPress={hideModal}>
              <VStack alignItems="flex-start" borderWidth={0}>
                <Ionicons name="close-outline" size={25} color="#fff" />
              </VStack>
            </TouchableOpacity>
          </HStack>

          <DetalleProducto
            idproducto={idItem}
            quodomID={quodomID}
            nombreProductoID={nombreProductoID}
            actualizarLista={ActualizarLista}
            mostrarQuodom={true}
          />
        </Modal>
      </View>
    );
  }
};
export default ListaProductosScreen;
