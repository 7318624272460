import React from "react";
import { HStack, Text, VStack, Center } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { View, TouchableOpacity } from "react-native";
import quodomTheme from "../../constants/Theme";
import ZonasListProfile from "../../components/SettingsUser/ZonasListProfile";
import {
  StyleSigInScreen,
  QuodomDetalle,
  StylePerfil,
} from "../../styles/StyleQuodom";
import AppBar from "../../components/AppBar";

const ZonasScreen = () => {
  const navigation = useNavigation();

  return (
    <View style={{ flex: 1, backgroundColor: "#F2F2F2" }}>
      <AppBar />

      <View
        style={{ flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 0 }}
      >
        <Text style={StylePerfil.textoTitulo}>Zonas</Text>
        <VStack justifyContent="flex-start" mt={5} mb={3} pl={3} pr={3}>
          <Center>
            <Text style={StyleSigInScreen.textoLoginyPass}>
              Seleccioná las zonas en las que quieras participar.
            </Text>
          </Center>
        </VStack>
        <ZonasListProfile></ZonasListProfile>
      </View>
    </View>
  );
};
export default ZonasScreen;
