import { StyleSheet, Dimensions } from "react-native";
import { WIDTH, HEIGHT, wp, hp, moderateScale } from "./responsive";
import { colorFondoGeneral, colorVioleta, colorAmarillo } from "./general";
import {
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
} from "@expo-google-fonts/prompt";
import { PixelRatio } from "react-native";
import { Jaldi_400Regular } from "@expo-google-fonts/jaldi";
import {
  WorkSans_100Thin,
  WorkSans_200ExtraLight,
  WorkSans_300Light,
  WorkSans_400Regular,
  WorkSans_500Medium,
  WorkSans_600SemiBold,
  WorkSans_700Bold,
  WorkSans_800ExtraBold,
  WorkSans_900Black,
  WorkSans_100Thin_Italic,
  WorkSans_200ExtraLight_Italic,
  WorkSans_300Light_Italic,
  WorkSans_400Regular_Italic,
  WorkSans_500Medium_Italic,
  WorkSans_600SemiBold_Italic,
  WorkSans_700Bold_Italic,
  WorkSans_800ExtraBold_Italic,
  WorkSans_900Black_Italic,
} from "@expo-google-fonts/work-sans";
import {
  Montserrat_100Thin,
  Montserrat_100Thin_Italic,
  Montserrat_200ExtraLight,
  Montserrat_200ExtraLight_Italic,
  Montserrat_300Light,
  Montserrat_300Light_Italic,
  Montserrat_400Regular,
  Montserrat_400Regular_Italic,
  Montserrat_500Medium,
  Montserrat_500Medium_Italic,
  Montserrat_600SemiBold,
  Montserrat_600SemiBold_Italic,
  Montserrat_700Bold,
  Montserrat_700Bold_Italic,
  Montserrat_800ExtraBold,
  Montserrat_800ExtraBold_Italic,
  Montserrat_900Black,
  Montserrat_900Black_Italic,
} from "@expo-google-fonts/montserrat";

// based on iphone 5s's scale
const scale = WIDTH / 320;

const { width, fontScale } = Dimensions.get("window");

function normalize(size) {
  const newSize = size * scale;

  return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 21;
}

function SizeHeight(screenHeight) {
  if (screenHeight > 750) {
    return 230;
  } else if (screenHeight >= 640) {
    return 220;
  } else {
    return 200;
  }
}

const DetalleResumenCotstyle = StyleSheet.create({
  textoTitulo: {
    fontWeight: "600",
    fontSize: 18,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "bold",
    flex: 0.05,
    alignSelf: "center",
    paddingTop: 10,
  },
  TextoModalResumen: {
    fontSize: 15,
    color: "#59584e",
  },
  TextoModalResumenBorde: {
    fontSize: 15,
    color: "#59584e",

    borderRadius: 20,
    borderWidth: 1,
    padding: 5,
  },
});

const DetalleProductoQuodomstyle = StyleSheet.create({
  itemContainerAgregar: {
    flex: 1,
    borderRadius: 12,
    padding: 10,
    //marginLeft: 8,
    //marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(100),
    backgroundColor: "#fff",
    borderColor: "#706f9a",
    borderWidth: 1,
    borderStyle: "dashed",
    zIndex: 2,
  },
  textoTituloChico: {
    fontWeight: "600",
    fontSize: 14,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "bold",
    flex: 0.05,
    alignSelf: "center",
    paddingTop: 10,
  },

  textoTitulo: {
    fontWeight: "600",
    fontSize: moderateScale(14),
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "bold",
    flex: 0.05,
    alignSelf: "center",
    paddingTop: 10,
  },

  gridView: {
    marginTop: 10,
    flex: 0.95,
  },
  pickerQuodoms: {
    backgroundColor: "#e3edfb",
    height: 40,
    width: 190,
    borderRadius: 5,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 8,
    fontWeight: "bold",
  },
  pickerQuodomsText: {
    color: "#0845a1",
    alignContent: "space-around",
    alignItems: "baseline",
    flex: 0.9,
  },
  card: {
    //shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    backgroundColor: "#706f9a",
    padding: 17,
    borderRadius: 10,
    borderColor: "white",
    borderWidth: 1,
  },
  itemContainer: {
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
    height: 220,
    backgroundColor: "#706f9a",
    borderColor: "#FFFFFF",
    borderWidth: 1,
  },
  itemfechavencimientoenvio: {
    fontSize: 12,
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Roboto",
  },
  itemDescripcion: {
    fontWeight: "600",
    fontSize: 16,
    color: "#fff",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  itemTextoBarraProgreso: {
    fontWeight: "600",
    fontSize: 16,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStyle: "italic",
  },
  itemFaltanDetalles: {
    fontWeight: "600",
    fontSize: 16,
    color: "#F6EE5D",
    fontFamily: "Roboto",
    fontWeight: "normal",
  },

  cardCompleto: {
    flex: 1,
    borderRadius: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: 260,
    backgroundColor: "#2dab66",
    borderColor: "#2dab66",
    borderWidth: 0,
    marginVertical: 10,
  },
  cardIncompleto: {
    flex: 1,
    borderRadius: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: 260,
    backgroundColor: "#706f9a",
    borderColor: "#706f9a",
    borderWidth: 0,
    marginVertical: 10,
  },

  cardCompletoMinimizado: {
    flex: 1,
    borderRadius: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: 120,
    backgroundColor: "#2dab66",
    borderColor: "#2dab66",
    borderWidth: 0,
    marginVertical: 10,
  },

  cardIncompletoMinimizado: {
    flex: 1,
    borderRadius: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    height: 120,
    backgroundColor: "#706f9a",
    borderColor: "#706f9a",
    borderWidth: 0,
    marginVertical: 10,
  },

  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },

  textoTitulo: {
    fontSize: 16,
    color: "#FFFFFF",
    width: wp("60%"),
  },

  textoTituloNegro: {
    fontSize: 16,
    color: "#4E4D4D",
    borderWidth: 0,
  },

  textoTituloBannerNegro: {
    fontSize: 15,
    color: "#4E4D4D",
    borderWidth: 0,
  },

  textoTitulo2: {
    fontSize: 15,
    color: "#FFFFFF",
  },

  textoTitulo2N: {
    fontSize: 15,
    color: "#4E4D4D",
  },

  textoTituloCotizado: {
    //fontFamily: 'Roboto',

    fontSize: 13,
    color: "#FFFFFF",
  },

  textoTituloCotizado2: {
    //fontFamily: 'Roboto',

    fontSize: moderateScale(5),
    color: "#FFFFFF",
  },

  textoTituloCotizadoNegro: {
    //fontFamily: 'Roboto',

    fontSize: 13,
    color: "#000",
  },

  textoTituloCotizadoResumen: {
    //fontFamily: 'Roboto',

    fontSize: 20,
    color: "#4D4D4D",
    width: wp(38),
  },

  textoTituloCotizadoResumen2: {
    //fontFamily: 'Roboto',

    fontSize: 17,
    color: "#4D4D4D",
    //width: wp(38),
  },

  textoPrecio: {
    fontSize: 16,
    color: "#FFFFFF",
  },
  textoPrecio2: {
    fontSize: 13,
    color: "#FFFFFF",
  },
  textoPrecio3: {
    fontSize: 15,
    color: "#4D4D4D",
  },

  textoTituloCotizadoSeleccion: {
    fontSize: 15,
    color: "#FFFFFF",
    width: wp(34),
  },
  textoFechaCotizadoSeleccion: {
    fontFamily: "Prompt_400Regular",
    fontSize: moderateScale(6),
    color: "#FFFFFF",
    width: wp(34),
  },

  textoCategoriaCotizado: {
    fontFamily: "Prompt_400Regular",
    fontSize: 15,
    color: "#FFFFFF",
    width: wp(35),
  },

  textoFechaCotizado: {
    //fontFamily: 'Roboto',

    fontSize: 13,
    color: "#FFFFFF",
  },

  textoCategoria: {
    fontFamily: "Prompt_400Regular",
    fontSize: 13,
    color: "#FFFFFF",
    width: wp("70%"),
  },

  textoCategoria2: {
    fontFamily: "Prompt_400Regular",
    fontSize: 13,
    color: "#FFFFFF",
  },

  textoCategoria2N: {
    fontFamily: "Prompt_400Regular",
    fontSize: 13,
    color: "#4D4D4D",
  },

  textoCompleta: {
    color: "#706F9A",
    fontSize: 18,
    //color: '#000'
  },

  textoEstadoNodoQuodom2: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  textoEstadoNodoQuodomCompleto: {
    fontSize: 17,
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  textoEstadoNodoQuodomCompletoMinimizado: {
    fontSize: 15,
    color: "#FFFFFF",
  },
  textoEstadoNodoQuodomCompleto2: {
    fontFamily: "Prompt_400Regular",
    fontSize: 14,
    color: "#FFFFFF",
  },
});

const QuodomList_Card = StyleSheet.create({
  textoTitulo: {
    fontWeight: "600",
    fontSize: 19,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "bold",
    flex: 0.05,
    alignSelf: "center",
    paddingTop: 10,
  },

  gridView: {
    marginTop: 0,
    marginLeft: 13,
    flex: 1,
  },

  card: {
    //shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    backgroundColor: "#706f9a",
    padding: 17,
    borderRadius: 10,
    borderColor: "white",
    borderWidth: 1,
  },
  itemContainerCrear: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    //width: wp(42.6),
    //height: hp(37),
    height: wp(8),
    backgroundColor: "#706f9a",
    borderColor: "#FFFFFF",
    borderWidth: 1,
    borderStyle: "dashed",
    zIndex: 2,
  },

  itemContainer: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    //width: wp(42.5),
    //height: hp(37),
    height: wp(8),
    backgroundColor: "#706f9a",
    borderColor: "#FFFFFF",
    borderWidth: 1,
    zIndex: 2,
  },

  itemContainerCompleto: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(42.6),
    //height: hp(37),
    height: wp(8),
    backgroundColor: "#706f9a",
    borderColor: "#FFFFFF",
    borderWidth: 1,
    zIndex: 2,
  },

  itemContainerEnviado: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(42.6),
    //height: hp(37),
    height: wp(8),
    borderColor: colorFondoGeneral,
    borderWidth: 1,
    backgroundColor: colorFondoGeneral,
    zIndex: 2,
  },
  itemContainerSelect: {
    justifyContent: "flex-start",
    borderRadius: 12,
    padding: 10,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    width: wp(22.6),
    height: hp(13.5),
    backgroundColor: "#706faa",
    borderColor: "#C6C6C6",
    borderWidth: 1,
    zIndex: 2,
  },

  itemContainerCotizado: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(42.6),
    height: wp(8),
    borderColor: "transparent",
    borderWidth: 1,
    backgroundColor: "#f8ae40",
    zIndex: 2,
  },

  itemContainerCerrado: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(42.6),
    height: wp(8),
    borderColor: "#4E4D4D",
    borderWidth: 1,
    backgroundColor: "#706F9A",
    zIndex: 2,
  },
  itemContainerVencido: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    // width: wp(42.6),
    height: wp(8),
    borderColor: "#4E4D4D",
    borderWidth: 1,
    backgroundColor: "#706F9A",
    zIndex: 2,
  },
  itemContainerComprado: {
    //justifyContent: 'flex-start',
    borderRadius: 12,
    padding: 0,
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 0,
    marginTop: 5,
    //  width: wp(42.6),
    height: wp(8),
    backgroundColor: "#2dab66",
    borderColor: "transparent",
    borderWidth: 1,
    zIndex: 2,
  },
  itemfechavencimientoenvio: {
    fontSize: 11,
    color: "#fff",
    flex: 1,
  },
  itemfechavencimientoenvioCerrado: {
    fontSize: 11,
    color: "#4E4D4D",
    flex: 1,
  },
  itemfechavencimientoenvioEnviado: {
    fontSize: 11,
    color: "#4E4D4D",
    flex: 1,
  },
  itemDescripcion: {
    fontSize: 16,
    color: "#fff",
    paddingTop: 5,
    paddingBottom: 8,
  },
  itemDescripcionCerrado: {
    fontSize: 16,
    color: "#4E4D4D",
    paddingTop: 5,
    paddingBottom: 8,
  },
  itemDescripcionEnviado: {
    fontSize: 16,
    color: "#4E4D4D",
    paddingTop: 5,
    paddingBottom: 8,
  },
  itemTextoBarraProgreso: {
    fontSize: 14,
    color: "#FFFFFF",
    fontFamily: "Prompt_400Regular",
    //fontWeight: 'normal',
  },
  itemFaltanDetalles: {
    //fontWeight: '600',
    fontSize: 11,
    color: "#F6EE5D",
    //
  },
  itemListosDetalles: {
    //fontWeight: '600',
    fontSize: 12,
    color: "#fff",
  },

  itemListosDetallesAmarillo: {
    //fontWeight: '600',
    fontSize: 11,
    color: "#F6EE5D",
  },

  itemFaltanDetallesCot: {
    //fontWeight: '600',
    fontSize: moderateScale(5),
    color: "#F6EE5D",

    textAlign: "right",
  },
  itemSinFaltanDetalles: {
    fontSize: 14,
    color: "#FFFFFF",
    //fontFamily: "Prompt_400Regular",
  },

  itemSinFaltanDetallesChico: {
    //fontWeight: '600',
    fontSize: 11,
    color: "#FFFFFF",
    //
  },

  itemTextoEnviado: {
    //fontWeight: '600',
    fontSize: 11,
    color: "#59584e",

    //fontWeight: 'normal'
  },

  itemTextoEnviadoCantidad: {
    fontSize: 11,
    color: "#59584e",
    fontFamily: "Prompt_400Regular",
  },

  itemTextoCotizado: {
    fontSize: 11,
    color: "#fff",
  },
  itemTextoCotizadoChico: {
    fontSize: 11,
    color: "#fff",
  },

  itemTextoFinalizado: {
    fontSize: 11,
    color: "#fff",
  },
  itemTextoCerrado: {
    fontSize: 11,
    color: "#4E4D4D",
  },
  itemTextoAmarillo: {
    fontSize: 11,
    color: "#F6EE5D",
  },
  itemTextoCerradoGrande: {
    fontSize: 11,
    color: "#4E4D4D",
    fontFamily: "Prompt_400Regular",
  },
  itemTextoFinalizado2: {
    fontSize: 11,
    color: "#fff",
  },
});

const QuodomDetalle = StyleSheet.create({
  /* BARRA SUPERIOR */
  textoNavigate: {
    fontSize: moderateScale(7),
    color: "#45444C",
  },

  textoTitulo: {
    fontSize: 20,
    color: "#706F9A",
    fontWeight: "bold",
  },
  textoTituloFinalizadoQ: {
    fontSize: moderateScale(12),
    color: "#fff",
    paddingRight: "30%",
  },
  textoTituloFinalizadoQ2: {
    fontSize: moderateScale(9),
    color: "#fff",
    paddingRight: "30%",
  },
  textoTituloFinalizado: {
    fontSize: moderateScale(12),
    color: "#fff",
  },
  textoTituloFinalizado2: {
    fontSize: 14,
    color: "#fff",
  },
  textoPrecioTotal: {
    fontSize: moderateScale(12),
    color: "#000",
  },
  textoPrecioTotal2: {
    fontSize: 19,
    color: "#000",
  },
  textoPrecioTotalVendido: {
    fontSize: 19,
    color: "#000",
  },
  textoPrecioTotalVendido2: {
    fontSize: 17,
    color: "#000",
  },

  textoPrecioTotalVendido3: {
    fontSize: 16,
    color: "#45444C",
  },

  textoPrecioItems: {
    fontSize: 13,
    color: "#45444C",
  },

  textoPrecioItemsBold: {
    fontSize: 13,
    color: "#8395a7",
  },

  textoPrecioItemsBold2: {
    fontSize: 15,
    color: "#8395a7",
  },
  textoTituloMisCotizacionWeb: {
    fontSize: 19,
    color: "#706F9A",
    fontWeight: "bold",
    textTransform: "uppercase",
  },

  textoTituloMisCotizacion: {
    fontSize: 19,
    color: "#fff",
    paddingLeft: "30px",
    fontWeight: "bold",
  },

  textoTituloMisCotizacionN: {
    fontSize: moderateScale(14),
    color: "#45444C",
  },

  textoTituloMisCotizacionNG: {
    fontSize: 20,
    color: "#45444C",
  },

  textoTituloMisCotizacionNGM2: {
    fontSize: 32,
    color: "#45444C",
    padding: 20,
  },

  textoTituloMisCotizacionNGM: {
    fontSize: 16,
    color: "#45444C",
  },

  textoTituloChicoVendido: {
    fontSize: 13,
    color: "#45444C",
    fontWeight: "bold",
    textAlign: "left",
  },

  textoTituloChicoVendidoB: {
    fontSize: 13,
    color: "#fff",

    textAlign: "left",
  },

  textoTituloChicoVendido2: {
    fontSize: 14,
    color: "#8395a7",
    textAlign: "left",
  },

  textoTituloChico: {
    fontSize: 15,
    color: "#45444C",

    textAlign: "left",
  },

  textoVence: {
    fontSize: moderateScale(6),
    color: "#45444C",

    textAlign: "left",
  },

  textoPedidoDe: {
    fontSize: moderateScale(7),
    color: "#8395a7",

    textAlign: "left",
    width: "90%",
  },

  textoVenceW: {
    fontSize: moderateScale(6),
    color: "#fff",

    textAlign: "left",
  },

  textoPedidoDeW: {
    fontSize: moderateScale(7),
    color: "#fff",

    textAlign: "left",
  },

  textoVendiste: {
    fontSize: 15,
    color: "#45444C",
    fontWeight: "bold",
    textAlign: "left",
  },

  textoVendisteB: {
    fontSize: 15,
    color: "#fff",

    textAlign: "left",
  },
  textoVendisteC: {
    fontSize: moderateScale(5),
    color: "#fff",

    textAlign: "left",
  },

  textoTituloChicoB: {
    fontSize: 15,
    color: "#fff",

    textAlign: "left",
  },
  textoTituloChicoBN: {
    fontSize: 15,
    color: "#45444C",

    textAlign: "left",
  },
  textoTituloChicoVendiste: {
    fontSize: moderateScale(17),
    color: "#45444C",
  },
  textoTituloChicoVendisteB: {
    fontSize: 17,
    color: "#FFF",
  },

  textoTituloChicoVendisteBN: {
    fontSize: 17,
    color: "#45444C",
  },

  textoTituloChicoEligio: {
    fontSize: 15,
    color: "#45444C",
  },

  textoInfoComprador: {
    fontSize: 13,
    color: "#45444C",
  },

  textoInfoCompradorBold: {
    fontSize: 13,
    color: "#45444C",
  },

  textoTituloChicoEnviaste: {
    fontSize: 13,
    color: "#45444C",
  },

  textoTituloChicoFinalizado: {
    fontSize: 15,
    color: "#fff",

    textAlign: "left",
  },
  textoTituloChicoVendio: {
    fontSize: 15,
    color: "#45444C",

    textAlign: "left",
  },
  textoTituloChicoLight: {
    fontSize: 15,
    color: "#45444C",
  },
  textoTituloMediano: {
    fontSize: 17,
    color: "#45444C",

    textAlign: "left",
  },
  textoEnviarA: {
    fontSize: 13,
    color: "#45444C",
    fontFamily: "Prompt_400Regular",
    textAlign: "left",
    width: "90%",
  },

  textoTituloSeleccionarProveedor: {
    fontSize: 15,
    color: "#45444C",

    textAlign: "left",
  },

  textoTituloGrandeFinalizado: {
    fontSize: moderateScale(14),
    color: "#fff",

    textAlign: "center",
  },

  textoChicoFinalizado2: {
    fontSize: moderateScale(6),
    color: "#fff",

    textAlign: "center",
  },

  textoChico: {
    fontSize: 15,
    color: "#45444C",

    paddingTop: 4,
    //width:wp(22)
  },

  textoChico2: {
    fontSize: 11,
    color: "#45444C",
  },

  textoChico3: {
    fontSize: 14,
    color: "#45444C",
  },

  textoChico2Bold: {
    fontSize: 15,
    color: "#000",

    //fontFamily: 'Montserrat_400Regular',
  },

  textoChicoFinalizado: {
    fontSize: 15,
    color: "#fff",

    paddingTop: 4,
    //width:wp(22)
  },

  textoAgregraDireccionPerfil: {
    fontSize: moderateScale(6),
    color: "#235EFF",
    fontFamily: "Prompt_400Regular",
    textAlign: "left",
    width: wp(80),
  },

  textoEnviarANuevoQuodomInput: {
    fontSize: moderateScale(12),
    color: "#45444C",

    textAlign: "left",
    backgroundColor: "#fff",
  },

  textoEnviarANuevoQuodom: {
    fontSize: 14,
    //color: '#45444C',
    fontFamily: "Prompt_400Regular",
    textAlign: "left",
    width: wp(78),
  },

  textoEnviarANuevoQuodomR: {
    fontSize: 14,
    color: "#DC7633",
    fontFamily: "Prompt_400Regular",
    textAlign: "left",
    width: wp(78),
  },

  textoEnviarANuevoQuodomChico: {
    fontSize: 12,
    //color: '#45444C',

    textAlign: "left",
  },

  itemTextoTipNuevoQuodom: {
    //fontWeight: '600',
    fontSize: 13,
    color: "#59584e",

    //fontWeight: 'normal'
  },

  TextoModalEliminar: {
    //fontWeight: '600',
    fontSize: 14,
    color: "#59584e",

    //fontWeight: 'normal'
  },

  TextoModalResumenBold: {
    fontSize: 15,
    color: "#27AE60",
  },

  TextoModalSuccess: {
    fontSize: moderateScale(8),
    color: "#FFFFFF",
  },

  TextoModalSuccess2: {
    fontSize: moderateScale(8),
    color: "#FFFFFF",
  },

  TextoModalSuccess3: {
    fontSize: moderateScale(6),
    color: "#FFFFFF",
  },

  TextoDescartar: {
    fontSize: moderateScale(7),
    color: "#EAF1F9",
  },

  TextoToast: {
    fontSize: moderateScale(7),
    color: "#fff",
  },

  TextoRedireccion: {
    fontSize: 13,
    color: "#fff",

    textAlign: "center",
  },

  TextoToastTitulo: {
    fontSize: moderateScale(7),
    color: "#fff",
  },

  TextoDescartarAzul: {
    fontSize: moderateScale(7),
    color: "#ADC2DF",

    padding: 10,
  },

  TextoModalSuccessQuodom: {
    fontSize: moderateScale(7),
    color: "#F6EE5D",
  },
  TextoModalSuccessButton: {
    fontSize: moderateScale(7),
    color: "#FFFFFF",
  },

  TextoModalSuccessTip: {
    fontSize: moderateScale(7),
    color: "#fff",
  },

  TextoModalSuccessTip2: {
    fontSize: moderateScale(9),
    color: "#fff",
  },

  TextoModalResumen: {
    fontSize: 15,
    color: "#59584e",
  },

  TextoModalResumen2: {
    fontSize: 15,
    color: "#59584e",

    //textAlign: 'right'
  },

  TextoModalResumenBold2: {
    fontSize: 15,
    color: "#45444C",
  },

  TextoModalItalic: {
    fontFamily: "Montserrat_400Regular_Italic",
    fontSize: 11,
    color: "#45444C",
    //textTransform: "uppercase",
  },

  TextoModalResumenTip: {
    fontSize: 13,
    color: "#59584e",
  },

  TextoModalEliminarBold: {
    //fontWeight: '600',
    fontSize: 15,
    color: "#45444C",
    fontFamily: "Prompt_400Regular",
    //fontWeight: 'normal'
  },

  TextoModalButtonCancelar: {
    fontSize: 15,
    color: "#FFF",
  },

  TextoModalButtonOK: {
    fontSize: 15,
    color: "#45444C",
  },

  TextoBotonElegir: {
    fontSize: 13,
    color: "#fff",

    //width:wp(1)
    alignItems: "center",
    backgroundColor: "transparent",
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#FFF",
    textAlign: "center",
  },

  TextoElegirProveedores: {
    fontSize: 11,
    color: "#F6EE5D",

    borderColor: "#F6EE5D",
    borderWidth: 1,
    padding: 4,
    borderRadius: 5,
    textAlign: "center",
  },

  TextoPrecioResumen: {
    fontSize: 20,
    color: "#4D4D4D",

    textAlign: "left",
    width: wp(15),
  },

  TextoPrecioResumen2: {
    fontSize: 13,
    color: "#4D4D4D",
  },

  TextoTituloModalEliminar: {
    //fontWeight: '600',
    fontSize: 15,
    color: "#59584e",

    //fontWeight: 'normal'
  },

  textoOtraUbicacion: {
    fontSize: moderateScale(12),
    color: "#45444C",
    textAlign: "left",
  },
  textoAgregarUbicacion: {
    fontSize: 14,
    color: "#706F9A",
    textAlign: "left",
  },

  textoItemUbicacion: {
    fontSize: 12,
    color: "#45444C",
  },

  textoItemDesde: {
    fontSize: 11,
    color: "#000",
  },

  textoItemUbicacionMedio: {
    fontSize: 13,
    color: "#45444C",
  },

  textoItemNotificacion: {
    fontSize: 13,
    color: "#45444C",
  },

  textoItemValor: {
    fontSize: 12,
    color: "#45444C",
  },

  textoItemUbicacionBold: {
    fontWeight: "bold",
    fontSize: 13,
    color: "#45444C",
  },

  textoItemUbicacionPrincipal: {
    fontSize: moderateScale(5),
    color: "#45444C",
  },

  textoItemUbicacionPrincipalPerfil: {
    fontSize: moderateScale(5),
    color: "#45444C",
  },

  textoItemDesvincular: {
    fontSize: moderateScale(5),
    color: "#235EFF",
  },

  textoItemFecha: {
    fontSize: moderateScale(5),
    color: "#235EFF",
  },

  gridView: {
    marginTop: 0,
    flex: 0.95,
    padding: 10,
  },

  textoMenuAcciones: {
    fontSize: moderateScale(7),
    color: "#45444C",

    textAlign: "left",
  },

  textoMenuAccionesEliminar: {
    fontSize: moderateScale(7),
    color: "#B03A2E",

    textAlign: "left",
  },

  textoMenuAccionesMon: {
    fontSize: 14,
    color: "#45444C",

    textAlign: "left",
  },

  card: {
    //shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    backgroundColor: "#706f9a",
    padding: 17,
    borderRadius: 10,
    borderColor: "white",
    borderWidth: 1,
  },
  itemContainer: {
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
    height: 220,
    backgroundColor: "#706f9a",
    borderColor: "#FFFFFF",
    borderWidth: 1,
  },
  itemContainerEnviado: {
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
    height: 220,
    borderColor: "#FFFFFF",
    borderWidth: 1,
    backgroundColor: colorFondoGeneral,
  },
  itemContainerCotizado: {
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
    height: 220,
    borderColor: "#FFFFFF",
    borderWidth: 1,
    backgroundColor: "#f8ae40",
  },
  itemContainerComprado: {
    justifyContent: "flex-start",
    borderRadius: 15,
    padding: 10,
    height: 220,
    backgroundColor: "#2dab66",
    borderColor: "#FFFFFF",
    borderWidth: 1,
  },
  itemfechavencimientoenvio: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Roboto",
  },
  itemDescripcion: {
    fontWeight: "600",
    fontSize: 20,
    color: "#fff",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },
  itemTextoBarraProgreso: {
    fontWeight: "600",
    fontSize: 15,
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontWeight: "normal",
    fontStyle: "italic",
  },
  itemFaltanDetalles: {
    fontWeight: "600",
    fontSize: 15,
    color: "#F6EE5D",
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
  itemTextoEnviado: {
    fontWeight: "600",
    fontSize: 15,
    color: "#59584e",
    fontFamily: "Roboto",
    fontWeight: "normal",
  },
  itemTextoEnviadoCantidad: {
    fontWeight: "600",
    fontSize: 15,
    color: "#59584e",
    fontFamily: "Roboto",
    fontWeight: "bold",
  },

  itemTextoEstado: {
    //fontSize: SizeFont(WIDTH),
    fontSize: 11,
    color: "#FFFFFF",
    textAlign: "right",
  },
  itemTextoCerrado: {
    //fontSize: SizeFont(WIDTH),
    fontSize: 11,
    color: "#4E4D4D",
    textAlign: "right",
  },
  itemTextoEstadoEnviado: {
    //fontSize: SizeFont(WIDTH),
    fontSize: 15,
    color: "#4E4D4D",
  },
  itemTextoEstadoCotizado: {
    //fontSize: SizeFont(WIDTH),
    fontSize: 14,
    color: "#FFFFFF",
    textAlign: "right",
  },
  itemTextoEstadoFinalizado: {
    //fontSize: SizeFont(WIDTH),
    fontSize: 15,
    color: "#FFFFFF",
    textAlign: "right",
  },

  itemValorCampo: {
    fontSize: 13,
    color: "#FFFFFF",
    //paddingTop: 6,
    //
  },

  itemValorCampoCat: {
    fontSize: 14,
    color: "#000",
    //paddingTop: 6,
  },

  itemValorCampoPicker: {
    fontSize: 15,
    color: "#FFFFFF",
    //paddingTop: 6,
  },
  itemValorCampoCotInput: {
    fontSize: moderateScale(6),
    color: "#FFFFFF",
    //paddingTop: 6,
  },

  itemValorCampoCotInputSmall: {
    fontSize: 15,
    color: "#000",
    //paddingTop: 6,
  },

  itemValorCampoCotizacion: {
    fontSize: moderateScale(5),
    color: "#F6EE5D",
    //paddingTop: 6,
  },

  itemValorCampoCotizacion2: {
    fontSize: moderateScale(6),
    color: "#fff",
    //paddingTop: 6,
  },

  itemValorCampoCotizacionBold: {
    fontSize: moderateScale(6),
    color: "#FFF",
    //paddingTop: 6,
  },

  itemTextoCrear: {
    fontSize: wp(1),
    color: "#fff",
    flex: 1,
  },
  itemTextoCrearDetalle: {
    fontSize: wp(0.8),
    color: "#fff",
    flex: 1,
  },
});

const StylePerfil = StyleSheet.create({
  textoTitulo: {
    fontSize: 16,
    color: "#45444C",
    textAlign: "center",
    fontWeight: "bold",
  },

  textoRating: {
    fontSize: 50,
    color: "#45444C",
    textAlign: "center",
    paddingTop: 43,
  },

  textoRating2: {
    fontSize: 50,
    color: "#45444C",
    textAlign: "center",
  },

  textoTituloLegenda: {
    fontSize: 12,
    color: "#45444C",
  },

  textoTituloObservaciones: {
    fontSize: moderateScale(7),
    color: "#45444C",
    textAlign: "left",
  },

  btnLoginyPass: {
    fontFamily: "Prompt_400Regular",
    fontSize: moderateScale(15),
    color: "#F6EE5D",
    backgroundColor: "#706F9A",
    //paddingTop: 10
  },

  TextoButtonActualizar: {
    fontSize: moderateScale(15),
    color: "#FFF",
    fontFamily: "Prompt_400Regular",
  },

  TextoButtonGenerico: {
    fontSize: 15,
    color: "#FFF",
  },

  TextoButtonGenerico2: {
    fontSize: 15,
    color: "#45444C",
  },

  infoContainer: {
    backgroundColor: "#F2F2F2",
    alignItems: "center",
    marginTop: "1%",
    borderRadius: 0,
    width: wp(100),
    padding: 0,
  },
  screen: {
    backgroundColor: "#F6EE5D",
  },

  textoChangeOK: {
    fontFamily: "Prompt_400Regular",
    fontSize: 14,
    color: "#fff",
  },

  textbox: {
    fontSize: 16,
    color: "#85929E",
    //backgroundColor:'#F6F6F6'
  },

  textboxLine: {
    fontSize: 16,
    color: "#85929E",
    //backgroundColor:'#F6F6F6'
    borderBottomWidth: 1,
    borderColor: "#ccc",
  },

  textbox2: {
    fontSize: 15,
    color: "#85929E",
    //backgroundColor:'#F6F6F6'
  },

  textboxMulti: {
    fontSize: moderateScale(15),
    color: "#85929E",
    height: hp(10),
    //backgroundColor:'#F6F6F6'
  },

  Textinput: {
    color: "#85929E",
    //height: 40,
    paddingHorizontal: 2,
    backgroundColor: "#fff",
    //marginTop: -5,
    fontSize: moderateScale(15),
  },

  TextinputEmpresa: {
    color: "#fff",
    //height: 40,
    backgroundColor: "#fff",
    //marginTop: -5,
    fontSize: 14,
  },

  TextinputSignUP: {
    //color: '#85929E',
    //height: 40,
    backgroundColor: "#F6EE5D",
    //marginTop: -5,
    fontSize: 15,
  },

  TextinputCambiarPass: {
    backgroundColor: "#fff",
    fontSize: 15,
  },

  TextinputObs: {
    color: "#85929E",
    //height: 40,
    paddingHorizontal: 2,
    backgroundColor: "#fff",
    //marginTop: -5,
    fontSize: moderateScale(7),
  },

  label: {
    fontSize: 13,
    color: "#45444C",
    textAlign: "left",
  },

  labelMon13L: {
    fontSize: 13,
    color: "#45444C",
    textAlign: "left",
  },

  labelMon13: {
    fontSize: 13,
    color: "#45444C",
    textAlign: "left",
  },

  labelObligatorio: {
    fontSize: 14,
    color: "#961717",
    textAlign: "left",
  },

  labelMon12: {
    fontSize: 12,
    color: "#45444C",
    textAlign: "left",
  },

  labelMon16A: {
    fontSize: 14,
    color: "#131b8f",
    textAlign: "left",
    borderWidth: 1,
    padding: 10,
    borderRadius: 8,
    borderColor: "#131b8f",
  },

  labelMon15A: {
    fontSize: 15,
    color: "#131b8f",
  },

  labelMon11L: {
    fontSize: 11,
    color: "#45444C",
    textAlign: "left",
  },

  button: {
    backgroundColor: "#706F9A",
    width: "70%",
    color: "#F6EE5D",
    margin: 5,
  },
  link: {
    color: "#45444C",
  },
});

const StyleProfileScreen = StyleSheet.create({
  textNombreProfile: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: moderateScale(8),
    color: "#45444C",
  },

  textTituloQuodom: {
    fontSize: moderateScale(8),
    color: "#45444C",
  },

  textoRatingProfile: {
    fontWeight: "bold",
    fontSize: 20,
    color: "#45444C",
    textAlign: "center",
    //paddingTop: 43
  },

  container: {
    flex: 1,
    backgroundColor: "#F6EE5D",
  },
  text: {
    fontSize: moderateScale(12),

    color: "#52575D",
  },

  textAvatar: {
    backgroundColor: "#706F9A",
  },

  textAvatar2: {
    backgroundColor: "transparent",
  },

  textOpciones: {
    fontSize: 15,
    marginLeft: "10px",
    color: "#52575D",
    width: wp(70),
  },
  textSub: {
    fontSize: 15,

    color: "#52575D",
  },
  textDevice: {
    fontSize: 15,

    color: "#52575D",
  },
  subText: {
    fontSize: 12,
    color: "#AEB5BC",
    textTransform: "uppercase",
    fontWeight: "500",
  },
  image: {
    flex: 1,
    width: undefined,
    height: undefined,
  },
  titleBar: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
    marginHorizontal: 16,
  },
  profileImage: {
    borderRadius: 100,
    overflow: "hidden",
  },
  dm: {
    backgroundColor: "#41444B",
    position: "absolute",
    top: 10,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  active: {
    backgroundColor: "#34FFB9",
    position: "absolute",
    bottom: 28,
    left: 18,
    padding: 4,
    height: 20,
    width: 20,
    borderRadius: 18,
  },
  add: {
    backgroundColor: "#41444B",
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 40,
    height: 40,
    borderRadius: 30,
    alignItems: "center",
    justifyContent: "center",
  },

  infoContainer: {
    alignSelf: "center",
    alignItems: "center",
    marginTop: 16,
  },

  statsContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginTop: 16,
  },
  statsBox: {
    alignItems: "center",
    flex: 1,
  },
  infoButton: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    marginTop: 16,
    flexDirection: "column",
    marginLeft: 16,
    marginRight: 16,
    borderTopWidth: 1,
    width: "100%",
    borderColor: "#AEB5BC",
  },

  textMenuDireccion: {
    fontSize: moderateScale(6),

    color: "#52575D",
  },

  textMenuFiltros: {
    fontSize: moderateScale(7),

    color: "#52575D",
  },

  textMenuAvatar: {
    fontSize: 13,

    color: "#52575D",
  },
});

const StyleSigInScreen = StyleSheet.create({
  textIniciarSession: {
    fontSize: 16,
    color: "#45444C",
    paddingTop: 10,
  },
  textTituloCrearCuenta: {
    fontSize: moderateScale(14),
    color: "#706F9A",
  },

  textTituloRegistroCuenta: {
    fontSize: moderateScale(12),
    color: "#706F9A",
  },

  textoLoginyPass: {
    fontSize: 15,
    color: "#45444C",
    //paddingTop: 10
  },

  textoLoginyPass4: {
    fontSize: 15,
    color: "#45444C",
    //paddingTop: 10
  },

  textoLoginyPassBold: {
    fontSize: 16,
    color: "#45444C",
    //paddingTop: 10
  },

  textoLoginyPassBoldMons: {
    fontSize: 16,
    color: "#45444C",
    //paddingTop: 10
  },

  textoContinuar: {
    fontFamily: "Prompt_400Regular",
    fontSize: 15,
    color: "#002ae3",
    padding: 5,
  },

  textoLoginChico: {
    fontSize: 15,
    color: "#45444C",
    //paddingTop: 10
  },

  textoLoginChico1: {
    fontSize: 11,
    color: "#45444C",
    //paddingTop: 10
  },

  textoCrearCuenta: {
    fontSize: 16,
    color: "#45444C",
    //textDecorationLine:'underline'
    //paddingTop: 10
  },

  textoOlvidaste: {
    fontSize: 15,
    color: "#45444C",
    //textDecorationLine:'underline'
    //paddingTop: 10
  },

  btnLoginyPass: {
    fontSize: 15,
    color: "#F6EE5D",
    backgroundColor: "#706F9A",
    //paddingTop: 10
  },

  btnLoginyPassA: {
    fontSize: 15,
    color: "#706F9A",
    backgroundColor: "#F6EE5D",
    //paddingTop: 10
  },

  textoLegales: {
    fontSize: 13,
    color: "#fff",
  },

  textoLegalesB: {
    fontSize: moderateScale(5),
    color: "#45444C",
  },

  textoWizardLegenda: {
    fontSize: moderateScale(7),
    //color: '#fff',
    //textDecorationLine:'underline'
    //paddingTop: 10
  },

  textoLegendaChica: {
    fontSize: moderateScale(5),
    color: "#fff",
  },

  textoLegendaChicaB: {
    fontSize: moderateScale(5),
    color: "#45444C",
  },

  textoErrorPass: {
    fontFamily: "Prompt_400Regular",
    fontSize: 14,
    color: "#d90404",
  },

  textoErrorPassmin: {
    fontFamily: "Prompt_400Regular",
    fontSize: 12,
    color: "#d90404",
  },

  textoChangeOK: {
    fontFamily: "Prompt_400Regular",
    fontSize: 14,
    color: "#fff",
  },

  textoCrearEmpresa: {
    fontSize: 15,
    color: "#fff",
  },
});

const StyleSelectCategoria = StyleSheet.create({
  defaultCategoria: {
    fontSize: 15,
    color: "#45444C",
    padding: 5,
  },

  defaultText: {
    fontSize: 14,
    color: "#fff",
    padding: 5,
  },

  defaultTextSelectWeb: {
    fontSize: 14,
    color: "#fff",
    padding: 5,
    width: "100%",
  },

  itemFlatList: {
    fontSize: 13,
    color: "#fff",
    //padding: 5
    width: "90%",
  },

  itemFlatListBold: {
    fontSize: 13,
    color: "#fff",
    fontWeight: "bold",
    //padding: 5
  },

  itemText: {
    fontSize: 11,
    color: "#45444C",
    textTransform: "uppercase",
  },

  itemTextBlanco: {
    fontSize: 11,
    color: "#fff",
    textTransform: "uppercase",
  },

  itemTextLarge: {
    fontSize: 15,
    color: "#45444C",
    textTransform: "uppercase",
  },

  TituloModal: {
    fontSize: 18,
    color: "#F6EE5D",
  },

  TituloModalFoto: {
    fontSize: 15,
    color: "#45444C",
  },

  TextoModalItalic: {
    fontFamily: "Montserrat_400Regular_Italic",
    fontSize: 12,
    color: "#45444C",
    //textTransform: "uppercase",
  },

  itemSelect: {
    fontSize: 12,
    color: "#45444C",
  },
});

export {
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
  StylePerfil,
  StyleProfileScreen,
  StyleSigInScreen,
  StyleSelectCategoria,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  Jaldi_400Regular,
  WorkSans_300Light,
  WorkSans_400Regular,
  Montserrat_300Light,
  Montserrat_400Regular,
  Montserrat_400Regular_Italic,
  Montserrat_500Medium,
  Montserrat_600SemiBold,
  WIDTH,
  HEIGHT,
  SizeHeight,
  normalize,
  DetalleResumenCotstyle,
};
