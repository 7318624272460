import React, { useEffect } from "react";
import { View, TouchableOpacity, BackHandler } from "react-native";
import {
  Slide,
  Alert,
  VStack,
  Heading,
  Divider,
  Center,
  NativeBaseProvider,
  Text,
} from "native-base";
import {
  MaterialIcons,
  MaterialCommunityIcons,
  Entypo,
  Ionicons,
} from "@expo/vector-icons";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import { hp } from "../../styles/responsive";
import { useNavigation } from "@react-navigation/native";
import { CrearOperNotificacionQuodom } from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const SuccessScreen = () => {
  const navigation = useNavigation();

  /*  async function EnviarNotificacionQuodom() {
         //ESTO ES RECONTRA MALO A NIVEL PERFORMANCE, CAMBIARLO A QUODOMAPI
         //console.log('enviar quodom');
         //crear cabecera cotización
         const value = await AsyncStorage.getItem('userToken');
         var titulonotificacion = 'Recibiste un pedido de cotización de uno de tus rubros! - Vence en 3 días';
         var textonotificacion = 'Quodom';
         let Data = {
             estado: 'ENVIADO'
         };
 
         //const token = await AsyncStorage.getItem('userToken');
 
         //var fechaEnvioActual = new Date(fechaEnvio);
 
         //al final, crear la notificación para el comprador.
         var fechaActual = new Date();
         var fechaCreacion = fechaActual.getFullYear() + '/' + fechaActual.getMonth() + '/' + fechaActual.getDate();
         const nuevanotificacion = await CrearOperNotificacionQuodom(value, 'ENVIOQUODOM', idquodom, fechaCreacion, titulonotificacion, textonotificacion);
 
     } */

  useEffect(() => {
    //console.log("Entre al useEffect de SuccessScreen.js");
    //EnviarNotificacionQuodom();
    const backAction = () => {
      navigation.navigate("MisQuodomsStack");
      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  return (
    <NativeBaseProvider>
      <VStack flex={1} bg="#2ECC71">
        <View
          style={{
            flex: 1,
            alignContent: "center",
            paddingLeft: "20%",
            paddingRight: "20%",
          }}
        >
          <VStack
            mt={hp(10)}
            bg="#2ECC71"
            alignItems="center"
            justifyContent="center"
          >
            <VStack alignItems="center" space={2}>
              <Ionicons name="md-checkmark-sharp" size={50} color="#fff" />
              <Text style={QuodomDetalle.TextoModalSuccess2}>
                ¡Quodom enviado con éxito!
              </Text>
            </VStack>

            <VStack
              pt="10"
              justifyContent="center"
              space={0}
              alignItems="center"
            >
              <Text
                textAlign="center"
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoModalSuccessTip2}
              >
                Recibirás cotizaciones de los productos seleccionados.
              </Text>
            </VStack>
            <Divider pl="10" pr="10" my={4} bg="#fff" />
            <VStack
              pt="5"
              justifyContent="center"
              space={2}
              alignItems="center"
            >
              <Text
                textAlign="center"
                adjustsFontSizeToFit={true}
                numberOfLines={5}
                style={QuodomDetalle.TextoModalSuccess3}
              >
                Durante los próximos 3 días podrás elegir los proveedores y
                realizar tu compra.
              </Text>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 20,
                  borderRadius: 5,
                }}
                onPress={() => navigation.navigate("MisQuodomsStack")}
              >
                <Text style={QuodomDetalle.TextoModalSuccessButton}>
                  ¡Listo!
                </Text>
              </TouchableOpacity>
            </VStack>
          </VStack>
        </View>
      </VStack>
    </NativeBaseProvider>
  );
};

export default SuccessScreen;
