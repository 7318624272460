import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  ObtenerDetalleQuodoms,
  ObtenerDatosDireccion,
  RepetirQuodom,
} from "../../api";
import {
  HStack,
  NativeBaseProvider,
  Center,
  FlatList,
  Button,
  Modal,
  Text,
  Icon,
  Image,
  VStack,
  Box,
} from "native-base";
import DetalleProductoEnviado from "./DetalleProductoEnviado";
import { useNavigation, useRoute } from "@react-navigation/native";
import { basico, colorFondoGeneral } from "../../styles/general";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { MaterialIcons, Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import Loader from "../Loader";
import { moderateScale, wp } from "../../styles/responsive";
import ListaMisQuodomsInicial from "../../screens/MisQuodoms/ListaMisQuodomsInicial";

const DetalleQuodomVencido = (props) => {
  const mounted = useRef(true);
  const route = useRoute();
  const navigation = useNavigation();
  ////console.log("PARAAAAAA", route.params);

  const [iddireccion, setiddireccion] = useState(0);
  const [nombreQuodom, setNombreQuodom] = useState("");
  const [idquodom, setidquodomState] = useState("");

  const [visible, setVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsDireccion, setDireccion] = useState("");
  const [colorAviso, setColorAviso] = useState("");
  const [isLoadingAdentro, setisLoadingAdentro] = useState(true);
  const [iddireccionState, setiddireccionState] = useState();
  const [isExtended, setIsExtended] = useState(true);
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [repitiendo, setrepitiendo] = useState(false);

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() DetalleQuodomVencido.js");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerDetalleQuodoms(tokenLeer, q.id);

    if (mounted.current) {
      if (postsData.length === 0) {
        setSinProductos(false);
      } else {
        ////console.log('POSSSSSSSSSSSSS', postsData)
        setFechaVencimiento(postsData[0].fechavencimientoaceptacion);
        setPosts(postsData);
      }
      setisLoading(false);
    }
  };

  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  const Repetir = async () => {
    setrepitiendo(true);
    ////console.log("REPETIR");
    const token = await AsyncStorage.getItem("userToken");

    let data = {
      descripcion: nombreQuodom,
      iddireccion: iddireccion,
      estado: "VENCIDO",
    };

    const res = await RepetirQuodom(token, data, idquodom);

    setShowModal(false);
    route.params.toTop;
    route.params.estado;
    navigation.goBack();
    //SL Se complica mandar al screen por el stack 'goBack()'
    //navigation.navigate('DetalleQuodom',
    //    { idquodom: res.idquodom, nombreQuodom: nombreQuodom, estadoQuodom: 'CREADO', iddireccion: iddireccion,
    //    cantproductos: '', volver: 'home' })
  };

  const DatosDireccion = async () => {
    //console.log("Entre al DatosDireccion() DetalleQuodomEnviado.js");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const tokenDir = await AsyncStorage.getItem("userToken");
    const datosDireccion = await ObtenerDatosDireccion(tokenDir, q.iddireccion);

    if (mounted.current) {
      if (!datosDireccion) {
        setDireccion("Debe seleccionar una direccion de entrega.");
        setColorAviso("#DC7633");
      } else {
        setDireccion(
          "Enviar a " +
            datosDireccion.alias +
            " " +
            datosDireccion.direccion +
            " " +
            datosDireccion.localidad
        );
        setColorAviso("#45444C");
        setiddireccionState(datosDireccion.id);
      }
    }
    setisLoadingAdentro(false);
  };

  useEffect(() => {
    //console.log("Entre al useEffect DetalleQuodomEnviado.js");
    setisLoadingAdentro(true);
    mounted.current = true;
    AsyncStorage.getItem("quodomSeleccionada").then((valor) => {
      const q = JSON.parse(valor);
      setidquodomState(q.id);
      setNombreQuodom(q.descripcion);
      setiddireccion(q.iddireccion);
      setisLoading(false);
    });
    LeerDatos();
    DatosDireccion();

    return () => (mounted.current = false);
  }, [route]);

  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 16 };

  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    setIsExtended(currentScrollPosition <= 0);
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <TouchableOpacity
            style={{ marginLeft: "10px", marginTop: "10px" }}
            onPress={() => navigation.toggleDrawer()}
          >
            <Icon
              size="8"
              as={<MaterialIcons name="menu" />}
              color={quodomTheme.COLORS["COLORVIOLETA"]}
            />
          </TouchableOpacity>
          <VStack
            flex={0.6}
            marginTop={"3%"}
            borderWidth={0}
            alignItems="center"
          >
            <Box w="100%" mx="auto" backgroundColor="#F6EE5D">
              <Center>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Image
                    source={require("../../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"2%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </TouchableOpacity>
              </Center>
            </Box>

            <FlatList
              onScroll={onScroll}
              contentContainerStyle={{
                flexGrow: 1,
              }}
              style={{
                backgroundColor: "#F6EE5D",
                marginLeft: "5%",
                marginRight: "4%",
                width: "90%",
              }}
              numColumns="2"
              data={posts}
              ListHeaderComponent={
                <VStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingTop: 5,
                      paddingRight: "6%",
                    }}
                    justifyContent={"space-between"}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <VStack justifyContent={"space-between"}>
                      <Text
                        style={{
                          paddingLeft: 10,
                          paddingTop: 5,
                          backgroundColor: colorFondoGeneral,
                        }}
                      >
                        <Text style={QuodomDetalle.textoTitulo}>
                          {nombreQuodom}{" "}
                        </Text>
                      </Text>
                    </VStack>

                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: "transparent",
                        padding: 15,
                        borderRadius: 20,
                        borderWidth: 1,
                      }}
                      onPress={() => setShowModal(true)}
                    >
                      <Text style={QuodomDetalle.TextoModalButtonOK}>
                        Repetir
                      </Text>
                    </TouchableOpacity>
                  </HStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingBottom: "2%",

                      paddingRight: "6%",
                    }}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <View>
                      <HStack space={1} alignItems="center">
                        <SimpleLineIcons
                          name="location-pin"
                          size={moderateScale(13)}
                          color={colorAviso}
                        />
                        <Text style={QuodomDetalle.textoEnviarA}>
                          {postsDireccion}
                        </Text>
                      </HStack>
                      <HStack
                        style={{ paddingLeft: 15, paddingTop: 10 }}
                        justifyContent="space-between"
                        alignItems="center"
                        overflow="hidden"
                      >
                        <Text style={QuodomDetalle.textoEnviarA}>
                          Pedido vencido el {CortarFecha(fechaVencimiento)}{" "}
                        </Text>
                      </HStack>
                    </View>
                  </HStack>
                </VStack>
              }
              ListFooterComponent={null}
              renderItem={({ item }) => (
                <DetalleProductoEnviado
                  detalleProducto={item.detalleProducto}
                  nombreProducto={item.nombreProducto}
                  atributo1={item.atributo1}
                  atributo2={item.atributo2}
                  atributoNombre1={item.nombreAtributo1}
                  atributoNombre2={item.nombreAtributo2}
                  cantidad={item.cantidad}
                  imagen={item.imagen}
                  refreshImagen={item.refreshImagen}
                  atributosFaltantes={item.atributosFaltantes}
                  idproducto={item.idproducto}
                  nombreCategoria={item.nombreCategoria}
                  id={item.id}
                  idquodom={item.idquodom}
                  cantProveedores={item.cantProveedores}
                  cantEnZona={item.cantEnZona}
                ></DetalleProductoEnviado>
              )}
              keyExtractor={(item) => item.id}
            />
          </VStack>

          <VStack flex={0.4}>
            <ListaMisQuodomsInicial
              actualizarLista={visible}
            ></ListaMisQuodomsInicial>
          </VStack>
        </HStack>

        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          hardwareAccelerated={true}
          statusBarTranslucent={true}
          animationType="none"
          transparent={true}
        >
          <Modal.Content width={wp(95)}>
            {!repitiendo ? (
              <>
                <Modal.CloseButton />
                <Modal.Header>
                  <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                    Atención!
                  </Text>
                </Modal.Header>
              </>
            ) : null}
            <Modal.Body pt="5" pb="5">
              {repitiendo ? (
                <HStack>
                  <Center flex="1" pt="2">
                    <ActivityIndicator size="large" color="#706F9A" />
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={2}
                      pt="5"
                      pb="5"
                      style={QuodomDetalle.TextoModalEliminar}
                    >
                      Repetiendo Quodom{" "}
                      <Text style={QuodomDetalle.TextoModalEliminarBold}>
                        {nombreQuodom}
                      </Text>{" "}
                      ...
                    </Text>
                  </Center>
                </HStack>
              ) : (
                <HStack>
                  <Text style={QuodomDetalle.TextoModalEliminar}>
                    Esta seguro de repetir el Quodom{" "}
                    <Text style={QuodomDetalle.TextoModalEliminarBold}>
                      {nombreQuodom}
                    </Text>{" "}
                    ?
                  </Text>
                </HStack>
              )}
            </Modal.Body>
            {!repitiendo ? (
              <Modal.Footer>
                <Button.Group space={2}>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "transparent",
                      padding: 10,
                      borderRadius: 5,
                      borderWidth: 1,
                    }}
                    onPress={() => Repetir()}
                  >
                    <Text style={QuodomDetalle.TextoModalButtonOK}>
                      Repetir
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#706F9A",
                      padding: 10,
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      setShowModal(false);
                    }}
                  >
                    <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                      Cancelar
                    </Text>
                  </TouchableOpacity>
                </Button.Group>
              </Modal.Footer>
            ) : null}
          </Modal.Content>
        </Modal>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 16,
    right: 16,
    position: "absolute",
  },
});

export default DetalleQuodomVencido;
