import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  useDisclose,
  IconButton,
  Stagger,
  HStack,
  Icon,
  Text,
  useToast,
  Center,
  VStack,
  Modal,
  Button,
  Divider,
} from "native-base";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Alert,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SendQuodom, ObtenerDireccionesUsuarioQ, getPorcById } from "../../api";
import { hp, wp } from "../../styles/responsive";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import SelectDirecciones from "../Direcciones/SelectDirecciones";
import { AnimatedFAB } from "react-native-paper";

const AccionesDetalleQuodom = ({ isExtended }) => {
  const mounted = useRef(true);
  const toast = useToast();
  const id = "id_toast";

  const navigation = useNavigation();
  const route = useRoute();

  const { idquodom, nombreQuodom, estadoQuodom, iddireccion } =
    route.params !== undefined ? route.params : ["", "", "", ""];
  ////console.log('isExtended', isExtended)
  const { isOpen, onToggle } = useDisclose();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [postsDireccion, setDireccion] = useState([]);
  const [porccompletado, setporccompletado] = useState();
  const [cantproductos, setcantproductos] = useState();
  const [iddireccionState, setiddireccionState] = useState(iddireccion);

  const EnviarQuodom = async () => {
    if (iddireccionState === null) {
      //console.log("El quodom no tiene direcciones de envio");
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "¡Atención!",
          status: "message",
          description: "Debe seleccionar una dirección de envió para continuar",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return false;
    }

    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");

    let quodomData = {
      iddireccion: iddireccionState,
    };

    const respuesta = await SendQuodom(token, idquodom, quodomData);
    //console.log(respuesta);

    if (!respuesta.res) {
      setisLoading(false);
      //console.log("ERROR");
    } else {
      //setisLoading(false);
      navigation.navigate("SuccessScreen");
    }
  };

  const Validar = async () => {
    let value = await AsyncStorage.getItem("userToken");
    let postsData = await getPorcById(value, idquodom);
    setporccompletado(postsData.porccompletado);
    setcantproductos(postsData.cantproductos);
    //SL tengo que validar que el porccompletado sea 1.00
    if (parseInt(postsData.cantproductos) === 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={2}
                  style={QuodomDetalle.TextoToast}
                >
                  Este Quodom no tiene productos para enviar a cotizar.
                </Text>
              </Box>
            );
          },
        });
      }
      return;
    }
    if (parseInt(postsData.porccompletado) !== 1.0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Este Quodom tiene detalles para completar. Reviselo y vuelva a
                  intentar.
                </Text>
              </Box>
            );
          },
        });
      }
      return;
    }
    setModalVisible(true);
  };

  const Datos = async () => {
    //console.log("Validar() AccionesDetalleQuodom.js");
    let value = await AsyncStorage.getItem("userToken");
    let postsData = await getPorcById(value, idquodom);
    ////console.log('POSSSSS',postsData);
    if (mounted.current) {
      setporccompletado(postsData.porccompletado);
      setcantproductos(postsData.cantproductos);
    }
  };

  const DatosDireccion = async () => {
    //console.log(
    // "Entre al DatosDireccion() AccionesDetalleQuodom.js nombre:",
    //  nombreQuodom,
    //  "estado",
    //  estadoQuodom
    //);

    const token = await AsyncStorage.getItem("userToken");
    //const datosDireccion = await ObtenerDatosDireccion(tokenDir, iddireccion);
    const datosDireccion = await ObtenerDireccionesUsuarioQ(token);
    ////console.log('datoooo', datosDireccion)
    if (mounted.current) {
      setDireccion(datosDireccion);
      setisLoading(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect de AccionesDetalleQuodom.js");
    //if (estadoQuodom === 'CREADO') {
    DatosDireccion();
    Datos();
    //}
    return () => (mounted.current = false);
  }, []);

  function handleSelected(e) {
    console.log("direselec", e);
    setiddireccionState(e);
  }

  const isIOS = false;
  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 28 };

  return (
    <Box>
      <TouchableOpacity
        style={{
          alignItems: "center",
          backgroundColor: "transparent",
          padding: 15,
          borderRadius: 20,
          borderWidth: 1,
        }}
        onPress={() => Validar()}
      >
        <Text style={QuodomDetalle.TextoModalButtonOK}>Enviar a Cotizar</Text>
      </TouchableOpacity>
      {/* MODAL Envio */}
      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        size="lg"
      >
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Resumen {nombreQuodom}
            </Text>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Center flex="1" h={hp(18)}>
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            ) : (
              <VStack space={3} w="100%" flex={1} justifyContent="center">
                <HStack
                  pt="1"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Text style={QuodomDetalle.TextoModalResumenBold2}>
                    Cantidad de productos
                  </Text>
                  <Text pr="4" style={QuodomDetalle.TextoModalResumenBold}>
                    {cantproductos}
                  </Text>
                </HStack>

                <VStack w="100%" space={0} justifyContent="flex-start">
                  <Text style={QuodomDetalle.TextoModalResumenBold2}>
                    Enviar a
                  </Text>
                  <SelectDirecciones
                    data={postsDireccion}
                    valorSeleccionado={handleSelected}
                    valor={iddireccionState}
                  />
                </VStack>

                {/*       <HStack alignItems="center" justifyContent="space-between">
                    <Text style={QuodomDetalle.TextoModalResumen}>Enviar a</Text>
                    <Text adjustsFontSizeToFit={true} numberOfLines={1} style={QuodomDetalle.TextoModalResumenBold}>{postsDireccion}</Text>
                  </HStack> */}

                <Divider my={2} />
                <VStack pl="2" pr="2" justifyContent="flex-start">
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={2}
                    style={QuodomDetalle.TextoModalResumenTip}
                  >
                    Una vez que envié el Quodom a cotizar no podrá agregar más
                    productos.
                  </Text>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.TextoModalResumenTip}
                  >
                    Ni modificar la dirección de entrega.
                  </Text>
                </VStack>
              </VStack>
            )}
          </Modal.Body>
          <Modal.Footer>
            {isLoading ? null : (
              <Button.Group space={2}>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    padding: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                  }}
                  onPress={() => EnviarQuodom()}
                >
                  <Text style={QuodomDetalle.TextoModalButtonOK}>Enviar</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setModalVisible(false);
                  }}
                >
                  <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                    Cancelar
                  </Text>
                </TouchableOpacity>
              </Button.Group>
            )}
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 16,
    right: 16,
    position: "absolute",
  },
});

export default AccionesDetalleQuodom;
