import React, { useState, useEffect, useRef } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  NativeBaseProvider,
  Box,
  Text,
  VStack,
  FormControl,
  Input,
  Link,
  Button,
  HStack,
  Divider,
  useToast,
  Image,
  Center,
  ScrollView,
} from "native-base";

import { AuthContext } from "../components/context";
import { LogInApi } from "../api";
import {
  StyleSigInScreen,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  Montserrat_600SemiBold,
  Montserrat_400Regular,
  Montserrat_300Light,
} from "../styles/StyleQuodom";
import Loader from "../components/Loader";
const SHA256crypt = require("js-sha256");
const auth = require("../config/auth.config");

import AsyncStorage from "@react-native-async-storage/async-storage";

const SignInScreen = () => {
  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
    Montserrat_600SemiBold,
    Montserrat_400Regular,
    Montserrat_300Light,
  });

  const [isloading, setisLoading] = useState(false);

  const currentUrl = window.location.pathname;
  useEffect(() => {
    goURL();
  }, []);

  function goURL() {
    if (currentUrl === "/registro") {
      navigation.navigate("SignUpScreen");
    }
  }
  /* 
  // Can use this function below, OR use Expo's Push Notification Tool-> https://expo.dev/notifications
  async function sendPushNotification(expoPushToken) {
    const message = {
      to: expoPushToken,
      sound: 'default',
      title: 'Original Title',
      body: 'And here is the body!',
      data: { someData: 'goes here' },
    };

    await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });

  } */

  const [data, setData] = React.useState({
    username: "",
    password: "",
    check_textInputChange: false,
    secureTextEntry: true,
    isValidUser: true,
    isValidPassword: true,
  });

  const navigation = useNavigation();

  const toast = useToast();
  const id = "id_toast";

  const { signIn } = React.useContext(AuthContext);

  const textInputChange = (val) => {
    if (val.trim().length >= 4) {
      setData({
        ...data,
        username: val,
        check_textInputChange: true,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        username: val,
        check_textInputChange: false,
        isValidUser: false,
      });
    }
  };

  const handlePasswordChange = (val) => {
    if (val.trim().length >= 8) {
      setData({
        ...data,
        password: val,
        isValidPassword: true,
      });
    } else {
      setData({
        ...data,
        password: val,
        isValidPassword: false,
      });
    }
  };

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry,
    });
  };

  const handleValidUser = (val) => {
    if (val.trim().length >= 4) {
      setData({
        ...data,
        isValidUser: true,
      });
    } else {
      setData({
        ...data,
        isValidUser: false,
      });
    }
  };

  function SignIn() {
    loginHandle(data.username, data.password);
  }

  const loginHandle = async (userName, password) => {
    setisLoading(true);

    let appSing = SHA256crypt.sha256(userName + password + auth.claveprivada);

    //let tokenNotif = (expoPushToken == undefined || expoPushToken == '') ? 'emuladorsinpushnotification' : expoPushToken;
    //sdt
    let userInfo = {
      username: userName,
      password: password,
      appSign: appSing,
      //SL El token no se tiene que guardar en la tabla users, porque siempre lo pisa por el ultimo, por ahora lo mando vacio.
      //tokenNotification: tokenNotif
      tokenNotification: "aca no va",
    };

    if (data.username.length == 0 || data.password.length == 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Por favor, complete el usuario y la contraseña.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    const foundUser = await LogInApi(userInfo);
    ////console.log('--- foundUser:', foundUser);
    //SL res == false es error muestro el message
    if (!foundUser.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: foundUser.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }
    await AsyncStorage.setItem("userSignIn", foundUser.email);
    ////console.log('+++++++++++++++++ LOGIN', foundUser.email);
    //  console.log("usuario", foundUser);
    signIn(foundUser);

    //setisLoading(false);
    const token1 = await AsyncStorage.getItem("userToken");
    // console.log("tokeen", token1);
    if (token1 === null) {
      // console.log("tokennn", token1);
      //  navigation.navigate("SignInScreen");
    } else {
      navigation.navigate("Home");
    }
  };

  if (!fontsLoaded) {
    return <Loader />;
  } else {
    return (
      <NativeBaseProvider>
        <Center
          safeArea
          flex={1}
          p={2}
          w="100%"
          mx="auto"
          backgroundColor="#F6EE5D"
        >
          <Box safeArea p={2} w="100%" mx="auto" backgroundColor="#F6EE5D">
            <Center mt={10}>
              <Image
                source={require("../assets/INICIO-16.png")}
                alt="Quodom."
                alignItems="center"
                width={300}
                height={100}
              />
            </Center>
          </Box>

          <Box borderWidth={0} w="30%">
            <VStack space={3} pt={4} alignItems="center">
              <Text style={StyleSigInScreen.textIniciarSession}>
                Iniciar sesión
              </Text>

              <FormControl alignItems="center">
                <Input
                  style={StyleSigInScreen.textoLoginyPass}
                  rounded="8"
                  bgColor="#fff"
                  placeholder="Usuario o Correo electronico"
                  width="100%"
                  variant="filled"
                  onChangeText={(val) => textInputChange(val)}
                  onEndEditing={(e) => handleValidUser(e.nativeEvent.text)}
                />
              </FormControl>

              <FormControl mb={1} alignItems="center">
                <Input
                  style={StyleSigInScreen.textoLoginyPass}
                  rounded="8"
                  bgColor="#fff"
                  type="password"
                  placeholder="Contraseña"
                  width="100%"
                  variant="filled"
                  onChangeText={(val) => handlePasswordChange(val)}
                />
              </FormControl>

              {isloading ? (
                <Button
                  style={StyleSigInScreen.btnLoginyPass}
                  isLoading
                  spinnerPlacement="end"
                  width="100%"
                  rounded="8"
                ></Button>
              ) : (
                <Button
                  style={StyleSigInScreen.btnLoginyPass}
                  width="100%"
                  rounded="8"
                  onPress={() => SignIn()}
                >
                  <Text style={StyleSigInScreen.btnLoginyPass}>Continuar</Text>
                </Button>
              )}

              <Link
                alignSelf="center"
                p={4}
                style={StyleSigInScreen.textoLoginyPass}
                onPress={() => navigation.navigate("ForgotPassword")}
              >
                <Text style={StyleSigInScreen.textoOlvidaste}>
                  ¿Olvidaste tu clave?
                </Text>
              </Link>

              <Divider my="2" w="15%" bgColor="#45444C"></Divider>

              <VStack justifyContent="center" space={0} mt={2}>
                <Link
                  p={4}
                  justifyContent="center"
                  //onPress={() => navigation.navigate('SignUpScreen')}
                  onPress={() => navigation.navigate("SignUpScreen")}
                >
                  <VStack justifyContent="center" alignItems="center">
                    <Text style={StyleSigInScreen.textoLoginChico}>
                      ¿No tenés cuenta?{" "}
                    </Text>
                    <Text style={StyleSigInScreen.textoCrearCuenta}>
                      Crear Cuenta
                    </Text>
                  </VStack>
                </Link>
              </VStack>
              <HStack mt={5} justifyContent="center">
                <Text style={StyleSigInScreen.textoLoginChico1}>
                  Protegido por re CAPTCHA -{" "}
                </Text>
                <Link justifyContent="center" href="#">
                  <Text style={StyleSigInScreen.textoLoginChico1}>
                    Privacidad
                  </Text>
                </Link>
                <Text style={StyleSigInScreen.textoLoginChico1}> - </Text>
                <Link justifyContent="center" href="#">
                  <Text style={StyleSigInScreen.textoLoginChico1}>
                    {" "}
                    Condiciones
                  </Text>
                </Link>
              </HStack>
            </VStack>
          </Box>
        </Center>
      </NativeBaseProvider>
    );
  }
};

//export default SignInScreen;

export default SignInScreen;
