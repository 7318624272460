import React, { useState, useEffect } from "react";
import { View, TouchableOpacity } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  HStack,
  VStack,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { wp } from "../../styles/responsive";
import { QuodomDetalle, QuodomList_Card } from "../../styles/StyleQuodom";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { createStackNavigator } from "@react-navigation/stack";

const NodoQuodomCrear = (props) => {
  const [user, setUser] = useState();

  let navigation = useNavigation();

  const loadUsuarioGuest = async () => {
    //console.log("Entre al NodoQuodomCrear.js");
    const userGuest = await AsyncStorage.getItem("userGuest");
    ////console.log('QUE SOY?', userGuest)
    setUser(userGuest);
  };

  useEffect(() => {
    loadUsuarioGuest();
  }, []);

  return (
    <View style={{ paddingTop: 0 }}>
      <Center style={QuodomList_Card.itemContainerCrear}>
        <Box pt="1" pr="2" pl="2">
          <Center>
            <HStack>
              <Ionicons
                name="alert-circle"
                style={{ marginTop: "2%" }}
                size={30}
                color="#9C9CB9"
              />
              <VStack>
                <Text style={QuodomDetalle.itemTextoCrear}>
                  No tenés Quodoms activos.
                </Text>
                <Text style={QuodomDetalle.itemTextoCrearDetalle}>
                  Navegá, agregá productos y se creará uno.
                </Text>
              </VStack>
            </HStack>
          </Center>
        </Box>
      </Center>
    </View>
  );
};

export default NodoQuodomCrear;
