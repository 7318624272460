import React, { useEffect, useState } from "react";
import { View, Image, Text, Pressable, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias, ModalStyle } from "../../styles/general";
import {
  EliminarDetalleQuodom,
  InsertarDetalleQuodom,
  ModificarDetalleQuodom,
} from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";

import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { moderateScale, wp } from "../../styles/responsive";

import SelectCantidad from "./Pickers/SelectCantidad";
import SelectAtributo from "./Pickers/SelectAtributo";

const SHA256crypt = require("js-sha256");
const auth = require("../../config/auth.config");

import {
  Box,
  Modal,
  Center,
  HStack,
  Stack,
  VStack,
  useDisclose,
  Button,
  Input,
} from "native-base";
import MenuAcciones4 from "./MenuAcciones4";
import AgregarProducto from "./AgregarProducto";

const DetalleProductoQuodom = (props) => {
  var imagen = require("../../assets/line_zigzag.png");

  const obtenerColor = (estado) => {
    if (estado) return "#FFFFFF";
    else return "#F6EE5D";
  };

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const [refreshing, setRefreshing] = useState(false);
  const [valorAtributo1, setvalorAtributo1] = useState(props.atributo1);
  const [valorAtributo2, setvalorAtributo2] = useState(props.atributo2);
  const [valorNameAtributo1, setvalorNameAtributo1] = useState(
    props.atributoNombre1
  );
  const [valorNameAtributo2, setvalorNameAtributo2] = useState(
    props.atributoNombre2
  );
  const [cant, setcant] = useState(props.cantidad);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, [refreshing]);

  const [minimizadoValue, setMininizadoValue] = useState(false);

  let detallequodomcompleto = false;
  const atr1 = valorNameAtributo1 !== "" && valorNameAtributo1 != null;
  const atr2 = valorNameAtributo2 !== "" && valorNameAtributo2 != null;

  const debugBorder = 0;
  const paddingAtr = atr1 ? (atr2 ? 24 : 16) : 8;

  let textoFaltantes = "";
  if (props.atributosFaltantes > 1) {
    textoFaltantes = "Faltan " + props.atributosFaltantes + " detalles";
  } else {
    textoFaltantes = "Falta 1 detalle";
  }

  const obtenerEstilo = (atributo1, atributo2, cantidad) => {
    if (atributo1 === 0) {
      atributo1 = null;
    }
    if (atributo2 === 0) {
      atributo2 = null;
    }
    //console.log("atributo1", atributo1);
    if (atr2 && atr1) {
      if (atributo1 && atributo2 && cantidad) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }

    if (!atr2 && atr1) {
      if (atributo1 && cantidad) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }

    if (!atr2 && !atr1) {
      if (cantidad) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }
  };

  const obtenerColorPicker = () => {
    if (detallequodomcompleto) return "#FFFFFF";
    else return "#000000";
  };

  const colorFondo = () => {
    if (detallequodomcompleto) return "#2DAB66";
    else return "#fff";
  };

  const colorBordes = () => {
    if (detallequodomcompleto) return "#fff";
    else return "transparent";
  };

  const obtenerColorSelect = () => {
    if (detallequodomcompleto) return "#FFFFFF";
    else return "#000000";
  };

  async function cambiarCantidad(idquodom, idlinea, valor) {
    ////console.log('valor', valor)
    if (valor === "" || valor === null) {
      valor = 0;
      setcant(0);
    }
    let nuevoValor = {
      idquodom: idquodom,
      cantidad: valor,
      id: idlinea,
    };

    const token = await AsyncStorage.getItem("userToken");
    ModificarDetalleQuodom(token, idlinea, nuevoValor);
  }

  async function funcionRestar(idquodom, idlinea) {
    if (parseInt(cant) <= 1) {
      ////console.log(parseInt(cant))
    } else {
      let resta = parseInt(cant) - 1;
      setcant(resta);
      cambiarCantidad(idquodom, idlinea, resta);
    }
  }

  async function funcionSumar(idquodom, idlinea) {
    ////console.log('Cant', parseInt(cant));
    let suma = parseInt(cant) + 1;
    setcant(suma);

    cambiarCantidad(idquodom, idlinea, suma);
  }

  const obtenerEstiloMinimizado = (atributo1, atributo2, cantidad) => {
    if (atributo1 && atributo2 && cantidad)
      return DetalleProductoQuodomstyle.cardCompletoMinimizado;
    else return DetalleProductoQuodomstyle.cardIncompletoMinimizado;
  };

  /* const Eliminar = async (props) => {
    const token = await AsyncStorage.getItem('userToken');
    EliminarDetalleQuodom(token, props.id);
    setModalVisibleEliminar(false);
    props.actualizarestado();
  } */

  /*  const DuplicarProducto = async (props) => {
     const value = await AsyncStorage.getItem('userToken');
     let signCreado = SHA256crypt.sha256(props.idquodom + props.idproducto + '1' + auth.claveprivada);
     let datainsercion = { idquodom: props.idquodom, idproducto: props.idproducto, cantidad: 1, nombreProducto: props.nombreProducto, appSign: signCreado }
     await InsertarDetalleQuodom(value, datainsercion);
     setModalVisibleDuplicar(false);
     props.actualizarestado();
   } */

  /*  useEffect(() => {
    
   }, []); */

  function actualizarCard() {
    setRefreshing((state) => !state);
    //setRefreshing(false);
  }

  function getvalorAtributo1(valor) {
    ////console.log(valor);
    setvalorAtributo1(valor);
  }

  function getvalorAtributo2(valor) {
    ////console.log(valor);
    setvalorAtributo2(valor);
  }

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const CardMinimizado = () => {
    return (
      <View>
        <Box
          rounded={11}
          /*  overflow="hidden" */
          width="100%"
          shadow={0}
          marginTop={4}
          backgroundColor={obtenerEstilo(valorAtributo1, valorAtributo2, cant)}
          zIndex={1}
        >
          {/*  <Center
            position="absolute"
            top={-10}
            right={5}
            zIndex={100}
          >
            <MaterialCommunityIcons name="bookmark-remove" size={35} color="#000" style={{ alignContent: 'flex-start' }}
              onPress={() => setModalVisibleEliminar(true)} />
          </Center> */}

          <View
            style={{
              flex: 1,
              position: "absolute",
              top: 5,
              right: 9,
              zIndex: 100,
            }}
          >
            <MenuAcciones4
              nombre={props.nombreProducto}
              props={props}
              actualizarestado={props.actualizarestado}
            />
          </View>

          <HStack
            space={1}
            bg="#fff"
            borderWidth={debugBorder}
            borderTopLeftRadius="11"
            borderTopRightRadius="11"
            alignItems="center"
            width="100%"
          >
            <VStack>
              <Image
                source={{
                  uri: props.imagen,
                }}
                //uri={props.imagen} // image address
                //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                style={estilosCategorias.ImagenDetalleQuodom}
              />
            </VStack>
            <VStack>
              <Text style={DetalleProductoQuodomstyle.textoTituloNegro}>
                {props.nombreProducto}
              </Text>
              {/*  <Text style={DetalleProductoQuodomstyle.textoCategoria}>{props.nombreCategoria}</Text> */}
            </VStack>
          </HStack>

          <View
            style={{
              paddingTop: 30,
              paddingRight: 0,
              margin: 0,
              position: "absolute",
              top: 35,
              right: 7,
              zIndex: 1000,
            }}
          >
            <MaterialIcons
              name="keyboard-arrow-down"
              size={40}
              color="#FFFFFF"
              onPress={() => setMininizadoValue(false)}
            />
          </View>

          {/*   <HStack justifyContent='flex-end'>
                  <MaterialIcons name="keyboard-arrow-up" size={40} color='#FFFFFF'
                    onPress={() => setMininizadoValue(true)} />
                </HStack> */}

          {detallequodomcompleto ? (
            <HStack space={1} mb={3} ml={2} mt={2}>
              <HStack space={1} pt="1" pb="1" alignItems="center">
                <FontAwesome name="check" size={25} color="white" />
                {/*  <Text style={DetalleProductoQuodomstyle.textoEstadoNodoQuodomCompletoMinimizado}>COMPLETO</Text> */}
              </HStack>
              {/*    <View
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: 'white',
                }}
              ></View> */}

              <HStack space={2} pt="1" pb="1">
                <Text
                  style={{
                    textAlign: "center",
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                    paddingRight: 3,
                    color: obtenerColor(detallequodomcompleto),
                    fontSize: 13,
                    fontFamily: "Prompt_300Light",
                    borderWidth: 1,
                    borderColor: "#FFFFFF",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 8,
                  }}
                >
                  Cant. {cant}{" "}
                </Text>
                {atr1 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 3,
                      paddingRight: 3,
                      color: obtenerColor(detallequodomcompleto),
                      fontSize: 12,

                      borderWidth: 1,
                      borderColor: obtenerColor(detallequodomcompleto),
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 8,
                    }}
                  >
                    {capitalize(props.atributoNombre1)} {props.atributo1}
                  </Text>
                ) : null}
                {atr2 ? (
                  <Text
                    style={{
                      textAlign: "center",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 3,
                      paddingRight: 3,
                      color: obtenerColor(detallequodomcompleto),
                      fontSize: 12,

                      borderWidth: 1,
                      borderColor: obtenerColor(detallequodomcompleto),
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 8,
                    }}
                  >
                    {capitalize(props.atributoNombre2)} {props.atributo2}
                  </Text>
                ) : null}
              </HStack>
            </HStack>
          ) : (
            <HStack space={2} mb={3} ml={2} mt={3}>
              <Text
                style={{
                  textAlign: "center",
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: obtenerColor(detallequodomcompleto),
                  fontSize: 13,

                  borderWidth: 1,
                  borderColor: obtenerColor(detallequodomcompleto),
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 8,
                }}
              >
                {props.cantProveedores} proveedores
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: obtenerColor(detallequodomcompleto),
                  fontSize: 13,

                  borderWidth: 1,
                  borderColor: obtenerColor(detallequodomcompleto),
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 8,
                }}
              >
                {props.cantEnZona} en tu zona
              </Text>
            </HStack>
          )}
        </Box>
      </View>
    );
  };

  return (
    <View
      style={{
        justifyContent: "center",
        width: "45%",
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        marginTop: 0,
        zIndex: 10,
      }}
    >
      {minimizadoValue ? (
        <CardMinimizado />
      ) : props.id === "1CREAR" ? (
        <AgregarProducto
          estado={props.onRefresh}
          codigoQuodom={props.idquodomState}
          nombreQuodom={props.nombreQuodom}
        />
      ) : (
        <View
          style={{
            justifyContent: "center",
            width: "100%",
            marginLeft: 10,
            marginRight: 10,
            marginBottom: 2,
            marginTop: 0,
          }}
        >
          <Box
            rounded={11}
            shadow={0}
            backgroundColor={obtenerEstilo(
              valorAtributo1,
              valorAtributo2,
              cant
            )}
            zIndex={1}
          >
            <View
              style={{
                flex: 1,
                position: "absolute",
                top: 5,
                right: 9,
                zIndex: 100,
              }}
            >
              <MenuAcciones4
                nombre={props.nombreProducto}
                props={props}
                actualizarestado={props.actualizarestado}
              />
            </View>

            <HStack
              space={1}
              bg="#fff"
              borderWidth={debugBorder}
              borderTopLeftRadius="11"
              borderTopRightRadius="11"
              alignItems="center"
            >
              <VStack>
                <Image
                  source={{
                    uri: props.imagen,
                  }}
                  style={estilosCategorias.ImagenDetalleQuodom}
                />
              </VStack>
              <VStack>
                <Text style={DetalleProductoQuodomstyle.textoTituloNegro}>
                  {props.nombreProducto}
                </Text>
              </VStack>
            </HStack>
            <HStack
              space={2}
              marginLeft={5}
              marginTop={3}
              borderWidth={debugBorder}
            >
              <Text
                style={{
                  textAlign: "center",
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: obtenerColor(detallequodomcompleto),
                  fontSize: 13,
                  borderWidth: 1,
                  borderColor: obtenerColor(detallequodomcompleto),
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 8,
                }}
              >
                {props.cantProveedores} proveedores
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  paddingTop: 3,
                  paddingBottom: 3,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: obtenerColor(detallequodomcompleto),
                  fontSize: 13,
                  borderWidth: 1,
                  borderColor: obtenerColor(detallequodomcompleto),
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 8,
                }}
              >
                {props.cantEnZona} en tu zona
              </Text>
              {/* <style={{ marginLeft: 6, textAlign: 'center', padding: 4, color: obtenerColor(detallequodomcompleto), fontSize: 13, fontFamily: 'Prompt_300Light', borderWidth: 1, borderColor: obtenerColor(detallequodomcompleto), shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5 }}> ... </style=> */}
            </HStack>

            <HStack
              space={2}
              marginLeft={2}
              marginRight={2}
              marginTop={3}
              marginBottom={2}
              borderWidth={debugBorder}
            >
              <Box w="40%" borderWidth={debugBorder}>
                {detallequodomcompleto ? (
                  <VStack
                    space={1}
                    alignItems="center"
                    justifyContent="center"
                    borderWidth={debugBorder}
                  >
                    <HStack
                      space={1}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <FontAwesome
                        name="check"
                        size={17}
                        color="white"
                        style={{ alignContent: "center", zIndex: 1 }}
                      />
                      <Text
                        style={
                          DetalleProductoQuodomstyle.textoEstadoNodoQuodomCompleto
                        }
                      >
                        COMPLETO
                      </Text>
                    </HStack>
                    <Image
                      source={imagen}
                      style={{ width: 300, height: 8 }}
                      resizeMode="contain"
                    ></Image>
                  </VStack>
                ) : (
                  <VStack
                    space={1}
                    alignItems="center"
                    borderWidth={debugBorder}
                    //pt="2"
                  >
                    <HStack
                      space={1}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <FontAwesome
                        name="remove"
                        size={17}
                        color="white"
                        style={{ alignContent: "center", zIndex: 1 }}
                      />
                      <Text
                        style={
                          DetalleProductoQuodomstyle.textoEstadoNodoQuodomCompleto
                        }
                      >
                        INCOMPLETO
                      </Text>
                    </HStack>
                    <Image
                      source={imagen}
                      style={{ width: 300, height: 8 }}
                      resizeMode="contain"
                    ></Image>
                  </VStack>
                )}

                {detallequodomcompleto ? (
                  <VStack pt={paddingAtr} pr={4} alignItems="flex-end">
                    <Text style={QuodomList_Card.itemSinFaltanDetalles}>
                      ¡LISTO!
                    </Text>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomList_Card.itemSinFaltanDetallesChico}
                    >
                      Ingresado con éxito
                    </Text>
                  </VStack>
                ) : (
                  <VStack
                    pt={paddingAtr}
                    pr={4}
                    space={0}
                    alignSelf="flex-end"
                    borderWidth={debugBorder}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={QuodomList_Card.itemFaltanDetalles}
                    >
                      {textoFaltantes}
                    </Text>
                    <HStack
                      space={1}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomList_Card.itemSinFaltanDetalles}
                      >
                        ¡Ingresalos!
                      </Text>
                      <MaterialCommunityIcons
                        name="arrow-right"
                        size={14}
                        color="#fff"
                      />
                    </HStack>
                  </VStack>
                )}
              </Box>

              <View
                style={{
                  borderLeftWidth: 1,
                  borderLeftColor: "white",
                  marginBottom: 5,
                  marginTop: 5,
                }}
              />

              <Box w="55%" marginBottom={0}>
                <HStack w="100%" pb={2} space={0} alignItems="center">
                  <Text style={[QuodomDetalle.itemValorCampo]}>Cantidad:</Text>
                  {/* <PickerCantidad idproducto={props.idproducto} idlinea={props.id} idquodom={props.idquodom} cantidad={props.cantidad} detallequodomcompleto={detallequodomcompleto} /> */}

                  <VStack
                    style={{ alignItems: "flex-end", flex: 1 }}
                    borderWidth={0}
                  >
                    <Input
                      w="24"
                      rounded={20}
                      h="10"
                      //borderColor='transparent'
                      bg={colorFondo()}
                      borderColor={colorBordes()}
                      color={obtenerColorSelect()}
                      _focus={{
                        backgroundColor: "gray.200",
                        borderColor: "#6604c9",
                        color: "#000",
                      }}
                      selectionColor="gray.200"
                      textAlign="center"
                      value={cant}
                      onEndEditing={() =>
                        cambiarCantidad(props.idquodom, props.id, cant)
                      }
                      onChangeText={(text) => {
                        setcant(text);
                      }}
                    />
                  </VStack>
                </HStack>

                {atr1 ? (
                  <View>
                    <View
                      style={{
                        borderBottomColor: "#FFFFFF",
                        borderBottomWidth: 1,
                      }}
                    />

                    <HStack
                      w="100%"
                      pb={2}
                      pt={2}
                      space={0}
                      alignItems="center"
                    >
                      <Text style={[QuodomDetalle.itemValorCampo]}>
                        {capitalize(valorNameAtributo1)}:
                      </Text>
                      <SelectAtributo
                        idproducto={props.idproducto}
                        idlinea={props.id}
                        idquodom={props.idquodom}
                        atributoNombre={props.atributoNombre1}
                        atributovalue={props.atributo1}
                        detallequodomcompleto={detallequodomcompleto}
                        nroAtr={1}
                        funcionestado={actualizarCard}
                        valordelpicker={getvalorAtributo1}
                      />
                    </HStack>
                  </View>
                ) : null}

                {atr2 ? (
                  <View>
                    <View
                      style={{
                        borderBottomColor: "#FFFFFF",
                        borderBottomWidth: 1,
                      }}
                    />
                    <HStack
                      w="100%"
                      pb={2}
                      pt={2}
                      space={0}
                      alignItems="center"
                    >
                      <Text style={[QuodomDetalle.itemValorCampo]}>
                        {capitalize(valorNameAtributo2)}:
                      </Text>
                      <SelectAtributo
                        idproducto={props.idproducto}
                        idlinea={props.id}
                        idquodom={props.idquodom}
                        atributoNombre={props.atributoNombre2}
                        atributovalue={props.atributo2}
                        detallequodomcompleto={detallequodomcompleto}
                        nroAtr={2}
                        funcionestado={actualizarCard}
                        valordelpicker={getvalorAtributo2}
                      />
                    </HStack>
                  </View>
                ) : null}

                <HStack justifyContent="flex-end" borderWidth={0}>
                  <MaterialIcons
                    name="keyboard-arrow-up"
                    size={35}
                    color="#FFFFFF"
                    onPress={() => setMininizadoValue(true)}
                  />
                </HStack>
              </Box>
            </HStack>
          </Box>
        </View>
      )}
    </View>
  );
};
export default DetalleProductoQuodom;
