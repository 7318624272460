import React, { useEffect, useState, useRef } from "react";
import {
  TouchableOpacity,
  RefreshControl,
  View,
  StyleSheet,
} from "react-native";
import {
  Center,
  HStack,
  Icon,
  Box,
  VStack,
  useToast,
  Text,
  Pressable,
  FlatList,
} from "native-base";
import {
  Ionicons,
  MaterialCommunityIcons,
  AntDesign,
} from "@expo/vector-icons";
import {
  ObtenerListaOfertas,
  IgnorarQuodom,
  ArchivarCH,
  DeshacerIgnorarQuodom,
  ObtenerListaOfertasArchivadas,
} from "../../api";
import OfertasItem from "./OfertasItem";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import {
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
} from "@expo-google-fonts/prompt";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hp, moderateScale, WIDTH, wp } from "../../styles/responsive";
import { colorVioleta } from "../../styles/general";
import quodomTheme from "../../constants/Theme";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import Loader from "../../components/Loader";
import FiltrosCotizaciones from "./FiltrosCotizaciones";

import { SwipeListView } from "react-native-swipe-list-view";
import { Button, Snackbar } from "react-native-paper";

const OfertasListArchivadas = (props) => {
  const mounted = useRef(true);

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
  });

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const isFocused = useIsFocused();
  const [cotizaciones, setCotizaciones] = useState("");
  const [oldcotizaciones, setoldcotizaciones] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingItem, setisLoadingItem] = useState(false);
  const [autoReload, setautoReload] = useState(4000);
  const [id, setId] = useState();
  const [visibleArchivar, setvisibleArchivar] = useState(false);
  const [visibleIgnorar, setvisibleIgnorar] = useState(false);

  const navigation = useNavigation();

  const getOfertas = async () => {
    try {
      const token = await AsyncStorage.getItem("userToken");
      //const user = await AsyncStorage.getItem('userID');
      ////console.log('Entre a getOfertas auto userId', user);
      const cotizaciones = await ObtenerListaOfertasArchivadas(token);
      ////console.log('COTIZACION', cotizaciones)
      if (mounted.current) {
        setCotizaciones(cotizaciones);
        setoldcotizaciones(cotizaciones);
        setisLoading(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const onRefresh = React.useCallback(async () => {
    await getOfertas();
  }, []);

  useEffect(() => {
    mounted.current = true;
    //console.log('Entre al useEffect de OfertasList.js');
    getOfertas();

    return () => (mounted.current = false);
  }, [isFocused, refreshing]);

  const EmptyList = ({}) => (
    <View style={{ marginVertical: 20, alignItems: "center" }}>
      <Box
        w="95%"
        bgColor="#EAEEF7"
        rounded={5}
        borderWidth={0}
        shadow={2}
        shadowRadius={8}
      >
        <HStack
          w="100%"
          justifyContent="flex-start"
          alignItems="center"
          space={3}
          p="5"
        >
          <AntDesign name="exclamationcircleo" size={24} color="black" />
          <Text
            w="90%"
            adjustsFontSizeToFit={true}
            numberOfLines={2}
            style={{
              fontSize: 14,
              fontFamily: "Montserrat_400Regular",
              color: "#000",
            }}
          >
            Aún no tienes cotizaciones archivadas.
          </Text>
        </HStack>
      </Box>
    </View>
  );

  const Footer = ({}) => <Center h={hp(20)}></Center>;

  const renderItem = (data) => (
    <OfertasItem cotizacion={data.item} isLoadingItem={isLoadingItem} />
  );

  const obtenerColor = (estado) => {
    if (estado === "VENDISTE" || estado === "COTIZADO") return "#454e7a";
    else return "#c41f06";
  };

  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: colorVioleta,
          alignContent: "center",
        }}
      >
        <HStack
          bg={quodomTheme.COLORS["COLORVIOLETA"]}
          px={2}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack
              space={2}
              alignItems="center"
              pt="3"
              pl="1"
              pr="4"
              pb="0"
              borderWidth={0}
            >
              <Ionicons name="arrow-back-sharp" size={25} color="white" />
            </HStack>
          </TouchableOpacity>
        </HStack>

        <HStack
          h="10"
          space={2}
          pl="15"
          pr="15"
          justifyContent="space-between"
          alignItems="center"
          borderWidth={0}
        >
          <Text style={QuodomDetalle.textoTituloMisCotizacion}>
            Cotizaciones Archivadas
          </Text>
          {/*  <FiltrosCotizaciones visible={visible} closeMenu={closeMenu} openMenu={openMenu} /> */}
        </HStack>

        <HStack mt="3">
          <FlatList
            vertical={true}
            data={cotizaciones}
            ListFooterComponent={Footer}
            renderItem={renderItem}
            ListEmptyComponent={EmptyList}
            //keyExtractor={(item) => item.idquodom.toString()}
            keyExtractor={(item) => item.idquodom.toString()}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh}
                colors={["#78e08f"]}
                progressBackgroundColor="#0a3d62"
              />
            }
          />

          {/*  <SwipeListView
            disableRightSwipe
            data={cotizaciones}
            renderItem={renderItem}
            //renderHiddenItem={renderHiddenItem}
            leftOpenValue={0}
            rightOpenValue={-74}
            previewRowKey={'0'}
            previewOpenValue={-40}
            previewOpenDelay={3000}
            //onRowDidOpen={onRowDidOpen}
            ListHeaderComponent={Header}
            ListFooterComponent={Footer}
            ListEmptyComponent={EmptyList}
            //onSwipeValueChange={onSwipeValueChange}
            //useNativeDriver={true}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={onRefresh} />}
            keyExtractor={item => item.idquodom.toString()}
          /> */}
        </HStack>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  itemButtons: {
    color: "#8395a7",
    marginRight: 0,
    textAlign: "left",
    padding: 0,
    marginEnd: 10,
    color: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    shadowRadius: 0,
    FowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    width: WIDTH * 0.25,
  },
  item: {
    color: "#8395a7",
    marginRight: 0,
    textAlign: "left",
    padding: 0,
    width: WIDTH * 0.4,
    color: "#FFFFFF",
    fontSize: 16,
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
  },

  backTextWhite: {
    //color: '#FFF',
    color: "#fff",
    fontSize: moderateScale(12),
    fontFamily: "Prompt_300Light",
    //borderWidth: 1,
    // height: hp(13)
  },

  rowBack: {
    alignItems: "center",
    backgroundColor: "#454e7a",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 16,
    marginBottom: 5,
    marginTop: 5,
    borderRadius: 5,
  },
  backRightBtn: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: "#454e7a",
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  backRightBtnRight: {
    backgroundColor: "#c41f06",
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
});

export default OfertasListArchivadas;
