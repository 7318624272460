import {StyleSheet, Animated, View, Dimensions,Text,TouchableOpacity} from 'react-native';
import React from 'react';
import { hp } from '../../../styles/responsive';
import { QuodomList_Card } from '../../../styles/StyleQuodom';
const {width} = Dimensions.get('screen');
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
const PaginationVendedor = ({data, scrollX, index}) => {
  const navigation = useNavigation();
  
  const Omitir = async () => {
    await AsyncStorage.setItem('ShowSlides', 'NO');
    navigation.navigate('Home');
  }


  return (
    <View style={styles.container} >
      {data.map((_, idx) => {
        const inputRange = [(idx - 1) * width, idx * width, (idx + 1) * width];

        const dotWidth = scrollX.interpolate({
          inputRange,
          outputRange: [12, 12, 12],
          extrapolate: 'clamp',
        });

    

        const backgroundColor = scrollX.interpolate({
          inputRange,
          outputRange: ['#706f9a', '#F6EE5D', '#706f9a'],
          extrapolate: 'clamp',
        });
     

        return (
       
          <Animated.View
            key={idx.toString()}
            style={[
              styles.dot,
              {width: dotWidth, backgroundColor},

              // idx === index && styles.dotActive,
            ]}
          />
       
        );
      })}
           <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    width:"30%"
                }}
                      onPress={() => Omitir()}
                     >
                
                   <Text style={styles.texto} >OMITIR</Text>
                </TouchableOpacity>
      
    </View>
  );
};

export default PaginationVendedor;

const styles = StyleSheet.create({
  texto:{
    fontSize: 18,
    color: '#F6EE5D',
    fontFamily: 'Prompt_300Light',
  },
  container: {
    height: hp(15),
    backgroundColor: '#706f9a',
    position: 'absolute',
    bottom: 0,
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft:80
  },
  dot: {
    borderWidth:1,
    borderColor:'#F6EE5D',
    width: 12,
    height: 12,
    borderRadius: 6,
    marginHorizontal: 3,
    backgroundColor: '#ccc',
  },
  dotActive: {
    backgroundColor: '#000',
  },
});