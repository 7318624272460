import React, { useEffect, useState, useRef } from "react";
import { ActivityIndicator } from "react-native";
import { Select, VStack, CheckIcon, HStack } from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getQuodomCreados } from "../../../api";
import { StyleSelectCategoria } from "../../../styles/StyleQuodom";
//import { MaterialIcons, MaterialCommunityIcons, Entypo, Ionicons } from "@expo/vector-icons";

const SelectQuodom = (props) => {
  ////console.log('PROPSDeat111', props.Default)
  const mounted = useRef(true);
  let [service, setService] = useState(props.Default);
  let [isLoading, setisLoading] = useState(true);

  const [data, setData] = useState([]);

  async function onSelected(selected) {
    ////console.log(selected);
    setService(selected);
    props.valordelpicker(selected);
  }

  const loadAtributo = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const atrData = await getQuodomCreados(token);

    let generico = {
      descripcion: "Crear Nuevo Quodom",
      id: "0",
    };

    atrData.data.push(generico);
    //atrData.push(generico);
    if (mounted.current) {
      setData(atrData.data);
      setisLoading(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (
      props.Default === "" ||
      props.Default === undefined ||
      props.Default === null
    ) {
      ////console.log('va a crear')
      setService("0");
      //props.valordelpicker('0');
    }

    loadAtributo();
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack style={{ alignItems: "flex-end", flex: 1 }} borderWidth={0}>
      {isLoading ? (
        <HStack justifyContent="center" w="90%" borderWidth={0}>
          <ActivityIndicator size="small" color="#706F9A" />
        </HStack>
      ) : (
        <Select
          //alignItems= 'center'
          //alignContent= 'center'
          selectedValue={service}
          bg="#fff"
          w="90%"
          h="10"
          borderRadius="20"
          borderColor="#45444C"
          style={StyleSelectCategoria.itemSelect}
          accessibilityLabel="1"
          placeholder="Seleccionar"
          _selectedItem={{
            rounded: 5,
            bg: "#706F9A",
            endIcon: <CheckIcon size="5" color="#fff" />,
            //fontFamily: 'Prompt_300Light',
          }}
          _item={{
            rounded: 5,
            color: "muted.800",
            marginLeft: 0,
            paddingLeft: 5,
          }}
          onValueChange={(itemValue) => onSelected(itemValue)}
        >
          {data.map((x) => {
            return (
              <Select.Item label={x.descripcion} value={x.id} key={x.id} />
            );
          })}
        </Select>
      )}
    </VStack>
  );
};

export default SelectQuodom;
