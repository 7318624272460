import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Input,
  useToast,
  Image,
} from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GuardarUsuario, CrearDireccion } from "../../api";

import ZonasList from "./../../components/SettingsUser/ZonasList";

import {
  StyleSigInScreen,
  QuodomDetalle,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { wp } from "../../styles/responsive";

const WizardVendedorScreen3 = () => {
  const mounted = useRef(true);
  const route = useRoute();
  const { addZona } = route.params !== undefined ? route.params : [""];
  ////console.log(addZona)
  const toast = useToast();
  const id = "id_toast";
  const navigation = useNavigation();

  const [user, setUser] = useState([]);

  /*  const loadProfile = async () => {
         const value = await AsyncStorage.getItem('userToken');
         const data = await ObtenerDataUsuario(value);
         if (mounted.current) {
             //console.log(data);
             setUser(data);
             setisLoading(false);
         }
     }; */

  const handleSubmit = async () => {
    const token = await AsyncStorage.getItem("userToken");
    //Guardo info usuario
    let data = {
      esVendedor: 1,
    };
    const UserRes = await GuardarUsuario(token, data);

    //SL ahora guardo la direccion

    const userID = await AsyncStorage.getItem("userID");

    let userDireccion = {
      userid: userID,
      //provincia: addZona.provincia,
      //partido: addZona.partido,
      //localidad: addZona.localidad,
      direccion: addZona.calle + " " + addZona.numero,
      cp: addZona.cp,
      alias: "Principal",
      default: 1,
      idprovincia: addZona.idprovincia,
      idpartido: addZona.idlocalidad,
      idzona: addZona.idzona,
      numero: addZona.numero,
      calle: addZona.calle,
    };

    ////console.log(userDireccion);
    CrearDireccion(token, userDireccion);

    //SL res == false es error muestro el message
    if (!UserRes.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Algo salió mal
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  respuesta.message
                </Text>
              </Box>
            );
          },
        });
      }
    } else {
      navigation.navigate("WizardVendedorSuccess");
    }
  };

  useEffect(() => {
    mounted.current = true;
    //loadProfile();
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack flex={1}>
      <View
        style={{ flex: 1, backgroundColor: "#fff", alignContent: "center" }}
      >
        <HStack
          bg={quodomTheme.COLORS["COLORVIOLETA"]}
          px={2}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space={4} alignItems="center">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <HStack space={2} alignItems="center" pt="3">
                <Ionicons name="arrow-back-sharp" size={25} color="white" />
              </HStack>
            </TouchableOpacity>
          </HStack>
        </HStack>

        <HStack
          bg={quodomTheme.COLORS["COLORVIOLETA"]}
          space={2}
          pl="15"
          pr="15"
          pb="3"
          mb="3"
          justifyContent="space-between"
          alignItems="center"
        >
          <VStack w={wp(90)}>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={QuodomDetalle.textoTituloMisCotizacion}
            >
              Completá el wizard y comenzá a vender.
            </Text>
            {/* <Text style={QuodomDetalle.textoTituloMisCotizacion}>Paso 3/3</Text> */}
          </VStack>
        </HStack>

        <Center>
          <VStack justifyContent="center">
            <HStack space={10} alignItems="flex-start">
              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/1completo.png")}
                  size={wp(15)}
                  alt="Enviado"
                ></Image>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.textoChico2}
                >
                  Datos empresa
                </Text>
              </Center>

              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/2completo.png")}
                  size={wp(15)}
                  alt="Disponibles"
                ></Image>
                <Text style={QuodomDetalle.textoChico2}>Categorias</Text>
              </Center>

              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/3activo.png")}
                  size={wp(15)}
                  alt="Elegí y comprá"
                ></Image>
                <Text style={QuodomDetalle.textoChico2Bold}>Zonas</Text>
              </Center>
            </HStack>
          </VStack>
        </Center>

        <View style={{ flex: 1, alignItems: "center" }}>
          <HStack
            w="96%"
            justifyContent="flex-start"
            mt={5}
            mb={3}
            pl={3}
            pr={3}
            borderWidth="0"
          >
            <Text style={StyleSigInScreen.textoLoginyPass}>
              Seleccioná las zonas en las que quieras participar.
            </Text>
          </HStack>

          <HStack flex={1}>
            <ZonasList />
          </HStack>
        </View>
      </View>

      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="0"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="space-between"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "40%",
          }}
          onPress={() => {
            navigation.navigate("WizardVendedor2");
          }}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Anterior</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "40%",
          }}
          onPress={() => {
            handleSubmit();
          }}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Finalizar</Text>
        </TouchableOpacity>
      </HStack>
    </VStack>
  );
};

export default WizardVendedorScreen3;
