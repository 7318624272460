import React, { useEffect, useState, useRef } from "react";
import {
  View,
  ActivityIndicator,
  TouchableOpacity,
  BackHandler,
  Alert,
  StyleSheet,
} from "react-native";
import { HStack, VStack, Center, Text, useToast, Box } from "native-base";
import { WebView } from "react-native-webview";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import { useRoute, useNavigation, useIsFocused } from "@react-navigation/core";
import { Ionicons } from "@expo/vector-icons";
import { Modal } from "react-native-paper";
import { sendEmailInvoice } from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PDFReader from "rn-pdf-reader-js";
import { AnimatedFAB } from "react-native-paper";
const WebPdf = () => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const route = useRoute();
  const id = "id_toast";
  const toast = useToast();

  const { idcotizacion, usuariovendedor, url } = route.params;
  const [isLoading, setisLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isExtended, setIsExtended] = useState(true);
  const enviarCorreo = async () => {
    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");
    const getEmail = await sendEmailInvoice(
      token,
      idcotizacion,
      usuariovendedor
    );

    if (mounted.current) {
      if (getEmail) {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "Correo Enviado",
            status: "message",
            description: "Se envio el correo con la factura adjunta",
            placement: "top",
            style: { backgroundColor: "#2ECC71" },
          });
        }
      } else {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "error",
            status: "message",
            description: "No se pudo enviar el correo",
            placement: "top",
            style: { backgroundColor: "#2ECC71" },
          });
        }
      }
    }
    setisLoading(false);
  };

  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 16 };

  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    setIsExtended(currentScrollPosition <= 0);
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect() WebPdf.js");

    return () => {
      mounted.current = false;
    };
  }, []);

  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 5,
    marginRight: 5,
    borderRadius: 0,
  };

  return (
    <>
      <View
        style={{ flex: 1, backgroundColor: "#706F9A", alignContent: "center" }}
      >
        {!isLoading ? (
          <HStack
            bg="#706F9A"
            px={2}
            py={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack space={4} alignItems="center">
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <HStack space={2} alignItems="center" pt="3">
                  <Ionicons name="arrow-back-sharp" size={25} color="white" />
                  <Text style={QuodomDetalle.textoTituloChicoFinalizado}>
                    Volver al Quodom
                  </Text>
                </HStack>
              </TouchableOpacity>
            </HStack>
          </HStack>
        ) : null}
        {isLoading ? (
          <Center flex={1}>
            <ActivityIndicator size="large" color="#fff" />
            <VStack alignItems="center" justifyContent="center" p="3" space={2}>
              <Text style={QuodomDetalle.TextoModalSuccess}>
                Enviando factura...
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoRedireccion}
              >
                Aguarde unos segundos.
              </Text>
            </VStack>
          </Center>
        ) : (
          <PDFReader
            source={{
              base64: url,
            }}
          />
        )}
        <AnimatedFAB
          icon={"send"}
          label={"Enviar por Correo"}
          variant="surface"
          extended={isExtended}
          onPress={() => enviarCorreo()}
          uppercase={false}
          animateFrom={"right"}
          iconMode={"dynamics"}
          style={[styles.fabStyle, style, fabStyle]}
        />
      </View>
      <Modal visible={visible} contentContainerStyle={containerStyle}>
        <VStack flex={1}>
          <Center>
            <ActivityIndicator size="large" color="#fff" />
          </Center>
        </VStack>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 10,
    right: 10,
    position: "absolute",
  },
});
export default WebPdf;
