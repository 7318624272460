import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Button,
  IconButton,
  Icon,
  Text,
  NativeBaseProvider,
  Center,
  Box,
  StatusBar,
  Image,
} from "native-base";
import { moderateScale, wp } from "../styles/responsive";
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import quodomTheme from "../constants/Theme";
import { Badge } from "react-native-paper";
import { TouchableOpacity } from "react-native";
import { ObtenerCountNotificaciones } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function AppBar(props) {
  const mounted = useRef(true);
  const isFocused = useIsFocused();
  const navigation = useNavigation();

  const [postData, setpostData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ShowNotificacion, setShowNotificacion] = useState();

  const loadNotificaciones = async () => {
    //console.log("Entre al loadNotificaciones AppBar.js");
    const userGuest = await AsyncStorage.getItem("userGuest");

    //setUser(userGuest);
    if (userGuest === "NO") {
      setShowNotificacion(false);
      const token = await AsyncStorage.getItem("userToken");
      const data = await ObtenerCountNotificaciones(token);

      if (mounted.current) {
        setpostData(data);
        setIsLoading(false);
      }
    } else {
      setShowNotificacion(false);
      //console.log("Usuario GUEST, no busco notificaciones");
    }

    ////console.log('///////////////////////', userGuest)
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect AppBar.js");
    loadNotificaciones();
    return () => (mounted.current = false);
  }, [isFocused]);

  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle="dark-content"
      />
      <HStack>
        <Box safeAreaTop backgroundColor="transparent" />
        <VStack VStack flex={1} backgroundColor={"#F6EE5D"}>
          <HStack
            bg="transparent"
            px={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack space={4} alignItems="center">
              <TouchableOpacity
                style={{ marginLeft: "10px", marginTop: "10px" }}
                onPress={() => navigation.toggleDrawer()}
              >
                <Icon
                  size="8"
                  as={<MaterialIcons name="menu" />}
                  color={quodomTheme.COLORS["COLORVIOLETA"]}
                />
              </TouchableOpacity>
            </HStack>
          </HStack>
          <HStack>
            <Box w="100%" mx="auto" backgroundColor="#F6EE5D">
              <Center>
                <TouchableOpacity onPress={() => navigation.navigate("Home")}>
                  <Image
                    source={require("../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"1%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </TouchableOpacity>
              </Center>
            </Box>
          </HStack>
        </VStack>
      </HStack>
    </>
  );
}
