import React from "react";
import { TouchableOpacity, View, Text, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { StyleSelectCategoria } from '../../styles/StyleQuodom';

const CategoriaItem = ({ categoria, handleDelete }) => {
  const navigation = useNavigation();

  return (
    <View style={styles.itemContainer}>
      <Text style={StyleSelectCategoria.itemFlatListBold}>{categoria.nombreCategoria}</Text>
      <TouchableOpacity
        onPress={() => handleDelete(categoria.id)}>
        <Ionicons name='close-circle' size={28} color={'#FFEE61'} />
      </TouchableOpacity>
    </View>

  );
};

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: "#706F9A",
    padding: 10,
    marginVertical: 4,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    marginBottom:10
  },

});
export default CategoriaItem;
