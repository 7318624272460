import React from "react";
import { View, TouchableOpacity } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  HStack,
  VStack,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { wp } from "../../styles/responsive";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
  QuodomList_Card,
} from "../../styles/StyleQuodom";
import { useNavigation, useRoute } from "@react-navigation/native";

const AgregarProducto = (props) => {
  let navigation = useNavigation();
  const route = useRoute();
  const { idquodom, nombreQuodom } =
    route.params !== undefined ? route.params : ["", ""];

  /*  function actualizar()  {
         estado()
       } */

  return (
    <View style={{ height: "100%" }}>
      <Center style={DetalleProductoQuodomstyle.itemContainerAgregar}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("Busqueda", {
              codigoQuodom: idquodom,
              nombreQuodom: nombreQuodom,
            })
          }
        >
          <VStack
            pt="1"
            pb="1"
            pr="10"
            pl="10"
            borderWidth={0}
            alignItems="center"
          >
            <Ionicons name="ios-add-circle-sharp" size={35} color="#706F9A" />
            <Text style={QuodomDetalle.itemTextoCrear}>Agregar producto</Text>
          </VStack>
        </TouchableOpacity>
      </Center>
    </View>
  );
};

export default AgregarProducto;
