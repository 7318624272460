import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState, useRef } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { ActivityIndicator, View, LogBox } from "react-native";
import {
  NativeBaseProvider,
  Box,
  Container as ContainerBase,
} from "native-base";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import RootStackScreen from "./screens/RootStackScreen";

import { AuthContext } from "./components/context";
import { MenuRoot } from "./navigation/Navigation";
import {
  createStackNavigator,
  CardStyleInterpolators,
  HeaderStyleInterpolators,
} from "@react-navigation/stack";
const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();
import { CustomDrawerContent } from "./screens/DrawerContent";
import SitioInicial from "./screens/Home/SitioInicial";
//import { Images } from "./constants/Images";

import ListaMisQuodoms from "./screens/MisQuodoms/ListaMisQuodoms";
import ListaMisQuodomsInicial from "./screens/MisQuodoms/ListaMisQuodomsInicial";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DetalleQuodom from "./components/MisQuodoms/DetalleQuodom";
import DetalleQuodomEnviado from "./components/MisQuodoms/DetalleQuodomEnviado";
import DetalleQuodomCotizado from "./components/MisQuodoms/DetalleQuodomCotizado";
import DetalleQuodomVencido from "./components/MisQuodoms/DetalleQuodomVencido";
import SeleccionarProveedor from "./components/MisQuodoms/SeleccionarProveedor";
import ResumenQuodomFinalizado from "./components/MisQuodoms/ResumenQuodomFinalizado";
import ResumenPago from "./components/MisQuodoms/ResumenPago";
import WebMobbex from "./components/MisQuodoms/WebMobbex";
import WebPdf from "./components/MisQuodoms/WebPdf";
import CalificacionVendedor from "./components/MisQuodoms/CalificacionVendedor";
import CalificacionComprador from "./screens/ofertas/CalificacionComprador";
import CalificacionCompradorEntregado from "./screens/ofertas/CalificacionCompradorEntregado";
import AccionesDetalleQuodom from "./components/MisQuodoms/AccionesDetalleQuodom";
import AppBar from "./components/AppBar";
import AccionesDetalleOfertas from "./screens/ofertas/AccionesDetalleOfertas";
import ProfileScreen from "./screens/Profile/ProfileScreen";
import AgregarDireccion from "./components/Direcciones/AgregarDireccion";
import ModificarDireccion from "./components/Direcciones/ModificarDireccion";
import DetalleUsuarioScreen from "./screens/Profile/DetalleUsuarioScreen";
import SeguridadUsuarioScreen from "./screens/Profile/SeguridadUsuarioScreen";
import CambiarPassScreen from "./screens/Profile/CambiarPassScreen";
//import BancosScreen from './screens/Profile/BancosScreen';
import MobbexScreen from "./screens/Profile/MobbexScreen";
import DireccionesScreen from "./screens/Profile/DireccionesScreen";
import CalificacionesScreen from "./screens/Profile/CalificacionesScreen";

import CategoriasScreen from "./screens/Settings/CategoriasScreen";
import ZonasScreen from "./screens/Settings/ZonasScreen";

import Busqueda from "./screens/Home/BusquedaScreen";
import QuodomNuevo from "./components/MisQuodoms/QuodomNuevo";
import ListaProductosScreen from "./components/Home/ListaProductos";

/* import WizardVendedorScreen from "./screens/Settings/WizardVendedor"; */
import WizardVendedorScreen1 from "./screens/Settings/WizardVendedor1";
import WizardVendedorScreen2 from "./screens/Settings/WizardVendedor2";
import WizardVendedorScreen3 from "./screens/Settings/WizardVendedor3";
import WizardVendedorScreenSuccess from "./screens/Settings/WizardVendedorSuccess";

//import CotizacionesList from "./screens/ofertas/CotizacionesList";
import DireccionEnvioQuodomScreen from "./components/Direcciones/DireccionEnvioQuodom";
import SuccessScreen from "./components/MisQuodoms/SuccessScreen";

import OfertasList from "./screens/ofertas/OfertasList";
import OfertasListArchivadas from "./screens/ofertas/OfertasListArchivadas";

import * as SplashScreen from "expo-splash-screen";
import { ObtenerDataUsuario } from "./api";

import * as Device from "expo-device";
import {
  setearInfoDispositivoLogin,
  ObtenerDataUsuarioConFoto,
  ObtenerUsuarioVinculado,
} from "./api";
import OfertasItemList from "./screens/ofertas/OfertasItemList";
import OfertasItemAceptada from "./screens/ofertas/OfertasItemAceptada";
import OfertasItemEnviado from "./screens/ofertas/OfertasItemEnviado";
import { SuccessScreenOferta } from "./screens/ofertas/SuccessScreenOferta";
import AgregarProducto from "./components/MisQuodoms/AgregarProducto";
import Notificaciones from "./components/Notificaciones";
import FAQ from "./screens/help/FAQ";
import * as Notifications from "expo-notifications";
import SlidesNew1Screen from "./screens/SlidesNew/SlidesNew1";
import SlidesNewVendedor1Screen from "./screens/SlidesNew/SlidesNewVendedor1";
import SignInScreen from "./screens/SignInScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignUpScreenVendor from "./screens/SignUpScreenVendor";
import ForgotPassword from "./screens/ForgotPassword";
import ElegirCuenta from "./screens/ElegirCuenta";
import CuentaCreada from "./screens/CuentaCreada";
import HomeScreen from "./screens/HomeScreen";
import { isMobile } from "react-device-detect";
const App = () => {
  const [userapp, setUserapp] = useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  const initialLoginState = {
    isLoading: true,
    userName: null,
    userToken: null,
  };

  async function ActualizarDatosDispositivo() {
    //console.log("ActualizarDatosDispositivo");
    //SDT20211111 agregar info del dispositivo, para las notificaciones-
    const token = await AsyncStorage.getItem("userToken");
    const userPushToken = await AsyncStorage.getItem("userPushToken");

    if (Device.isDevice) {
      let infodispositivo = {
        dispositivo: "PC",
        tokennotificaciones: userPushToken,
        marca: Device.brand,
        fabricante: Device.manufacturer,
        modelo: "Explorador",
        idmodelo: Device.modelId,
        diseniomodelo: Device.designName,
        nombreproducto: Device.productName,
        anioclasedispositivo: Device.deviceYearClass,
        memoriatotal: Device.totalMemory,
        osname: Device.osName,
        osversion: Device.osVersion,
        osbuildid: Device.osBuildId,
        osinteranbuildid: Device.osInternalBuildId,
        devicename: Device.deviceName,
        activo: 1,
        Vinculado: 1,
      };

      //SL Sin await porque no necesito la respuesta para seguir.
      setearInfoDispositivoLogin(infodispositivo, token);
    }
    //SDT20211111 agregar info del dispositivo, para las notificaciones+
  }

  const loginReducer = (prevState, action) => {
    switch (action.type) {
      case "RETRIEVE_TOKEN":
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGIN":
        return {
          ...prevState,
          userName: action.id,
          userToken: action.token,
          isLoading: false,
        };
      case "LOGOUT":
        return {
          ...prevState,
          userName: null,
          userToken: null,
          isLoading: false,
        };
      case "REGISTER":
        return {
          ...prevState,
          userName: null,
          userToken: null,
          isLoading: false,
        };
    }
  };

  const [loginState, dispatch] = React.useReducer(
    loginReducer,
    initialLoginState
  );

  const authContext = React.useMemo(
    () => ({
      signIn: async (foundUser) => {
        ////console.log('++++++++++++++ ENTRO AL SIGNIN', foundUser.token);
        const userToken = foundUser.token;
        const userName = foundUser.username;
        // const userID = foundUser.id;

        try {
          //  await AsyncStorage.setItem('userID', userID);
          if (typeof userToken !== undefined && userToken != null) {
            const keys = await AsyncStorage.getAllKeys();
            await AsyncStorage.multiRemove(keys);
            await AsyncStorage.setItem("userToken", userToken);
            const data = await ObtenerDataUsuario(userToken);
            if (typeof data.id !== undefined && data.id != null) {
              await AsyncStorage.setItem("userID", data.id);
              await AsyncStorage.setItem("userGuest", "NO");
            }
            if (
              typeof data.ShowSlides !== undefined &&
              data.ShowSlides != null
            ) {
              await AsyncStorage.setItem(
                "ShowSlides",
                data.ShowSlides ? "SI" : "NO"
              );
            }
            if (
              typeof data.esVendedor !== undefined &&
              data.esVendedor != null
            ) {
              await AsyncStorage.setItem(
                "esVendedor",
                data.esVendedor ? "SI" : "NO"
              );
            }
          } else {
            const keys = await AsyncStorage.getAllKeys();
            await AsyncStorage.multiRemove(keys);
            dispatch({ type: "LOGOUT" });
          }
          //SL Valido si la foto de perfil cambio
          var currentRefreshFoto = await AsyncStorage.getItem(
            "userRefreshFoto"
          );

          ////console.log('este es el valor del storage:', currentRefreshFoto, 'este es el valor que viene', foundUser.refreshFoto);
          if (currentRefreshFoto !== foundUser.refreshFoto) {
            ////console.log('son distintas asi que actualizo la foto');
            const fotoPerfil = await ObtenerDataUsuarioConFoto(userToken);
            ////console.log('Llame al API  ObtenerDataUsuarioConFoto:', fotoPerfil);
            ////console.log('Llame al API y su valor de foto es:', fotoPerfil.foto);
            if (
              typeof fotoPerfil.foto !== undefined &&
              fotoPerfil.foto !== null
            ) {
              await AsyncStorage.setItem("userFoto", fotoPerfil.foto);
              await AsyncStorage.setItem(
                "userRefreshFoto",
                fotoPerfil.refreshFoto
              );
            } else {
            }
          }
        } catch (e) {
          //console.log(e);
        }

        //console.log("--- UserToken:", userToken);
        ActualizarDatosDispositivo();

        dispatch({ type: "LOGIN", id: userName, token: userToken });
      },

      signOut: async () => {
        try {
          /*await AsyncStorage.removeItem('userToken');
        await AsyncStorage.removeItem('userRefreshFoto');
        await AsyncStorage.removeItem('userFoto');*/
          const keys = await AsyncStorage.getAllKeys();
          await AsyncStorage.multiRemove(keys);
        } catch (e) {
          //console.log(e);
        }
        dispatch({ type: "LOGOUT" });
        navigation.navigate("SignInScreen");
      },

      signUp: async (UserRegistrado) => {
        const userToken = UserRegistrado.token;
        const userName = UserRegistrado.username;

        try {
          if (typeof fotoPerfil.foto !== undefined && fotoPerfil.foto != null) {
            await AsyncStorage.setItem("userToken", userToken);
            const data = await ObtenerDataUsuario(userToken);
            if (typeof data.id !== undefined && data.id != null) {
              await AsyncStorage.setItem("userID", data.id);
            }
          } else {
            const keys = await AsyncStorage.getAllKeys();
            await AsyncStorage.multiRemove(keys);
            dispatch({ type: "LOGOUT" });
          }
        } catch (e) {
          //console.log(e);
        }
        dispatch({ type: "REGISTER", id: userName, token: userToken });
      },

      toggleTheme: () => {
        //setIsDarkTheme( isDarkTheme => !isDarkTheme );
      },
    }),
    []
  );

  useEffect(() => {
    if (isMobile) {
      window.location.replace("https://quodom.com.ar/");
    }
    //console.log(
    //  "Inicio de la APP useEffect App.js .....",
    //   process.env.NODE_ENV
    // );
    LogBox.ignoreAllLogs(true);

    async function prepare() {
      try {
        ////console.log('ENTRO AL PREPARE()');
        await SplashScreen.preventAutoHideAsync();

        let userToken;
        userToken = null;

        try {
          userToken = await AsyncStorage.getItem("userToken");
        } catch (e) {
          //console.log(e);
        }
        //console.log("--- UserToken INICIAL:", userToken);

        let infodispositivo = { dispositivo: null };

        infodispositivo = {
          dispositivo: "PC",
          modelo: "Explorador",
        };

        ////console.log(infodispositivo)
        let data;
        data = await ObtenerDataUsuario(userToken);
        //console.log("User LOGIN", data.email);

        setUserapp(data);

        if (typeof data.id !== undefined && data.id !== null) {
          await AsyncStorage.setItem("userID", data.id);
        } else {
          /*   //console.log("-- Sin USERID, debe ser GUEST ---");
          //SL NUEVO
          dispatch({ type: "LOGOUT" });
          userToken =
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI4ZTQzODM0Yi05YjhmLTQ3YmEtYTNhZC0zYTQ4NTA4YmEwNDAiLCJpYXQiOjE2ODM1Njc2ODksImV4cCI6MjAzNjM2NzY4OX0.dpFHFWPtbOpb1E_dv7oc8naiAsDqyNNfRNwqfB4eCJA";

          data = await ObtenerDataUsuario(userToken);
          //console.log("User LOGIN GUEST", data.email);
          await AsyncStorage.setItem("userID", data.id);
          await AsyncStorage.setItem("userToken", userToken);
          await AsyncStorage.setItem("userGuest", "SI");*/
          //END
          //console.log("-- Sin USERID ---");
          dispatch({ type: "LOGOUT" });
        }

        // //console.log(userapp);
        if (data.res == false) {
          const keys = await AsyncStorage.getAllKeys();
          await AsyncStorage.multiRemove(keys);
          dispatch({ type: "LOGOUT" });
        } else {
          dispatch({ type: "RETRIEVE_TOKEN", token: userToken });
        }

        // pre-load/cache assets: images, fonts, and videos
        await func.loadAssetsAsync();
      } catch (e) {
        // console.warn(e);
      } finally {
        setIsLoading(false);
      }
    }
    prepare();
  }, []);

  React.useEffect(() => {
    // when loading is complete
    if (isLoading === false) {
      // hide splash function
      const hideSplash = async () => SplashScreen.hideAsync();

      // hide splash screen to show app
      hideSplash();
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  function SitioInicialScreen({ navigation }) {
    return (
      <NativeBaseProvider>
        <View style={{ flex: 1 }}>
          <SitioInicial></SitioInicial>
        </View>
      </NativeBaseProvider>
    );
  }

  function Ayuda({ navigation }) {
    return (
      <View style={{ flex: 1 }}>
        <FAQ></FAQ>
      </View>
    );
  }
  function Root() {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={SitioInicialScreen}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="SignUpScreen"
          component={SignUpScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WizardVendedor1"
          component={WizardVendedorScreen1}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Ofertas"
          component={OfertasList}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Ayuda"
          component={Ayuda}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SignInScreen"
          component={SignInScreen}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="MisQuodoms"
          component={ListaMisQuodoms}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MisQuodomsInicial"
          component={ListaMisQuodomsInicial}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="Busqueda"
          component={Busqueda}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ListaProductos"
          component={ListaProductosScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DireccionEnvioQuodomScreen"
          component={DireccionEnvioQuodomScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AgregarDireccion"
          component={AgregarDireccion}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ModificarDireccion"
          component={ModificarDireccion}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Perfil"
          component={ProfileScreen}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="WizardVendedor2"
          component={WizardVendedorScreen2}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WizardVendedor3"
          component={WizardVendedorScreen3}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WizardVendedorSuccess"
          component={WizardVendedorScreenSuccess}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="DetalleQuodom"
          component={DetalleQuodom}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DetalleQuodomEnviado"
          component={DetalleQuodomEnviado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DetalleQuodomCotizado"
          component={DetalleQuodomCotizado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DetalleQuodomVencido"
          component={DetalleQuodomVencido}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ResumenQuodomFinalizado"
          component={ResumenQuodomFinalizado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SeleccionarProveedor"
          component={SeleccionarProveedor}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ResumenPago"
          component={ResumenPago}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="OfertasItemAceptada"
          component={OfertasItemAceptada}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CalificacionVendedor"
          component={CalificacionVendedor}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CalificacionComprador"
          component={CalificacionComprador}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="CalificacionCompradorEntregado"
          component={CalificacionCompradorEntregado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MisQuodomsStack"
          component={ListaMisQuodoms}
          options={{
            headerShown: false,
            headerStyleInterpolator: HeaderStyleInterpolators.forUIKit,
          }}
        />
        <Stack.Screen
          name="OfertasList"
          component={OfertasList}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="OfertasItemList"
          component={OfertasItemList}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SuccessScreenOferta"
          component={SuccessScreenOferta}
          options={{
            presentation: "transparentModal",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SuccessScreen"
          component={SuccessScreen}
          options={{
            presentation: "transparentModal",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="WebMobbex"
          component={WebMobbex}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="WebPdf"
          component={WebPdf}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="QuodomNuevo"
          component={QuodomNuevo}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="OfertasItemEnviado"
          component={OfertasItemEnviado}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SlidesNew1"
          component={SlidesNew1Screen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SlidesNewVendedor1"
          component={SlidesNewVendedor1Screen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SignUpScreenVendor"
          component={SignUpScreenVendor}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ForgotPassword"
          component={ForgotPassword}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ElegirCuenta"
          component={ElegirCuenta}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CuentaCreada"
          component={CuentaCreada}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="Detalle"
          component={DetalleUsuarioScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SeguridadUsuario"
          component={SeguridadUsuarioScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CambiarPass"
          component={CambiarPassScreen}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="Categoria"
          component={CategoriasScreen}
          options={{ headerShown: false }}
        />
        {/* <navigPerfil.Screen name="BancosScreen" component={BancosScreen} options={{ headerShown: false }} /> */}
        <Stack.Screen
          name="CalificacionesScreen"
          component={CalificacionesScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MobbexScreen"
          component={MobbexScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DireccionesScreen"
          component={DireccionesScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Zonas"
          component={ZonasScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="OfertasListArchivadas"
          component={OfertasListArchivadas}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AccionesDetalleQuodom"
          component={AccionesDetalleQuodom}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AgregarProducto"
          component={AgregarProducto}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AccionesDetalleOfertas"
          component={AccionesDetalleOfertas}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
  }
  return (
    <AuthContext.Provider value={authContext}>
      <NavigationContainer linking={{ enabled: true }}>
        <NativeBaseProvider>
          <Box safeArea flex={1}>
            <Drawer.Navigator
              initialRouteName="Home"
              screenOptions={{
                headerShown: false,
                headerTitle: "",
              }}
              drawerContent={(props) => <CustomDrawerContent {...props} />}
            >
              <Drawer.Screen
                name="stack"
                component={Root}
                options={{ headerShown: false }}
              />
            </Drawer.Navigator>
          </Box>
        </NativeBaseProvider>
      </NavigationContainer>
    </AuthContext.Provider>
  );
};

export default App;
