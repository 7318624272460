import React from "react";
import { View, Text, TouchableOpacity, Image } from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias } from "../../styles/general";
import { Box, HStack, VStack } from "native-base";

const DetalleProductoCotizado = (props) => {
  ////console.log('PROPS DetalleProductoCotizado', props)
  const navigation = useNavigation();

  const MarcarSeleccionado = (Item) => {
    //console.log("entre al DetalleProductoCotizado con id", Item);
    props.marcarSeleccionado(Item);
  };

  const obtenerEstilo = (cantidadSeleccionados) => {
    if (cantidadSeleccionados === "1") return "#2dab66";
    else return "#706f9a";
  };

  /*  useEffect(() => {
         //console.log('HOLLLAAAAAAAAA')
     }, []);  */

  return (
    <View>
      <View style={basico.container}>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate("SeleccionarProveedor", {
              idquodom: props.idquodom,
              nombreProducto: props.nombreProducto,
              idquodomline: props.id,
              nombreQuodom: props.nombreQuodom,
              imagen: props.imagen,
              refreshImagen: props.refreshImagen,
              cantidadCotizado: props.cantidadCotizado,
              marcarSeleccion: MarcarSeleccionado,
              atributo1: props.atributo1,
              atributo2: props.atributo2,
              nombreAtributo1: props.nombreAtributo1,
              nombreAtributo2: props.nombreAtributo2,
              cantidadEnvio: props.cantidadEnvio,
            })
          }
        >
          <Box
            rounded={8}
            width="100%"
            shadow={2}
            shadowRadius={8}
            marginTop={2}
            backgroundColor={obtenerEstilo(props.cantidadSeleccionados)}
            zIndex={1}
            borderWidth={0}
            borderColor="#fff"
          >
            <VStack space={0}>
              <HStack justifyContent="space-between" space={0}>
                <VStack
                  justifyContent="center"
                  bg="#fff"
                  borderBottomRightRadius="10"
                  borderBottomLeftRadius="7"
                  borderTopLeftRadius={7}
                >
                  <Image
                    source={{
                      uri: props.imagen,
                    }}
                    //uri={props.imagen} // image address
                    //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                    style={estilosCategorias.ImagenDetalleQuodomCotizado}
                  />
                </VStack>

                <VStack pl="1" justifyContent="center" w="45%" borderWidth={0}>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={4}
                    style={DetalleProductoQuodomstyle.textoTituloCotizado}
                  >
                    {props.nombreProducto}
                  </Text>
                </VStack>

                <View
                  style={{
                    borderLeftWidth: 1,
                    borderLeftColor: "white",
                    marginTop: 8,
                    marginBottom: 8,
                  }}
                />

                <VStack
                  space={1}
                  justifyContent="center"
                  pr="5"
                  pl="5"
                  pt="2"
                  pb="2"
                >
                  <Text style={QuodomDetalle.TextoBotonElegir}>ELEGIR</Text>
                  <Text style={QuodomDetalle.TextoElegirProveedores}>
                    {props.cantidadCotizado} proveedores
                  </Text>
                </VStack>
              </HStack>
            </VStack>
          </Box>
        </TouchableOpacity>
      </View>
    </View>
  );
};
export default DetalleProductoCotizado;
