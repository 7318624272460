import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Box,
  useToast,
  ScrollView,
  FlatList,
  Input,
} from "native-base";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ObtenerDispositivosVinculados, updateDesvincular } from "../../api";

import { wp, hp, moderateScale } from "../../styles/responsive";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";

import Moment from "moment";
import "moment/locale/es";
import AppBar from "../../components/AppBar";
const SeguridadUsuarioScreen = () => {
  const mounted = useRef(true);
  const isFocused = useIsFocused();
  const [postData, setpostData] = useState([]);
  const [user, setUser] = useState([]);
  const [deviceId, setdeviceId] = useState("");

  const [isLoading, setisLoading] = useState(true);
  const [isdesv, setisdesv] = useState(false);
  const navigation = useNavigation();
  const [refreshing, setRefreshing] = React.useState(false);
  const toast = useToast();
  const id = "id_toast";

  const [refreshPage, setRefreshPage] = useState(false);
  const [iddireccionState, setiddireccionState] = useState("");

  const loadDispositivos = async () => {
    //console.log("Entre al loadDispositivos SeguridadUsuariosScreen.js");
    const token = await AsyncStorage.getItem("userToken");
    const data = await ObtenerDispositivosVinculados(token);

    const userPushToken = await AsyncStorage.getItem("userPushToken");

    if (mounted.current) {
      setdeviceId(userPushToken);
      setpostData(data);
      setisLoading(false);
    }
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(1000).then(() => setRefreshing(false));
  }, []);

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  function desde(fecha) {
    Moment.locale("es");
    return Moment(fecha, "YYYY-MM-DD h:mm:ss").fromNow();
  }

  function esEsteDevice(id) {
    if (id === deviceId) return true;
    return false;
  }

  const handleEditItem = (Item) => {
    const newData = postData.map((item) => {
      if (item.id === Item) {
        item.loading = true;
        return item;
      }
      return item;
    });
    setpostData(newData);
    Desvincular(Item);
  };

  async function Desvincular(id) {
    const token = await AsyncStorage.getItem("userToken");

    let data = {
      id: id,
      Vinculado: 0,
    };

    await updateDesvincular(token, data);
    onRefresh();
  }

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect SeguridadUsuariosScreen.js");
    loadDispositivos();
    return () => (mounted.current = false);
  }, [isFocused, refreshPage, refreshing]);

  const obtenerEstilo = (id) => {
    if (id === deviceId) return QuodomDetalle.textoItemUbicacionBold;
    else return QuodomDetalle.textoItemUbicacionMedio;
  };

  const footerlist = () => {
    return <Center h="10"></Center>;
  };

  return (
    <View style={{ flex: 1 }}>
      <AppBar />
      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <HStack w={wp(100)} style={{ flex: 1 }} backgroundColor="#F2F2F2">
          <VStack style={StylePerfil.infoContainer} space={2}>
            <HStack mt="5" justifyContent="flex-start">
              <Text style={StylePerfil.textoTitulo}>Contraseña de acceso</Text>
            </HStack>

            <VStack
              bg="#fff"
              shadow="2"
              p="3"
              mt="2"
              borderRadius="2"
              space={2}
              justifyContent="flex-start"
              w={wp(50)}
            >
              <Text style={StylePerfil.label}>Contraseña</Text>
              <Input
                mt="-3"
                variant="underlined"
                isDisabled
                style={StylePerfil.textbox}
                value="******"
              />

              <VStack p="1">
                <TouchableOpacity
                  onPress={() => navigation.navigate("CambiarPass")}
                >
                  <HStack space={1}>
                    <Text style={QuodomDetalle.textoAgregraDireccionPerfil}>
                      Cambiar contraseña
                    </Text>
                    <MaterialIcons
                      name="keyboard-arrow-right"
                      size={moderateScale(18)}
                      color="#235EFF"
                    />
                  </HStack>
                </TouchableOpacity>
              </VStack>
            </VStack>

            <HStack pt="5" justifyContent="flex-start">
              <Text style={StylePerfil.textoTitulo}>
                Dispositivos vinculados
              </Text>
            </HStack>

            <HStack flex={1} w={wp(50)}>
              {refreshing ? (
                <Center
                  flex="1"
                  bg="#fff"
                  shadow="2"
                  p="3"
                  mt="2"
                  borderRadius="2"
                  h={hp(15)}
                >
                  <ActivityIndicator size="large" color="#706F9A" />
                </Center>
              ) : (
                <FlatList
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshPage}
                      onRefresh={onRefresh}
                    />
                  }
                  data={postData}
                  //style={{  borderRadius: 2, shadow: 2}}
                  //contentContainerStyle={{ flexGrow: 1, paddingBottom: 5 }}
                  //ListFooterComponent={footerlist}
                  renderItem={({ item, index }) => (
                    <Box
                      borderBottomWidth="1"
                      _dark={{
                        borderColor: "gray.600",
                      }}
                      borderColor="coolGray.200"
                      pl="3"
                      pr="5"
                      py="3"
                      bg="#fff"
                    >
                      <HStack space={3} alignItems="center">
                        <Ionicons
                          name="phone-portrait-outline"
                          size={24}
                          color="black"
                        />
                        <VStack w="65%">
                          <Text style={obtenerEstilo(item.tokennotificaciones)}>
                            {item.dispositivo}
                          </Text>
                          {!esEsteDevice(item.tokennotificaciones) ? (
                            <Text style={QuodomDetalle.textoItemDesde}>
                              {desde(item.lastLogin)}
                            </Text>
                          ) : (
                            <Text style={QuodomDetalle.textoItemDesde}>
                              Este dispositivo
                            </Text>
                          )}
                        </VStack>
                        <VStack>
                          {!esEsteDevice(item.tokennotificaciones) ? (
                            <TouchableOpacity
                              onPress={() => handleEditItem(item.id)}
                            >
                              {item.loading ? (
                                <Center pl="7" alignItems="flex-end">
                                  <ActivityIndicator
                                    size="small"
                                    color="#706F9A"
                                  />
                                </Center>
                              ) : (
                                <Text
                                  style={QuodomDetalle.textoItemDesvincular}
                                >
                                  Desvincular
                                </Text>
                              )}
                            </TouchableOpacity>
                          ) : null}
                        </VStack>
                      </HStack>
                    </Box>
                  )}
                  keyExtractor={(item) => item.id.toString()}
                />
              )}
            </HStack>
          </VStack>
        </HStack>
      )}
    </View>
  );
};

export default SeguridadUsuarioScreen;
