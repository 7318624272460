import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Box,
  Divider,
  TextArea,
  ScrollView,
} from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";
import { CerrarQuodom, ObtenerQuodom } from "../../api";

import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";
import { AirbnbRating } from "react-native-ratings";

const CalificacionVendedor = () => {
  const mounted = useRef(true);
  const navigation = useNavigation();
  const route = useRoute();

  const [visible, setVisible] = useState(true);
  const [textoComentario, settextoComentario] = useState("Comentarios");
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setrating] = useState(3);
  const [obs, setObs] = useState("");

  const Cerrar = async () => {
    setIsLoading(true);
    const token = await AsyncStorage.getItem("userToken");

    let data = {
      idcotizacion: route.params.idcotizacion,
      idvendor: route.params.idVendor,
      valor: rating,
      obs: obs,
      idquodom: route.params.idquodom,
    };

    await CerrarQuodom(token, route.params.idquodom, data);
    route.params.actualizarEstado(route.params.idcotizacion);
    setIsLoading(false);
    const Quodom = await ObtenerQuodom(token, route.params.idquodom);
    navigation.navigate("ResumenQuodomFinalizado", {
      idquodom: Quodom.id,
      nombreQuodom: Quodom.descripcion,
      nro: Quodom.nro,
      iddireccion: Quodom.iddireccion,
      fechapago: Quodom.fechapago,
    });
    //  navigation.goBack();
  };

  const ratingCompleted = (rating) => {
    setrating(rating);
    //setVisible(true);
    if (rating <= 2) {
      settextoComentario("Contamos que paso");
    }
  };

  useEffect(() => {
    mounted.current = true;
    ////console.log('SOn los paranas', route.params);
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack flex={1} bg="#fff">
      <ScrollView>
        <View
          style={{ flex: 1, backgroundColor: "#fff", alignContent: "center" }}
        >
          <HStack
            bg={quodomTheme.COLORS["DEFAULT"]}
            px={2}
            py={5}
            mb="1"
            justifyContent="space-between"
            alignItems="center"
            shadow={2}
          >
            <HStack space={4} alignItems="center">
              <TouchableOpacity onPress={() => navigation.goBack()}>
                <HStack space={2} alignItems="center">
                  <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
                  <Text style={QuodomDetalle.textoNavigate}>
                    Recibi mis productos
                  </Text>
                </HStack>
              </TouchableOpacity>
            </HStack>
          </HStack>

          <VStack p="5" space={4} borderWidth={0}>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={2}
              style={QuodomDetalle.textoTituloMisCotizacionNGM}
            >
              ¿Recibiste tus productos?
            </Text>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={3}
              style={QuodomDetalle.textoTituloSeleccionarProveedor}
            >
              Confirmanos y contanos qué te pareció la experiencia con el
              vendedor.
            </Text>
          </VStack>

          <VStack p="5" space={3} borderWidth={0}>
            {/* <Text adjustsFontSizeToFit={true} numberOfLines={3} style={QuodomDetalle.textoTituloSeleccionarProveedor}>Tus calificaciones seran enviadas junto con tus datos personales a vendedor.</Text> */}

            <Box borderWidth={0} p="5" mt="2" borderColor="#c8c7c8" rounded={5}>
              <HStack pb="5" justifyContent="center">
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.textoTituloMisCotizacionNGM}
                >
                  Mi experiencia fue
                </Text>
              </HStack>

              <Divider></Divider>

              <AirbnbRating
                count={5}
                reviews={["Mala", "Regular", "Buena", "Muy Buena", "Excelente"]}
                defaultRating={3}
                size={35}
                reviewSize={20}
                //reviewColor="#FFCB27"
                //ratingBackgroundColor='#c8c7c8'
                starStyle={{ marginLeft: 8, marginRight: 8 }}
                onFinishRating={ratingCompleted}
              />
              {visible ? (
                <Box mt="5" visible={visible} alignItems="center" w="100%">
                  <TextArea
                    onChangeText={(text) => setObs(text)}
                    style={QuodomDetalle.textoChico3}
                    h={20}
                    value={obs}
                    bgColor="#fff"
                    placeholder={textoComentario}
                    w="100%"
                    maxW="300"
                  />
                </Box>
              ) : null}
            </Box>
          </VStack>
        </View>
      </ScrollView>
      {/*  <VStack p='5' space={4}>
                <Text adjustsFontSizeToFit={true} numberOfLines={4} style={QuodomDetalle.textoAgregraDireccionPerfil}>Nota: Si el vendedor no considera que la experiencia fue de una estrella segun nuestras politicas él puede tomar venganza y Quodom S.A no sera responsable de los daños dentales ocacionados.</Text>
            </VStack> */}
      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="0"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="center"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "90%",
          }}
          onPress={() => Cerrar()}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Enviar</Text>
        </TouchableOpacity>
      </HStack>
    </VStack>
  );
};

export default CalificacionVendedor;
