import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Alert,
  TouchableOpacity,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  deleteZona,
  ObtenerZonasUsuario,
  AsociarZona,
  ObtenerProvincias,
  ObtenerLocalidad,
  ObtenerZonas,
} from "../../api";

import {
  HStack,
  FlatList,
  VStack,
  Center,
  ScrollView,
  Heading,
  AspectRatio,
  Spacer,
  Text,
  useToast,
} from "native-base";
import {
  StyleSelectCategoria,
  StylePerfil,
  StyleSigInScreen,
} from "../../styles/StyleQuodom";
import { Ionicons, AntDesign } from "@expo/vector-icons";
import SelectZonaWizard from "../MisQuodoms/Pickers/SelectZonasWizard";
import SelectProvinciaWizard from "../MisQuodoms/Pickers/SelectProvinciaWizard";
import SelecLocalidadWizard from "../MisQuodoms/Pickers/SelectLocalidadWizard";

const ZonasListProfile = ({ navigation }) => {
  const mounted = useRef(true);
  const toast = useToast();
  const id = "id_toast";

  const [zonas, setZonas] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [addZona, setAddZona] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const [dataProv, setDataProv] = useState([]);
  const [dataLoc, setDataLoc] = useState([]);
  const [dataZonas, setDataZonas] = useState([]);

  const [prov, setProv] = useState();
  const [loc, setLoc] = useState();
  const [zona, setZona] = useState();
  const [vacio, setVacio] = useState(false);

  const loadProvincia = async () => {
    //const token = await AsyncStorage.getItem('userToken');
    const ProvData = await ObtenerProvincias();

    if (!ProvData || ProvData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      ProvData.push(generico);
    }
    if (mounted.current) {
      setDataProv(ProvData);
    }
  };

  const loadLocalidad = async (prov) => {
    //const token = await AsyncStorage.getItem('userToken');
    const LocData = await ObtenerLocalidad(prov, "COMPRADOR");
    if (mounted.current) {
      setDataLoc(LocData);
    }
  };

  const loadZonas = async (loc) => {
    //const token = await AsyncStorage.getItem('userToken');
    const ZonasData = await ObtenerZonas(loc);

    let generico = {
      nombrezona: "Todos",
      id: 0,
    };
    ZonasData.push(generico);

    if (mounted.current) {
      setDataZonas(ZonasData);
    }
  };

  const handleDelete = (id) => {
    Alert.alert("Eliminar Zona", "Esta seguro que desea eliminar la zona?", [
      {
        text: "Cancelar",
        style: "cancel",
      },
      {
        text: "Ok",
        onPress: async () => {
          setisLoading(true);
          const value = await AsyncStorage.getItem("userToken");
          await deleteZona(value, id);
          await LeerDatos();
        },
      },
    ]);
  };

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const handleAddZona = async (e) => {
    setZona(e);
    ////console.log('addZona.idzona', e)
    if (e === null || e === "" || e === undefined) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Seleccione una Localidad/Barrio para agregar.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return;
    }

    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");
    //Guardo info usuario
    let data = {
      idlocalidad: addZona.idlocalidad,
      idprovincia: addZona.idprovincia,
      idzona: e,
    };
    ////console.log('data', data);
    const respuesta = await AsociarZona(token, data);

    //SL res == false es error muestro el message
    if (!respuesta.res) {
      Alert.alert("Error", "No se pudo agregar la zona");
      ////console.log(respuesta.res);
      setisLoading(false);
    }

    setAddZona({
      ...addZona,
      idzona: "",
    });
    setVacio(false);
    LeerDatos();
  };

  const LeerDatos = async () => {
    try {
      const value = await AsyncStorage.getItem("userToken");
      const zonas = await ObtenerZonasUsuario(value);
      ////console.log(zonas);
      if (mounted.current) {
        if (zonas.length === 0) {
          setVacio(true);
        }
        setZonas(zonas);
        setisLoading(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  function handleSelectedProvincia(e) {
    if (mounted.current) {
      setAddZona({
        ...addZona,
        idprovincia: e,
      });
      setProv(e);
      loadLocalidad(e);
    }
  }

  function handleSelectedLocalidad(e) {
    //console.log(e);
    setAddZona({
      ...addZona,
      idlocalidad: e,
    });
    setLoc(e);
    //console.log("datoLoc:", loc);
    loadZonas(e);
  }

  function handleSelectedZona(e) {
    ////console.log('zona', e);
    setAddZona({
      ...addZona,
      idzona: e,
    });

    handleAddZona(parseInt(e));
  }

  useEffect(() => {
    mounted.current = true;
    setisLoading(true);
    LeerDatos();
    loadProvincia();

    return () => (mounted.current = false);
  }, [refreshing]);

  const EmptyPlaceHolder = () => {
    return (
      <HStack
        pl="2"
        pr="2"
        pt="5"
        pb="5"
        mt="5"
        mb="5"
        borderWidth={1}
        borderColor="#45444C"
        alignItems="center"
        space={2}
        rounded={8}
      >
        <VStack>
          <AntDesign name="warning" size={24} color="#45444C" />
        </VStack>
        <VStack w="90%">
          <Text
            adjustsFontSizeToFit={true}
            numberOfLines={1}
            style={StyleSigInScreen.textoLoginyPass}
          >
            Sin Zonas.
          </Text>
          <Text
            adjustsFontSizeToFit={true}
            numberOfLines={2}
            style={StyleSigInScreen.textoLoginyPass}
          >
            Agregue zonas para recibir cotizaciones.
          </Text>
        </VStack>
      </HStack>
    );
  };

  if (isLoading) {
    return (
      <Center flex={1} h="sm">
        <ActivityIndicator size="large" color="#706F9A" />
      </Center>
    );
  } else {
    return (
      <View style={{ alignItems: "center" }}>
        <VStack w="96%" justifyContent="center" borderWidth={0}>
          <Center>
            <HStack
              justifyContent="space-between"
              marginLeft={"15%"}
              marginRight={"15%"}
            >
              <VStack
                p="0"
                mt="0"
                space={0}
                justifyContent="flex-start"
                pl="1"
                paddingLeft={10}
              >
                <Text style={StylePerfil.labelMon13}>Provincia</Text>
                <select
                  style={{ width: "200px" }}
                  id="ddlprovincia"
                  class="form-select"
                  value={prov}
                  onChange={(e) => handleSelectedProvincia(e.target.value)}
                >
                  <option value="0">Seleccionar</option>
                  {dataProv && dataProv !== undefined
                    ? dataProv.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr.id}>
                            {ctr.provincia}
                          </option>
                        );
                      })
                    : "No hay provincias"}
                </select>
              </VStack>

              <VStack
                p="0"
                mt="0"
                space={0}
                justifyContent="flex-start"
                pl="1"
                paddingLeft={10}
              >
                <Text style={StylePerfil.labelMon13}>Partido</Text>
                <select
                  style={{ width: "200px" }}
                  id="ddlLocalidad"
                  class="form-select"
                  value={loc}
                  onChange={(e) => handleSelectedLocalidad(e.target.value)}
                >
                  <option value="0">Seleccionar</option>
                  {dataLoc && dataLoc !== undefined
                    ? dataLoc.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr.id}>
                            {ctr.nombre}
                          </option>
                        );
                      })
                    : "No hay localidades"}
                </select>
              </VStack>

              <VStack
                pt="0"
                mt="0"
                space={0}
                justifyContent="flex-start"
                pl="1"
                paddingLeft={10}
              >
                <Text style={StylePerfil.labelMon13}>Localidad/Barrio</Text>
                <HStack
                  w="100%"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <VStack w="100%">
                    <select
                      style={{ width: "200px" }}
                      id="ddlZonas"
                      class="form-select"
                      value={zona}
                      onChange={(e) => handleSelectedZona(e.target.value)}
                    >
                      <option value="0">Seleccionar</option>
                      {dataZonas && dataZonas !== undefined
                        ? dataZonas.map((ctr, index) => {
                            return (
                              <option key={index} value={ctr.id}>
                                {ctr.nombrezona}
                              </option>
                            );
                          })
                        : "No hay zonas"}
                    </select>
                  </VStack>
                </HStack>
              </VStack>
            </HStack>
          </Center>

          <FlatList
            data={zonas}
            ListEmptyComponent={<EmptyPlaceHolder />}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            horizontal={false}
            numColumns={3}
            style={{
              marginLeft: "15%",
              marginRight: "15%",
            }}
            renderItem={({ item }) => (
              <View style={styles.itemContainer}>
                <HStack>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={StyleSelectCategoria.itemFlatList}
                  >
                    {item.nombreLocalidad} -{" "}
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={StyleSelectCategoria.itemFlatListBold}
                    >
                      {item.nombrezona}
                    </Text>
                  </Text>

                  <TouchableOpacity onPress={() => handleDelete(item.id)}>
                    <Ionicons name="close-circle" size={28} color={"#FFEE61"} />
                  </TouchableOpacity>
                </HStack>
              </View>
            )}
            keyExtractor={(item) => item.id.toString()}
          />

          {/*  <HStack pt="2" mt="0" space={0} justifyContent='flex-end' borderWidth={0}>
            <TouchableOpacity
              style={{
                alignItems: "center",
                backgroundColor: "#706F9A",
                padding: 10,
                borderRadius: 5
              }}
              onPress={() => { handleAddZona() }}
            >
              <Text style={StyleZona.TextoButtonActualizar}>Agregar</Text>
            </TouchableOpacity>
          </HStack> */}
        </VStack>
      </View>
    );
  }
};
export default () => {
  return <ZonasListProfile />;
};

const styles = StyleSheet.create({
  itemContainer: {
    backgroundColor: "#706F9A",
    justifyContent: "center",
    width: "30%",
    borderRadius: 8,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 2,
    marginTop: 10,
    height: "40px",
  },
});
