import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  View,
  Divider,
  Modal,
  Button,
  HStack,
  useToast,
} from "native-base";
import { QuodomDetalle, StyleProfileScreen } from "../../styles/StyleQuodom";
import {
  deleteDireccion,
  dirPrincipal,
  ObtenerDireccionesUsuario,
} from "../../api";
import { Entypo, Ionicons, Feather } from "@expo/vector-icons";
import { wp } from "../../styles/responsive";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";

const MenuAcciones = (props) => {
  const toast = useToast();
  const id = "id_toast";
  const navigation = useNavigation();
  const [showModal, setShowModal] = useState(false);

  const Eliminar = async (id) => {
    const value = await AsyncStorage.getItem("userToken");
    const direcciones = await ObtenerDireccionesUsuario(value);
    var count = direcciones.length;

    if (count === 1) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Error",
          status: "message",
          description: "No se puede eliminar tu unica dirección",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
    } else {
      await deleteDireccion(value, id);
    }

    setShowModal(false);
    props.refresh(true);
  };

  const SetearPrincipal = async (id) => {
    const userID = await AsyncStorage.getItem("userID");
    const value = await AsyncStorage.getItem("userToken");
    await dirPrincipal(value, id, userID);

    props.refresh(true);
  };

  return (
    <View>
      <Center flex={1}>
        <Box alignItems="flex-start">
          <Menu
            p="0"
            borderWidth="0"
            borderColor="#52575D"
            shadow={5}
            //w={wp(50)}
            placement="left top"
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  {({ isHovered, isFocused, isPressed }) => {
                    return (
                      <Box
                        p="3"
                        rounded="8"
                        style={{
                          transform: [
                            {
                              scale: isPressed ? 0.9 : 1,
                            },
                          ],
                        }}
                      >
                        <Entypo
                          name="dots-three-vertical"
                          size={17}
                          color="#45444C"
                        />
                      </Box>
                    );
                  }}
                </Pressable>
              );
            }}
          >
            <Menu.Item
              onPress={() =>
                navigation.navigate("ModificarDireccion", {
                  iddir: props.iddireccion,
                })
              }
              p="3"
              borderRadius="3"
            >
              <HStack space={2} alignItems="center">
                <Ionicons name="create-outline" size={23} color="#52575D" />
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Modificar
                </Text>
              </HStack>
            </Menu.Item>

            <Menu.Item
              onPress={() => SetearPrincipal(props.iddireccion)}
              p="3"
              borderRadius="3"
            >
              <HStack space={2} alignItems="center">
                <Ionicons
                  name="md-location-outline"
                  size={23}
                  color="#52575D"
                />
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Direccion Principal
                </Text>
              </HStack>
            </Menu.Item>

            <Divider></Divider>

            <Menu.Item
              onPress={() => setShowModal(true)}
              p="3"
              borderRadius="3"
            >
              <HStack space={2} alignItems="center">
                <Ionicons name="ios-trash-outline" size={23} color="#52575D" />
                <Text style={StyleProfileScreen.textMenuFiltros}>Eliminar</Text>
              </HStack>
            </Menu.Item>
          </Menu>
        </Box>
      </Center>

      <View>
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <Modal.Content width={wp(95)}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                Atención!
              </Text>
            </Modal.Header>
            <Modal.Body pt="5" pb="5">
              <Text style={QuodomDetalle.TextoModalEliminar}>
                Esta seguro de eliminar la dirección{" "}
                <Text style={QuodomDetalle.TextoModalEliminarBold}>
                  {props.alias}
                </Text>{" "}
                ?
              </Text>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    padding: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                  }}
                  onPress={() => Eliminar(props.iddireccion)}
                >
                  <Text style={QuodomDetalle.TextoModalButtonOK}>Eliminar</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    setShowModal(false);
                  }}
                >
                  <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                    Cancelar
                  </Text>
                </TouchableOpacity>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    </View>
  );
};

export default MenuAcciones;
