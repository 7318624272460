import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, Title, Caption, Drawer } from "react-native-paper";
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer";
import { VStack, HStack } from "native-base";
import { StyleProfileScreen } from "../styles/StyleQuodom";
import { ObtenerDataUsuario } from "../api";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { AuthContext } from "../components/context";
import AsyncStorage from "@react-native-async-storage/async-storage";

export function CustomDrawerContent(props) {
  const { signOut, toggleTheme } = React.useContext(AuthContext);

  const [user, setUser] = useState([]);
  const [userFoto, setUserFoto] = useState([]);
  //const [nombreAvatar, setnombreAvatar] = useState('');

  const loadUser = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const data = await ObtenerDataUsuario(value);
    const ProfileFoto = await AsyncStorage.getItem("userFoto");
    ////console.log('ENTRE AL DRAWER CONTENT Y USERFOTO VALE', ProfileFoto);
    setUser(data);

    /*   if (ProfileFoto !== null) {
              setUserFoto(ProfileFoto);
          } else {
  
              if (typeof (user.nombre) !== 'undefined' && user.nombre !== null) {
                  if (user.esVendedor) {
                      let PrimeraLetraNombre = user.nombre.charAt(0);
                      setnombreAvatar(PrimeraLetraNombre);
                  } else {
                      let PrimeraLetraNombre = user.nombre.charAt(0);
                      let PrimeraLetraApellido = user.apellido.charAt(0);
                      setnombreAvatar(PrimeraLetraNombre + PrimeraLetraApellido);
                  }
  
              }
          } */
    ////console.log('esVendedor', user.esVendedor);
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView {...props} data={user}>
        <View style={styles.drawerContent}>
          <View style={styles.userInfoSection}>
            <View style={{ flexDirection: "row", marginTop: 5 }}>
              {/*  {userFoto !== null ? (
                                <Avatar.Image
                                    source={{ uri: "data:image/png;base64," + userFoto }}
                                    size={50}
                                />
                            ) : (
                                <Avatar.Text 
                                size={50} 
                                label={nombreAvatar}
                                style={{backgroundColor: '#706F9A'}} />
                            )
                            } */}
              <HStack justifyContent="center" alignItems="center" space={4}>
                <Avatar.Image
                  source={require("../assets/icon.png")}
                  size={50}
                  style={StyleProfileScreen.textAvatar2}
                />

                <VStack>
                  <Title
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={styles.title}
                  >
                    {user.nombre}
                  </Title>
                  {user.userGuest === 0 ? (
                    user.esVendedor === 0 ? (
                      <Caption style={styles.caption}>{user.email} </Caption>
                    ) : (
                      <Caption style={styles.caption}>
                        {user.RazonSocial}{" "}
                      </Caption>
                    )
                  ) : null}
                </VStack>
              </HStack>
            </View>
          </View>

          <Drawer.Section style={styles.drawerSection}>
            {user.userGuest === 0 ? (
              <Drawer.Item
                icon={({ color, size }) => (
                  <Icon name="home-outline" color={color} size={size} />
                )}
                label="Home"
                onPress={() => {
                  props.navigation.navigate("Home");
                }}
              />
            ) : null}

            {/* INICIAR SESSION */}
            {user.userGuest === 1 ? (
              <Drawer.Item
                icon={({ color, size }) => (
                  <Icon name="login" color={color} size={size} />
                )}
                label="Ingresar"
                onPress={() => {
                  signOut();
                }}
              />
            ) : null}

            {/* CREAR CUENTA */}
            {user.userGuest === 1 ? (
              <Drawer.Item
                icon={({ color, size }) => (
                  <Icon name="account-edit-outline" color={color} size={size} />
                )}
                label="Crear Cuenta"
                onPress={() => {
                  props.navigation.navigate("SignUpScreen");
                }}
              />
            ) : null}

            {user.userGuest === 0 ? (
              <Drawer.Item
                icon={({ color, size }) => (
                  <Icon name="account-outline" color={color} size={size} />
                )}
                label="Perfil"
                onPress={() => {
                  props.navigation.navigate("Perfil");
                }}
              />
            ) : null}

            {user.esVendedor !== 0 ? (
              <Drawer.Item
                icon={({ color, size }) => (
                  <Icon name="file-sign" color={color} size={size} />
                )}
                label="Mis cotizaciones"
                onPress={() => {
                  props.navigation.navigate("Ofertas");
                }}
              />
            ) : null}
          </Drawer.Section>
        </View>
      </DrawerContentScrollView>

      <Drawer.Section style={styles.bottomDrawerSection}>
        {user.userGuest === 0 ? (
          user.esVendedor == 0 ? (
            <Drawer.Item
              icon={({ color, size }) => (
                <Icon name="cash-register" color={color} size={size} />
              )}
              label="¿Queres Vender?"
              onPress={() => {
                props.navigation.navigate("WizardVendedor1");
              }}
            />
          ) : null
        ) : null}
        <Drawer.Item
          icon={({ color, size }) => (
            <Icon name="help-circle-outline" color={color} size={size} />
          )}
          label="Ayuda"
          onPress={() => {
            props.navigation.navigate("Ayuda");
          }}
        />

        {user.userGuest === 0 ? (
          <Drawer.Item
            icon={({ color, size }) => (
              <Icon name="exit-to-app" color={color} size={size} />
            )}
            label="Cerrar sesion"
            onPress={() => {
              signOut();
            }}
          />
        ) : null}
      </Drawer.Section>
    </View>
  );
}

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  userInfoSection: {
    paddingLeft: 10,
  },
  title: {
    fontSize: 15,
    marginTop: 3,
    fontWeight: "bold",
  },
  caption: {
    fontSize: 12,
    lineHeight: 14,
  },
  row: {
    marginTop: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
  },
  paragraph: {
    fontWeight: "bold",
    marginRight: 3,
  },
  drawerSection: {
    marginTop: 15,
  },
  bottomDrawerSection: {
    marginBottom: 15,
    borderTopColor: "#f4f4f4",
    borderTopWidth: 0,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
});
