import React, { useEffect, useState, useRef } from "react";
import { View, TouchableOpacity } from "react-native";
import { ObtenerOfertasItemsEnviados } from "../../api";
import {
  HStack,
  VStack,
  Center,
  Box,
  FlatList,
  Text,
  Divider,
} from "native-base";
import { useRoute, useNavigation } from "@react-navigation/core";
import { QuodomDetalle } from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Ionicons } from "@expo/vector-icons";
import Loader from "../../components/Loader";
import OfertasRenderItemEnviado from "./OfertasRenderItemEnviado";

const OfertasItemEnviado = (props) => {
  const mounted = useRef(true);
  const route = useRoute();

  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [fechaentrega, setFechaentrega] = useState();
  const [cotizacion, setCotizacon] = useState([]);
  const [idquodom, setIdquodom] = useState("");
  const [nombreusuariocomprador, setnombre] = useState("");
  const navigation = useNavigation();

  const CortarFecha = (texto) => {
    if (texto === null || texto === "" || texto === undefined) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  const LeerDatos = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const valor = await AsyncStorage.getItem("cotizacionSeleccionada");
    const coti = JSON.parse(valor);
    //console.log("cotii", coti);
    const postsData = await ObtenerOfertasItemsEnviados(value, coti.idquodom);
    //console.log("postsData", postsData);
    if (mounted.current) {
      setFechaentrega(postsData[0].fechaentrega);
      //
      setPosts(postsData);
      setisLoading(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect OfertasItemEnviado.js");
    AsyncStorage.getItem("cotizacionSeleccionada").then((valor) => {
      const coti = JSON.parse(valor);
      setCotizacon(coti);
      setIdquodom(coti.idquodom);
      setnombre(coti.nombreusuariocomprador);
      setisLoading(false);
    });
    LeerDatos();
    return () => (mounted.current = false);
  }, [route]);

  function stringToBoolean(int) {
    switch (int) {
      case 1:
        return true;
      case 0:
      case null:
        return false;
      default:
        return Boolean(int);
    }
  }

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{ flex: 1, backgroundColor: "#F6EE5D", alignContent: "center" }}
      >
        <HStack
          bg="#F6EE5D"
          px={2}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space={4} alignItems="center">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <HStack space={2} alignItems="center" pt="3">
                <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
                <Text style={QuodomDetalle.textoTituloChicoBN}>
                  Productos para {nombreusuariocomprador}{" "}
                </Text>
              </HStack>
            </TouchableOpacity>
          </HStack>
        </HStack>

        <VStack flex={1} pr="3" pl="3" borderWidth={0}>
          <Center mt="3" pb="3">
            <Text style={QuodomDetalle.textoTituloChicoVendisteBN}>
              ¡Enviado!
            </Text>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={2}
              style={QuodomDetalle.textoTituloChicoEnviaste}
            >
              {nombreusuariocomprador} ya recibió tus cotizaciones.
            </Text>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={QuodomDetalle.textoTituloChicoEnviaste}
            >
              Te avisaremos si hay novedades.
            </Text>
            <Text
              pt="2"
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={QuodomDetalle.textoTituloChicoEnviaste}
            >
              Fecha de entrega propuesta: {CortarFecha(fechaentrega)}
            </Text>
          </Center>

          <View
            style={{
              flex: 1,
              backgroundColor: "#F6EE5D",
              alignContent: "center",
            }}
          >
            <FlatList
              data={posts}
              //style={QuodomDetalle.gridView}
              ListFooterComponent={<Center h={20}></Center>}
              renderItem={({ item }) => (
                <OfertasRenderItemEnviado
                  nombreproducto={item.nombreproducto}
                  enviovendedor={stringToBoolean(item.enviovendedor)}
                  cantidad={item.cantidad}
                  imagen={item.imagen}
                  refreshImagen={item.refreshImagen}
                  id={item.id}
                  incluirenvio={item.incluirenvio}
                  preciounitario={item.preciounitario}
                  imagendelvendedor={item.imagendelvendedor}
                  comprador_atributo1={item.atributo1}
                  comprador_atributo2={item.atributo2}
                  comprador_nombreAtributo1={item.nombreAtributo1}
                  comprador_nombreAtributo2={item.nombreAtributo2}
                  vendedor_atributo1={item.vendedor_atributo1}
                  vendedor_atributo2={item.vendedor_atributo2}
                  vendedor_nombreAtributo1={item.vendedor_nombreAtributo1}
                  vendedor_nombreAtributo2={item.vendedor_nombreAtributo2}
                  fechaentrega={item.fechaentrega}
                ></OfertasRenderItemEnviado>
              )}
              keyExtractor={(item) => item.id.toString()}
            />
          </View>
        </VStack>
        {/* <Box pb='2' pl='4' pr='4' safeAreaBottom bg='#fff' p="3" pt="2" shadow={5} space={2} justifyContent='center'>
          <HStack space={3} justifyContent='center' alignItems='center'>
            <Text style={QuodomDetalle.textoPrecioTotalVendido}>Total: ${parseFloat(cotizacion.totalcotizado) * 1}</Text>
          </HStack>

        </Box> */}

        <VStack
          pb="2"
          pl="4"
          pr="4"
          safeAreaBottom
          bg="#fff"
          p="3"
          pt="2"
          shadow={5}
          space={2}
          justifyContent="center"
        >
          {/*  <Divider></Divider> */}
          <HStack pt="2" pb="2" justifyContent="center">
            <Text style={QuodomDetalle.textoPrecioTotalVendido}>
              Total cotizado:{" "}
              {cotizacion.totalcotizado.toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </Text>
          </HStack>
        </VStack>
      </View>
    );
  }
};

export default OfertasItemEnviado;
