import React, { useEffect, useState } from "react";
import {
  FlatList,
  SafeAreaView,
  View,
  Alert,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { Center, VStack, Text, HStack } from "native-base";
import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { deleteCategoria, ObtenerCategoriasUsuario } from "../../api";
import CategoriaItem from "./CategoriaItem";
import { StyleSigInScreen } from "../../styles/StyleQuodom";
import { AntDesign } from "@expo/vector-icons";

const CategoriaList = (props) => {
  const [categoria, setCategorias] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isFocused = useIsFocused();

  const getUsers = async () => {
    //console.log('Entre al getUsers() CategoriaList.js');
    try {
      const value = await AsyncStorage.getItem("userToken");
      const categoria = await ObtenerCategoriasUsuario(value);
      ////console.log(categoria.length);
      props.cantidad(categoria.length);
      setCategorias(categoria);
    } catch (error) {
      //console.log(error);
    }
    setIsLoading(false);
  };

  const onRefresh = React.useCallback(async () => {
    await getUsers();
  }, []);

  const handleDelete = (id) => {
    Alert.alert(
      "Eliminar Categoria",
      "Seguro que desea eliminar la categoria?",
      [
        {
          text: "Cancelar",
          style: "cancel",
        },
        {
          text: "Ok",
          onPress: async () => {
            setIsLoading(true);
            const value = await AsyncStorage.getItem("userToken");
            await deleteCategoria(value, id);
            await getUsers();
            setIsLoading(false);
          },
        },
      ]
    );
  };

  const EmptyPlaceHolder = () => {
    return (
      <HStack
        pl="2"
        pr="2"
        pt="5"
        pb="5"
        mt="5"
        mb="5"
        borderWidth={1}
        borderColor="#45444C"
        alignItems="center"
        space={2}
        rounded={8}
      >
        <VStack>
          <AntDesign name="warning" size={24} color="#45444C" />
        </VStack>
        <VStack w="90%">
          <Text
            adjustsFontSizeToFit={true}
            numberOfLines={1}
            style={StyleSigInScreen.textoLoginyPass}
          >
            Sin Categorias.
          </Text>
          <Text
            adjustsFontSizeToFit={true}
            numberOfLines={2}
            style={StyleSigInScreen.textoLoginyPass}
          >
            Agregue categorias para recibir cotizaciones.
          </Text>
        </VStack>
      </HStack>
    );
  };

  useEffect(() => {
    //console.log('Entre al useEffect() CategoriaList.js');
    //props.loading(true);
    getUsers();
    ////console.log("called");
  }, [isFocused, props.Actualizar]);

  const renderItem = ({ item }) => (
    <CategoriaItem categoria={item} handleDelete={handleDelete} />
  );

  return (
    <View style={{ flex: 1, paddingTop: 15 }}>
      {isLoading ? (
        <Center flex="1" h="xs">
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <FlatList
          flex={1}
          borderWidth={0}
          ListEmptyComponent={<EmptyPlaceHolder />}
          data={categoria}
          ListFooterComponent={<Center h="10"></Center>}
          renderItem={renderItem}
          keyExtractor={(item) => item.id.toString()}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
        />
      )}
    </View>
  );
};

export default CategoriaList;
