import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  SafeAreaView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  HStack,
  ScrollView,
  useToast,
  VStack,
  Divider,
  Center,
  Modal,
  Button,
} from "native-base";
import { Avatar } from "react-native-paper";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import {
  ObtenerDataUsuario,
  CambiarFotoProfile,
  ObtenerDataUsuarioConFoto,
  getCalificacionesUsuario,
  GuardarUsuario,
} from "../../api";
import * as ImagePicker from "expo-image-picker";
import * as FileSystem from "expo-file-system";
import { useNavigation, useIsFocused } from "@react-navigation/native";
import AppBar from "../../components/AppBar";
import { Rating } from "react-native-ratings";
import {
  StyleProfileScreen,
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import * as Device from "expo-device";
import { hp, moderateScale, wp } from "../../styles/responsive";

import LoaderTextoProfile from "../../components/Loader/LoaderTextoProfile";
import LoaderFotoProfile from "../../components/Loader/LoaderFotoProfile";
import MenuAccionesFoto from "./MenuAccionesFoto";
import MenuNuevoAvatar from "./MenuNuevoAvatar";

import { Camera, CameraType } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";
import { AuthContext } from "../../components/context";
const ProfileScreen = () => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);

  const navigation = useNavigation();
  const toast = useToast();
  const id = "id_toast";
  const [user, setUser] = useState([]);
  const [refreshPage, setRefreshPage] = useState();
  const [userFoto, setUserFoto] = useState(null);
  const [nombreAvatar, setnombreAvatar] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [calificacion, setCalificacion] = useState([]);
  const [modalVisibleEliminar, setModalVisibleEliminar] = useState(false);
  const { signOut, toggleTheme } = React.useContext(AuthContext);
  const loadProfile = async () => {
    const value = await AsyncStorage.getItem("userToken");
    var currentRefreshFoto = await AsyncStorage.getItem("userRefreshFoto");

    const data = await ObtenerDataUsuario(value);
    ////console.log('data', data);
    if (mounted.current) {
      setUser(data);
    }
    ////console.log('ProfileFoto', ProfileFoto);
    const calif = await getCalificacionesUsuario(value);
    if (mounted.current) {
      ////console.log('---------- calificacion', calif)
      if (calif === null) {
        let data = {
          calificacion: 0,
          cantcalificaciones: 0,
        };
        setCalificacion(data);
      } else {
        setCalificacion(calif);
      }

      ////console.log('---------- calificacion2', calificacion.cantcalificaciones)
    }

    let ProfileFoto;
    if (currentRefreshFoto !== data.refreshFoto) {
      const fotoPerfil = await ObtenerDataUsuarioConFoto(value);
      if (typeof fotoPerfil.foto !== undefined && fotoPerfil.foto !== null) {
        await AsyncStorage.setItem("userFoto", fotoPerfil.foto);
        await AsyncStorage.setItem("userRefreshFoto", fotoPerfil.refreshFoto);
        ProfileFoto = fotoPerfil.foto;
      }
    } else {
      ProfileFoto = await AsyncStorage.getItem("userFoto");
    }

    if (ProfileFoto !== null) {
      if (mounted.current) {
        setUserFoto(ProfileFoto);
      }
    } else {
      if (data.esVendedor) {
        let PrimeraLetraNombre = data.nombre.charAt(0);
        if (mounted.current) {
          setnombreAvatar(PrimeraLetraNombre);
        }
      } else {
        //console.log("soy null asi que le clavo el nombre");
        let PrimeraLetraNombre = data.nombre.charAt(0);
        let PrimeraLetraApellido = data.apellido.charAt(0);
        if (mounted.current) {
          setnombreAvatar(PrimeraLetraNombre + PrimeraLetraApellido);
        }
      }
    }
    if (mounted.current) {
      setisLoading(false);
    }
  };

  const Modificar = async (value) => {
    pickImage();
  };

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Obtén la parte codificada en base64 del resultado
        resolve(base64String);
      };

      reader.onerror = () => {
        reject(new Error("Error al leer el archivo"));
      };

      reader.readAsDataURL(file);
    });
  }

  const Eliminar = async () => {
    //console.log("Entre a eliminar");
    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");
    user.foto = null;
    user.refreshFoto = "borrar";
    await CambiarFotoProfile(token, user);
    await AsyncStorage.removeItem("userFoto");
    await AsyncStorage.removeItem("userRefreshFoto");

    setUserFoto(null);
    setRefreshPage(Math.random().toString());
  };

  const Actualizar = () => {
    setRefreshPage(Math.random().toString());
    //console.log("Refresh cambiarState ProfileScreen.js");
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.3,
    });
    setisLoading(true);
    if (!result.canceled) {
      const token = await AsyncStorage.getItem("userToken");

      let fotoBase64 = await readFileAsync(result.uri);
      /*  let fotoBase64 = await FileSystem.readAsStringAsync(result.uri, {
        encoding: "base64",
      });*/

      user.foto = fotoBase64;
      let saveUser = await CambiarFotoProfile(token, user);

      if (!saveUser.res) {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "Algo salió mal",
            status: "error",
            description: saveUser.message,
            placement: "bottom",
            style: { backgroundColor: "#2ECC71" },
          });
        }
        setisLoading(false);
        return;
      }
      if (typeof fotoBase64 !== undefined && fotoBase64 != null) {
        await AsyncStorage.setItem("userFoto", fotoBase64);
        await AsyncStorage.setItem("userRefreshFoto", saveUser.refresh);
      } else {
      }

      setUserFoto(fotoBase64);
      setRefreshPage(Math.random().toString());
      //console.log("Soy el refresh", refreshPage);
    }
    setisLoading(false);
  };

  const pickFromCamera = async () => {
    const { granted } = await Camera.requestCameraPermissionsAsync();

    if (granted) {
      let data = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 0.5,
        base64: true,
      });

      ////console.log('DATAAAA', data.base64)
      if (!data.canceled) {
        await handleUpload(data.uri);
      }
    } else {
      Alert.alert("you need to give up permission to work");
    }
  };

  const handleUpload = async (uri) => {
    setisLoading(true);

    const token = await AsyncStorage.getItem("userToken");
    let uriCompressed = "";

    const Result = await ImageManipulator.manipulateAsync(
      uri,
      [{ resize: { width: 480, height: 480 } }],
      { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
    );
    uriCompressed = Result;
    let fotoBase64 = await readFileAsync(result.uri);
    /*  let fotoBase64 = await FileSystem.readAsStringAsync(uriCompressed.uri, {
      encoding: FileSystem.EncodingType.Base64,
    });*/

    user.foto = fotoBase64;
    let saveUser = await CambiarFotoProfile(token, user);

    if (!saveUser.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: saveUser.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    setUserFoto(fotoBase64);
    setisLoading(false);
  };

  const EliminarcuentaModal = async () => {
    setisLoading(true);
    const token = await AsyncStorage.getItem("userToken");
    user.username = "ELIMINADO-" + user.username;
    user.email = "ELIMINADO-" + user.email;
    const UserRes = await GuardarUsuario(token, user);
    if (!UserRes.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: UserRes.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    } else {
      setisLoading(false);
    }
    signOut();
  };
  const eliminarCuenta = () => {
    setModalVisibleEliminar(true);
    /* if (!toast.isActive(id)) {
      toast.show({
        id,
        title: "Atención",
        status: "warning",
        description:
          "Por motivos de seguridad, para eliminar su cuenta debe enviarnos un correo a ayuda@quodom.com.ar con el correo que se registro.",
        placement: "bottom",
        style: { backgroundColor: "#2ECC71" },
      });
    }*/
  };

  useEffect(() => {
    mounted.current = true;
    setisLoading(true);
    //console.log("Entre al useEffect ProfileScreen.js");
    loadProfile();
    return () => (mounted.current = false);
  }, [refreshPage]);

  return (
    <SafeAreaView flex="1" backgroundColor="#F2F2F2">
      <ScrollView scrollToOverflowEnabled={false} backgroundColor="#F2F2F2">
        <AppBar />
        <VStack flex="1" pr="3" pl="3" borderWidth={0}>
          {isLoading ? (
            <VStack>
              <View style={{ alignSelf: "center" }}>
                <View style={StyleProfileScreen.profileImage}>
                  <LoaderFotoProfile />
                </View>
                <View style={StyleProfileScreen.add}></View>
              </View>

              <VStack
                alignItems="center"
                justifyContent="center"
                mt="2"
                space={2}
              >
                <LoaderTextoProfile />
              </VStack>
            </VStack>
          ) : (
            <VStack style={{ marginTop: "1%" }}>
              <Center>
                <Text
                  style={{
                    textAlign: "center",

                    fontWeight: "bold",
                  }}
                >
                  PERFIL
                </Text>
              </Center>
              <View
                style={{
                  alignSelf: "center",
                  marginTop: "1%",
                  marginBottom: "1%",
                }}
              >
                <View style={StyleProfileScreen.profileImage}>
                  {userFoto !== null ? (
                    <Avatar.Image
                      source={{ uri: "data:image/png;base64," + userFoto }}
                      size={100}
                    />
                  ) : (
                    <Avatar.Text
                      size={100}
                      label={nombreAvatar}
                      style={StyleProfileScreen.textAvatar}
                    />
                  )}
                </View>
                <View style={StyleProfileScreen.add}>
                  {userFoto === null ? (
                    /*  (<TouchableOpacity style={StyleProfileScreen.button} onPress={pickImage}>
                                                 <MaterialIcons name="add-a-photo" size={20} color="#DFD8C8" />
                                             </TouchableOpacity> */
                    <MenuNuevoAvatar
                      camara={pickFromCamera}
                      dispositivo={pickImage}
                    />
                  ) : (
                    <MenuAccionesFoto
                      modificar={Modificar}
                      eliminar={Eliminar}
                    />
                  )}
                </View>
              </View>

              <VStack
                alignItems="center"
                justifyContent="center"
                mt="2"
                space={0}
              >
                {user.esVendedor ? (
                  <Text style={StyleProfileScreen.textNombreProfile}>
                    {user.RazonSocial}
                  </Text>
                ) : (
                  <Text style={StyleProfileScreen.textNombreProfile}>
                    {user.nombre} {user.apellido}
                  </Text>
                )}

                {/* <Text style={StyleProfileScreen.textDevice}>{Device.isDevice ? Device.deviceName : 'Emulador'}</Text> */}

                {calificacion.cantcalificaciones !== 0 ? (
                  <TouchableOpacity
                    onPress={() =>
                      navigation.navigate("CalificacionesScreen", {
                        calificacion: calificacion,
                      })
                    }
                  >
                    <VStack
                      pt="1"
                      alignItems="center"
                      justifyItems="center"
                      space={1}
                    >
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={StyleProfileScreen.textDevice}
                      >
                        Mi Calificación{" "}
                      </Text>
                      <HStack
                        alignItems="center"
                        justifyItems="center"
                        space={1}
                      >
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={StyleProfileScreen.textoRatingProfile}
                        >
                          {" "}
                          {calificacion.calificacion}
                        </Text>
                        <Rating
                          showRating={false}
                          tintColor="#F2F2F2"
                          ratingCount={5}
                          imageSize={26}
                          isDisabled={true}
                          startingValue={calificacion.calificacion}
                          readonly={true}
                        />
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={StyleProfileScreen.textSub}
                        >
                          ({calificacion.cantcalificaciones})
                        </Text>
                      </HStack>
                    </VStack>
                  </TouchableOpacity>
                ) : null}
              </VStack>
            </VStack>
          )}

          <View style={{ alignItems: "center" }}>
            <VStack space={1} mt="3" width="40%">
              {!isLoading ? (
                <VStack
                  bg="#fff"
                  shadow="1"
                  mt="5"
                  borderRadius="0"
                  justifyContent="flex-start"
                >
                  <VStack style={{ padding: "1%" }}>
                    <TouchableOpacity
                      onPress={() =>
                        navigation.navigate("Detalle", {
                          updateScreen: Actualizar,
                        })
                      }
                    >
                      <HStack alignItems="center" justifyContent="center">
                        <Ionicons
                          name="person-outline"
                          size={24}
                          color="#41444B"
                        ></Ionicons>
                        <Text style={StyleProfileScreen.textOpciones}>
                          Mis datos
                        </Text>
                        <MaterialIcons
                          name="keyboard-arrow-right"
                          size={moderateScale(16)}
                          color="#41444B"
                        />
                      </HStack>
                    </TouchableOpacity>
                  </VStack>

                  <Divider></Divider>

                  <VStack style={{ padding: "1%" }}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("DireccionesScreen")}
                    >
                      <HStack alignItems="center" justifyContent="center">
                        <Ionicons
                          name="map-outline"
                          size={24}
                          color="#41444B"
                        ></Ionicons>
                        <Text style={StyleProfileScreen.textOpciones}>
                          Mis Direcciones
                        </Text>
                        <MaterialIcons
                          name="keyboard-arrow-right"
                          size={moderateScale(16)}
                          color="#41444B"
                        />
                      </HStack>
                    </TouchableOpacity>
                  </VStack>

                  <Divider></Divider>

                  <VStack style={{ padding: "1%" }}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate("SeguridadUsuario")}
                    >
                      <HStack alignItems="center" justifyContent="center">
                        <Ionicons
                          name="md-key-outline"
                          size={24}
                          color="#41444B"
                        ></Ionicons>
                        <Text style={StyleProfileScreen.textOpciones}>
                          Seguridad
                        </Text>
                        <MaterialIcons
                          name="keyboard-arrow-right"
                          size={moderateScale(16)}
                          color="#41444B"
                        />
                      </HStack>
                    </TouchableOpacity>
                  </VStack>

                  {user.esVendedor ? (
                    <VStack>
                      <Divider></Divider>
                      <VStack style={{ padding: "1%" }}>
                        <TouchableOpacity
                          onPress={() => navigation.navigate("MobbexScreen")}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Ionicons
                              name="card-outline"
                              size={24}
                              color="#41444B"
                            />
                            <Text style={StyleProfileScreen.textOpciones}>
                              Datos Fresa
                            </Text>
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={moderateScale(16)}
                              color="#41444B"
                            />
                          </HStack>
                        </TouchableOpacity>
                      </VStack>
                      <Divider></Divider>
                      <VStack style={{ padding: "1%" }}>
                        <TouchableOpacity
                          onPress={() => navigation.navigate("Categoria")}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Ionicons
                              name="grid-outline"
                              size={24}
                              color="#41444B"
                            />
                            <Text style={StyleProfileScreen.textOpciones}>
                              Categorías
                            </Text>
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={moderateScale(16)}
                              color="#41444B"
                            />
                          </HStack>
                        </TouchableOpacity>
                      </VStack>
                      <Divider></Divider>
                      <VStack style={{ padding: "1%" }}>
                        <TouchableOpacity
                          onPress={() => navigation.navigate("Zonas")}
                        >
                          <HStack alignItems="center" justifyContent="center">
                            <Ionicons
                              name="location-outline"
                              size={24}
                              color="#41444B"
                            ></Ionicons>
                            <Text style={StyleProfileScreen.textOpciones}>
                              Zonas
                            </Text>
                            <MaterialIcons
                              name="keyboard-arrow-right"
                              size={moderateScale(16)}
                              color="#41444B"
                            />
                          </HStack>
                        </TouchableOpacity>
                      </VStack>
                    </VStack>
                  ) : null}
                  <Divider></Divider>
                  <VStack style={{ padding: "1%" }}>
                    <TouchableOpacity onPress={() => eliminarCuenta()}>
                      <HStack alignItems="center" justifyContent="center">
                        <Ionicons
                          name="person-outline"
                          size={24}
                          color="#41444B"
                        ></Ionicons>
                        <Text style={StyleProfileScreen.textOpciones}>
                          Eliminar Cuenta
                        </Text>
                        <MaterialIcons
                          name="keyboard-arrow-right"
                          size={moderateScale(16)}
                          color="#41444B"
                        />
                      </HStack>
                    </TouchableOpacity>
                  </VStack>
                </VStack>
              ) : (
                <Center flex="1" h="xs">
                  <ActivityIndicator size="large" color="#706F9A" />
                </Center>
              )}
            </VStack>
          </View>
        </VStack>
      </ScrollView>
      {/* MODAL CONFIRMACION ELIMINAR */}
      <Modal
        isOpen={modalVisibleEliminar}
        onClose={() => setModalVisibleEliminar(false)}
      >
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Atención!
            </Text>
          </Modal.Header>
          <Modal.Body pt="5" pb="5">
            <Text style={QuodomDetalle.TextoModalEliminar}>
              ¿Esta seguro de eliminar la cuenta?
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "transparent",
                  padding: 10,
                  borderRadius: 5,
                  borderWidth: 1,
                }}
                onPress={() => EliminarcuentaModal()}
              >
                <Text style={QuodomDetalle.TextoModalButtonOK}>Eliminar</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => setModalVisibleEliminar(!modalVisibleEliminar)}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Cancelar
                </Text>
              </TouchableOpacity>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </SafeAreaView>
  );
};
export default ProfileScreen;
