import React, { useEffect, useState, useRef } from "react";
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { HStack, Center, VStack, Divider, Box, Stack } from "native-base";

import { DetalleProductoQuodomstyle } from "../styles/StyleQuodom";
import { SitioInicialStyle } from "../styles/general";
import { useNavigation } from "@react-navigation/native";
import { ObtenerProductosDestacados } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import "react-magic-slider-dots/dist/magic-dots.css";
import Slider from "react-slick";
import { hp, wp } from "../styles/responsive";
import "slick-carousel/slick/slick.css";
import "./slick-theme.css";
const BannerPrincipal = (props) => {
  const navigation = useNavigation();
  const [posts, setPosts] = useState([]);
  const mounted = useRef(true);
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const data = [
    {
      id: "578",
      nombreproducto: "Pepsi Black botella",
      categoria: "Bebidas",
      image: "http://img.quodom.com.ar/producto/578.png",
      refresh: "1P578",
    },
    {
      id: "635",
      nombreproducto: "Fond de Cave",
      categoria: "Bebidas",
      image: "http://img.quodom.com.ar/producto/635.png",
      refresh: "1P635",
    },
    {
      id: "1",
      nombreproducto: "Cera 5 litros",
      categoria: "Articulos de limpieza",
      image: "http://img.quodom.com.ar/producto/1.png",
      refresh: "P1",
    },
    {
      id: "498",
      nombreproducto: "Pañuelo descartable caja 150 uni.",
      categoria: "Papelera",
      image: "http://img.quodom.com.ar/producto/498.png",
      refresh: "P498",
    },
    {
      id: "548",
      nombreproducto: "Barbijos descartables por 100 unidades",
      categoria: "Papelera",
      image: "http://img.quodom.com.ar/producto/548.png",
      refresh: "P548",
    },
  ];

  const LeerDatos = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const DestacadosData = await ObtenerProductosDestacados(value);
    if (mounted.current) {
      setPosts(DestacadosData);
    }
  };

  useEffect(() => {
    mounted.current = true;
    LeerDatos();
    return () => (mounted.current = false);
  }, []);

  function AbrirModal(id, nombre) {
    //console.log("AbrirModal con id", id);
    //setModalVisible(true);
    props.setidItem(id);
    props.setNombreProducto(nombre);
    props.visible(true);
  }

  return (
    <div style={{ width: wp(53), marginBottom: "3%", marginTop: "2%" }}>
      <Center>
        <Stack space={0}>
          <Text style={SitioInicialStyle.TituloMasBuscados}>Mas buscados</Text>
        </Stack>
      </Center>
      <View style={{ alignItems: "center" }}>
        <Box w="100%">
          <Divider my="2" bg="#706F9A" />
        </Box>
      </View>
      <Slider {...settings}>
        {posts.map(function (item) {
          return (
            <div key={item.id}>
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => AbrirModal(item.id, item.nombreproducto)}
                style={styles.item}
              >
                <HStack
                  w={wp(15)}
                  h={wp(10)}
                  alignItems="center"
                  justifyContent="center"
                  space={1}
                  bg="#fff"
                  borderWidth={0}
                  borderRadius={11}
                >
                  <VStack borderWidth={0}>
                    <Image
                      source={{
                        uri: item.imagenDestacado,
                      }}
                      //  uri={item.imagenDestacado} // image address
                      //   cacheKey={item.refreshImagenDestacado} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                      style={{ width: wp(5), height: wp(6), padding: 0 }}
                    />
                  </VStack>
                  <VStack
                    w="60%"
                    pt="0"
                    pb="0"
                    borderWidth={0}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <HStack p="2">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        style={
                          DetalleProductoQuodomstyle.textoTituloBannerNegro
                        }
                      >
                        {item.tituloDestacado}
                      </Text>
                    </HStack>
                    <Divider></Divider>
                    <HStack p="2">
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={3}
                        style={
                          DetalleProductoQuodomstyle.textoTituloCotizadoNegro
                        }
                      >
                        {item.nombreDestacado}
                      </Text>
                    </HStack>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 100,
    width: "100%",
  },
  containerContent: {
    paddingLeft: 16,
  },
  heading: {
    color: "#fff",
    paddingBottom: 6,
    textAlign: "center",
  },
  tagline: {
    color: "#ccc",
    paddingBottom: 6,
    textAlign: "center",
  },
  item: {
    marginRight: 16,
    width: wp(15),
    //height:100
  },
  image: {
    backgroundColor: "#fff",
    //height: 148,
    //width: 250,
    borderRadius: 10,
  },
  title: {
    color: "#000",
    marginTop: 4,
    textAlign: "center",
  },
});

export default BannerPrincipal;
