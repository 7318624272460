import React, { useEffect, useState } from "react";
import {
  Box,
  FlatList,
  HStack,
  VStack,
  Text,
  View,
  Pressable,
  Center,
  Stack,
  Divider,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { Alert, Image, StyleSheet, TouchableOpacity } from "react-native";
import { ObtenerSubCategorias, AsociarCategoria } from "../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hp, wp } from "../styles/responsive";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

import { StyleSelectCategoria } from "../styles/StyleQuodom";
//import { updateNamedExports } from 'typescript';
import "react-magic-slider-dots/dist/magic-dots.css";
import Slider from "react-slick";
import { DetalleProductoQuodomstyle } from "../styles/StyleQuodom";
import "slick-carousel/slick/slick.css";
import "./slick-theme.css";

export const BannerSubCategorias = (props) => {
  ////console.log('entre BANNER');
  const loadingData = [
    { id: "1" },
    { id: "2" },
    { id: "3" },
    { id: "4" },
    { id: "5" },
    { id: "6" },
    { id: "7" },
    { id: "8" },
  ];

  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [ultimaCat, setUltimaCat] = useState(0);
  const [addCat, setAddCat] = useState([]);
  const [error, setError] = useState();
  const [refreshPage, setRefreshPage] = useState("");
  const [IDCat, setIDCategoria] = useState("");

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
  };

  if (ultimaCat !== props.catPadre) {
    //setUltimaCat(0);
    setUltimaCat(props.catPadre);
  }

  function ActualizarLista(id, nombre) {
    ////console.log(id + ' - ' + nombre);
    //console.log("entre ActualizarLista");
    setIDCategoria(id);
    props.actListaSubCat(id);
    props.actNombreCat(nombre);
  }

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos BannerSubCategoria.js");
    const value = await AsyncStorage.getItem("userToken");
    let categoriapadre = "0";
    if (props.catPadre == "0") {
      categoriapadre = await AsyncStorage.getItem("catPadre");
      ////console.log("desde async", categoriapadre);
    } else {
      categoriapadre = props.catPadre;
      ////console.log("desde prop", categoriapadre);
    }
    ////console.log("categoria es:", categoriapadre);
    if (
      categoriapadre == undefined ||
      categoriapadre === "0" ||
      categoriapadre === ""
    ) {
      setUltimaCat(1);
      categoriapadre = 1;
    }
    const SubCat = await ObtenerSubCategorias(value, categoriapadre);
    setPosts(SubCat);
    ////console.log('SubCat', SubCat)
    if (!SubCat || SubCat.length === 0) {
      //console.log("SubCat Vacia");
    } else {
      var d = SubCat[0];
      ////console.log('QUE 1100', d);
      ActualizarLista(d.id, d.nombrecategoria);
    }
    setisLoading(false);
    //props.isLoading(false);
  };

  useEffect(() => {
    //console.log("Entre al useEffect BannerSubCategoria.js");
    //props.isLoading(true);
    /*  if (props.catPadre === "0") {
      setUltimaCat(1);
    }*/
    setisLoading(true);
    LeerDatos();
  }, [ultimaCat]);

  const Loading = () => {
    return (
      <FlatList
        horizontal={true}
        data={loadingData}
        width="100%"
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ alignItems: "center" }}
        renderItem={({ item }) => (
          <HStack style={{ backgroundColor: "#FFF" }}>
            <ContentLoader
              animate={false}
              speed={0.5}
              width={wp(30)}
              height={108}
              //viewBox="0 0 80 90"
              backgroundColor="#cacace"
              foregroundColor="#fdfdfc"
            >
              <Rect x="22" y="15" rx="8" ry="8" width="60" height="60" />
              <Rect x="22" y="85" rx="2" ry="3" width="60" height="7" />
              {/*   <Rect x="20" y="75" rx="2" ry="3" width="60" height="7" /> */}
            </ContentLoader>
          </HStack>
        )}
        keyExtractor={(item) => item.id.toString()}
      />
    );
  };

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div style={{ width: wp(53), backgroundColor: "#fff" }}>
        <Slider {...settings}>
          {posts.map(function (item) {
            return (
              <div key={item.id}>
                <Pressable
                  onPress={() => ActualizarLista(item.id, item.nombrecategoria)}
                >
                  {({ isHovered, isFocused, isPressed }) => {
                    return (
                      <HStack>
                        {IDCat == item.id ? (
                          <HStack
                            w="150"
                            h="110"
                            alignItems="center"
                            justifyContent="center"
                            space={1}
                            bg="#fff"
                            borderWidth={0}
                          >
                            <VStack
                              w="100%"
                              pt="0"
                              pb="0"
                              borderWidth={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <HStack p="2">
                                <Ionicons
                                  name="checkmark-outline"
                                  size={60}
                                  style={{ position: "absolute" }}
                                ></Ionicons>
                                <View paddingRight={0} style={{ opacity: 0.2 }}>
                                  <Image
                                    source={{
                                      uri: item.imagen,
                                    }}
                                    //uri={props.imagen} // image address
                                    //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                    style={{ width: wp(5), height: wp(5) }}
                                  />
                                </View>
                              </HStack>

                              <HStack space={0} justifyContent="center">
                                <View paddingRight={0}>
                                  <Text style={StyleSelectCategoria.itemText}>
                                    {item.nombrecategoria}
                                  </Text>
                                </View>
                              </HStack>
                            </VStack>
                          </HStack>
                        ) : (
                          <HStack
                            w="150"
                            h="110"
                            alignItems="center"
                            justifyContent="center"
                            space={1}
                            bg="#fff"
                            borderWidth={0}
                          >
                            <VStack
                              w="100%"
                              pt="0"
                              pb="0"
                              borderWidth={0}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <HStack p="2">
                                <Image
                                  source={{
                                    uri: item.imagen,
                                  }}
                                  //uri={props.imagen} // image address
                                  //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                  style={{ width: 70, height: 70 }}
                                />
                              </HStack>

                              <HStack space={0} justifyContent="center">
                                <View paddingRight={0}>
                                  <Text style={StyleSelectCategoria.itemText}>
                                    {item.nombrecategoria}
                                  </Text>
                                </View>
                              </HStack>
                            </VStack>
                          </HStack>
                        )}
                      </HStack>
                    );
                  }}
                </Pressable>
              </div>
            );
          })}
        </Slider>
      </div>
    );

    /*
      <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={() => ActualizarLista(item.id, item.nombrecategoria)}
                  style={styles.item}
                >
    return (
      <div style={{ width: "50%" }}>
        <Slider {...settings}>
          {posts.map(function (item) {
            return (
              <div key={item.id}>
                <Pressable
                  onPress={() => ActualizarLista(item.id, item.nombrecategoria)}
                >
                  {({ isHovered, isFocused, isPressed }) => {
                    return (
                      <HStack style={{ backgroundColor: "#FFF" }}>
                        {IDCat == item.id ? (
                          <Center
                            borderRightWidth="1"
                            borderColor="coolGray.200"
                            pl="5"
                            pr="5"
                            mt="2"
                            mb="2"
                            style={{
                              backgroundColor: "#FFF",
                              transform: [
                                {
                                  scale: isPressed ? 0.96 : 1,
                                },
                              ],
                            }}
                          >
                            <HStack space={0} justifyContent="center">
                              <Ionicons
                                name="checkmark-outline"
                                size={60}
                                style={{ position: "absolute" }}
                              ></Ionicons>
                              <View paddingRight={0} style={{ opacity: 0.2 }}>
                                <Image
                                  source={{
                                    uri: item.imagen,
                                  }}
                                  //uri={props.imagen} // image address
                                  //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                  style={{ width: 70, height: 70 }}
                                />
                              </View>
                            </HStack>
                            <HStack space={0} justifyContent="center">
                              <View paddingRight={0}>
                                <Text style={StyleSelectCategoria.itemText}>
                                  {item.nombrecategoria}
                                </Text>
                              </View>
                            </HStack>
                          </Center>
                        ) : (
                          <Center
                            borderRightWidth="1"
                            borderColor="coolGray.200"
                            pl="5"
                            pr="5"
                            //py="2"
                            mt="2"
                            mb="2"
                            style={{
                              backgroundColor: "#FFF",
                              transform: [
                                {
                                  scale: isPressed ? 0.96 : 1,
                                },
                              ],
                            }}
                          >
                            <HStack space={0} justifyContent="center">
                              <View paddingRight={0}>
                                <Image
                                  source={{
                                    uri: item.imagen,
                                  }}
                                  //uri={props.imagen} // image address
                                  //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                                  style={{ width: 70, height: 70 }}
                                />
                              </View>
                            </HStack>

                            <HStack space={0} justifyContent="center">
                              <View paddingRight={0}>
                                <Text style={StyleSelectCategoria.itemText}>
                                  {item.nombrecategoria}
                                </Text>
                              </View>
                            </HStack>
                          </Center>
                        )}
                      </HStack>
                    );
                  }}
                </Pressable>
              </div>
            );
          })}
        </Slider>
      </div>
    );*/
  }
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 100,
    width: "100%",
  },
  containerContent: {
    paddingLeft: 16,
  },
  heading: {
    color: "#fff",
    paddingBottom: 6,
    textAlign: "center",
  },
  tagline: {
    color: "#ccc",
    paddingBottom: 6,
    textAlign: "center",
  },
  item: {
    marginRight: 16,
    //height:100
  },
  image: {
    backgroundColor: "#fff",
    //height: 148,
    //width: 250,
    borderRadius: 10,
  },
  title: {
    color: "#000",
    marginTop: 4,
    textAlign: "center",
  },
});
export default BannerSubCategorias;
