import React from "react";

import { Select, VStack, CheckIcon, Center, Box } from "native-base";
import { QuodomDetalle } from "../../styles/StyleQuodom";

const SelectDirecciones = (props) => {
  ////console.log('los PROPS de selectDirecciones', props)
  let [service, setService] = React.useState(parseInt(props.valor));

  function cortarDireccion(texto) {
    if (texto.length > 107) {
      return texto.slice(0, 107) + " ...";
    } else {
      return texto;
    }
  }

  function onSelected(selected) {
    ////console.log(selected.value, selected.label)
    setService(selected);
    props.valorSeleccionado(selected);
  }

  return (
    <Box width={"100%"}>
      <select
        id="ddldireccion"
        class="form-select"
        value={service}
        onChange={(e) => onSelected(e.target.value)}
      >
        <option value="0">Seleccionar</option>
        {props.data && props.data !== undefined
          ? props.data.map((ctr, index) => {
              return (
                <option key={ctr.id} value={ctr.id}>
                  {ctr.alias + " - " + ctr.direccion + ", " + ctr.localidad}
                  {ctr.valoratributo}
                </option>
              );
            })
          : "No hay direcciones"}
      </select>
    </Box>
  );
};

export default SelectDirecciones;
