import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerProductosAtributos,
  ModificarDetalleQuodom,
} from "../../../api";
import { Select, VStack, CheckIcon } from "native-base";
import { StyleSelectCategoria } from "../../../styles/StyleQuodom";

const SelectAtributoModal = (props) => {
  ////console.log('PROPS', props);
  const [data, setData] = useState([]);
  let [service, setService] = useState(props.atributovalue);

  const colorBordes = (estado) => {
    if (props.DP) {
      return "#45444C";
    }
  };

  async function onSelected(selected) {
    //console.log("atributo seleccionado", selected);
    setService(selected);
    props.valordelpicker(selected);
  }

  const loadAtributo = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const atrData = await ObtenerProductosAtributos(
      value,
      props.idproducto,
      props.atributoNombre
    );

    if (!atrData || atrData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      atrData.push(generico);
    }
    setData(atrData);
  };

  useEffect(() => {
    setService(props.atributovalue);
    loadAtributo();
  }, []);

  return (
    <VStack style={{ alignItems: "flex-end", flex: 1 }} borderWidth={0}>
      <Select
        selectedValue={service}
        bg="#fff"
        w="90%"
        h="10"
        borderRadius="20"
        //p='2'
        borderColor={colorBordes(props.detallequodomcompleto)}
        color="#45444C"
        accessibilityLabel="Seleccionar"
        placeholder="Seleccionar"
        _selectedItem={{
          bg: "#706F9A",
          endIcon: <CheckIcon size="5" color="#000" />,
          color: "#fff",
        }}
        customDropdownIconProps={{ color: "#45444C", mr: "3" }}
        style={StyleSelectCategoria.itemSelect}
        onValueChange={(itemValue) => onSelected(itemValue)}
      >
        {data.map((x) => {
          return (
            <Select.Item
              label={x.valoratributo}
              value={x.valoratributo}
              key={x.id}
            />
          );
        })}
      </Select>
    </VStack>
  );
};

export default SelectAtributoModal;
