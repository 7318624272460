import React, { useState } from "react";
import { View, Text } from "react-native";
import {
  DetalleProductoQuodomstyle,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias } from "../../styles/general";
import {
  seleccionProductoComprador,
  getCalificacionesVendor,
  ObtenerQuodom,
} from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hp, moderateScale, wp } from "../../styles/responsive";
import { ActivityIndicator, TouchableOpacity, LogBox } from "react-native";
import {
  Modal,
  Box,
  Center,
  HStack,
  VStack,
  Checkbox,
  Radio,
  Icon,
  Divider,
  Button,
  Image,
  FlatList,
} from "native-base";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { QuodomDetalle } from "../../styles/StyleQuodom";
//import { Modal } from 'react-native-paper';
import { Rating, AirbnbRating } from "react-native-ratings";
import { useNavigation } from "@react-navigation/native";

import Moment from "moment";
import "moment/locale/es";

const SeleccionDetalleProveedor = (props) => {
  const navigation = useNavigation();
  const [visibleFoto, setVisibleFoto] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [realod, setreload] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const [postCalificaciones, setPostsCalificaciones] = useState([]);
  //const [checked, onChange] = useState(props.seleccionComprador);

  LogBox.ignoreLogs([
    "Non-serializable values were found in the navigation state",
  ]);

  function cortarTexto(texto) {
    if (texto.length > 40) {
      return texto.slice(0, 40) + " ...";
    } else {
      return texto;
    }
  }

  const obtenerColor = (estado) => {
    if (estado) return "#2dab66";
    else return "#F6EE5D";
  };

  const obtenerColorBg = (estado) => {
    if (estado) return "#fff";
  };

  const obtenerEstilo = (estado) => {
    if (estado) return "#2dab66";
    else return "#706f9a";
  };

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const CortarFecha = (texto) => {
    if (texto === null || texto === "" || texto === undefined) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };
  function MostrarModal() {
    props.setImagen(props.imagen);
    props.modalFoto(props.imagen);
  }

  async function onCheckmarkPress(id) {
    ////console.log('Entre al Check');

    props.marcar(id);
    props.modal(true);
    let checkedIn = !props.seleccionComprador;
    const token = await AsyncStorage.getItem("userToken");
    let data = {
      id: id,
      idquodom: props.idquodom,
      idquodomline: props.idquodomline,
      check: checkedIn,
    };
    ////console.log('DATA', data);

    await seleccionProductoComprador(token, data);
    props.modal(false);
    if (checkedIn) {
      irCotizacion();
    }
  }
  const irCotizacion = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const Quodom = await ObtenerQuodom(token, props.idquodom);
    navigation.navigate("DetalleQuodomCotizado", {
      idquodom: Quodom.id,
      nombreQuodom: Quodom.descripcion,
      estadoQuodom: Quodom.estado,
      iddireccion: Quodom.iddireccion,
      fechaenvio: Quodom.fechaenvio,
    });
  };
  async function AbrirModal(usuarioVendedor) {
    setModalVisible(true);
    setisLoading(true);
    ////console.log('AbrirModal con usuarioVendedor', usuarioVendedor);
    const token = await AsyncStorage.getItem("userToken");
    const data = await getCalificacionesVendor(token, usuarioVendedor);
    ////console.log(data);

    setPostsCalificaciones(data);
    setisLoading(false);
    //setModalVisible(true);
  }

  function desde(fecha) {
    Moment.locale("es");
    return Moment(fecha, "YYYY-MM-DD h:mm:ss").fromNow();
  }

  return (
    <View>
      <TouchableOpacity
        onPress={() => onCheckmarkPress(props.idcotizacionline)}
      >
        <HStack flex={1} mb="2" ml="0" mr="0" justifyContent="center">
          <Box
            w="50%"
            roundedTopLeft={8}
            roundedBottomLeft={8}
            bgColor={obtenerEstilo(props.seleccionComprador)}
          >
            <HStack space={0} alignItems="center">
              <VStack>
                {props.refreshImagen === "base64" ? (
                  <TouchableOpacity onPress={() => setVisibleFoto(true)}>
                    <Image
                      source={{ uri: "data:image/png;base64," + props.imagen }}
                      alt="."
                      style={estilosCategorias.ImagenDetalleQuodom}
                    />
                  </TouchableOpacity>
                ) : (
                  <Image
                    source={{
                      uri: props.imagen,
                    }}
                    style={estilosCategorias.ImagenDetalleQuodom}
                  />
                )}
              </VStack>

              <VStack
                style={{ paddingTop: 2, paddingLeft: 5, paddingRight: 5 }}
                w="65%"
              >
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={DetalleProductoQuodomstyle.textoTituloCotizado}
                >
                  {cortarTexto(props.nombreProducto)}
                </Text>
              </VStack>
            </HStack>

            <HStack pt="2" pl="3" pb="4">
              <VStack space={2} borderWidth={0} w="95%">
                {props.envio ? (
                  <HStack
                    w="95%"
                    justifyContent="center"
                    space={1}
                    bg={obtenerColorBg(props.seleccionComprador)}
                    alignItems="center"
                    style={{
                      textAlign: "center",
                      padding: 3,
                      borderWidth: 1,
                      borderColor: obtenerColor(props.seleccionComprador),
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 5,
                    }}
                  >
                    <MaterialCommunityIcons
                      name="truck-outline"
                      size={18}
                      color={obtenerColor(props.seleccionComprador)}
                    />
                    <Text
                      style={{
                        color: obtenerColor(props.seleccionComprador),
                        fontSize: 13,
                      }}
                    >
                      Envio Incluido
                    </Text>
                  </HStack>
                ) : (
                  <VStack
                    w="95%"
                    justifyContent="center"
                    space={0}
                    bg={obtenerColorBg(props.seleccionComprador)}
                    alignItems="center"
                    style={{
                      textAlign: "center",
                      padding: 3,
                      borderWidth: 1,
                      borderColor: obtenerColor(props.seleccionComprador),
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 5,
                    }}
                  >
                    <HStack space={1}>
                      <MaterialIcons
                        name="storefront"
                        size={18}
                        color={obtenerColor(props.seleccionComprador)}
                      />
                      <Text
                        style={{
                          color: obtenerColor(props.seleccionComprador),
                          fontSize: 13,
                        }}
                      >
                        Retira en tienda
                      </Text>
                    </HStack>
                    <VStack>
                      <Text
                        style={{
                          color: obtenerColor(props.seleccionComprador),
                          fontSize: 13,
                        }}
                      >
                        ({props.distancia})
                      </Text>
                    </VStack>
                  </VStack>
                )}

                <Text style={DetalleProductoQuodomstyle.textoPrecio}>
                  Precio{" "}
                  {props.preciounitario.toLocaleString("es-AR", {
                    style: "currency",
                    currency: "ARS",
                  })}
                </Text>

                {props.cantcalificaciones !== 0 ? (
                  <TouchableOpacity
                    onPress={() => AbrirModal(props.usuarioVendedor)}
                  >
                    <HStack
                      alignItems="center"
                      justifyItems="center"
                      pt="1"
                      pb="1"
                      space={0}
                      borderWidth={0}
                    >
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={DetalleProductoQuodomstyle.textoTituloCotizado2}
                      >
                        Calificación{" "}
                      </Text>
                      <Rating
                        showRating={false}
                        style={{ paddingTop: 2 }}
                        tintColor={obtenerEstilo(props.seleccionComprador)}
                        ratingCount={5}
                        imageSize={12}
                        isDisabled={true}
                        startingValue={props.calificacion}
                        readonly={true}
                      />
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={DetalleProductoQuodomstyle.textoTituloCotizado2}
                      >
                        {" "}
                        {props.calificacion}{" "}
                      </Text>
                      <Ionicons
                        name="information-circle-outline"
                        size={17}
                        color="#fff"
                      />
                    </HStack>
                  </TouchableOpacity>
                ) : (
                  <HStack alignItems="center" justifyItems="center" space={0}>
                    <Text
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={DetalleProductoQuodomstyle.textoTituloCotizado2}
                    >
                      Sin calificaciónes aún.
                    </Text>
                  </HStack>
                )}
              </VStack>
            </HStack>
          </Box>

          <Box w="45%" bg="#fff" justifyContent="center">
            <VStack alignItems="center" space={1} pl="1" borderWidth={0}>
              <HStack w="95%" pb="1">
                <Text
                  style={{ fontSize: 13, fontFamily: "Montserrat_600SemiBold" }}
                >
                  Caracteristicas
                </Text>
              </HStack>

              <HStack w="95%">
                <Text
                  style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
                >
                  Cantidad:
                  <Text
                    style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}
                  >
                    {" "}
                    {props.cantidad}
                  </Text>
                </Text>
              </HStack>

              {props.atributo1 !== null ? (
                <HStack w="95%">
                  <Text
                    textAlign="center"
                    style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
                  >
                    {capitalize(props.nombreAtributo1)}:{" "}
                    <Text
                      style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}
                    >
                      {props.atributo1}
                    </Text>
                  </Text>
                </HStack>
              ) : null}

              {props.atributo2 !== null ? (
                <HStack w="95%">
                  <Text
                    textAlign="center"
                    style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
                  >
                    {capitalize(props.nombreAtributo2)}:{" "}
                    <Text
                      style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}
                    >
                      {props.atributo2}
                    </Text>
                  </Text>
                </HStack>
              ) : null}

              <HStack w="95%">
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  textAlign="center"
                  style={{ fontSize: 12, fontFamily: "Montserrat_300Light" }}
                >
                  Fecha entrega:{" "}
                  <Text
                    style={{ fontSize: 12, fontFamily: "Prompt_400Regular" }}
                  >
                    {CortarFecha(props.fechaentrega)}
                  </Text>
                </Text>
              </HStack>
            </VStack>
          </Box>

          <Box
            w="5%"
            bg="#fff"
            roundedTopRight={8}
            roundedBottomRight={8}
            justifyContent="center"
          >
            <VStack alignItems="center" borderWidth={0} pr="3">
              <Checkbox
                isChecked={props.seleccionComprador}
                //isChecked={true}
                aria-label="Seleccionar Cotizacion"
                colorScheme="green"
                onPress={() => onCheckmarkPress(props.idcotizacionline)}
              ></Checkbox>
            </VStack>
          </Box>
        </HStack>
      </TouchableOpacity>

      {/* MODAL CALIFICACIONES */}
      <Modal
        Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        size="lg"
      >
        <Modal.Content width={wp(95)}>
          {/*   <Modal.CloseButton /> */}
          {/*  <Modal.Header><Text style={QuodomDetalle.TextoTituloModalEliminar}>Caracteristicas</Text></Modal.Header> */}
          <Modal.Body bg={"#fff"}>
            {isLoading ? (
              <Center flex="1" h={hp(18)}>
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            ) : (
              <VStack space={3} bg="#fff">
                <VStack
                  justifyContent="center"
                  backgroundColor="#fff"
                  h="24"
                  borderWidth={0}
                >
                  <HStack
                    alignItems="center"
                    justifyItems="center"
                    space={1}
                    bg="#f2f2f2"
                    rounded={8}
                  >
                    <Text
                      h="20"
                      borderWidth={0}
                      adjustsFontSizeToFit={true}
                      numberOfLines={1}
                      style={StylePerfil.textoRating2}
                    >
                      {" "}
                      {props.calificacion}
                    </Text>
                    <VStack>
                      <Rating
                        fractions={2}
                        showRating={false}
                        tintColor="#f2f2f2"
                        ratingCount={5}
                        imageSize={40}
                        isDisabled={true}
                        startingValue={props.calificacion}
                        readonly={true}
                      />
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={1}
                        style={QuodomDetalle.textoItemNotificacion}
                      >
                        {" "}
                        Promedio entre {props.cantcalificaciones}{" "}
                        calificaciones.
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
                <FlatList
                  data={postCalificaciones}
                  renderItem={({ item, index }) => (
                    <Box
                      borderBottomWidth="1"
                      _dark={{
                        borderColor: "gray.600",
                      }}
                      borderColor="coolGray.200"
                      pl="5"
                      pr="5"
                      py="3"
                      bg="#fff"
                    >
                      <HStack space={2} alignItems="center">
                        {/*  <Ionicons name="information-circle-outline" size={30} color="black" /> */}
                        <VStack w="95%" borderWidth={0}>
                          {/* <HStack justifyContent='space-between'> */}
                          <Text style={QuodomDetalle.textoItemFecha}>
                            {desde(item.createdAt)}
                          </Text>
                          <HStack
                            alignItems="center"
                            justifyItems="center"
                            space={0}
                          >
                            <AirbnbRating
                              showRating={false}
                              tintColor="#fff"
                              ratingCount={5}
                              size={10}
                              isDisabled={true}
                              defaultRating={item.valor}
                              readonly={true}
                            />
                            <Text
                              adjustsFontSizeToFit={true}
                              numberOfLines={1}
                              style={QuodomDetalle.textoItemValor}
                            >
                              {" "}
                              ({item.valor})
                            </Text>
                          </HStack>

                          <Text
                            adjustsFontSizeToFit={true}
                            numberOfLines={2}
                            style={QuodomDetalle.textoItemUbicacionBold}
                          >
                            {item.obs}
                          </Text>
                        </VStack>
                      </HStack>
                    </Box>
                  )}
                  keyExtractor={(item) => item.id.toString()}
                />
              </VStack>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => {
                  setModalVisible(false);
                }}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Cerrar
                </Text>
              </TouchableOpacity>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <Modal
        isOpen={visibleFoto}
        onClose={() => setVisibleFoto(false)}
        size="lg"
      >
        <Modal.Content width={wp(110)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>Foto</Text>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <Center flex="1" h={hp(18)}>
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            ) : (
              <Center>
                <HStack
                  w="100%"
                  bg="#fff"
                  justifyContent="center"
                  roundedBottom={5}
                >
                  <Image
                    source={{
                      uri: "data:image/png;base64," + props.imagen,
                    }}
                    alt="."
                    style={estilosCategorias.ImagenDetalleQuodomGrande}
                    resizeMode="contain"
                  />
                </HStack>
              </Center>
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </View>
  );
};
export default SeleccionDetalleProveedor;
