import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  ScrollView,
  Input,
  FormControl,
  TextArea,
  Checkbox,
  Center,
  Button,
} from "native-base";
import { TouchableOpacity, View, Alert, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ModDireccion,
  ObtenerDatosDireccion,
  ObtenerProvincias,
  ObtenerLocalidad,
  ObtenerZonas,
} from "../../api";
import {
  useNavigation,
  useRoute,
  useIsFocused,
} from "@react-navigation/native";
import { wp, hp, moderateScale } from "../../styles/responsive";
import { Ionicons } from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";

import SelectProvincia from "./SelectProvincia";
import SelectPartido from "./SelectPartido";
import SelectZona from "./SelectZona";

const ModificarDireccion = (params) => {
  const mounted = useRef(true);

  const isFocused = useIsFocused();
  const route = useRoute();
  const { iddir } = route.params !== undefined ? route.params : [""];

  const [isLoading, setisLoading] = useState(true);
  const [isGuardando, setisGuandando] = useState(false);
  const [data, setData] = useState([]);
  const [dataProv, setDataProv] = useState([]);
  const [dataPart, setDataPart] = useState([]);
  const [dataZonas, setDataZonas] = useState([]);
  const [changeOK, setChangeOK] = useState(false);
  const [prov, setProv] = useState();
  const [zona, setZona] = useState("");

  const [defaultCheck, setDefault] = useState(false);

  const navigation = useNavigation();

  const aliasChange = (val) => {
    setData({
      ...data,
      alias: val,
    });
  };

  const cpChange = (val) => {
    setData({
      ...data,
      cp: val,
    });
  };

  const calleChange = (val) => {
    setData({
      ...data,
      calle: val,
    });
  };

  const numeroChange = (val) => {
    setData({
      ...data,
      numero: val,
    });
  };

  const pisoChange = (val) => {
    setData({
      ...data,
      piso: val,
    });
  };

  const obsChange = (val) => {
    setData({
      ...data,
      obs: val,
    });
  };

  function handleSelectedProvincia(e) {
    ////console.log(e);
    setData({
      ...data,
      idprovincia: e,
    });
    setProv(e);
    loadLocalidad(e);
  }

  function handleSelectedPartido(e) {
    ////console.log(e);
    setData({
      ...data,
      idpartido: e,
    });

    loadZonas(e);
  }

  function handleSelectedZona(e) {
    ////console.log(e);
    setData({
      ...data,
      idzona: e,
    });
  }

  const SaveHandle = async () => {
    setisGuandando(true);
    ////console.log('lo que tengo', data)
    setChangeOK(false);
    if (
      data.alias === null ||
      data.cp === null ||
      data.idprovincia === null ||
      data.idpartido === null ||
      data.idzona === null ||
      data.calle === null ||
      data.numero === null
    ) {
      Alert.alert(
        "Atención",
        "Por favor, complete todos los campos obligatorios del formulario."
      );
      setisGuandando(false);
      return;
    }

    const userID = await AsyncStorage.getItem("userID");
    const token = await AsyncStorage.getItem("userToken");

    let userDireccion = {
      iddireccion: iddir,
      userid: userID,
      provincia: data.provincia,
      partido: data.partido,
      localidad: data.localidad,
      direccion:
        data.calle +
        " " +
        data.numero +
        (data.piso !== null && data.piso !== undefined ? " " + data.piso : ""),
      cp: data.cp,
      alias: data.alias,
      default: defaultCheck,
      idprovincia: data.idprovincia,
      idpartido: data.idpartido,
      idzona: data.idzona,
      observaciones: data.obs,
      numero: data.numero,
      piso: data.piso !== null && data.piso !== undefined ? data.piso : null,
      calle: data.calle,
    };
    ////console.log('soy el userDireccion ', userDireccion);

    //Guardo info usuario
    const respuesta = await ModDireccion(token, userDireccion);
    ////console.log('ssoy la RESPUESTA', respuesta);
    //SL res == false es error muestro el message
    if (respuesta !== "") {
      Alert.alert("Error", "No se pudo modificar la direccion.");
      setisGuandando(false);
    } else {
      setChangeOK(true);
      //navigation.goBack();
      setisGuandando(false);
    }
  };

  const loadDireccion = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const dir = await ObtenerDatosDireccion(value, iddir);
    let idpro = dir.idprovincia;

    if (mounted.current) {
      setData(dir);
      loadProvincia();
      loadZonas(idpro);
    }
  };

  const loadProvincia = async () => {
    //const token = await AsyncStorage.getItem('userToken');
    const ProvData = await await ObtenerProvincias();
    ////console.log(atrData);
    if (!ProvData || ProvData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      ProvData.push(generico);
    }
    if (mounted.current) {
      setDataProv(ProvData);
    }
    setisLoading(false);
  };

  const loadLocalidad = async (prov) => {
    //const token = await AsyncStorage.getItem('userToken');
    const LocData = await ObtenerLocalidad(prov, "COMPRADOR");
    if (mounted.current) {
      setDataPart(LocData);
    }
  };

  const loadZonas = async (loc) => {
    //const token = await AsyncStorage.getItem('userToken');
    const ZonasData = await ObtenerZonas(loc);

    /*   if (!ZonasData || ZonasData.length === 0) {
              let generico = {
                  valoratributo: 'No aplica',
                  id: 3210,
              }
              ZonasData.push(generico);
          } */
    if (mounted.current) {
      setDataZonas(ZonasData);
    }
    //setisLoading(false);
  };

  useEffect(() => {
    //console.log("Entre al useEffect ModificarDireccion.js");
    mounted.current = true;

    setisLoading(true);
    loadDireccion();
    //loadProvincia();

    return () => (mounted.current = false);
  }, [isFocused]);

  return (
    <VStack flex="1">
      <HStack
        zIndex={100}
        bg={quodomTheme.COLORS["DEFAULT"]}
        justifyContent="space-between"
        alignItems="center"
        shadow={2}
      >
        <HStack space={4} alignItems="center">
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack space={2} alignItems="center">
              <Ionicons
                name="arrow-back-sharp"
                size={moderateScale(15)}
                color="black"
              />
              <Text style={QuodomDetalle.textoNavigate}>
                Modificar dirección
              </Text>
            </HStack>
          </TouchableOpacity>
        </HStack>
      </HStack>

      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <ScrollView w={wp(100)} backgroundColor="#F2F2F2">
          <VStack style={StylePerfil.infoContainer} space={2}>
            {changeOK ? (
              <HStack
                bg="#2ECC71"
                shadow="2"
                p="3"
                mt="2"
                borderRadius="2"
                space={2}
                justifyContent="center"
                alignItems="center"
                w={wp(94)}
              >
                <Ionicons
                  name="checkmark-circle-outline"
                  size={24}
                  color="#fff"
                />
                <Text style={StylePerfil.textoChangeOK}>
                  La modificacion se realizo con exito!
                </Text>
              </HStack>
            ) : null}

            <VStack
              bg="#fff"
              shadow="2"
              p="3"
              mt="2"
              borderRadius="2"
              space={5}
              justifyContent="flex-start"
              w={wp(94)}
            >
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Alias<Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  mt="-2"
                  pb="0"
                  variant="underlined"
                  style={StylePerfil.textbox2}
                  value={data.alias}
                  onChangeText={(val) => aliasChange(val)}
                />
              </VStack>

              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Código postal
                  <Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  mt="-2"
                  pb="0"
                  variant="underlined"
                  style={StylePerfil.textbox2}
                  value={data.cp}
                  onChangeText={(val) => cpChange(val)}
                />
              </VStack>
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Provincia<Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <select
                  id="ddlprovincia"
                  class="form-select"
                  value={prov}
                  onChange={(e) => handleSelectedProvincia(e.target.value)}
                >
                  <option value="0">Seleccionar</option>
                  {dataProv && dataProv !== undefined
                    ? dataProv.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr.id}>
                            {ctr.provincia}
                          </option>
                        );
                      })
                    : "No hay provincias"}
                </select>
              </VStack>
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Partido<Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <select
                  id="ddlLocalidad"
                  class="form-select"
                  value={loc}
                  onChange={(e) => handleSelectedLocalidad(e.target.value)}
                >
                  <option value="0">Seleccionar</option>
                  {dataLoc && dataLoc !== undefined
                    ? dataLoc.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr.id}>
                            {ctr.nombre}
                          </option>
                        );
                      })
                    : "No hay localidades"}
                </select>
              </VStack>
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Localidad/Barrio
                  <Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <select
                  id="ddlZonas"
                  class="form-select"
                  value={zona}
                  onChange={(e) => handleSelectedZona(e.target.value)}
                >
                  <option value="0">Seleccionar</option>
                  {dataZonas && dataZonas !== undefined
                    ? dataZonas.map((ctr, index) => {
                        return (
                          <option key={index} value={ctr.id}>
                            {ctr.nombrezona}
                          </option>
                        );
                      })
                    : "No hay zonas"}
                </select>
              </VStack>
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Calle / Av.
                  <Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  mt="-2"
                  pb="0"
                  variant="underlined"
                  style={StylePerfil.textbox2}
                  value={data.calle}
                  onChangeText={(val) => calleChange(val)}
                />
              </VStack>

              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Número<Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  mt="-2"
                  pb="0"
                  variant="underlined"
                  style={StylePerfil.textbox2}
                  value={data.numero}
                  onChangeText={(val) => numeroChange(val)}
                />
              </VStack>

              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Piso/Departamento (opcional)
                </Text>
                <Input
                  mt="-2"
                  pb="0"
                  variant="underlined"
                  style={StylePerfil.textbox2}
                  value={data.piso}
                  onChangeText={(val) => pisoChange(val)}
                />
              </VStack>

              {/*   <VStack bg='#fff' shadow="0" p="3" mt="5" borderRadius="1" space={3} justifyContent='flex-start'>
                                    <HStack space={3}>
                                        <Checkbox
                                        aria-label='principal'
                                            colorScheme="purple"
                                            onChange={(isChecked) => {
                                                getchecked(isChecked);
                                            }}
                                            isChecked={defaultCheck}
                                        />
                                        <Text style={StylePerfil.label}>Marcar como principal</Text>
                                    </HStack>
                                </VStack> */}

              <VStack pt="1" justifyContent="flex-start" w={wp(95)}>
                <Text style={StylePerfil.textoTituloObservaciones}>
                  Indicaciones adicionales (Opcional)
                </Text>
              </VStack>
              <VStack mt="-3">
                <Input
                  variant="outline"
                  style={StylePerfil.textboxMulti}
                  value={data.obs}
                  onChangeText={(val) => obsChange(val)}
                  multiline={true}
                />
              </VStack>
            </VStack>
          </VStack>
          <Center h="10"></Center>
        </ScrollView>
      )}

      {!isLoading ? (
        <HStack
          pb="2"
          pl="2"
          pr="2"
          safeAreaBottom
          bg="#fff"
          shadow="3"
          p="3"
          mt="0"
          borderRadius="0"
          space={1}
          alignItem="center"
          justifyContent="center"
        >
          {isGuardando ? (
            <Button
              style={StylePerfil.btnLoginyPass}
              isLoading
              spinnerPlacement="end"
              width="90%"
              rounded="8"
            ></Button>
          ) : (
            <TouchableOpacity
              style={{
                alignItems: "center",
                backgroundColor: "#706F9A",
                padding: 10,
                borderRadius: 5,
                width: "90%",
              }}
              onPress={() => {
                SaveHandle();
              }}
            >
              <Text style={StylePerfil.TextoButtonGenerico}>
                Modificar dirección
              </Text>
            </TouchableOpacity>
          )}
        </HStack>
      ) : null}
    </VStack>
  );
};

export default ModificarDireccion;
