import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons";
import {
  NativeBaseProvider,
  Box,
  Text,
  Heading,
  VStack,
  FormControl,
  Input,
  Link,
  Button,
  Icon,
  IconButton,
  HStack,
  Divider,
  Image,
  Center,
  ScrollView,
  useToast,
} from "native-base";
import { TextInput } from "react-native-paper";

import { StyleSheet, TouchableOpacity } from "react-native";
import { SignUpApi } from "../api";
import {
  QuodomDetalle,
  StyleSigInScreen,
  StylePerfil,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  Montserrat_600SemiBold,
  Montserrat_300Light,
} from "../styles/StyleQuodom";
import quodomTheme from "../constants/Theme";

const SHA256crypt = require("js-sha256");
const auth = require("../config/auth.config");

const SignUpScreen = () => {
  const navigation = useNavigation();
  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
    Montserrat_600SemiBold,
    Montserrat_300Light,
  });

  const [secure, setSecure] = useState(true);
  const [isloading, setisLoading] = useState(false);
  const [passOK, setPassOK] = useState(true);
  const [data, setData] = React.useState({
    Nombre: "",
    Apellido: "",
    Telefono: "",
    Email: "",
    Password: "",
    username: "",
    codArea: "",
    Password2: "",
  });

  //const { signUp } = React.useContext(AuthContext);
  const toast = useToast();
  const id = "id_toast";

  const formatMobileNumber = (text) => {
    var cleaned = ("" + text).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{0})(\d{4})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "", match[2], "", match[3], "-", match[4]].join("");
      return number;
    }
    return text;
  };

  const NombreChange = (val) => {
    setData({
      ...data,
      Nombre: val.trim(),
    });
  };

  const ApellidoChange = (val) => {
    setData({
      ...data,
      Apellido: val.trim(),
    });
  };

  const AreaChange = (val) => {
    setData({
      ...data,
      codArea: val.trim(),
    });
  };

  const TelefonoChange = (val) => {
    setData({
      ...data,
      Telefono: formatMobileNumber(val.trim()),
    });
  };

  const EmailChange = (val) => {
    setData({
      ...data,
      Email: val.trim(),
      username: val.trim(),
    });
  };

  const PasswordChange = (val) => {
    setData({
      ...data,
      Password: val,
    });
  };

  const PasswordChange2 = (val) => {
    ////console.log('Pass1', data.Password, 'Pass2', val);

    if (data.Password !== val) {
      setPassOK(false);
    } else {
      setData({
        ...data,
        Password2: val,
      });
      setPassOK(true);
    }
  };

  const userChange = (val) => {
    setData({
      ...data,
      username: val,
    });
  };

  const SignUpHandle = async () => {
    setisLoading(true);
    if (
      data.Nombre.length == 0 ||
      data.Apellido.length == 0 ||
      data.Telefono.length == 0 ||
      data.Email.length == 0 ||
      data.Password.length == 0 ||
      data.Password2.length == 0
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          variant: "solid",
          status: "warning",
          description: "Por favor, complete todos los campos del formulario.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }

      setisLoading(false);
      return;
    }

    if (!passOK) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Revise que las contraseñas sean iguales.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    let appSign = SHA256crypt.sha256(data.Email + auth.claveprivada);

    let userInfoSign = {
      username: data.username,
      email: data.Email,
      nombre: data.Nombre,
      apellido: data.Apellido,
      dni: "0000000000",
      telefono: data.codArea + " " + data.Telefono,
      password: data.Password,
      appSign: appSign,
      esVendedor: 0,
      codArea: data.codArea,
      ShowSlides: 1,
    };

    const UserSignIn = await SignUpApi(userInfoSign);

    //SL res == false es error muestro el message
    if (!UserSignIn.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: UserSignIn.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }
    /*  let userRegistrado = {
       username: data.Email,
       token: UserSignIn.token
     } */
    setisLoading(false);
    //console.log("USUARIO CREADO");
    ////console.log("USUARIO CREADO");
    navigation.navigate("CuentaCreada", { email: data.Email });
  };
  return (
    <NativeBaseProvider>
      <Center
        safeArea
        flex={1}
        p={2}
        w="100%"
        mx="auto"
        style={styles.screen}
        backgroundColor="#F6EE5D"
      >
        <Center>
          <Image
            source={require("../assets/quodom-logox2.png")}
            alt="Alternate Text"
            size="md"
          />
        </Center>

        <VStack
          pt="5"
          pb="5"
          pl="4"
          pr="4"
          w="40%"
          space={2}
          backgroundColor="#F6EE5D"
          alignContent="center"
        >
          <VStack w="100%" justifyContent="center" alignItems="center">
            <Text pb="2" style={StyleSigInScreen.textIniciarSession}>
              ¡Regístrate!
            </Text>
            <TouchableOpacity
              onPress={() => navigation.navigate("SignUpScreenVendor")}
            >
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={2}
                pb="2"
                style={StyleSigInScreen.textoLoginyPassBoldMons}
              >
                ¿Queres vender?{" "}
                <Text style={StyleSigInScreen.textoLoginyPassBoldMons}>
                  Crear una cuenta empresa
                </Text>
              </Text>
            </TouchableOpacity>
          </VStack>

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Nombre"
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            value={data.Nombre}
            onChangeText={(val) => NombreChange(val)}
            theme={{ fonts: { regular: { fontFamily: "Prompt_300Light" } } }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Apellido"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            value={data.Apellido}
            onChangeText={(val) => ApellidoChange(val)}
            theme={{ fonts: { regular: { fontFamily: "Prompt_300Light" } } }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Cod. Area"
            placeholder="Sin el 0"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            keyboardType="numeric"
            value={data.AreaTelefono}
            onChangeText={(val) => AreaChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
            }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Telefono"
            placeholder="Sin el 15"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            keyboardType="numeric"
            value={data.Telefono}
            onChangeText={(val) => TelefonoChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
            }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            keyboardType="email-address"
            label="E-mail"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            value={data.Email}
            onChangeText={(val) => EmailChange(val)}
            theme={{ fonts: { regular: { fontFamily: "Prompt_300Light" } } }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Contraseña"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            value={data.Password}
            right={
              <TextInput.Icon
                name={secure ? "eye-off" : "eye"}
                color="#706F9A"
                onPress={() => setSecure(!secure)}
              />
            }
            secureTextEntry={secure ? true : false}
            onChangeText={(val) => PasswordChange(val)}
            theme={{ fonts: { regular: { fontFamily: "Prompt_300Light" } } }}
          />

          <TextInput
            style={StylePerfil.TextinputSignUP}
            mode="outlined"
            label="Repetir Contraseña"
            //backgroundColor='#F6EE5D'
            outlineColor="#706F9A"
            activeOutlineColor="#706F9A"
            //value={alias}
            secureTextEntry={secure ? true : false}
            onChangeText={(val) => PasswordChange2(val)}
            theme={{ fonts: { regular: { fontFamily: "Prompt_300Light" } } }}
          />

          {!passOK ? (
            <Text style={StyleSigInScreen.textoErrorPass}>
              Las contraseñas no coinciden.
            </Text>
          ) : null}

          <HStack mt="2" w="100%">
            {isloading ? (
              <Button
                style={StyleSigInScreen.btnLoginyPass}
                isLoading
                spinnerPlacement="end"
                rounded="5"
                width="100%"
                h="60"
              ></Button>
            ) : (
              <Button
                style={StyleSigInScreen.btnLoginyPass}
                rounded="5"
                width="100%"
                h="60"
                onPress={() => {
                  SignUpHandle();
                }}
              >
                <Text style={StyleSigInScreen.btnLoginyPass}>Continuar</Text>
              </Button>
            )}
          </HStack>
          <Text style={StyleSigInScreen.textoLegalesB}>
            Al registrarme, declaro que soy mayor de edad y acepto los Términos
            y condiciones y las Políticas de privacidad de QUODOM
          </Text>

          <HStack>
            <Text style={StyleSigInScreen.textoLegalesB}>
              Protegido por re CAPTCHA -{" "}
            </Text>
            <Link
              style={styles.link}
              fontSize="xs"
              justifyContent="center"
              href="#"
            >
              <Text style={StyleSigInScreen.textoLegalesB}>Privacidad </Text>
            </Link>
            <Text fontSize="xs" style={styles.label} fontWeight={400}>
              {" "}
              -{" "}
            </Text>
            <Link
              style={styles.link}
              fontSize="xs"
              justifyContent="center"
              href="#"
            >
              <Text style={StyleSigInScreen.textoLegalesB}>Condiciones </Text>
            </Link>
          </HStack>
        </VStack>
        <Center h={3}></Center>
      </Center>
    </NativeBaseProvider>
  );
};

export default SignUpScreen;

const styles = StyleSheet.create({
  screen: {
    backgroundColor: "#F6EE5D",
    //backgroundColor: '#FFF',
  },

  label: {
    color: "#45444C",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#706F9A",
    width: "100%",
    color: "#F6EE5D",
  },
  link: {
    color: "#45444C",
  },
});
