import React, { useEffect, useState } from "react";
import { View, TouchableOpacity, ActivityIndicator, Text } from "react-native";
import { useToast, Input, VStack } from "native-base";
import { QuodomDetalle, StyleSigInScreen } from "../../styles/StyleQuodom";

const CambiarNombreQuodom = (props) => {
  const toast = useToast();
  const id = "id_toast";

  const [postData, setpostData] = useState([]);
  const [nombreQuodom, setnombreQuodom] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [isUpdating, setisUpdating] = useState(false);

  const NombreChange = (val) => {
    setnombreQuodom(val);
  };
  const Cambio = () => {
    props.cambiarNombre(nombreQuodom);
  };
  useEffect(() => {
    //console.log("propss", props);
    setnombreQuodom(props.nombreQuodom);
  }, []);

  return (
    <VStack>
      <Input
        width="100%"
        style={StyleSigInScreen.textoLoginyPass}
        rounded="8"
        bgColor="#fff"
        onChangeText={(text) => NombreChange(text)}
        value={nombreQuodom}
      />
      <TouchableOpacity
        style={{
          alignItems: "center",
          backgroundColor: "transparent",
          padding: 10,
          borderRadius: 5,
          borderWidth: 1,
        }}
        onPress={() => Cambio()}
      >
        <Text style={QuodomDetalle.TextoModalButtonOK}>Cambiar</Text>
      </TouchableOpacity>
    </VStack>
  );
};

export default CambiarNombreQuodom;
