import React from "react";
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native";

const LoaderTextoProfile = (props) => {
  return (
    <ContentLoader
      animate={false}
      speed={0.5}
      width={300}
      height={66}
      viewBox="0 0 300 66"
      backgroundColor="#cacace"
      foregroundColor="#fdfdfc"
    >
      <Rect x="4" y="6" rx="2" ry="2" width="291" height="30" />
      <Rect x="64" y="52" rx="2" ry="2" width="170" height="20" />
      {/*   <Rect x="88" y="81" rx="1" ry="1" width="110" height="10" /> */}
    </ContentLoader>
  );
};

export default LoaderTextoProfile;
