import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  RefreshControl,
  TouchableOpacity,
  ActivityIndicator,
  Share,
} from "react-native";
import { ObtenerResumenPago, Pago } from "../../api";
import {
  HStack,
  useToast,
  VStack,
  Center,
  FlatList,
  Divider,
  Button,
  Box,
  Image,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { colorVioleta } from "../../styles/general";
import {
  QuodomDetalle,
  StyleSelectCategoria,
  StylePerfil,
} from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Loader from "../Loader";
import { hp, moderateScale, wp } from "../../styles/responsive";
import ResumenPagoItem from "./ResumenPagoItem";
import NumberFormat from "react-number-format";
import { Modal } from "react-native-paper";

const ResumenPago = (props) => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);

  const toast = useToast();
  const id_toast = "id_toast";

  const route = useRoute();
  const navigation = useNavigation();
  const { idquodom, nombreQuodom, vengode } =
    route.params !== undefined ? route.params : ["", "", ""];

  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingAdentro, setisLoadingAdentro] = useState(false);
  const [isLinkGenerado, setisLinkGenerado] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [url, setUrl] = useState();
  const [textoCompra, settextoCompra] = useState("");
  const [visible, setVisible] = useState(false);
  const [isExtended, setIsExtended] = useState(true);
  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    setIsExtended(currentScrollPosition <= 0);
  };
  const LeerDatos = async () => {
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerResumenPago(tokenLeer, idquodom);

    if (mounted.current) {
      setPosts(postsData);
      ////console.log(postsData);

      let textoProducto = "";
      let textoProveedores = "";

      if (parseInt(postsData.cantProductos) > 1) {
        textoProducto = "productos";
      } else {
        textoProducto = "producto";
      }

      if (parseInt(postsData.cantProveedores) > 1) {
        textoProveedores = "proveedores";
      } else {
        textoProveedores = "proveedor";
      }
      settextoCompra(
        "Elegiste " +
          postsData.cantProductos +
          " " +
          textoProducto +
          " de tu Quodom"
      );

      setisLoading(false);
      setisLoadingAdentro(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect ResumenPago.js");
    LeerDatos();
    //DatosDireccion();
    return () => (mounted.current = false);
  }, [isFocused, refreshing]);

  const containerStyle = {
    backgroundColor: "transparent",
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 0,
    paddingLeft: "25%",
    paddingRight: "25%",
  };
  const hideModal = () => setVisible(false);

  const GenerarLink = async () => {
    hideModal();
    setisLoadingAdentro(true);
    setIsLink(true);
    const token = await AsyncStorage.getItem("userToken");

    let checkout = {
      idquodom: idquodom,
      nombreQuodom: nombreQuodom,
      importe: posts.precioTotal,
      linkPago: true,
    };

    const pago = await Pago(token, checkout);

    if (pago.result) {
      setUrl(pago.data.url);
      setisLinkGenerado(true);
      setisLoadingAdentro(false);
    } else {
      toast.closeAll();
      ////console.log(pago.data);
      setisLoadingAdentro(false);
      toast.show({
        id_toast,
        placement: "bottom",
        render: () => {
          return (
            <Box bg="red.500" p="5" rounded="sm" mb={5}>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={QuodomDetalle.TextoToastTitulo}
              >
                Atención
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoToast}
              >
                {pago.message}
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoToast}
              >
                Vuelva a ingresar nuevamente.
              </Text>
            </Box>
          );
        },
      });
    }
  };

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: url,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
        } else {
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const Finalizar = async () => {
    hideModal();
    setisLoadingAdentro(true);
    setIsLink(false);
    const token = await AsyncStorage.getItem("userToken");

    let checkout = {
      idquodom: idquodom,
      nombreQuodom: nombreQuodom,
      importe: posts.precioTotal,
    };

    const pago = await Pago(token, checkout);
    ////console.log("-----------PAGO", pago);
    if (pago.result) {
      if (pago.message === "APROBADO") {
        ////console.log("El pago ya esta APROBADO", pago);
        //navigation.navigate('WebMobbex', { idquodom: idquodom, nombreQuodom: nombreQuodom, url: pago.data.mobbex_url, tokenId: pago.data.mobbex_id, vengode: vengode })
        navigation.navigate("ResumenQuodomFinalizado", {
          idquodom: idquodom,
          nombreQuodom: nombreQuodom,
          nro: pago.nro,
          iddireccion: pago.iddireccion,
          fechapago: pago.fechapago,
        });

        /*   if (pago.message === 'CREADO') {
                //console.log('soy el res GUARDADO', pago.data.mobbex_url, pago.data.mobbex_id);
                navigation.navigate('WebMobbex', { idquodom: idquodom, nombreQuodom: nombreQuodom, url: pago.data.mobbex_url, tokenId: pago.data.mobbex_id, vengode: vengode }) */
      } else {
        ////console.log("soy el res NO GUARDADO", pago.data.url, pago.data.id);
        navigation.navigate("WebMobbex", {
          idquodom: idquodom,
          nombreQuodom: nombreQuodom,
          url: pago.data.url,
          tokenId: pago.data.id,
          vengode: vengode,
        });
      }
    } else {
      toast.closeAll();
      ////console.log(pago.data);
      setisLoadingAdentro(false);
      toast.show({
        id_toast,
        placement: "bottom",
        render: () => {
          return (
            <Box bg="red.500" p="5" rounded="sm" mb={5}>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={1}
                style={QuodomDetalle.TextoToastTitulo}
              >
                Atención
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoToast}
              >
                {pago.message}
              </Text>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={3}
                style={QuodomDetalle.TextoToast}
              >
                Vuelva a ingresar nuevamente.
              </Text>
            </Box>
          );
        },
      });
    }
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: colorVioleta, marginBottom: 0 }}>
        <View
          style={{
            Flex: 1,
            zIndex: 100,
            position: "absolute",
            top: 10,
            bottom: 0,
            width: "100%",
            borderWidth: 0,
            backgroundColor: colorVioleta,
          }}
        >
          <VStack>
            <Center
              style={{
                backgroundColor: colorVioleta,
              }}
            >
              <HStack width={"60%"} paddingTop={"2%"} paddingBottom={"1%"}>
                <HStack space={4} alignItems="center">
                  <TouchableOpacity onPress={() => navigation.goBack()}>
                    <HStack space={2} alignItems="center" pt="3">
                      <Ionicons
                        name="arrow-back-sharp"
                        size={25}
                        color="white"
                      />
                      <Text style={QuodomDetalle.textoTituloChicoFinalizado}>
                        Revisa y Paga
                      </Text>
                    </HStack>
                  </TouchableOpacity>
                </HStack>
              </HStack>
              <HStack width={"60%"}>
                <Text style={QuodomDetalle.textoTituloFinalizadoQ}>
                  {nombreQuodom}
                </Text>
              </HStack>
              <HStack width={"60%"}>
                {isLinkGenerado ? (
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: colorVioleta,
                      alignContent: "center",
                      padding: 10,
                    }}
                  >
                    <VStack pt="10" alignItems="center" justifyItems="center">
                      <VStack space={4} pb="24">
                        <Text
                          adjustsFontSizeToFit={true}
                          numberOfLines={1}
                          style={QuodomDetalle.textoTituloGrandeFinalizado}
                        >
                          Link de pago generado correctamente.
                        </Text>

                        <Box bg="#fff" rounded={8} p="2">
                          <HStack
                            h="12"
                            alignItems="center"
                            justifyItems="center"
                            alignContent="center"
                          >
                            <Text
                              adjustsFontSizeToFit={true}
                              numberOfLines={1}
                              style={QuodomDetalle.TextoModalResumenBold2}
                            >
                              {url}
                            </Text>
                          </HStack>
                        </Box>
                      </VStack>
                      <VStack
                        w="100%"
                        alignItems="center"
                        justifyItems="center"
                        space={1}
                      >
                        <TouchableOpacity
                          style={{
                            alignItems: "center",
                            backgroundColor: "#706F9A",
                            padding: 15,
                            borderRadius: 5,
                            width: "100%",
                            borderColor: "#fff",
                            borderWidth: 1,
                          }}
                          onPress={onShare}
                        >
                          <Text style={StylePerfil.TextoButtonGenerico}>
                            Compartir link de pago
                          </Text>
                        </TouchableOpacity>
                        <Text
                          w="80%"
                          adjustsFontSizeToFit={true}
                          numberOfLines={3}
                          style={QuodomDetalle.TextoRedireccion}
                        >
                          Recuerde que este link tiene una validez de 4 horas,
                          luego de ese tiempo quedara anulado y debera generar
                          uno nuevo.
                        </Text>
                      </VStack>
                    </VStack>
                  </View>
                ) : (
                  <>
                    {isLoadingAdentro ? (
                      <View
                        style={{
                          flex: 1,
                          backgroundColor: colorVioleta,
                          alignContent: "center",
                          padding: 10,
                        }}
                      >
                        <Center flex="1">
                          <ActivityIndicator size="large" color="#fff" />
                          <VStack
                            alignItems="center"
                            justifyContent="center"
                            p="3"
                            space={2}
                          >
                            {isLink ? (
                              <>
                                <Text
                                  adjustsFontSizeToFit={true}
                                  numberOfLines={1}
                                  style={QuodomDetalle.TextoModalSuccess}
                                >
                                  Generando link de pago...
                                </Text>
                                <Text
                                  adjustsFontSizeToFit={true}
                                  numberOfLines={2}
                                  style={QuodomDetalle.TextoRedireccion}
                                >
                                  Aguarde unos segundos.
                                </Text>
                              </>
                            ) : (
                              <>
                                <Text style={QuodomDetalle.TextoModalSuccess}>
                                  Creando Pago...
                                </Text>
                                <Text
                                  adjustsFontSizeToFit={true}
                                  numberOfLines={3}
                                  style={QuodomDetalle.TextoRedireccion}
                                >
                                  Aguarde unos segundos y sera direccionado a la
                                  plataforma de pago.
                                </Text>
                              </>
                            )}
                          </VStack>
                        </Center>
                      </View>
                    ) : (
                      <FlatList
                        onScroll={onScroll}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        data={posts.rows}
                        ListHeaderComponent={({ index }) => (
                          <Center>
                            <VStack mt={2}>
                              <Text
                                style={
                                  QuodomDetalle.textoTituloGrandeFinalizado
                                }
                              >
                                Revisa tus productos
                              </Text>
                            </VStack>

                            <VStack mt={2}>
                              <Text style={QuodomDetalle.textoChicoFinalizado2}>
                                {textoCompra}
                              </Text>
                            </VStack>
                          </Center>
                        )}
                        renderItem={({ item, index }) => (
                          <Box
                            borderRadius={8}
                            width="100%"
                            shadow={2}
                            shadowRadius={8}
                            marginTop={3}
                            backgroundColor="#fff"
                            zIndex={1}
                            p="2"
                          >
                            <ResumenPagoItem
                              index={index}
                              nombreProducto={item.nombreProducto}
                              preciounitario={item.preciounitario}
                              envio={item.envio}
                              cantidad={item.cantidad}
                            />
                          </Box>
                        )}
                        keyExtractor={(item) => Math.random().toString()}
                      />
                    )}
                  </>
                )}
              </HStack>
              <HStack width={"60%"}>
                <VStack
                  borderWidth={0}
                  pb="5"
                  pl="5"
                  pr="5"
                  w="100%"
                  safeAreaBottom
                  bg="#fff"
                  shadow="3"
                  pt="5"
                  mt="2"
                  borderRadius="0"
                  space={0}
                  justifyContent="center"
                >
                  <HStack
                    space={0}
                    justifyContent="space-between"
                    alignItems="center"
                    borderWidth={0}
                    w="100%"
                  >
                    <VStack
                      w="55%"
                      space={2}
                      justifyContent="flex-start"
                      borderWidth={0}
                    >
                      <NumberFormat
                        value={posts.precioTotal}
                        displayType="text"
                        thousandSeparator={"."}
                        decimalScale={2}
                        decimalSeparator={","}
                        prefix="$"
                        renderText={(value) => (
                          <Text style={QuodomDetalle.textoPrecioTotal2}>
                            Total: {value}
                          </Text>
                        )}
                      />

                      {/*  <HStack w='32' space={0} bg='#fff' justifyContent='center' alignItems='center' style={{ textAlign: 'center', padding: 5, borderWidth: 1, borderColor: '#4D4D4D', shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5, display: 'none' }}>
                                        <Text style={{ color: '#4D4D4D', fontSize: 12, fontFamily: 'Prompt_300Light' }}>!Tengo un cupón!</Text>
                                    </HStack> */}
                    </VStack>
                    <Divider
                      bg="#4E4D4D"
                      thickness="1"
                      mx="2"
                      orientation="vertical"
                    />
                    <VStack w="40%" borderWidth={0} alignItems="flex-end">
                      <TouchableOpacity onPress={() => setVisible(true)}>
                        <HStack
                          w="32"
                          space={0}
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            backgroundColor: "#68677C",
                            textAlign: "center",
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 15,
                            paddingRight: 15,
                            borderWidth: 0,
                            borderRadius: 5,
                          }}
                        >
                          {/* <MaterialCommunityIcons name="credit-card-check-outline" size={18} color="#000"></MaterialCommunityIcons> */}
                          <Text
                            style={{
                              color: "#fff",
                              fontSize: moderateScale(8),
                              fontFamily: "Prompt_400Regular",
                            }}
                          >
                            Continuar
                          </Text>
                        </HStack>
                      </TouchableOpacity>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>
            </Center>
          </VStack>
          <Modal
            visible={visible}
            onDismiss={hideModal}
            contentContainerStyle={containerStyle}
          >
            <HStack
              pl={5}
              pr={5}
              pt={4}
              pb={4}
              w="100%"
              bg="#706F9A"
              justifyContent="space-between"
              roundedTop={5}
            >
              <VStack space={1}>
                <Text style={StyleSelectCategoria.TituloModal}>
                  Seleccione una opcion
                </Text>
              </VStack>
              <TouchableOpacity onPress={hideModal}>
                <VStack alignItems="flex-start" borderWidth={0}>
                  <Ionicons name="close-outline" size={25} color="#fff" />
                </VStack>
              </TouchableOpacity>
            </HStack>
            <VStack
              bg="#fff"
              p="10"
              roundedBottom={5}
              justifyContent="center"
              alignItems="center"
              space={5}
              borderWidth="0"
            >
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 15,
                  borderRadius: 5,
                  width: "90%",
                }}
                onPress={() => {
                  Finalizar();
                }}
              >
                <Text style={StylePerfil.TextoButtonGenerico}>Pagar ahora</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 15,
                  borderRadius: 5,
                  width: "90%",
                }}
                onPress={() => {
                  GenerarLink();
                }}
              >
                <Text style={StylePerfil.TextoButtonGenerico}>
                  Generar link de pago
                </Text>
              </TouchableOpacity>
            </VStack>
          </Modal>
        </View>
      </View>
    );
  }
};
export default () => {
  return <ResumenPago />;
};
