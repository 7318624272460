import React from "react";

import { createStackNavigator } from "@react-navigation/stack";

import SplashScreen from "./SplashScreen";
import SignInScreen from "./SignInScreen";
import SignUpScreen from "./SignUpScreen";
import SignUpScreenVendor from "./SignUpScreenVendor";
import ForgotPassword from "./ForgotPassword";
import ElegirCuenta from "./ElegirCuenta";
import CuentaCreada from "./CuentaCreada";

const RootStack = createStackNavigator();

const RootStackScreen = () => (
  <RootStack.Navigator screenOptions={{ headerShown: false }}>
    <RootStack.Screen name="SignInScreen" component={SignInScreen} />
    <RootStack.Screen name="SignUpScreen" component={SignUpScreen} />
    <RootStack.Screen
      name="SignUpScreenVendor"
      component={SignUpScreenVendor}
    />
    <RootStack.Screen name="ForgotPassword" component={ForgotPassword} />
    <RootStack.Screen name="ElegirCuenta" component={ElegirCuenta} />
    <RootStack.Screen name="CuentaCreada" component={CuentaCreada} />
  </RootStack.Navigator>
);

//export default RootStackScreen;

export default () => {
  return <RootStackScreen />;
};
