import React from "react";

import { Select, VStack, CheckIcon, Center } from "native-base";
import { StyleSelectCategoria } from "../../styles/StyleQuodom";

const SelectDireccionesWizard = (props) => {
  ////console.log('los PROPS de selectDirecciones', props)
  let [service, setService] = React.useState(parseInt(props.valor));

  function cortarDireccion(texto) {
    if (texto.length > 37) {
      return texto.slice(0, 37) + " ...";
    } else {
      return texto;
    }
  }

  function onSelected(selected) {
    //console.log(selected.value, selected.label)
    setService(selected);
    props.valorSeleccionado(selected);
  }

  return (
    <VStack style={{ alignItems: "flex-start" }} borderWidth={0}>
      <Select
        selectedValue={service}
        w="100%"
        bg="#fff"
        style={StyleSelectCategoria.defaultCategoria}
        accessibilityLabel="1"
        placeholder="Seleccionar"
        _selectedItem={{
          bg: "#706F9A",
          endIcon: <CheckIcon size="5" />,
        }}
        onValueChange={(key) => onSelected(key)}
      >
        {props.data.map((x, idx) => {
          return (
            <Select.Item
              label={
                x.id === 0
                  ? "Nueva Dirección"
                  : cortarDireccion(
                      x.alias + " - " + x.direccion + ", " + x.localidad
                    )
              }
              value={x.id}
              key={idx}
            />
          );
        })}
      </Select>
    </VStack>
  );
};

export default SelectDireccionesWizard;
