import React, { useEffect, useState, useRef } from "react";
import { View, Text, TouchableOpacity, Linking } from "react-native";
import { StylePerfil, QuodomDetalle } from "../../styles/StyleQuodom";
import { basico } from "../../styles/general";
import { useNavigation } from "@react-navigation/native";
import quodomTheme from "../../constants/Theme";
import { hp, wp, moderateScale } from "../../styles/responsive";

import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import {
  Center,
  HStack,
  Stack,
  VStack,
  FlatList,
  Divider,
  Switch,
  NativeBaseProvider,
  ScrollView,
  Box,
} from "native-base";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader, { Rect } from "react-content-loader/native";
import Moment from "moment";
//import { List } from 'react-native-paper';
import Accordion from "react-native-collapsible/Accordion";

const FAQ = () => {
  // const isFocused = useIsFocused();
  //  const mounted = useRef(true);
  //const isCancelled = useRef(false);
  const navigation = useNavigation();
  const [activeSections, setactiveSections] = useState([]);
  const dataArray = [
    {
      title: "¿Qué costo tiene comprar en Quodom?",
      content:
        "En Quodom podes comprar sin costo adicional. Los precios de los productos serán fijados por cada vendedor.",
    },
    {
      title: "¿En qué zonas se puede comprar?",
      content:
        "Con Quodom podés comprar en los partidos de San Isidro, Vicente Lopez y Gral. San Martín",
    },
    {
      title: "¿En qué zonas se puede vender?",
      content:
        "Con Quodom podes vender desde CABA y en los partidos de: Gral. San Martín, Vicente López, San Isidro, Escobar, Hurlingham, Ituzaingó, José C. Paz, Malvinas Argentinas, Moreno, Morón, Pilar, San Miguel, y Tres de Febrero.",
    },
    {
      title: "¿Quién cotiza mis productos?",
      content:
        "Tus productos serán cotizados por proveedores registrados en Quodom",
    },
    {
      title: "¿Cómo pido cotizaciones de mis productos?",
      content:
        "Para pedir cotizaciones logueate como comprador, seleccioná los productos y completá todos los datos solicitados.",
    },
    {
      title: "¿Cuándo vence mi pedido de cotización?",
      content:
        "Los pedidos de cotización vencen a las 72 hs. Los proveedores deberán cotizar los productos dentro de ese plazo.",
    },
    {
      title: "¿Cómo y cuándo recibo mis productos?",
      content:
        "Con la cotización de cada producto vas a conocer las formas y plazos de entrega de cada proveedor que puede ser: entrega a domicilio o retiro en sucursal. Los vendedores a la hora de cotizar tus productos fijarán la fecha de entrega o retiro de los productos. ",
    },
    {
      title: "¿Qué productos puedo comprar y/o vender?",
      content:
        "Podes comprar y vender los productos ofrecidos por Quodom. Si queres agregar un producto contactate con productos@quodom.com.ar",
    },
    {
      title: "¿Qué métodos de pago puedo usar?",
      content:
        "Las compras pueden abonarse con todas las tarjetas de crédito/débito",
    },
    {
      title: "¿Cómo recibo las facturas de mis compras?",
      content:
        "Una vez finalizada la compra podrás ver la opción “Ver Factura”, en caso de que los vendedores no hayan cargado la factura en Quodom podés solicitarlas a tus proveedores y coordinar la forma de envío. ",
    },
    {
      title: "No recibí mis productos en la fecha acordada",
      content:
        "En caso de que no recibas tus productos en la fecha acordada podés realizar el reclamo con el vendedor. Si necesitás ayuda, queremos ayudarte, envianos tu inquietud a soporte@quodom.com.ar y nos pondremos en contacto lo antes posible para brindarte una solución.",
    },
    {
      title: "Recibí un producto no solicitado o dañado",
      content:
        "En caso de que recibas productos no solicitados o dañado podés realizar el reclamo con el vendedor. Si necesitás ayuda, queremos ayudarte, envianos tu inquietud a soporte@quodom.com.ar y nos pondremos en contacto lo antes posible para brindarte una solución.",
    },
    {
      title: "¿Necesitas ayuda?",
      content:
        "Si necesitás ayuda, queremos ayudarte, envianos tu inquietud a soporte@quodom.com.ar y nos pondremos en contacto lo antes posible para brindarte una solución.",
    },
  ];

  useEffect(() => {
    ////console.log(dataArray);
  }, []);

  const updateSections = (sections) => {
    ////console.log('activeSections', activeSections)
    setactiveSections(sections.includes(undefined) ? [] : sections);
  };

  const renderContent = (section) => {
    ////console.log('section', section)
    return (
      /*    <HStack pr='5' pl='5' pt='1' mb='4' >
                   <Text style={QuodomDetalle.textoItemNotificacion}>{section.content}</Text>
               </HStack> */
      <HStack w="100%" pl="2" pr="2">
        <Box
          bg="transparent"
          p="4"
          borderWidth={1}
          borderColor="#2ECC71"
          borderRadius={8}
          w="100%"
        >
          <Text style={QuodomDetalle.textoItemUbicacionBold}>Respuesta:</Text>
          <HStack justifyContent="space-between" alignItems="center">
            <Text style={QuodomDetalle.textoItemNotificacion}>
              {section.content}
            </Text>
          </HStack>
        </Box>
      </HStack>
    );
  };

  const renderHeader = (section) => {
    ////console.log('renderHeader', section)
    return (
      <HStack w="100%" pt="2" p="2">
        <Box bg="transparent" p="4" borderWidth={1} borderRadius={8} w="100%">
          <HStack justifyContent="space-between" alignItems="center">
            <VStack w="95%">
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={2}
                style={StylePerfil.textoTitulo}
              >
                {section.title}
              </Text>
            </VStack>
            <Ionicons
              name="ios-chevron-down-outline"
              size={25}
              color="#45444C"
            />
          </HStack>
        </Box>
      </HStack>
    );
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#FFFFFF" }}>
      <HStack
        bg={quodomTheme.COLORS["DEFAULT"]}
        px={2}
        py={5}
        mb="0"
        justifyContent="space-between"
        alignItems="center"
        shadow={2}
      >
        <VStack space={4} borderWidth={0}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack space={2} alignItems="center">
              <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
              <Text style={QuodomDetalle.textoNavigate}>Ayuda</Text>
            </HStack>
          </TouchableOpacity>
        </VStack>
      </HStack>

      <HStack mt="5" mb="5" justifyContent="center">
        <Text style={QuodomDetalle.textoTitulo}>Preguntas frecuentes</Text>
      </HStack>

      <ScrollView>
        <Accordion
          underlayColor={"#FFFFFF"}
          sections={dataArray}
          activeSections={activeSections}
          //renderSectionTitle={renderSectionTitle}
          renderHeader={renderHeader}
          renderContent={renderContent}
          onChange={updateSections}
        />
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
          }}
          onPress={() => {
            Linking.openURL("mailto:soporte@quodom.com.ar");
          }}
        >
          <Text style={QuodomDetalle.TextoModalButtonCancelar}>
            Contactanos
          </Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  );
};

export default FAQ;
