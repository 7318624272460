import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Box,
  useToast,
  ScrollView,
  FlatList,
  Input,
  Button,
  Divider,
  Link,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerDataUsuario,
  GuardarUsuario,
  ObtenerDireccionesUsuario,
  CheckCuitMobbex,
} from "../../api";

import { wp, hp, moderateScale } from "../../styles/responsive";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";
import AppBar from "../../components/AppBar";
import SelectBancos from "./SelectBancos";

const MobbexScreen = () => {
  const mounted = useRef(true);
  const route = useRoute();

  const isFocused = useIsFocused();
  const [user, setUser] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isGuardando, setisGuandando] = useState(false);
  const navigation = useNavigation();

  const toast = useToast();
  const id = "id_toast";

  const [refreshPage, setRefreshPage] = useState(false);

  const loadProfile = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const data = await ObtenerDataUsuario(value);
    //await AsyncStorage.setItem('esVendedor', data.esVendedor ? 'SI' : 'NO');

    //console.log("loadProfile", data);

    if (mounted.current) {
      setUser(data);
      setisLoading(false);
    }
  };

  const NombreChange = (val) => {
    setUser({
      ...user,
      BANK_Nombre: val,
    });
  };

  const CuitChange = (val) => {
    setUser({
      ...user,
      BANK_CUIT: val,
    });
  };

  function handleSelectedCondFiscal(e) {
    setUser({
      ...user,
      BANK_Banco: e,
    });
  }

  const SaveHandle = async () => {
    setisGuandando(true);
    const token = await AsyncStorage.getItem("userToken");

    if (user.BANK_Nombre == null || user.BANK_CUIT == null) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={2}
                  style={QuodomDetalle.TextoToast}
                >
                  Por favor, complete todos los campos del formulario.
                </Text>
              </Box>
            );
          },
        });
      }
      setisGuandando(false);
      return;
    }

    const UserRes = await GuardarUsuario(token, user);
    let userMobbex = {
      CUIT: user.BANK_CUIT,
    };
    const checkMobbex = await CheckCuitMobbex(token, userMobbex);

    //SL res == false es error muestro el message
    if (!UserRes.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: UserRes.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisGuandando(false);
      return;
    } else {
      if (UserRes.res && !checkMobbex) {
        setRefreshPage(true);
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "Cuenta Fresa",
            variant: "solid",
            description:
              "Se guardaron los datos correctamente pero no se pudo validar Fresa, chequee si su cuenta esta validada.",
            placement: "bottom",
            style: { backgroundColor: "#2ECC71" },
          });
        }
      } else {
        setRefreshPage(true);
        if (!toast.isActive(id)) {
          toast.show({
            id,
            title: "Cuenta Fresa",
            variant: "solid",
            description:
              "Se guardaron los datos correctamente y se valido la cuenta Fresa",
            placement: "bottom",
            style: { backgroundColor: "#2ECC71" },
          });
        }
      }

      setisGuandando(false);
      //route.params.updateScreen();
      return;
    }
  };

  const Actualizar = async () => {
    //setRefreshPage(Math.random().toString());
    setisLoadingDir(true);
    await obtenerDirecciones();
    //console.log("Refresh cambiarState DetalleUsuarioScreen.js");
    setisLoadingDir(false);
  };

  useEffect(() => {
    mounted.current = true;
    loadProfile();
    return () => (mounted.current = false);
  }, [isFocused, refreshPage]);

  return (
    <View style={{ flex: 1 }}>
      <AppBar />
      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <HStack w={wp(100)} style={{ flex: 1 }} backgroundColor="#F2F2F2">
          <VStack style={StylePerfil.infoContainer} space={2}>
            <HStack mt="5" justifyContent="flex-start">
              <Text style={StylePerfil.textoTitulo}>Datos de la cuenta</Text>
            </HStack>

            <VStack
              bg="#fff"
              shadow="2"
              p="3"
              mt="2"
              borderRadius="2"
              space={2}
              justifyContent="flex-start"
              w={wp(50)}
            >
              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  Razón Social
                  <Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  ml="-3"
                  mt="-1"
                  variant="unstyled"
                  focusBorderColor="#706F9A"
                  style={StylePerfil.textbox}
                  value={user.BANK_Nombre}
                  onChangeText={(val) => NombreChange(val)}
                />
              </VStack>

              <Divider mt="-3"></Divider>

              <VStack>
                <Text style={StylePerfil.labelMon13}>
                  CUIT/CUIL
                  <Text style={StylePerfil.labelObligatorio}> *</Text>
                </Text>
                <Input
                  ml="-3"
                  mt="-1"
                  variant="unstyled"
                  focusBorderColor="#706F9A"
                  style={StylePerfil.textbox}
                  value={user.BANK_CUIT}
                  onChangeText={(val) => CuitChange(val)}
                />
              </VStack>

              <Divider mt="-3"></Divider>
              {user.VendedorHabilitado ? (
                <VStack>
                  <Text style={StylePerfil.labelMon13}>
                    Cuenta habilitada para vender
                  </Text>
                  <MaterialIcons
                    name="check-circle"
                    size={moderateScale(10)}
                    color="#706F9A"
                  />
                </VStack>
              ) : (
                <VStack>
                  <Text style={StylePerfil.labelMon13}>
                    No habilitado para vender, validar cuenta
                  </Text>
                  <MaterialIcons
                    name="highlight-remove"
                    size={moderateScale(10)}
                    color="#706F9A"
                  />
                </VStack>
              )}
              <VStack>
                <Link
                  _text={{ color: "#45444C", fontSize: "md" }}
                  justifyContent="center"
                  href="https://fresapagos.com/"
                >
                  Fresapagos.com
                </Link>
              </VStack>
              {user.VendedorHabilitado ? (
                <VStack></VStack>
              ) : (
                <VStack>
                  <Text
                    _text={{ color: "#45444C", fontSize: "md" }}
                    justifyContent="center"
                  >
                    Si ya creaste tu cuenta fresa escribinos a
                    soporte@quodom.com
                  </Text>
                </VStack>
              )}
            </VStack>
            {isLoading ? null : (
              <VStack w={wp(40)} paddingTop={10}>
                {isGuardando ? (
                  <Button
                    style={StylePerfil.btnLoginyPass}
                    isLoading
                    spinnerPlacement="end"
                    width="100%"
                    rounded="8"
                  ></Button>
                ) : (
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      backgroundColor: "#706F9A",
                      padding: 10,
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      SaveHandle();
                    }}
                  >
                    <Text style={StylePerfil.TextoButtonGenerico}>Validar</Text>
                  </TouchableOpacity>
                )}
              </VStack>
            )}
          </VStack>
        </HStack>
      )}
    </View>
  );
};
export default MobbexScreen;
