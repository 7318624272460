import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  Box,
  useToast,
  ScrollView,
  FlatList,
  Input,
  Button,
  Divider,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerDataUsuario,
  GuardarUsuario,
  ObtenerDireccionesUsuario,
} from "../../api";

import { wp, hp, moderateScale } from "../../styles/responsive";
import {
  MaterialIcons,
  Ionicons,
  AntDesign,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { QuodomDetalle, StylePerfil } from "../../styles/StyleQuodom";

import SelectCondicionFiscal from "../../components/SettingsUser/Pickers/SelectCondicionFiscal";

import { TextInputMask } from "react-native-masked-text";
import AppBar from "../../components/AppBar";
const DetalleUsuarioScreen = () => {
  const mounted = useRef(true);
  const route = useRoute();

  const isFocused = useIsFocused();
  const [postData, setpostData] = useState([]);
  const [user, setUser] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingDir, setisLoadingDir] = useState(true);
  const [isGuardando, setisGuandando] = useState(false);
  const navigation = useNavigation();

  const toast = useToast();
  const id = "id_toast";

  const [dataInfo, setDataInfo] = React.useState({
    Nombre: user.nombre,
    Apellido: user.apellido,
    dni: user.dni,
    Cuit: user.cuit,
    codArea: user.codArea,
    Telefono: user.telefono,
    RazonSocial: user.RazonSocial,
    CondicionVendedor: user.CondicionVendedor,
    Fact_RazonSocial: user.Fact_RazonSocial,
    Fact_CUIT: user.Fact_CUIT,
    Fact_Direccion: user.Fact_Direccion,
    Fact_CondicionFiscal: user.Fact_CondicionFiscal,
  });

  const [refreshPage, setRefreshPage] = useState(false);
  const [iddireccionState, setiddireccionState] = useState("");

  const loadProfile = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const data = await ObtenerDataUsuario(value);
    await AsyncStorage.setItem("esVendedor", data.esVendedor ? "SI" : "NO");

    ////console.log('loadProfile', data.esVendedor);

    if (mounted.current) {
      setUser(data);

      setDataInfo({
        Nombre: user.nombre,
        Apellido: user.apellido,
        dni: user.dni,
        Cuit: user.cuit,
        codArea: user.codArea,
        Telefono: user.telefono,
        RazonSocial: user.RazonSocial,
        CondicionVendedor: user.CondicionVendedor,
        Fact_RazonSocial: user.Fact_RazonSocial,
        Fact_CUIT: user.Fact_CUIT,
        Fact_Direccion: user.Fact_Direccion,
        Fact_CondicionFiscal: user.Fact_CondicionFiscal,
      });
      setisLoading(false);
    }
  };

  const obtenerDirecciones = async () => {
    const value = await AsyncStorage.getItem("userToken");
    const direcciones = await ObtenerDireccionesUsuario(value);

    if (mounted.current) {
      setpostData(direcciones);
      setisLoadingDir(false);
    }
  };

  const NombreChange = (val) => {
    setUser({
      ...user,
      nombre: val,
    });
  };

  const ApellidoChange = (val) => {
    setUser({
      ...user,
      apellido: val,
    });
  };

  const CuitChange = (val) => {
    setUser({
      ...user,
      cuit: val,
    });
  };

  const DNIChange = (val) => {
    setUser({
      ...user,
      dni: val,
    });
  };

  const TelefonoChange = (val) => {
    setUser({
      ...user,
      telefono: val,
    });
  };

  const CodareaChange = (val) => {
    setUser({
      ...user,
      codArea: val,
    });
  };

  const Fact_RazonSocialChange = (val) => {
    setUser({
      ...user,
      Fact_RazonSocial: val,
    });
  };

  const Fact_CUITChange = (val) => {
    setUser({
      ...user,
      Fact_CUIT: val,
    });
  };

  const Fact_DireccionChange = (val) => {
    setUser({
      ...user,
      Fact_Direccion: val,
    });
  };

  /* const Fact_CondicionFiscalChange = (val) => {
    setUser({
      ...user,
      Fact_CondicionFiscal: val
    });
  } */

  function handleSelectedCondFiscal(e) {
    setUser({
      ...user,
      CondicionVendedor: e,
    });
  }

  const SaveHandle = async () => {
    setisGuandando(true);
    const token = await AsyncStorage.getItem("userToken");

    /*  let userInfoSign = {
       Nombre: user.Nombre,
       Apellido: user.Apellido,
       dni: user.dni,
       Cuit: user.Cuit,
       Telefono: user.Telefono,
       RazonSocial: user.RazonSocial
     }; */

    if (
      user.nombre == "" ||
      user.apellido == "" ||
      user.dni == "" ||
      user.telefono == ""
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description:
            "Por favor, complete los campos Nombre, Apellido, DNI y Telefono del formulario.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisGuandando(false);
      return;
    }

    const UserRes = await GuardarUsuario(token, user);

    //SL res == false es error muestro el message
    if (!UserRes.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: UserRes.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisGuandando(false);
      return;
    } else {
      setRefreshPage(true);
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "success",
          description: "Se guardaron los datos correctamente",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisGuandando(false);
      route.params.updateScreen();
      return;
    }
  };

  useEffect(() => {
    mounted.current = true;
    loadProfile();
    obtenerDirecciones();

    return () => (mounted.current = false);
  }, [isFocused, refreshPage]);

  return (
    <VStack flex="1" backgroundColor="#F2F2F2">
      <AppBar />
      {isLoading ? (
        <Center flex="1" h={hp(18)}>
          <ActivityIndicator size="large" color="#706F9A" />
        </Center>
      ) : (
        <ScrollView
          nestedScrollEnabled={true}
          //  w={wp(100)}
          //    height={wp(100)}
          backgroundColor="#F2F2F2"
        >
          <VStack style={StylePerfil.infoContainer} space={2}>
            <Text style={StylePerfil.textoTitulo}>Datos de la cuenta</Text>
            <VStack
              bg="#fff"
              shadow="2"
              p="3"
              mt="2"
              borderRadius="2"
              space={3}
              justifyContent="flex-start"
              w={wp(30)}
            >
              <VStack>
                <Text style={StylePerfil.labelMon13}>Usuario</Text>
                <Input
                  ml="-3"
                  mt="-1"
                  variant="unstyled"
                  isDisabled
                  style={StylePerfil.textbox}
                  value={user.username}
                />
              </VStack>

              <Divider mt="-3"></Divider>

              {user.esVendedor ? (
                <View>
                  <VStack>
                    <Text style={StylePerfil.labelMon13}>Razón Social</Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      isDisabled
                      style={StylePerfil.textbox}
                      value={user.RazonSocial}
                    />
                  </VStack>
                  <Divider mt="0"></Divider>
                </View>
              ) : null}

              <VStack>
                <Text style={StylePerfil.label}>Correo electronico</Text>
                <Input
                  ml="-3"
                  mt="-1"
                  variant="unstyled"
                  isDisabled
                  style={StylePerfil.textbox}
                  value={user.email}
                />
              </VStack>

              {/*  <VStack p='1'>
                  <HStack space={1}>
                    <Text style={QuodomDetalle.textoAgregraDireccionPerfil}>Agrega correo alternativo</Text>
                    <MaterialIcons name="keyboard-arrow-right" size={moderateScale(18)} color='#235EFF' />
                  </HStack>
                </VStack> */}
            </VStack>

            <HStack pt="5" justifyContent="flex-start">
              <VStack pt="0" pb="5" ml="3" mr="3" w={wp(30)}>
                <Text style={StylePerfil.textoTitulo}>Datos personales</Text>
                <VStack
                  bg="#fff"
                  shadow="2"
                  p="3"
                  mt="2"
                  borderRadius="2"
                  space={4}
                  justifyContent="flex-start"
                >
                  <VStack>
                    <Text style={StylePerfil.labelMon13}>Nombre</Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      focusBorderColor="#706F9A"
                      style={StylePerfil.textbox}
                      value={user.nombre}
                      onChangeText={(val) => NombreChange(val)}
                    />
                  </VStack>

                  <Divider mt="-3"></Divider>

                  <VStack>
                    <Text style={StylePerfil.labelMon13}>Apellido</Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      focusBorderColor="#706F9A"
                      style={StylePerfil.textbox}
                      value={user.apellido}
                      onChangeText={(val) => ApellidoChange(val)}
                    />
                  </VStack>

                  <Divider mt="-3"></Divider>

                  {user.esVendedor ? (
                    <VStack>
                      <Text style={StylePerfil.labelMon13}>CUIT</Text>
                      <Input
                        ml="-3"
                        mt="-1"
                        variant="unstyled"
                        isDisabled
                        style={StylePerfil.textbox}
                        value={user.cuit}
                        onChangeText={(val) => CuitChange(val)}
                      />
                    </VStack>
                  ) : (
                    <VStack>
                      <Text style={StylePerfil.labelMon13}>DNI</Text>
                      <Input
                        ml="-3"
                        mt="-1"
                        variant="unstyled"
                        isDisabled
                        style={StylePerfil.textbox}
                        value={user.dni}
                        onChangeText={(val) => DniChange(val)}
                      />
                    </VStack>
                  )}

                  <Divider mt="-3"></Divider>

                  <HStack space={1} w="100%" borderWidth={0}>
                    <VStack w="35%">
                      <Text style={StylePerfil.labelMon13}>Cod. Area</Text>
                      <Input
                        ml="-3"
                        mt="-1"
                        variant="unstyled"
                        keyboardType="numeric"
                        style={StylePerfil.textbox}
                        value={user.codArea}
                        onChangeText={(val) => CodareaChange(val)}
                      />
                    </VStack>
                    <VStack w="64%">
                      <Text style={StylePerfil.labelMon13}>Teléfono</Text>
                      <Input
                        ml="-3"
                        mt="-1"
                        variant="unstyled"
                        keyboardType="numeric"
                        style={StylePerfil.textbox}
                        value={user.telefono}
                        onChangeText={(val) => TelefonoChange(val)}
                      />
                    </VStack>
                  </HStack>
                </VStack>
              </VStack>

              <VStack pt="0" pb="5" ml="3" mr="3" w={wp(30)}>
                <Text style={StylePerfil.textoTitulo}>
                  Datos de facturación
                </Text>
                <VStack
                  bg="#fff"
                  shadow="2"
                  p="3"
                  mt="2"
                  borderRadius="2"
                  space={4}
                  justifyContent="flex-start"
                >
                  <VStack>
                    <Text style={StylePerfil.labelMon13}>Condicion Fiscal</Text>
                    <SelectCondicionFiscal
                      condfiscal={user.CondicionVendedor}
                      valorSeleccionado={handleSelectedCondFiscal}
                    />
                  </VStack>

                  <Divider mt="-3"></Divider>

                  <VStack>
                    <Text style={StylePerfil.labelMon13}>
                      Nombre/Razón Social
                    </Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      focusBorderColor="#706F9A"
                      style={StylePerfil.textbox}
                      value={user.Fact_RazonSocial}
                      onChangeText={(val) => Fact_RazonSocialChange(val)}
                    />
                  </VStack>

                  <Divider mt="-3"></Divider>

                  <VStack>
                    <Text style={StylePerfil.labelMon13}>CUIT/CUIL</Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      keyboardType="numeric"
                      focusBorderColor="#706F9A"
                      style={StylePerfil.textbox}
                      value={user.Fact_CUIT}
                      onChangeText={(val) => Fact_CUITChange(val)}
                    />
                  </VStack>

                  <Divider mt="-3"></Divider>

                  <VStack>
                    <Text style={StylePerfil.labelMon13}>Dirección</Text>
                    <Input
                      ml="-3"
                      mt="-1"
                      variant="unstyled"
                      focusBorderColor="#706F9A"
                      style={StylePerfil.textbox}
                      value={user.Fact_Direccion}
                      onChangeText={(val) => Fact_DireccionChange(val)}
                    />
                  </VStack>
                </VStack>
              </VStack>
            </HStack>
          </VStack>
        </ScrollView>
      )}

      {isLoading ? null : (
        <Center>
          <VStack
            pb="2"
            pl="2"
            pr="2"
            safeAreaBottom
            p="3"
            mt="2"
            borderRadius="0"
            space={2}
            justifyContent="flex-start"
            backgroundColor="#F2F2F2"
            w={wp(30)}
          >
            <VStack>
              {isGuardando ? (
                <Button
                  style={StylePerfil.btnLoginyPass}
                  isLoading
                  spinnerPlacement="end"
                  width="100%"
                  rounded="8"
                ></Button>
              ) : (
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => {
                    SaveHandle();
                  }}
                >
                  <Text style={StylePerfil.TextoButtonGenerico}>
                    Actualizar
                  </Text>
                </TouchableOpacity>
              )}
            </VStack>
          </VStack>
        </Center>
      )}
    </VStack>
  );
};

export default DetalleUsuarioScreen;
