import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  BackHandler,
  StyleSheet,
} from "react-native";
import { ObtenerResumenVendido, RepetirQuodom } from "../../api";
import {
  HStack,
  NativeBaseProvider,
  VStack,
  Center,
  FlatList,
  Box,
  Image,
  Modal,
  Button,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { colorVioleta } from "../../styles/general";
import { QuodomDetalle } from "../../styles/StyleQuodom";

import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { Ionicons, FontAwesome5 } from "@expo/vector-icons";
import Loader from "../Loader";
import { hp, wp, moderateScale } from "../../styles/responsive";
import ResumenItem from "./ResumenItem";
import { AnimatedFAB } from "react-native-paper";

const ResumenQuodomFinalizado = (props) => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);
  const route = useRoute();
  const navigation = useNavigation();
  const { idquodom, nombreQuodom, nro, vengode, iddireccion } =
    route.params !== undefined ? route.params : ["", "", "", "", ""];
  ////console.log('route.params', route.params)
  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingAdentro, setisLoadingAdentro] = useState(false);
  const [textoCompra, settextoCompra] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [repitiendo, setrepitiendo] = useState(false);
  const [isExtended, setIsExtended] = useState(true);

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() ResumenQuodomFinalizado.js");
    const token = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerResumenVendido(token, idquodom);
    ////console.log(''postsData)
    if (mounted.current) {
      setPosts(postsData);
      ////console.log('postsData',postsData);
      let textoProducto = "";
      let textoProveedores = "";

      if (parseInt(postsData.cantProductos) > 1) {
        textoProducto = "productos";
      } else {
        textoProducto = "producto";
      }

      if (parseInt(postsData.cantProveedores) > 1) {
        textoProveedores = "proveedores";
      } else {
        textoProveedores = "proveedor";
      }
      settextoCompra(
        "Compraste " +
          postsData.cantProductos +
          " " +
          textoProducto +
          " a " +
          postsData.cantProveedores +
          " " +
          textoProveedores
      );

      setisLoading(false);
      setisLoadingAdentro(false);
    }
  };
  const volver = () => {
    ////console.log('vengo de ', vengode);

    navigation.goBack();

    return true;
  };

  const Repetir = async () => {
    setrepitiendo(true);
    ////console.log('REPETIR');
    const token = await AsyncStorage.getItem("userToken");

    let data = {
      descripcion: nombreQuodom,
      iddireccion: iddireccion,
      estado: "FINALIZADO",
    };

    const res = await RepetirQuodom(token, data, idquodom);

    setShowModal(false);
    route.params.toTop;
    route.params.estado;
    navigation.goBack();
    //SL Se complica mandar al screen por el stack 'goBack()'
    //navigation.navigate('DetalleQuodom',
    //    { idquodom: res.idquodom, nombreQuodom: nombreQuodom, estadoQuodom: 'CREADO', iddireccion: iddireccion,
    //    cantproductos: '', volver: 'home' })
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect ResumenQuodomFinalizado.js");

    LeerDatos();

    const backAction = () => {
      ////console.log('vengo de ', vengode);
      volver();

      return true;
    };

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => {
      backHandler.remove();
      mounted.current = false;
    };
    //DatosDireccion();
  }, [isFocused, refreshing]);

  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 16 };

  const onScroll = ({ nativeEvent }) => {
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;
    setIsExtended(currentScrollPosition <= 0);
  };
  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: colorVioleta, marginBottom: 0 }}>
        <View
          style={{
            Flex: 1,
            zIndex: 100,
            position: "absolute",
            top: 10,
            bottom: 0,
            width: "100%",
            borderWidth: 0,
            backgroundColor: colorVioleta,
          }}
        >
          <VStack>
            <Center
              style={{
                backgroundColor: colorVioleta,
              }}
            >
              <HStack width={"60%"} paddingTop={"2%"} paddingBottom={"1%"}>
                <TouchableOpacity onPress={volver}>
                  <HStack space={2} alignItems="center" pt="3">
                    <Ionicons name="arrow-back-sharp" size={25} color="white" />
                    <Text style={QuodomDetalle.textoTituloChicoFinalizado}>
                      Mis Quodoms
                    </Text>
                  </HStack>
                </TouchableOpacity>
              </HStack>
              <HStack width={"60%"}>
                <Text style={QuodomDetalle.textoTituloFinalizadoQ}>
                  {nombreQuodom}
                </Text>
                <Text style={QuodomDetalle.textoTituloFinalizadoQ2}>{nro}</Text>
                <TouchableOpacity onPress={() => setShowModal(true)}>
                  <HStack
                    space={1}
                    bg="#fff"
                    alignItems="center"
                    style={{
                      backgroundColor: "#F2F2F2",
                      textAlign: "center",
                      padding: 6,
                      borderWidth: 1,
                      borderColor: "#4D4D4D",
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: "#4D4D4D",
                        fontSize: moderateScale(6),
                      }}
                    >
                      Repetir Quodom
                    </Text>
                  </HStack>
                </TouchableOpacity>
              </HStack>
              <HStack
                style={{ paddingLeft: 5, paddingTop: 0 }}
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
              >
                <VStack justifyContent="center">
                  <HStack space={6}>
                    <Center>
                      <Image
                        source={require("../../assets/1.png")}
                        size={63}
                        alt="Enviado"
                      ></Image>
                      <Text style={QuodomDetalle.textoChicoFinalizado}>
                        Enviado
                      </Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/2.png")}
                        size={63}
                        alt="Disponibles"
                      ></Image>
                      <Text style={QuodomDetalle.textoChicoFinalizado}>
                        Disponibles
                      </Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/3.png")}
                        size={63}
                        alt="Elige"
                      ></Image>
                      <Text style={QuodomDetalle.textoChicoFinalizado}>
                        Elegí y comprá
                      </Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/4.png")}
                        size={63}
                        alt="Recibe"
                      ></Image>
                      <Text style={QuodomDetalle.textoChicoFinalizado}>
                        Recibí o retirá
                      </Text>
                    </Center>
                  </HStack>
                </VStack>
              </HStack>

              <HStack
                style={{ paddingLeft: 5, paddingTop: 0 }}
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
              >
                <Center>
                  <VStack mt={2}>
                    <Text style={QuodomDetalle.textoTituloGrandeFinalizado}>
                      ¡Compra realizada!
                    </Text>
                  </VStack>

                  <VStack mt={2}>
                    <Text style={QuodomDetalle.textoChicoFinalizado2}>
                      {textoCompra}
                    </Text>
                  </VStack>
                  <VStack mt={1}>
                    <Text style={QuodomDetalle.textoChicoFinalizado2}>
                      Recibi o retira tus productos.
                    </Text>
                  </VStack>
                </Center>
              </HStack>

              <HStack width={"60%"}>
                {isLoadingAdentro ? (
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: colorFondoGeneral,
                      alignContent: "center",
                    }}
                  >
                    <Center flex="1" mt="20">
                      <ActivityIndicator size="large" color="#706F9A" h="30%" />
                    </Center>
                  </View>
                ) : (
                  <FlatList
                    onScroll={onScroll}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    data={posts.rows}
                    /*    ListHeaderComponent={
                       
                     } */
                    ListFooterComponent={<Center h={hp(20)}></Center>}
                    renderItem={({ item, index }) => (
                      <Box
                        flex={1}
                        borderRadius={8}
                        width="100%"
                        shadow={3}
                        marginTop={3}
                        backgroundColor="#fff"
                        zIndex={1}
                        p="4"
                      >
                        <ResumenItem
                          index={index}
                          idquodom={idquodom}
                          idvendor={item.usuarioVendedor}
                        />
                      </Box>
                    )}
                    keyExtractor={(item) => item.id.toString()}
                  />
                )}
              </HStack>
            </Center>
          </VStack>
          <Modal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            hardwareAccelerated={true}
            statusBarTranslucent={true}
            animationType="none"
            transparent={true}
          >
            <Modal.Content width={wp(95)}>
              {!repitiendo ? (
                <>
                  <Modal.CloseButton />
                  <Modal.Header>
                    <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                      Atención!
                    </Text>
                  </Modal.Header>
                </>
              ) : null}
              <Modal.Body pt="5" pb="5">
                {repitiendo ? (
                  <HStack>
                    <Center flex="1" pt="2">
                      <ActivityIndicator size="large" color="#706F9A" />
                      <Text
                        adjustsFontSizeToFit={true}
                        numberOfLines={2}
                        pt="5"
                        pb="5"
                        style={QuodomDetalle.TextoModalEliminar}
                      >
                        Repetiendo Quodom{" "}
                        <Text style={QuodomDetalle.TextoModalEliminarBold}>
                          {nombreQuodom}
                        </Text>{" "}
                        ...
                      </Text>
                    </Center>
                  </HStack>
                ) : (
                  <HStack>
                    <Text style={QuodomDetalle.TextoModalEliminar}>
                      Esta seguro de repetir el Quodom{" "}
                      <Text style={QuodomDetalle.TextoModalEliminarBold}>
                        {nombreQuodom}
                      </Text>{" "}
                      ?
                    </Text>
                  </HStack>
                )}
              </Modal.Body>
              {!repitiendo ? (
                <Modal.Footer>
                  <Button.Group space={2}>
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: "transparent",
                        padding: 10,
                        borderRadius: 5,
                        borderWidth: 1,
                      }}
                      onPress={() => Repetir()}
                    >
                      <Text style={QuodomDetalle.TextoModalButtonOK}>
                        Repetir
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: "#706F9A",
                        padding: 10,
                        borderRadius: 5,
                      }}
                      onPress={() => {
                        setShowModal(false);
                      }}
                    >
                      <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                        Cancelar
                      </Text>
                    </TouchableOpacity>
                  </Button.Group>
                </Modal.Footer>
              ) : null}
            </Modal.Content>
          </Modal>
        </View>
      </View>
    );
  }
};
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 16,
    right: 16,
    position: "absolute",
  },
});
export default ResumenQuodomFinalizado;
