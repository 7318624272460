import React, { useState, useEffect, useRef } from "react";
import { useNavigation } from "@react-navigation/native";
import {
  NativeBaseProvider,
  Box,
  Text,
  VStack,
  Link,
  Button,
  HStack,
  Image,
  Center,
  ScrollView,
  useToast,
  Icon,
} from "native-base";
//import AsyncStorage from '@react-native-async-storage/async-storage';
import { Ionicons } from "@expo/vector-icons";
import { TextInput } from "react-native-paper";
import { StyleSheet, TouchableOpacity } from "react-native";
import {
  SignUpApi,
  ObtenerProvincias,
  ObtenerLocalidad,
  ObtenerZonas,
  CrearDireccionPublic,
} from "../api";
import {
  StyleSigInScreen,
  StylePerfil,
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
  Montserrat_600SemiBold,
  Montserrat_300Light,
} from "../styles/StyleQuodom";
import quodomTheme from "../constants/Theme";

import SelectProvinciaSignUp from "../components/MisQuodoms/Pickers/SelectProvinciaSignUp";
import SelecLocalidadSignUp from "../components/MisQuodoms/Pickers/SelectLocalidadSignUp";
import SelectZonasSignUp from "../components/MisQuodoms/Pickers/SelectZonasSignUp";

const SHA256crypt = require("js-sha256");
const auth = require("../config/auth.config");

import { AuthContext } from "../components/context";
import { hp, wp } from "../styles/responsive";

const SignUpScreenVendor = () => {
  const mounted = useRef(true);
  const navigation = useNavigation();

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
    Montserrat_600SemiBold,
    Montserrat_300Light,
  });

  const [secure, setSecure] = useState(true);

  const [isloading, setisLoading] = useState(false);
  const [passOK, setPassOK] = useState(true);
  const [addZona, setAddZona] = useState([]);

  const [dataProv, setDataProv] = useState([]);
  const [dataLoc, setDataLoc] = useState([]);
  const [dataZonas, setDataZonas] = useState([]);

  const [prov, setProv] = useState("");
  const [loc, setLoc] = useState();
  const [zona, setZona] = useState();

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect SignUpScreenVendor.js");
    loadProvincia();
    return () => (mounted.current = false);
  }, []);

  const loadProvincia = async () => {
    //const token = await AsyncStorage.getItem('userToken');
    const ProvData = await await ObtenerProvincias();

    if (!ProvData || ProvData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      ProvData.push(generico);
    }
    if (mounted.current) {
      setDataProv(ProvData);
    }
  };

  const loadLocalidad = async (prov) => {
    //const token = await AsyncStorage.getItem('userToken');
    const LocData = await ObtenerLocalidad(prov, "VENDEDOR");
    if (mounted.current) {
      setDataLoc(LocData);
    }
  };

  const loadZonas = async (loc) => {
    //const token = await AsyncStorage.getItem('userToken');
    const ZonasData = await ObtenerZonas(loc);
    if (mounted.current) {
      setDataZonas(ZonasData);
    }
  };

  const [data, setData] = React.useState({
    CUIT: "",
    RazonSocial: "",
    Telefono: "",
    Email: "",
    Password: "",
    username: "",
  });

  //const { signUp } = React.useContext(AuthContext);
  const toast = useToast();
  const id = "id_toast";

  const formatMobileNumber = (text) => {
    var cleaned = ("" + text).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{0})(\d{4})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "", match[2], "", match[3], "-", match[4]].join("");
      return number;
    }
    return text;
  };

  const CUITChange = (val) => {
    setData({
      ...data,
      CUIT: val.trim(),
    });
  };

  const RazonSocialChange = (val) => {
    setData({
      ...data,
      RazonSocial: val.trim(),
    });
  };

  const AreaChange = (val) => {
    setData({
      ...data,
      codArea: val.trim(),
    });
  };

  const TelefonoChange = (val) => {
    setData({
      ...data,
      Telefono: formatMobileNumber(val.trim()),
    });
  };

  const EmailChange = (val) => {
    setData({
      ...data,
      Email: val.trim(),
      username: val.trim(),
    });
  };

  const PasswordChange = (val) => {
    setData({
      ...data,
      Password: val,
    });
  };

  const PasswordChange2 = (val) => {
    ////console.log('Pass1', data.Password, 'Pass2', val);

    if (data.Password !== val) {
      setPassOK(false);
    } else {
      setPassOK(true);
    }
  };

  const CpChange = (e) => {
    setAddZona({
      ...addZona,
      cp: e,
    });
  };

  const CalleChange = (e) => {
    setAddZona({
      ...addZona,
      calle: e,
    });
  };

  const NumeroChange = (e) => {
    setAddZona({
      ...addZona,
      numero: e,
    });
  };

  function handleSelectedProvincia(e) {
    setAddZona({
      ...addZona,
      idprovincia: e,
    });
    setProv(e);
    loadLocalidad(e);
  }

  function handleSelectedLocalidad(e) {
    //console.log('Localidad: ', e)
    setAddZona({
      ...addZona,
      idpartido: e,
    });
    setLoc(e);
    loadZonas(e);
  }

  function handleSelectedZona(e) {
    setAddZona({
      ...addZona,
      idzona: e,
    });
  }

  const SignUpHandle = async () => {
    setisLoading(true);

    if (
      data.CUIT.length == 0 ||
      data.RazonSocial.length == 0 ||
      data.Telefono.length == 0 ||
      data.Email.length == 0 ||
      data.Password.length == 0
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Por favor, complete todos los campos del formulario.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    if (
      addZona.cp.length == 0 ||
      addZona.calle.length == 0 ||
      addZona.numero.length == 0 ||
      addZona.idprovincia.length == 0 ||
      addZona.idprovincia.length == 0 ||
      addZona.idzona.length == 0
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Por favor, complete todos los campos de Dirección.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    if (!passOK) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description: "Revise que las contraseñas sean iguales.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      setisLoading(false);
      return;
    }

    let appSign = SHA256crypt.sha256(data.Email + auth.claveprivada);

    let userInfoSign = {
      username: data.Email,
      cuit: data.CUIT,
      RazonSocial: data.RazonSocial,
      codArea: data.codArea,
      telefono: data.Telefono,
      email: data.Email,
      password: data.Password,
      appSign: appSign,
      esVendedor: 1,
      nombre: data.RazonSocial,
    };

    const UserSignIn = await SignUpApi(userInfoSign);

    //SL res == false es error muestro el message
    if (!UserSignIn.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: UserSignIn.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }

      setisLoading(false);
      return;
    }

    //SL Aca guardo los datos de direccion
    let userDireccion = {
      userid: UserSignIn.id,
      provincia: addZona.provincia,
      partido: addZona.partido,
      localidad: addZona.localidad,
      direccion: addZona.calle + " " + addZona.numero,
      cp: addZona.cp,
      alias: "Principal",
      default: 1,
      idprovincia: addZona.idprovincia,
      idpartido: addZona.idpartido,
      idzona: addZona.idzona,
      numero: addZona.numero,
      calle: addZona.calle,
    };

    ////console.log(userDireccion);
    CrearDireccionPublic(userDireccion);

    setisLoading(false);
    ////console.log('USUARIO CREADO');
    navigation.navigate("CuentaCreada", { email: data.Email });
  };

  return (
    <NativeBaseProvider>
      <Center
        safeArea
        flex={1}
        p={2}
        w="100%"
        mx="auto"
        style={styles.screen}
        backgroundColor="#706F9A"
      >
        <Center>
          <Image
            source={require("../assets/quodom-logox2A.png")}
            alt="Alternate Text"
            size="sm"
          />
        </Center>

        <VStack
          pt="5"
          pb="5"
          pl="5"
          pr="10"
          w="40%"
          space={2}
          backgroundColor="#706F9A"
        >
          <VStack
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            space={0}
          >
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={StyleSigInScreen.textoCrearEmpresa}
            >
              Para vender creá una cuenta empresa
            </Text>
          </VStack>

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="CUIT"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            keyboardType="numeric"
            backgroundColor="#fff"
            value={data.CUIT}
            onChangeText={(val) => CUITChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#ffffff",
                accent: "#ffffff",
                primary: "#ffffff",
                placeholder: "#ffffff",
                background: "#ffffff",
              },
            }}
          />

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Razón Social"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            bg="#fff"
            //autoCapitalize='words'
            //value={data.RazonSocial}
            onChangeText={(val) => RazonSocialChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "#ffffff",
              },
            }}
          />

          <HStack space={1} w="100%" borderWidth={0}>
            <VStack w="35%">
              <TextInput
                style={StylePerfil.TextinputEmpresa}
                mode="outlined"
                label="Cod. Area"
                placeholder="Sin el 0"
                //backgroundColor='#F6EE5D'
                color="#fff"
                outlineColor="#fff"
                activeOutlineColor="#fff"
                selectionColor="#fff"
                keyboardType="numeric"
                value={data.codArea}
                onChangeText={(val) => AreaChange(val)}
                theme={{
                  fonts: { regular: { fontFamily: "Prompt_300Light" } },
                  colors: {
                    text: "#f5f5f5",
                    accent: "#ffffff",
                    primary: "#a3d1ff",
                    placeholder: "#f5f5f5",
                    background: "#ffffff",
                  },
                }}
              />
            </VStack>
            <VStack w="64%">
              <TextInput
                style={StylePerfil.TextinputEmpresa}
                mode="outlined"
                label="Telefono"
                placeholder="Sin el 15"
                //backgroundColor='#F6EE5D'
                color="#fff"
                outlineColor="#fff"
                activeOutlineColor="#fff"
                selectionColor="#fff"
                keyboardType="numeric"
                value={data.Telefono}
                onChangeText={(val) => TelefonoChange(val)}
                theme={{
                  fonts: { regular: { fontFamily: "Prompt_300Light" } },
                  colors: {
                    text: "#f5f5f5",
                    accent: "#ffffff",
                    primary: "#a3d1ff",
                    placeholder: "#f5f5f5",
                    background: "#ffffff",
                  },
                }}
              />
            </VStack>
          </HStack>

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="E-mail"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            keyboardType="email-address"
            value={data.Email}
            autoCapitalize="none"
            onChangeText={(val) => EmailChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />

          {!passOK ? (
            <Text style={StyleSigInScreen.textoErrorPass}>
              Las contraseñas no coinciden.
            </Text>
          ) : null}

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Contraseña"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            right={
              <TextInput.Icon
                name={secure ? "eye-off" : "eye"}
                color="#fff"
                onPress={() => setSecure(!secure)}
              />
            }
            value={data.Password}
            secureTextEntry={secure ? true : false}
            onChangeText={(val) => PasswordChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Repetir Contraseña"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            secureTextEntry={secure ? true : false}
            onChangeText={(val) => PasswordChange2(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />

          <VStack
            mt="2"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            space={0}
          >
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={StyleSigInScreen.textoCrearEmpresa}
            >
              Dirección Comercial
            </Text>

            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={2}
              style={StyleSigInScreen.textoLegendaChica}
            >
              (Debe ser la dirección donde retiran los productos).
            </Text>
          </VStack>

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Codigo Postal"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            //secureTextEntry={true}
            onChangeText={(val) => CpChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />
          <select
            style={{ width: "100%" }}
            id="ddlprovincia"
            class="form-select"
            value={prov}
            onChange={(e) => handleSelectedProvincia(e.target.value)}
          >
            <option value="0">Seleccionar</option>
            {dataProv && dataProv !== undefined
              ? dataProv.map((ctr, index) => {
                  return (
                    <option key={index} value={ctr.id}>
                      {ctr.provincia}
                    </option>
                  );
                })
              : "No hay provincias"}
          </select>

          <select
            style={{ width: "100%" }}
            id="ddlLocalidad"
            class="form-select"
            value={loc}
            onChange={(e) => handleSelectedLocalidad(e.target.value)}
          >
            <option value="0">Seleccionar</option>
            {dataLoc && dataLoc !== undefined
              ? dataLoc.map((ctr, index) => {
                  return (
                    <option key={index} value={ctr.id}>
                      {ctr.nombre}
                    </option>
                  );
                })
              : "No hay localidades"}
          </select>
          <select
            style={{ width: "100%" }}
            id="ddlZonas"
            class="form-select"
            value={zona}
            onChange={(e) => handleSelectedZona(e.target.value)}
          >
            <option value="0">Seleccionar</option>
            {dataZonas && dataZonas !== undefined
              ? dataZonas.map((ctr, index) => {
                  return (
                    <option key={index} value={ctr.id}>
                      {ctr.nombrezona}
                    </option>
                  );
                })
              : "No hay zonas"}
          </select>

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Calle / Av."
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            onChangeText={(val) => CalleChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />

          <TextInput
            style={StylePerfil.TextinputEmpresa}
            mode="outlined"
            label="Numero"
            color="#fff"
            outlineColor="#fff"
            activeOutlineColor="#fff"
            selectionColor="#fff"
            onChangeText={(val) => NumeroChange(val)}
            theme={{
              fonts: { regular: { fontFamily: "Prompt_300Light" } },
              colors: {
                text: "#f5f5f5",
                accent: "#ffffff",
                primary: "#a3d1ff",
                placeholder: "#f5f5f5",
                background: "transparent",
              },
            }}
          />

          <HStack mt="3">
            {isloading ? (
              <Button
                style={StyleSigInScreen.btnLoginyPassA}
                isLoading
                spinnerPlacement="end"
                rounded="5"
                width="100%"
                h="60"
              ></Button>
            ) : (
              <Button
                style={StyleSigInScreen.btnLoginyPassA}
                rounded="5"
                width="100%"
                h="60"
                onPress={() => {
                  SignUpHandle();
                }}
              >
                <Text style={StyleSigInScreen.btnLoginyPassA}>Continuar</Text>
              </Button>
            )}
          </HStack>

          <Text style={StyleSigInScreen.textoLegales}>
            Al registrarme, declaro que soy mayor de edad y acepto los Términos
            y condiciones y las Políticas de privacidad de QUODOM S.A.
          </Text>

          <HStack>
            <Text style={StyleSigInScreen.textoLegales}>
              Protegido por re CAPTCHA -{" "}
            </Text>
            <Link
              style={styles.link}
              fontSize="xs"
              justifyContent="center"
              href="#"
            >
              <Text style={StyleSigInScreen.textoLegales}>Privacidad </Text>
            </Link>
            <Text style={StyleSigInScreen.textoLegales}> - </Text>
            <Link
              style={styles.link}
              fontSize="xs"
              justifyContent="center"
              href="#"
            >
              <Text style={StyleSigInScreen.textoLegales}>Condiciones </Text>
            </Link>
          </HStack>
        </VStack>
        <Center h={hp(5)}></Center>
      </Center>
    </NativeBaseProvider>
  );
};

export default SignUpScreenVendor;

const styles = StyleSheet.create({
  screen: {
    //backgroundColor: '#F6EE5D',
    //backgroundColor: '#FFF',
  },

  label: {
    color: "#fff",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#706F9A",
    width: "100%",
    color: "#F6EE5D",
  },
  link: {
    color: "#45444C",
  },
});
