import * as React from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";

import {
  NativeBaseProvider,
  VStack,
  Text,
  Center,
  HStack,
  Divider,
  Icon,
  IconButton,
} from "native-base";
import { estilosCategorias } from "../../styles/general";

const NodoCategoriaBuscar = (props) => {
  const navigation = useNavigation();

  return (
    <View>
      <NativeBaseProvider>
        <VStack borderWidth={0} alignItems="center">
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("ListaProductos", {
                catNombre: props.titulo,
                catPadre: props.id,
                vengoDelNodo: true,
                txt: null,
                volver: "BUSCAR",
              });
            }}
          >
            <Image
              source={{
                uri: props.imagenSearch,
              }}
              //uri={props.imagen} // image address
              //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
              style={estilosCategorias.logogris}
            />

            <Text style={estilosCategorias.textoiconos}>{props.titulo}</Text>
          </TouchableOpacity>
        </VStack>
      </NativeBaseProvider>
    </View>
  );
};

export default NodoCategoriaBuscar;
