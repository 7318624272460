import React from "react";
import { Select, VStack, CheckIcon, Center } from "native-base";
import { StyleSelectCategoria } from "../../../styles/StyleQuodom";

const SelectZonaWizard = (props) => {
  let [service, setService] = React.useState(props.valor);
  ////console.log('SELETEC', props.valor, service)

  function onSelected(selected) {
    setService(selected);
    props.valorSeleccionado(selected);
  }

  return (
    <VStack style={{ alignItems: "flex-start" }} borderWidth={0}>
      <Select
        w="100%"
        selectedValue={service}
        bg="#fff"
        style={StyleSelectCategoria.defaultCategoria}
        accessibilityLabel="Seleccionar"
        placeholder="Seleccionar"
        _selectedItem={{
          bg: "#706F9A",
          endIcon: <CheckIcon size="5" />,
        }}
        onValueChange={(itemValue) => onSelected(itemValue)}
      >
        {props.data.map((x, idx) => {
          return <Select.Item label={x.nombrezona} value={x.id} key={idx} />;
        })}
      </Select>
    </VStack>
  );
};

export default SelectZonaWizard;
