import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  useToast,
  Image,
  Box,
} from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { View, TouchableOpacity, ActivityIndicator } from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ObtenerCategoriasUsuario, AsociarCategoria } from "../../api";
import CategoriaList from "../../components/SettingsUser/CategoriaList";

import {
  StyleSigInScreen,
  QuodomDetalle,
  StylePerfil,
} from "../../styles/StyleQuodom";
import { wp } from "../../styles/responsive";
import SelectCategoria from "../../components/MisQuodoms/Pickers/SelectCategoria";

const WizardVendedorScreen2 = () => {
  const route = useRoute();

  const mounted = useRef(true);
  ////console.log('DATA', route.params.data);
  const toast = useToast();
  const id = "id_toast";
  const navigation = useNavigation();

  const [addCat, setAddCat] = useState("");
  const [refreshPage, setRefreshPage] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState("Agregar categoría");

  const cantidad = (valor) => {
    if (valor !== 0) {
      setPlaceholder("Agregar más categoría");
    } else {
      setPlaceholder("Agregar categoría");
    }
  };

  function handleSelectedCategoria(e) {
    //console.log(e)
    setAddCat(e);
    handleAddCategoria(e);
  }

  const handleAddCategoria = async (e) => {
    if (e != "") {
      setisLoading(true);
      const token = await AsyncStorage.getItem("userToken");
      let data = { idcategoria: e };
      const respuesta = await AsociarCategoria(token, data);
      if (!respuesta.res) {
        if (!toast.isActive(id)) {
          toast.show({
            id,
            render: () => {
              return (
                <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.TextoToastTitulo}
                  >
                    Algo salió mal
                  </Text>
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={3}
                    style={QuodomDetalle.TextoToast}
                  >
                    respuesta.message
                  </Text>
                </Box>
              );
            },
          });
        }
        setError(true);
      }
      setRefreshPage(refreshPage + 1);
      setAddCat("");
      setisLoading(false);
    }
  };

  const SiguentePaso = async () => {
    toast.closeAll();
    const value = await AsyncStorage.getItem("userToken");
    const data = await ObtenerCategoriasUsuario(value);

    if (data.length === 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          render: () => {
            return (
              <Box bg="gray.500" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Atención
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={3}
                  style={QuodomDetalle.TextoToast}
                >
                  Debe agregar por lo menos una categoria en donde participar.
                </Text>
              </Box>
            );
          },
        });
      }

      return;
    } else {
      navigation.navigate("WizardVendedor3", { addZona: route.params.addZona });
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);

  return (
    <VStack flex={1}>
      <View
        style={{ flex: 1, backgroundColor: "#fff", alignContent: "center" }}
      >
        <HStack
          bg={quodomTheme.COLORS["COLORVIOLETA"]}
          px={2}
          py={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack space={4} alignItems="center">
            <TouchableOpacity onPress={() => navigation.goBack()}>
              <HStack space={2} alignItems="center" pt="3">
                <Ionicons name="arrow-back-sharp" size={25} color="white" />
              </HStack>
            </TouchableOpacity>
          </HStack>
        </HStack>

        <HStack
          bg={quodomTheme.COLORS["COLORVIOLETA"]}
          space={2}
          pl="15"
          pr="15"
          pb="3"
          mb="3"
          justifyContent="space-between"
          alignItems="center"
        >
          <VStack w={wp(90)}>
            <Text
              adjustsFontSizeToFit={true}
              numberOfLines={1}
              style={QuodomDetalle.textoTituloMisCotizacion}
            >
              Completa el wizard y comenza a vender.
            </Text>
            {/*  <Text style={QuodomDetalle.textoTituloMisCotizacion}>Paso 2/3</Text> */}
          </VStack>
        </HStack>

        <Center>
          <VStack justifyContent="center">
            <HStack space={10} alignItems="flex-start">
              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/1completo.png")}
                  size={wp(15)}
                  alt="Enviado"
                ></Image>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.textoChico2}
                >
                  Datos empresa
                </Text>
              </Center>

              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/2activo.png")}
                  size={wp(15)}
                  alt="Disponibles"
                ></Image>
                <Text style={QuodomDetalle.textoChico2Bold}>Categorias</Text>
              </Center>

              <Center borderWidth={0} w="24">
                <Image
                  source={require("../../assets/Wizard/3noactivo.png")}
                  size={wp(15)}
                  alt="Elegí y comprá"
                ></Image>
                <Text style={QuodomDetalle.textoChico2}>Zonas</Text>
              </Center>
            </HStack>
          </VStack>
        </Center>

        {isLoading ? (
          <View
            style={{ flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 0 }}
          >
            <HStack justifyContent="flex-start" mt={5} mb={5}>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={2}
                style={StyleSigInScreen.textoLoginyPass4}
              >
                Seleccioná las categorias en las que quieras participar.
              </Text>
            </HStack>
            <HStack justifyContent="flex-start" mt={5} mb={5}>
              <Center flex="1">
                <ActivityIndicator size="large" color="#706F9A" />
              </Center>
            </HStack>
          </View>
        ) : (
          <View
            style={{ flex: 1, paddingLeft: 15, paddingRight: 15, marginTop: 0 }}
          >
            <HStack justifyContent="flex-start" mt={5} mb={5}>
              <Text
                adjustsFontSizeToFit={true}
                numberOfLines={2}
                style={StyleSigInScreen.textoLoginyPass4}
              >
                Seleccioná las categorias en las que quieras participar.
              </Text>
            </HStack>

            <HStack alignItems="center">
              <SelectCategoria
                onSelectedValue={handleSelectedCategoria}
                placeholder={placeholder}
              />
            </HStack>

            {/*   <HStack justifyContent='flex-start' mt={5}>
                                <Text style={StyleSigInScreen.textoLoginyPass}>Categorias seleccionadas</Text>
                            </HStack> */}

            <CategoriaList
              Actualizar={refreshPage}
              cantidad={cantidad}
            ></CategoriaList>
          </View>
        )}
      </View>

      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="0"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="space-between"
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "40%",
          }}
          onPress={() => {
            navigation.navigate("WizardVendedor1");
          }}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Anterior</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={{
            alignItems: "center",
            backgroundColor: "#706F9A",
            padding: 10,
            borderRadius: 5,
            width: "40%",
          }}
          onPress={() => SiguentePaso()}
        >
          <Text style={StylePerfil.TextoButtonGenerico}>Siguente</Text>
        </TouchableOpacity>
      </HStack>
    </VStack>
  );
};

export default WizardVendedorScreen2;
