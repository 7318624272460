import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  TextInput,
} from "react-native";
import {
  ObtenerDetalleQuodoms,
  ObtenerDatosDireccion,
  ModificarQuodom,
  ObtenerQuodom,
} from "../../api";
import {
  HStack,
  VStack,
  NativeBaseProvider,
  Center,
  FlatList,
  Modal,
  Button,
  Input,
  useToast,
  Box,
  Icon,
  Image,
} from "native-base";
import DetalleProductoQuodom from "./DetalleProductoQuodom";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { basico, colorFondoGeneral, ModalStyle } from "../../styles/general";
import { QuodomDetalle, StyleSigInScreen } from "../../styles/StyleQuodom";
import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import { MaterialIcons, Ionicons, SimpleLineIcons } from "@expo/vector-icons";
import Loader from "../Loader";
import AgregarProducto from "./AgregarProducto";
import AccionesDetalleQuodom from "./AccionesDetalleQuodom";
import { moderateScale, wp } from "../../styles/responsive";
import DireccionEnvioQuodom from "../Direcciones/DireccionEnvioQuodom";
import ListaMisQuodomsInicial from "../../screens/MisQuodoms/ListaMisQuodomsInicial";
import CambiarNombreQuodomControler from "../Direcciones/CambiarNombreQuodom";
const DetalleQuodom = (props) => {
  const isFocused = useIsFocused();
  const mounted = useRef(true);
  const toast = useToast();
  const id = "id_toast";

  const route = useRoute();
  const navigation = useNavigation();

  const [estadoQuodom, setEstadoQuodom] = useState("");
  const [cantproductos, setCantProductos] = useState(0);
  const [iddireccion, setiddireccion] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isExtended, setIsExtended] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [nombreQuodom, setNombreQuodom] = useState("");
  const [idquodom, setidquodomState] = useState("");
  const [NombreQuodomNuevo, setNombreQuodomNuevo] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [sinProductos, setSinProductos] = useState(true);
  const [estadoQuodomState, setestadoQuodomState] = useState("");
  const [posts, setPosts] = useState([]);
  const [postsDireccion, setDireccion] = useState("");
  const [colorAviso, setColorAviso] = useState("");
  const [iddireccionState, setiddireccionState] = useState();
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingAdentro, setisLoadingAdentro] = useState(true);
  const [modalVisibleDire, setModalVisibleDire] = useState(false);
  const [refreshList, setrefreshList] = useState();
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const onRefresh = React.useCallback(async () => {
    await LeerDatos();
  }, []);
  const NombreChange = async (val) => {
    //console.log("va nuevo", val);
    setNombreQuodomNuevo(val);
    await CambiarNombreQuodom(val);
  };
  const CambiarNombreQuodom = async (val) => {
    if (val.length === 0) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          placement: "bottom",
          render: () => {
            return (
              <Box bg="gray.700" p="5" rounded="sm" mb={5}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.TextoToastTitulo}
                >
                  Atención
                </Text>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={2}
                  style={QuodomDetalle.TextoToast}
                >
                  El nombre del Quodom no puede quedar vacio.
                </Text>
              </Box>
            );
          },
        });
      }
      return false;
    }
    setModalVisible(false);
    setNombreQuodom(val);
    const token = await AsyncStorage.getItem("userToken");
    let data = { descripcion: val };
    ModificarQuodom(token, idquodom, data);
    setVisible(true);
    setrefreshList(Math.random().toString());
  };

  function cortarDireccion(texto) {
    if (texto.length > 35) {
      return texto.slice(0, 35) + " ...";
    } else {
      return texto;
    }
  }

  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() DetalleQuodom.js");
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const postsData = await ObtenerDetalleQuodoms(tokenLeer, q.id);
    const Quodom = await ObtenerQuodom(tokenLeer, q.id);
    //console.log("q es esto", postsData);
    setEstadoQuodom(Quodom.estado);
    setNombreQuodom(Quodom.descripcion);
    setCantProductos(Quodom.cantproductos);
    setiddireccion(Quodom.iddireccion);
    if (mounted.current) {
      setNombreQuodomNuevo(Quodom.descripcion);
      if (postsData.length === 0) {
        setSinProductos(false);
      } else {
        setPosts(postsData);
        let itemNuevo = {
          id: "1CREAR",
        };
        postsData.splice(0, 0, itemNuevo);
      }

      if (typeof idquodom !== undefined && idquodom !== null) {
        ////console.log('entre:' + idquodom);
        await AsyncStorage.setItem("UltimoQuodom", idquodom);
        //await AsyncStorage.setItem('UltimoQuodomName', nombreQuodom);
      }
      setisLoading(false);
    }
    setisLoading(false);
  };

  const DatosDireccion = async () => {
    //console.log("Entre al DatosDireccion() DetalleQuodoms.js");

    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const tokenDir = await AsyncStorage.getItem("userToken");
    const Quodom = await ObtenerQuodom(tokenDir, q.id);

    if (Quodom.iddireccion === null) {
      setDireccion("Debe seleccionar una direccion de entrega.");
      setColorAviso("#DC7633");
      setisLoading(false);
      setisLoadingAdentro(false);
      return;
    }

    const datosDireccion = await ObtenerDatosDireccion(
      tokenDir,
      Quodom.iddireccion
    );

    if (mounted.current) {
      if (!datosDireccion) {
        setDireccion("Debe seleccionar una direccion de entrega.");
        setColorAviso("#DC7633");
      } else {
        let direccion = cortarDireccion(
          datosDireccion.alias +
            " " +
            datosDireccion.direccion +
            " " +
            datosDireccion.localidad
        );

        setDireccion("Enviar a " + direccion);

        setColorAviso("#45444C");
        setiddireccionState(datosDireccion.id);
      }
      setisLoadingAdentro(false);
    }

    //SL Espero para que termine el render del item del Flatlist
    let espera = 100;
    if (sinProductos) {
      espera = 10;
    }
    delay(espera).then(() => {
      setisLoadingAdentro(false);
    });
  };

  const cambiarState = () => {
    setisLoadingAdentro(true);
    ////console.log('Refresh cambiarState DetalleQuodom.js')
    onRefresh();
    setisLoadingAdentro(false);
    setrefreshList(Math.random().toString());
  };

  const cambiariddireccion = (newState) => {
    navigation.setParams({
      iddireccion: newState,
    });
    setiddireccionState(newState);
  };

  /* const SetearQuodom = async () => {
    if (props.estado === 'CREADO') {
    }
  } */

  useEffect(() => {
    //console.log("Entre al useEffect DetalleQuodoms.js");
    setisLoadingAdentro(true);
    AsyncStorage.getItem("quodomSeleccionada").then((valor) => {
      const q = JSON.parse(valor);
      //console.log("qq", q);
      setidquodomState(q.id);
    });
    mounted.current = true;

    // if (isFocused) {
    LeerDatos();
    DatosDireccion();
    //  }

    //SetearQuodom();

    if (estadoQuodom != undefined) {
      setestadoQuodomState(estadoQuodom);
    }
    return () => (mounted.current = false);
  }, [isFocused, refreshing, route]);

  const onScroll = ({ nativeEvent }) => {
    ////console.log('isExtended Detalle', isExtended)
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;

    setIsExtended(currentScrollPosition <= 0);
    //return (currentScrollPosition <= 0)
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#F6EE5D",
          color: "#45444C",
        }}
      >
        <HStack flex={1}>
          <TouchableOpacity
            style={{ marginLeft: "10px", marginTop: "10px" }}
            onPress={() => navigation.toggleDrawer()}
          >
            <Icon
              size="8"
              as={<MaterialIcons name="menu" />}
              color={quodomTheme.COLORS["COLORVIOLETA"]}
            />
          </TouchableOpacity>
          <VStack
            flex={0.6}
            marginTop={"3%"}
            borderWidth={0}
            alignItems="center"
          >
            <Box w="100%" mx="auto" backgroundColor="#F6EE5D">
              <Center>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <Image
                    source={require("../../assets/INICIO-16.png")}
                    alt="Quodom."
                    alignItems="center"
                    marginBottom={"2%"}
                    style={{ width: wp(10), height: wp(3) }}
                  />
                </TouchableOpacity>
              </Center>
            </Box>

            <FlatList
              onScroll={onScroll}
              contentContainerStyle={{
                flexGrow: 1,
              }}
              style={{
                backgroundColor: "#F6EE5D",
                marginLeft: "5%",
                marginRight: "4%",
                width: "90%",
              }}
              numColumns="2"
              data={posts}
              ListHeaderComponent={
                <VStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingTop: 5,
                      paddingRight: "6%",
                    }}
                    justifyContent={"space-between"}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <TouchableOpacity onPress={() => setModalVisible(true)}>
                      <VStack justifyContent={"space-between"}>
                        <Text
                          style={{
                            paddingLeft: 10,
                            paddingTop: 5,
                            backgroundColor: colorFondoGeneral,
                          }}
                        >
                          <Text style={QuodomDetalle.textoTitulo}>
                            {nombreQuodom}{" "}
                          </Text>
                          <Ionicons
                            name="create-outline"
                            size={24}
                            color="#706F9A"
                          />
                        </Text>
                      </VStack>
                    </TouchableOpacity>
                    <AccionesDetalleQuodom
                      codigoQuodom={idquodom}
                      nombreQuodom={nombreQuodom}
                      estadoQuodom={estadoQuodom}
                      iddireccion={iddireccionState}
                      cantproductos={cantproductos}
                      isExtended={isExtended}
                    />
                  </HStack>
                  <HStack
                    style={{
                      paddingLeft: "5%",
                      paddingBottom: "2%",

                      paddingRight: "6%",
                    }}
                    overflow="hidden"
                    borderWidth={0}
                  >
                    <TouchableOpacity onPress={() => setModalVisibleDire(true)}>
                      <HStack space={1} alignItems="center">
                        <SimpleLineIcons
                          name="location-pin"
                          size={13}
                          color={colorAviso}
                        />
                        <Text style={QuodomDetalle.textoEnviarA}>
                          {postsDireccion}
                        </Text>
                        <MaterialIcons
                          name="keyboard-arrow-right"
                          size={13}
                          color={colorAviso}
                        />
                      </HStack>
                    </TouchableOpacity>
                  </HStack>
                </VStack>
              }
              ListFooterComponent={null}
              renderItem={({ item }) => (
                <DetalleProductoQuodom
                  detalleProducto={item.detalleProducto}
                  nombreProducto={item.nombreProducto}
                  atributo1={item.atributo1}
                  atributo2={item.atributo2}
                  atributoNombre1={item.nombreAtributo1}
                  atributoNombre2={item.nombreAtributo2}
                  cantidad={item.cantidad}
                  imagen={item.imagen}
                  refreshImagen={item.refreshImagen}
                  atributosFaltantes={item.atributosFaltantes}
                  idproducto={item.idproducto}
                  nombreCategoria={item.nombreCategoria}
                  id={item.id}
                  idquodom={item.idquodom}
                  actualizarestado={cambiarState}
                  cantProveedores={item.cantProveedores}
                  cantEnZona={item.cantEnZona}
                  estado={onRefresh}
                  codigoQuodom={idquodom}
                  nombreQuodom={nombreQuodom}
                ></DetalleProductoQuodom>
              )}
              keyExtractor={(item) => item.id}
            />
          </VStack>

          <VStack flex={0.4}>
            <ListaMisQuodomsInicial
              actualizarLista={refreshList}
            ></ListaMisQuodomsInicial>
          </VStack>
        </HStack>

        <Modal
          isOpen={modalVisibleDire}
          onClose={() => setModalVisibleDire(!modalVisibleDire)}
          useNativeDriver={true}
        >
          <Modal.Content width={"50%"}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                Cambiar dirección
              </Text>
            </Modal.Header>
            <Modal.Body pt="5" pb="5">
              <DireccionEnvioQuodom
                cambiarId={cambiariddireccion}
                iddireccion={iddireccionState}
                idquodom={idquodom}
                actualizarmodal={setModalVisibleDire}
                actualizarpage={setRefreshing}
              ></DireccionEnvioQuodom>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <Modal
          isOpen={modalVisible}
          onClose={() => setModalVisible(!modalVisible)}
          // hardwareAccelerated={true}
          //statusBarTranslucent={true}
          //useNativeDriver={true}
          useNativeDriver={true}
        >
          <Modal.Content width={wp(95)}>
            <Modal.CloseButton />
            <Modal.Header>
              <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                Cambiar nombre del Quodom
              </Text>
            </Modal.Header>
            <Modal.Body pt="5" pb="5">
              <CambiarNombreQuodomControler
                cambiarNombre={NombreChange}
                nombreQuodom={nombreQuodom}
              ></CambiarNombreQuodomControler>
            </Modal.Body>
            <Modal.Footer>
              <Button.Group space={2}>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "#706F9A",
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onPress={() => setModalVisible(!modalVisible)}
                >
                  <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                    Cancelar
                  </Text>
                </TouchableOpacity>
              </Button.Group>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </View>
    );
  }
};
export default DetalleQuodom;
