import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from '@react-navigation/drawer';
import {
  MaterialCommunityIcons
} from '@expo/vector-icons';
import {
  NativeBaseProvider,
  Button,
  Box,
  HamburgerIcon,
  Pressable,
  Heading,
  VStack,
  Text,
  Center,
  HStack,
  Divider,
  Icon
} from 'native-base';
import SitioInicial from '../screens/Home/SitioInicial';
import ListaProductos from '../components/Home/ListaProductos';
import ListaMisQuodoms from '../screens/MisQuodoms/ListaMisQuodoms';
import Notificaciones from '../components/Notificaciones';

const Drawer = createDrawerNavigator();


const getIcon = (screenName) => {
  switch (screenName) {
    case 'Home':
      return "home"
    case 'MisQuodoms':
      return 'archive'

    default:
      return undefined
  }
}

function CustomDrawerContent(props) {
  return (
    <DrawerContentScrollView {...props} safeArea>
      <VStack space={6} my={2} mx={1}>
        <Box px={4}>
          <Text bold color="gray.700">Mail</Text>
          <Text fontSize={14} mt={1} color="gray.500" fontWeight={500}>john_doe@gmail.com</Text>
        </Box>
        <VStack divider={<Divider />} space={4}>
          <VStack space={3}>
            {props.state.routeNames.map((name, index) => (
              <Pressable
                px={5}
                py={3}
                rounded="md"
                bg={index === props.state.index ? 'rgba(6, 182, 212, 0.1)' : 'transparent'}
                onPress={(event) => {
                  props.navigation.navigate(name);
                }}
              >
                <HStack space={7} alignItems="center">
                  <Icon
                    color={index === props.state.index ? 'primary.500' : 'gray.500'}
                    size={5} as={<MaterialCommunityIcons name={getIcon(name)} />} />
                  <Text fontWeight={500} color={index === props.state.index ? 'primary.500' : 'gray.700'}>
                    {name}
                  </Text>
                </HStack>
              </Pressable>
            ))}
          </VStack>
        </VStack>
      </VStack>
    </DrawerContentScrollView>
  );
}

const MenuRoot = ({ navigation }) => {
  return (
    <NativeBaseProvider>
      <Box safeArea flex={1} style={{ backgroundColor: '#f6ee5d' }}>
        <Drawer.Navigator drawerContent={(props) => <CustomDrawerContent {...props} />}>
          <Drawer.Screen name="Home" component={SitioInicial} />
          <Drawer.Screen name="MisQuodoms" component={ListaMisQuodoms} />
          <Drawer.Screen name="ListaProductos" component={ListaProductos} />
          <Drawer.Screen name="Notificaciones" component={Notificaciones} />

        </Drawer.Navigator>
      </Box>
    </NativeBaseProvider>

  );
}

export default MenuRoot;

