import React, { useState } from "react";
import { Switch } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  View,
  Divider,
  HStack,
  Input,
} from "native-base";

import { StyleProfileScreen } from "../../styles/StyleQuodom";

import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import { hp, moderateScale, wp } from "../../styles/responsive";

const OrdenarSeleccionProveedor = (props) => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  return (
    <View>
      <Center flex={1}>
        <Box alignItems="flex-start">
          <Menu
            borderRadius="8"
            closeOnSelect={false}
            //bg='#fff'
            p="0"
            borderWidth="0"
            borderColor="#45444C"
            shadow={5}
            //w={wp(50)}
            placement="bottom left"
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  {({ isPressed }) => {
                    return (
                      <Box
                        p="0"
                        rounded="0"
                        style={{
                          transform: [
                            {
                              scale: isPressed ? 0.95 : 1,
                            },
                          ],
                        }}
                      >
                        <HStack
                          space={1}
                          alignItems="center"
                          pt="1"
                          pb="1"
                          pl="3"
                          pr="3"
                          style={{
                            textAlign: "center",
                            borderWidth: 1,
                            borderColor: "#45444C",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 20,
                          }}
                        >
                          <AntDesign
                            name="menu-fold"
                            size={20}
                            color="#45444C"
                          />
                          <Text
                            style={{
                              color: "#45444C",
                              fontSize: moderateScale(8),
                              fontFamily: "Montserrat_400Regular",
                            }}
                          >
                            Ordenar
                          </Text>
                          {/* <MaterialCommunityIcons name="filter-menu-outline" size={moderateScale(17)} color='#45444C' /> */}
                        </HStack>
                      </Box>
                    );
                  }}
                </Pressable>
              );
            }}
          >
            <Menu.OptionGroup title="Precio" type="radio">
              <Menu.ItemOption
                value="MA"
                onPress={() => props.Ordenar(0, "PRE")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  De mayor a menor
                </Text>
              </Menu.ItemOption>
              <Menu.ItemOption
                value="ME"
                onPress={() => props.Ordenar(1, "PRE")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  De menor a mayor
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>

            <Divider></Divider>

            <Menu.OptionGroup title="Distancia" type="radio">
              <Menu.ItemOption
                value="venc3"
                onPress={() => props.Ordenar(1, "DIST")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  De mayor a menor
                </Text>
              </Menu.ItemOption>

              <Menu.ItemOption
                value="venc4"
                onPress={() => props.Ordenar(2, "DIST")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  De menor a mayor
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>

            <Divider></Divider>

            <Menu.OptionGroup title="Calificacion" type="radio">
              <Menu.ItemOption
                value="venc3"
                onPress={() => props.Ordenar(0, "CLAS")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Mejores primero
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>
          </Menu>
        </Box>
      </Center>
    </View>
  );
};

export default OrdenarSeleccionProveedor;
