import React from 'react';
import { TouchableOpacity } from "react-native";
import { NativeBaseProvider, Stack, Box, Divider, VStack, HStack, Text, Center, Heading, View, ScrollView, Image } from 'native-base';
import { StyleSigInScreen, useFonts, Prompt_300Light, Prompt_400Regular, Prompt_500Medium } from '../styles/StyleQuodom';
import { useRoute, useNavigation } from '@react-navigation/native';
import { Avatar, Button, Card, Title, Paragraph } from 'react-native-paper';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { hp, wp } from '../styles/responsive';

const CuentaCreada = (props) => {

    const navigation = useNavigation();
    const route = useRoute();
    let [fontsLoaded] = useFonts({
        Prompt_300Light,
        Prompt_400Regular,
        Prompt_500Medium,

    });

    const LeftContent = props =>
        <Image source={require('../assets/4.png')}
            style={{ width: 60, height: 40 }}
            alt='Cuenta Comprador' />

    const LeftContentVendor = props =>
        <Image source={require('../assets/3i.png')}
            style={{ width: 60, height: 40 }}
            alt='Cuenta Vendedor' />


    return (
        <View flex={1} backgroundColor='#F6EE5D'>

            <Center safeArea pt='10'>
                <Image
                    source={require("../assets/quodom-logox2.png")}
                    alt="Alternate Text"
                    size='md'
                />

            </Center>


            <VStack safeArea >
                <Box alignItems="center" pt='2' pb='5' >
                    <Text style={StyleSigInScreen.textIniciarSession}>¡Felicitaciones!</Text>
                </Box>

                <Box
                    p={2}
                    w="100%"
                    backgroundColor='#F6EE5D'
                >
                    <Card>
                        <Card.Title titleStyle={StyleSigInScreen.textTituloRegistroCuenta} title="Gracias por registrarse" left={LeftContent} />
                        <Card.Content>
                            <VStack space={2}>
                            <Text style={StyleSigInScreen.textoLoginChico}>
                                Revisa tu correo electrónico {route.params.email} para activar la cuenta.
                            </Text>
                            <Text style={StyleSigInScreen.textoLoginChico}>
                                Una vez validada ingresá y comenzá a utilizar tu cuenta.
                            </Text>
                            </VStack>
                        </Card.Content>

                        <Card.Actions>
                            <Box width='100%' alignItems='flex-end'>
                                <Button
                                    onPress={() => navigation.navigate('SignInScreen')}>
                                    <Text style={StyleSigInScreen.textoContinuar}>Ir al inicio</Text></Button>
                            </Box>
                        </Card.Actions>
                    </Card>
                </Box>

                <Center h={hp(35)}>

                </Center>

            </VStack>

        </View >

    );
}


export default () => {
    return (
        <NativeBaseProvider>
            <CuentaCreada />
        </NativeBaseProvider>
    )
}