import React from "react";
import { Popover, Button, Box, VStack, Text } from "native-base";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { QuodomDetalle } from "../../styles/StyleQuodom";

function DetalleImporteVenta(props) {
  ////console.log("props detalle", props)
  return (
    <Box w="10%" alignItems="flex-start">
      <Popover
        trigger={(triggerProps) => {
          return (
            <TouchableOpacity {...triggerProps}>
              <Ionicons
                name="information-circle-outline"
                size={24}
                color="black"
              />
            </TouchableOpacity>
          );
        }}
      >
        <Popover.Content w="80">
          <Popover.CloseButton />

          <Popover.Body>
            <VStack>
              <Text style={QuodomDetalle.textoPrecioItems}>
                Importe total vendido:
                <Text style={QuodomDetalle.textoPrecioItemsBold}>
                  {" "}
                  {props.total.toLocaleString("es-AR", {
                    style: "currency",
                    currency: "ARS",
                  })}
                </Text>
              </Text>
              <Text style={QuodomDetalle.textoPrecioItems}>
                Cargo QUODOM:
                <Text style={QuodomDetalle.textoPrecioItemsBold}>
                  {" "}
                  {(props.total * 0.15).toLocaleString("es-AR", {
                    style: "currency",
                    currency: "ARS",
                  })}
                </Text>
              </Text>
              <Text style={QuodomDetalle.textoPrecioItems}>
                Total a liquidar:
                <Text style={QuodomDetalle.textoPrecioItemsBold2}>
                  {" "}
                  {(props.total - props.total * 0.15).toLocaleString("es-AR", {
                    style: "currency",
                    currency: "ARS",
                  })}
                </Text>
              </Text>
            </VStack>
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </Box>
  );
}

export default DetalleImporteVenta;
