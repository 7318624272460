import { SafeAreaView, StyleSheet } from "react-native";
import { WIDTH, HEIGHT, wp, hp, moderateScale } from "./responsive";

// color fondo aplicación #F6EE5D
const colorFondoGeneral = "#F6EE5D";
const colorAmarillo = "#F6EE5D";
const colorVioleta = "#706F9A";

const basico = StyleSheet.create({
  container: {
    flex: 1,
  },

  containerWeb: {
    flex: 1,
    padding: 5,
  },

  row: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    borderRadius: 10,
    width: 200,
  },
});

const estilosCategorias = StyleSheet.create({
  container: {
    paddingTop: 50,
  },
  tinyLogo: {
    width: 50,
    height: 50,
  },
  logo: {
    width: wp(5),
    height: wp(5),
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5,
  },

  logogris: {
    width: 70,
    height: 70,
    borderTopLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  ImagenDetalleQuodom: {
    //height: 60,
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 30,
    backgroundColor: "#fff",
  },
  ImagenDetalleQuodomGrande: {
    borderRadius: 5,
    width: moderateScale(200),
    height: moderateScale(200),
    padding: 5,
    backgroundColor: "#fff",
  },
  ImagenDetalleQuodomChica: {
    height: 30,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
  },

  BoxDetalleQuodomCotizado: {
    padding: 5,
    backgroundColor: "#fff",
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderBottomLeftRadius: 8,
  },

  ImagenDetalleQuodomCotizado: {
    height: 50,
    width: 50,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 8,
    padding: 30,
    backgroundColor: "#fff",
  },

  ImagenDetalleQuodomCotizadoSeleccion: {
    height: 50,
    width: 50,
    borderTopLeftRadius: 8,
    borderBottomRightRadius: 10,

    padding: 30,
    backgroundColor: "#fff",
  },

  textoiconos: {
    fontFamily: "Prompt_400Regular",
    width: 100,
    fontSize: 12,
    lineHeight: 15,
    textAlign: "center",
    color: "#4E4D4D",
  },
});
const inputs = StyleSheet.create({
  preinputs: {
    height: 18,
    color: "#FFFFFF",
    fontSize: 15,
  },
  input: {
    borderWidth: 1,
    borderColor: "#FFFFFF",
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
  },
  picker: {
    borderWidth: 1,
    borderColor: "#FFFFFF",
    borderRadius: 5,
    width: 120,
    height: 23,
  },
});

const Pick = StyleSheet.create({
  pickerQuodoms: {
    backgroundColor: "#FFFFFF",
    height: 40,
    width: 325,
    borderRadius: 5,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 8,
    fontWeight: "bold",
  },
  pickerQuodomsText: {
    color: "#000000",
    alignContent: "space-around",
    alignItems: "baseline",
    flex: 0.9,
    paddingLeft: 5,
  },
  pickerDetalleQuodoms: {
    backgroundColor: "#FFFFFF",
    height: 33,
    width: wp(30),
    alignItems: "baseline",
    borderRadius: 60,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 6,
    fontWeight: "bold",
  },
  pickerQuodomsTextCompleto: {
    color: "#FFFFFF",
    alignContent: "space-around",
    alignItems: "baseline",
    flex: 0.9,
    paddingLeft: 5,
  },

  pickerDetalleQuodomsCompleto: {
    backgroundColor: "#2DAB66",
    height: 33,
    width: wp(30),
    alignItems: "baseline",
    borderRadius: 50,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 5,
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: "#FFFFFF",
  },
  pickerQuodomsTextCantidad: {
    color: "#000000",
    alignContent: "space-around",
    alignItems: "baseline",

    //flex: 0.9,
    textAlign: "center",
  },
  pickerQuodomsTextCantidadCompleto: {
    color: "#FFFFFF",
    alignContent: "space-around",
    alignItems: "baseline",
    flex: 0.9,
    textAlign: "center",
  },
  pickerDetalleCantidadCompleto: {
    backgroundColor: "#2DAB66",
    height: 33,
    width: wp(21),
    alignItems: "baseline",
    borderRadius: 50,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 5,
    fontWeight: "bold",
    textAlign: "center",
    borderWidth: 1,
    borderColor: "#FFFFFF",
  },
  pickerDetalleCantidad: {
    backgroundColor: "#FFFFFF",
    height: 33,
    width: wp(21),
    alignItems: "baseline",
    borderRadius: 50,
    alignItems: "center",
    alignContent: "center",
    paddingTop: 6,
    fontWeight: "bold",
    textAlign: "center",
  },

  selectCompleto: {
    backgroundColor: "#2DAB66",
    borderColor: "#FFFFFF",
  },

  pickerNormal: {
    backgroundColor: "#FFFFFF",
  },
});

const ModalStyle = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 10,
    padding: 10,
    elevation: 2,
  },

  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },

  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
});

const SitioInicialStyle = StyleSheet.create({
  TituloMasBuscados: {
    color: "#706F9A",
    alignContent: "space-around",
    alignItems: "baseline",

    fontSize: 17,
  },
});

export {
  basico,
  estilosCategorias,
  colorFondoGeneral,
  colorAmarillo,
  colorVioleta,
  inputs,
  Pick,
  ModalStyle,
  SitioInicialStyle,
};
