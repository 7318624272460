import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Pressable,
  TextInput,
  TouchableOpacity,
  Alert,
  StyleSheet,
  Switch,
  ActivityIndicator,
  Image,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias, ModalStyle } from "../../styles/general";
import {
  CrearLineasCotizacionVenta,
  CrearCabCotizacionVenta2,
  ActualizarLineaCotizacionVenta2,
} from "../../api";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import * as FileSystem from "expo-file-system";

import { Camera, CameraType } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";

import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { hp, moderateScale, wp } from "../../styles/responsive";
import { StyleProfileScreen } from "../../styles/StyleQuodom";
import { Avatar } from "react-native-paper";
const auth = require("../../config/auth.config");
import { Box, Center, HStack, VStack, Modal, Button } from "native-base";
import SelectAtributoVendedor from "../../components/MisQuodoms/Pickers/SelectAtributoVendedor";
import NumberFormat from "react-number-format";
import { TextInputMask } from "react-native-masked-text";
import * as DocumentPicker from "expo-document-picker";
const OfertasRenderItem = (props) => {
  ////console.log('----------------OfertasRenderItem:', props.nombreproducto, '-', props.id);
  var imagen = require("../../assets/line_zigzag.png");

  const obtenerColor = (estado) => {
    if (estado) return "#FFFFFF";
    else return "#F6EE5D";
  };

  const scaleXS = 1.2;
  const scaleYS = 1.2;
  const scalePT = 0.5;
  const scalePB = 0.5;

  const [valorPrecioUnitario, setValorPrecioUnitario] = useState(
    props.preciounitario
  );
  const [refreshing, setRefreshing] = useState(false);
  const [valoridCot, setvaloridCot] = useState(props.idcotizacion);

  const [minimizadoValue, setMininizadoValue] = useState(false);
  const [toggleCheckBox, setToggleCheckBox] = useState(props.enviovendedor);
  const [userFoto, setUserFoto] = useState(props.imagendelvendedor);

  const [valorNameAtributo1, setvalorNameAtributo1] = useState(
    props.atributoNombre1
  );
  const [valorNameAtributo2, setvalorNameAtributo2] = useState(
    props.atributoNombre2
  );
  const [VendedorvalorNameAtributo1, setVendedorvalorNameAtributo1] = useState(
    props.vendedor_atributoNombre1
  );
  const [VendedorvalorNameAtributo2, setVendedorvalorNameAtributo2] = useState(
    props.vendedor_atributoNombre2
  );
  const [VendedorvalorAtributo1, setVendedorvalorAtributo1] = useState(
    props.vendedor_atributo1
  );
  const [VendedorvalorAtributo2, setVendedorvalorAtributo2] = useState(
    props.vendedor_atributo2
  );
  const [modal, setModal] = useState(false);
  const [modalVisibleDuplicar, setModalVisibleDuplicar] = useState(false);

  const atr1 = valorNameAtributo1 !== "" && valorNameAtributo1 != null;
  const atr2 = valorNameAtributo2 !== "" && valorNameAtributo2 != null;

  const vend_atr1 =
    VendedorvalorNameAtributo1 !== "" && VendedorvalorNameAtributo1 != null;
  const vend_atr2 =
    VendedorvalorNameAtributo2 !== "" && VendedorvalorNameAtributo2 != null;

  const paddingAtr = atr2 ? 8 : 0;
  const debugBorder = 0;

  let detallequodomcompleto = false;

  let textoFaltantes = "";
  if (props.atributosFaltantes > 1) {
    textoFaltantes = "Faltan datos";
  } else {
    textoFaltantes = "Faltan datos";
  }

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Obtén la parte codificada en base64 del resultado
        resolve(base64String);
      };

      reader.onerror = () => {
        reject(new Error("Error al leer el archivo"));
      };

      reader.readAsDataURL(file);
    });
  }
  //SL el ENVIO
  const toggleSwitch = async () => {
    //props.modalLoading(true);
    setToggleCheckBox((previousState) => !previousState);
    let envio = !toggleCheckBox;

    const token = await AsyncStorage.getItem("userToken");

    let IdCotizacion = valoridCot;

    if (IdCotizacion === null) {
      const modCrearHeader = await CrearCabCotizacionVenta2(
        token,
        props.idquodom
      );
      setvaloridCot(modCrearHeader.id);
      IdCotizacion = modCrearHeader.id;
    }

    let data = {
      idquodomline: props.id,
      idquodom: props.idquodom,
      idcotizacion: IdCotizacion,
      envio: envio,
      duplicado: props.duplicado,
    };
    // wait(100).then(() => {
    ActualizarLineaCotizacionVenta2(token, data);
    // });
    ////console.log('ENVIO', envio);
  };

  async function getvalorAtributo1(valor) {
    //props.modalLoading(true);
    setVendedorvalorAtributo1(valor);
    const token = await AsyncStorage.getItem("userToken");

    let IdCotizacion = valoridCot;

    if (IdCotizacion === null) {
      const modCrearHeader = await CrearCabCotizacionVenta2(
        token,
        props.idquodom
      );
      setvaloridCot(modCrearHeader.id);
      IdCotizacion = modCrearHeader.id;
    }
    let data = {
      idquodomline: props.id,
      idquodom: props.idquodom,
      idcotizacion: IdCotizacion,
      atributo1: valor,
      duplicado: props.duplicado,
    };
    //wait(100).then(() => {
    ActualizarLineaCotizacionVenta2(token, data);
    //});
    //props.modalLoading(false);
  }

  const pickDoc = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "image/*",
      });
      //console.log(result);
      // var lastThree = result.name.substr(result.name.length - 3);

      if (result.type == "success") {
        if (!result.cancelled) {
          setRefreshing(true);

          const token = await AsyncStorage.getItem("userToken");
          let uriCompressed = "";

          const Result = await ImageManipulator.manipulateAsync(
            result.uri,
            [{ resize: { width: 480, height: 480 } }],
            { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
          );
          uriCompressed = Result;
          //console.log("comprimido", uriCompressed);
          /* let fotoBase64 = await FileSystem.readAsStringAsync(uriCompressed.uri, {
              encoding: FileSystem.EncodingType.Base64,
            });*/
          let fotoBase64 = uriCompressed.uri.split(",")[1];
          let IdCotizacion = valoridCot;

          if (IdCotizacion === null) {
            const modCrearHeader = await CrearCabCotizacionVenta2(
              token,
              props.idquodom
            );
            setvaloridCot(modCrearHeader.id);
            IdCotizacion = modCrearHeader.id;
          }

          let data = {
            idquodomline: props.id,
            idquodom: props.idquodom,
            idcotizacion: IdCotizacion,
            imagendelvendedor: fotoBase64,
            refreshImage: "base64",
            duplicado: props.duplicado,
          };
          // wait(100).then(() => {
          ActualizarLineaCotizacionVenta2(token, data);
          // });

          setUserFoto(fotoBase64);
          setModal(false);
          setRefreshing(false);
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  async function getvalorAtributo2(valor) {
    //props.modalLoading(true);
    setVendedorvalorAtributo2(valor);
    const token = await AsyncStorage.getItem("userToken");

    let IdCotizacion = valoridCot;

    if (IdCotizacion === null) {
      const modCrearHeader = await CrearCabCotizacionVenta2(
        token,
        props.idquodom
      );
      setvaloridCot(modCrearHeader.id);
      IdCotizacion = modCrearHeader.id;
    }

    let data = {
      idquodomline: props.id,
      idquodom: props.idquodom,
      idcotizacion: IdCotizacion,
      atributo2: valor,
      duplicado: props.duplicado,
    };
    //wait(100).then(() => {
    ActualizarLineaCotizacionVenta2(token, data);
    //});
    //props.modalLoading(false);
  }

  async function ActualizarItemCotizacion(text) {
    setValorPrecioUnitario(text);
    //props.modalLoading(true);
    ////console.log('Precio $ TEXT', text);
    const token = await AsyncStorage.getItem("userToken");
    let precio = text;
    if (precio !== null) {
      precio = precio.replace("$", "");
      precio = precio.replace(/\./g, "");
    }
    ////console.log('PRECIO', precio);
    let IdCotizacion = valoridCot;

    if (precio === "" || precio === null) {
      setValorPrecioUnitario(0);
    }

    if (IdCotizacion === null) {
      const modCrearHeader = await CrearCabCotizacionVenta2(
        token,
        props.idquodom
      );
      setvaloridCot(modCrearHeader.id);
      IdCotizacion = modCrearHeader.id;
    }
    ////console.log('Precio $ PRECIO', precio);
    let data = {
      idquodomline: props.id,
      idquodom: props.idquodom,
      idcotizacion: IdCotizacion,
      preciounitario: precio === "" || precio === null ? 0 : precio,
      duplicado: props.duplicado,
    };
    //wait(100).then(() => {
    ActualizarLineaCotizacionVenta2(token, data);
    //});
    //props.modalLoading(false);
  }

  const obtenerEstilo = (atributo1, atributo2, valorUnitario) => {
    if (valorUnitario) {
      valorUnitario = parseFloat(valorUnitario);
    } else {
      valorUnitario = null;
    }

    if (vend_atr1 && vend_atr2) {
      if (
        atributo1 &&
        atributo2 &&
        valorUnitario !== 0 &&
        valorUnitario !== null &&
        valorUnitario !== NaN
      ) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }

    if (!vend_atr2 && vend_atr1) {
      if (
        atributo1 &&
        valorUnitario !== 0 &&
        valorUnitario !== null &&
        valorUnitario !== NaN
      ) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }

    if (!vend_atr2 && !vend_atr1) {
      if (
        valorUnitario !== 0 &&
        valorUnitario !== null &&
        valorUnitario !== NaN
      ) {
        detallequodomcompleto = true;
        return "#2dab66";
      } else {
        return "#706f9a";
      }
    }
    /* if (detallequodomcompleto){
          const token = await AsyncStorage.getItem('userToken');
    
          let IdCotizacion = valoridCot;
      
          if (IdCotizacion === null) {
            const modCrearHeader = await CrearCabCotizacionVenta2(token, props.idquodom);
            setvaloridCot(modCrearHeader.id);
            IdCotizacion = modCrearHeader.id;
          }
      
          let data = {
            idquodomline: props.id,
            idquodom: props.idquodom,
            idcotizacion: IdCotizacion,
            atributo2: valor,
            duplicado: props.duplicado
          };
      
          ActualizarLineaCotizacionVenta2(token, data);
        }*/
  };

  const colorFondo = () => {
    if (detallequodomcompleto) return "#2DAB66";
    else return "#fff";
  };

  const colorBordes = () => {
    if (detallequodomcompleto) return "#fff";
    else return "transparent";
  };

  const obtenerColorSelect = () => {
    if (detallequodomcompleto) return "#FFFFFF";
    else return "#000000";
  };

  /*  const [hasPermission, setHasPermission] = useState(null);
   const [type, setType] = useState(CameraType.back); */

  /*  useEffect(() => {
     //console.log('Entre al useEffect de OfertasRenderItem.js');
 
     ////console.log(vend_atr1, valorNameAtributo1, vend_atr2, valorNameAtributo2);
   }, []); */

  function EnvioIncluido(int) {
    switch (int) {
      case true:
        return "Envío incluído";
      case false:
      case null:
        return "Sin Envio";
      default:
        return "Sin Envio";
    }
  }

  /*  async function getvalorMarca(valor) {
     //console.log(valor);
     setvalorMarca(valor); 
   } */

  function actualizarCard() {
    setRefreshing(true);
    setRefreshing(false);
  }

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const handleUpload = async (uri) => {
    ////console.log('uri', uri);
  };

  const pickFromGallery = async () => {
    const { granted } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    if (granted) {
      let data = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5,
        base64: true,
      });
      if (!data.cancelled) {
        ////console.log('data:' + data);
        await handleUpload(data.uri);
      }
    } else {
      Alert.alert("you need to give up permission to work");
    }
  };

  const pickFromCamera = async () => {
    const { granted } = await Camera.requestCameraPermissionsAsync();
    //const { granted } = await Permissions.askAsync(Permissions.CAMERA)
    if (granted) {
      let data = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5,
        base64: true,
      });

      ////console.log('DATAAAA', data.base64)
      if (!data.cancelled) {
        await handleUpload(data.uri);
      }
    } else {
      Alert.alert("you need to give up permission to work");
    }
  };

  const DuplicarProducto = async () => {
    const token = await AsyncStorage.getItem("userToken");
    let IdCotizacion = valoridCot;

    if (IdCotizacion === null) {
      const modCrearHeader = await CrearCabCotizacionVenta2(
        token,
        props.idquodom
      );
      setvaloridCot(modCrearHeader.id);
      IdCotizacion = modCrearHeader.id;
    }
    let data = {
      idquodomline: props.id,
      idquodom: props.idquodom,
      idcotizacion: IdCotizacion,
      duplicado: Math.random().toString(),
    };
    ////console.log('data', data);
    await CrearLineasCotizacionVenta(token, data);

    setModalVisibleDuplicar(false);
    props.actualizarestado(Math.random().toString());
  };

  const CardMinimizado = () => {
    return (
      <View style={basico.container}>
        <Box
          rounded={11}
          /*  overflow="hidden" */
          width="100%"
          shadow={0}
          marginTop={2}
          backgroundColor={obtenerEstilo(
            VendedorvalorAtributo1,
            VendedorvalorAtributo2,
            valorPrecioUnitario
          )}
          zIndex={1}
        >
          <TouchableOpacity
            activeOpacity={0.95}
            onPress={() => setMininizadoValue(false)}
          >
            <HStack
              space={1}
              borderWidth={debugBorder}
              bg="#fff"
              roundedTop={8}
            >
              <View>
                <Image
                  source={{
                    uri: props.imagen,
                  }}
                  //uri={props.imagen} // image address
                  //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                  style={estilosCategorias.ImagenDetalleQuodom}
                />
              </View>
              <VStack pt="2" w="73%" borderWidth={debugBorder}>
                <Text style={DetalleProductoQuodomstyle.textoTitulo2N}>
                  {props.nombreproducto}
                </Text>
                <Text style={DetalleProductoQuodomstyle.textoCategoria2N}>
                  {props.nombrecategoria}{" "}
                  {props.duplicado !== null ? "(Duplicado)" : null}
                </Text>
              </VStack>
            </HStack>
          </TouchableOpacity>
          <View
            style={{
              paddingTop: 15,
              paddingRight: 0,
              margin: 0,
              position: "absolute",
              top: 0,
              right: 10,
            }}
          >
            <MaterialIcons
              name="keyboard-arrow-down"
              size={25}
              color="#45444C"
              onPress={() => setMininizadoValue(false)}
            />
          </View>

          {detallequodomcompleto ? (
            <HStack
              space={2}
              mb={3}
              ml={2}
              mt={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <HStack justifyContent="flex-start" space={2}>
                <FontAwesome
                  name="check"
                  size={moderateScale(16)}
                  color="white"
                  style={{ alignContent: "center", zIndex: 1 }}
                />{" "}
                <Text
                  style={{
                    fontSize: 17,
                    color: "#FFFFFF",
                    fontWeight: "bold",
                  }}
                >
                  COMPLETO
                </Text>
                <Text
                  style={{
                    textAlign: "center",
                    borderLeftWidth: 1,
                    borderColor: "#fff",
                    padding: 1,
                    color: obtenerColor(detallequodomcompleto),
                    fontSize: moderateScale(13),
                  }}
                >
                  {" "}
                  {EnvioIncluido(toggleCheckBox)}{" "}
                </Text>
              </HStack>
              <HStack justifyContent="flex-end" pr="2">
                <NumberFormat
                  value={valorPrecioUnitario}
                  displayType="text"
                  thousandSeparator={"."}
                  decimalScale={2}
                  decimalSeparator={","}
                  prefix="$"
                  renderText={(value) => (
                    <Text
                      style={DetalleProductoQuodomstyle.textoEstadoNodoQuodom}
                    >
                      {value}
                    </Text>
                  )}
                />
                {/* <Text style={DetalleProductoQuodomstyle.textoEstadoNodoQuodom}>${valorPrecioUnitario} </Text> */}
              </HStack>
            </HStack>
          ) : (
            <HStack
              mt="3"
              mb="3"
              pl="3"
              pr="3"
              justifyContent="space-between"
              alignItems="center"
            >
              <FontAwesome
                name="remove"
                size={moderateScale(16)}
                color="white"
                style={{ alignContent: "center", zIndex: 1 }}
              />
              <Text
                style={{
                  fontSize: 17,
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                {" "}
                INCOMPLETO
              </Text>
              <NumberFormat
                value={valorPrecioUnitario}
                displayType="text"
                thousandSeparator={"."}
                decimalScale={2}
                decimalSeparator={","}
                prefix="$"
                renderText={(value) => (
                  <Text
                    style={DetalleProductoQuodomstyle.textoEstadoNodoQuodom}
                  >
                    {value}
                  </Text>
                )}
              />

              {/*    <Text style={DetalleProductoQuodomstyle.textoEstadoNodoQuodom}>${valorPrecioUnitario}</Text> */}
            </HStack>
          )}
        </Box>
      </View>
    );
  };

  return (
    <View>
      {minimizadoValue ? (
        <CardMinimizado />
      ) : (
        <View style={basico.container}>
          <Box
            rounded={8}
            /*  overflow="hidden" */
            w="100%"
            shadow={3}
            shadowRadius={8}
            marginTop={2}
            backgroundColor={obtenerEstilo(
              VendedorvalorAtributo1,
              VendedorvalorAtributo2,
              valorPrecioUnitario
            )}
            zIndex={1}
            borderWidth={debugBorder}
          >
            <TouchableOpacity
              activeOpacity={0.95}
              onPress={() => setMininizadoValue(true)}
            >
              <HStack
                space={1}
                borderWidth={debugBorder}
                bg="#fff"
                roundedTop={8}
              >
                <View>
                  <Image
                    source={{
                      uri: props.imagen,
                    }}
                    //uri={props.imagen} // image address
                    //  cacheKey={props.refreshImage} // sale del campo resfreshImage, si cambio la imagen tengo que cambiar ese campo para que la vuelva a bajar
                    style={estilosCategorias.ImagenDetalleQuodom}
                  />
                </View>
                <VStack pt="2" w="73%" borderWidth={debugBorder}>
                  <Text style={DetalleProductoQuodomstyle.textoTitulo2N}>
                    {props.nombreproducto}
                  </Text>
                  <Text style={DetalleProductoQuodomstyle.textoCategoria2N}>
                    {props.nombrecategoria}{" "}
                    {props.duplicado !== null ? "(Duplicado)" : null}
                  </Text>
                </VStack>
              </HStack>
            </TouchableOpacity>
            <HStack
              style={{
                paddingTop: 15,
                paddingRight: 0,
                margin: 0,
                position: "absolute",
                top: 0,
                right: 10,
              }}
            >
              <VStack>
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={25}
                  color="#45444C"
                  onPress={() => setMininizadoValue(true)}
                />
              </VStack>
              {detallequodomcompleto && props.duplicado === null ? (
                <VStack>
                  <Ionicons
                    name="duplicate-outline"
                    size={23}
                    color="#45444C"
                    onPress={() => setModalVisibleDuplicar(true)}
                  />
                </VStack>
              ) : null}
            </HStack>

            <HStack
              space={2}
              pl="3"
              pr="3"
              marginTop={2}
              marginBottom={2}
              borderWidth={debugBorder}
              w="100%"
            >
              <Box
                w="33%"
                borderWidth={debugBorder}
                pl="1"
                pr="1"
                pt="0"
                justifyContent="space-between"
              >
                <Box>
                  <Text
                    style={{
                      fontSize: moderateScale(8),

                      color: "#F6EE5D",
                    }}
                  >
                    Características:
                  </Text>
                  <Box pt="1">
                    <Text style={QuodomDetalle.itemValorCampoCotizacionBold}>
                      Cantidad:{" "}
                      <Text style={QuodomDetalle.itemValorCampoCotizacion2}>
                        {props.cantidad}
                      </Text>
                    </Text>
                  </Box>
                  {atr1 ? (
                    <View
                      style={{
                        paddingBottom: 1,
                        paddingTop: 5,
                        borderWidth: 0,
                      }}
                    >
                      <View
                        style={{
                          borderBottomColor: "#FFFFFF",
                          borderBottomWidth: 0.9,
                        }}
                      />
                      <Box pt="1">
                        <Text
                          style={QuodomDetalle.itemValorCampoCotizacionBold}
                        >
                          {capitalize(props.atributoNombre1)}:{" "}
                          <Text style={QuodomDetalle.itemValorCampoCotizacion2}>
                            {props.atributo1}
                          </Text>
                        </Text>
                      </Box>
                    </View>
                  ) : null}
                  {atr2 ? (
                    <View
                      style={{
                        paddingBottom: 0,
                        paddingTop: 5,
                        borderWidth: 0,
                      }}
                    >
                      <View
                        style={{
                          borderBottomColor: "#FFFFFF",
                          borderBottomWidth: 0.9,
                        }}
                      />
                      <Box pt="1">
                        <Text
                          style={[QuodomDetalle.itemValorCampoCotizacionBold]}
                        >
                          {capitalize(props.atributoNombre2)}:{" "}
                          <Text style={QuodomDetalle.itemValorCampoCotizacion2}>
                            {props.atributo2}
                          </Text>
                        </Text>
                      </Box>
                    </View>
                  ) : null}
                </Box>
                {/*   <Box>
                  <Text style={{ textAlign: 'center', padding: 3, color: '#F6EE5D', fontSize: moderateScale(11), fontFamily: 'Prompt_300Light', borderWidth: 1, borderColor: '#F6EE5D', shadowRadius: 0, shadowOpacity: 0, elevation: 0, borderRadius: 5 }}><MaterialCommunityIcons name="truck-outline" size={moderateScale(12)} color="#F6EE5D" /> En tu Zona</Text>
                </Box> */}
                <VStack
                  space={1}
                  alignItems="center"
                  pt="1"
                  justifyContent="center"
                  borderWidth={debugBorder}
                >
                  <HStack
                    w={wp(30)}
                    mt="0"
                    space={2}
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      textAlign: "center",
                      padding: 2,
                      borderWidth: 1,
                      borderColor: "transparent",
                      shadowRadius: 0,
                      shadowOpacity: 0,
                      elevation: 0,
                      borderRadius: 5,
                    }}
                  >
                    <MaterialCommunityIcons
                      name="truck-outline"
                      size={17}
                      color="transparent"
                    />
                    <Text
                      style={{
                        color: "transparent",
                        fontSize: 12,
                      }}
                    >
                      En tu zona
                    </Text>
                  </HStack>
                </VStack>

                {detallequodomcompleto ? (
                  <VStack
                    space={1}
                    pt="2"
                    justifyItems="space-between"
                    borderWidth={debugBorder}
                  >
                    <HStack space={1}>
                      <FontAwesome
                        name="check"
                        size={moderateScale(17)}
                        color="white"
                        style={{ alignContent: "center", zIndex: 1 }}
                      />
                      <Text
                        style={{
                          fontSize: 17,
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          paddingTop: "4%",
                        }}
                      >
                        COMPLETO
                      </Text>
                    </HStack>
                  </VStack>
                ) : (
                  <VStack space={1} pt="2" borderWidth={debugBorder}>
                    <HStack space={1}>
                      <FontAwesome
                        name="remove"
                        size={moderateScale(16)}
                        color="white"
                        style={{ alignContent: "center", zIndex: 1 }}
                      />
                      <Text
                        style={{
                          fontSize: 17,
                          color: "#FFFFFF",
                          fontWeight: "bold",
                          paddingTop: "4%",
                        }}
                      >
                        INCOMPLETO
                      </Text>
                    </HStack>
                  </VStack>
                )}

                {detallequodomcompleto ? (
                  <VStack pr={4} alignItems="flex-end"></VStack>
                ) : (
                  <VStack pr={2} space={0} borderWidth={debugBorder}>
                    <HStack space={1} alignItems="center">
                      <Text style={QuodomList_Card.itemFaltanDetalles}>
                        {textoFaltantes}
                      </Text>
                      <MaterialCommunityIcons
                        name="arrow-right"
                        size={14}
                        color="#fff"
                      />
                    </HStack>
                    {/* <HStack space={1} alignItems='center' justifyContent='flex-end' >
                      <Text style={QuodomList_Card.itemSinFaltanDetalles}>¡Ingresalos!</Text>
                      <MaterialCommunityIcons name="arrow-right" size={14} color="#fff" />
                    </HStack> */}
                  </VStack>
                )}
              </Box>

              <View
                style={{
                  borderLeftWidth: 0.9,
                  borderLeftColor: "white",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              />

              <Box w="33%" borderWidth={debugBorder} pr="2">
                <HStack
                  pt={scalePT}
                  pb={scalePB}
                  w="100%"
                  space={2}
                  alignItems="center"
                  style={{ paddingTop: "10%" }}
                >
                  <Text style={[QuodomDetalle.itemValorCampoCotInput]}>
                    Incluir envío:
                  </Text>

                  <Switch
                    style={{
                      transform: [{ scaleX: scaleXS }, { scaleY: scaleYS }],
                    }}
                    trackColor={{ false: "#fff", true: "#81b0ff" }}
                    thumbColor={toggleCheckBox ? "#F6EE5D" : "#fff"}
                    ios_backgroundColor="#ccc"
                    onValueChange={toggleSwitch}
                    value={toggleCheckBox}
                  />
                </HStack>

                {vend_atr1 ? (
                  <View style={{ paddingTop: "10%" }}>
                    <HStack
                      w="100%"
                      pb={2}
                      pt={2}
                      space={0}
                      alignItems="center"
                    >
                      <Text style={[QuodomDetalle.itemValorCampoCotInput]}>
                        {capitalize(props.vendedor_atributoNombre1)}:
                      </Text>
                      <SelectAtributoVendedor
                        idproducto={props.idproducto}
                        idlinea={props.id}
                        idquodom={props.idquodom}
                        atributoNombre={props.vendedor_atributoNombre1}
                        atributovalue={props.vendedor_atributo1}
                        detallequodomcompleto={detallequodomcompleto}
                        nroAtr={1}
                        funcionestado={actualizarCard}
                        valordelpicker={getvalorAtributo1}
                      />
                      {/* <PickerAtributoVendedor idproducto={props.idproducto} idlinea={props.id} idquodom={props.idquodom} atributoNombre={props.vendedor_atributoNombre1} atributovalue={props.vendedor_atributo1} detallequodomcompleto={detallequodomcompleto} funcionestado={actualizarCard} valordelpicker={getvalorAtributo1} /> */}
                    </HStack>
                  </View>
                ) : null}

                {vend_atr2 ? (
                  <View style={{ paddingTop: "10%" }}>
                    <HStack
                      w="100%"
                      pb={2}
                      pt={2}
                      space={0}
                      alignItems="center"
                    >
                      <Text style={[QuodomDetalle.itemValorCampoCotInput]}>
                        {capitalize(props.vendedor_atributoNombre2)}:
                      </Text>
                      <SelectAtributoVendedor
                        idproducto={props.idproducto}
                        idlinea={props.id}
                        idquodom={props.idquodom}
                        atributoNombre={props.vendedor_atributoNombre2}
                        atributovalue={props.vendedor_atributo2}
                        detallequodomcompleto={detallequodomcompleto}
                        nroAtr={2}
                        funcionestado={actualizarCard}
                        valordelpicker={getvalorAtributo2}
                      />
                      {/* <SelectMarca /> */}
                    </HStack>
                  </View>
                ) : null}
                <View style={{ paddingTop: "10%" }}>
                  <HStack w="100%" pb={2} pt={2} space={0} alignItems="center">
                    <Text style={[QuodomDetalle.itemValorCampoCotInput]}>
                      Precio: $
                    </Text>
                    <VStack
                      style={{ alignItems: "flex-end", flex: 1 }}
                      borderWidth={0}
                    >
                      {/* <Input w='90%' rounded={20} h='10'
                        style={DetalleProductoQuodomstyle.textoCompleta}
                        keyboardType="number-pad"
                        bg={colorFondo()}
                        borderColor={colorBordes()}
                        color={obtenerColorSelect()}
                        _focus={{
                          backgroundColor: "gray.200",
                          borderColor: "#6604c9",
                          color: '#000'
                        }}
                        value={valorPrecioUnitario}
                        onEndEditing={() => ActualizarItemCotizacion()}
                        onChangeText={(text) => {
                          setValorPrecioUnitario(text);
                        }}></Input> */}
                      {props.puedeVender ? (
                        <TextInputMask
                          type={"money"}
                          options={{
                            precision: 0,
                            separator: ",",
                            delimiter: ".",
                            unit: "",
                            suffixUnit: "",
                          }}
                          style={{
                            backgroundColor: colorFondo(),
                            paddingLeft: 10,
                            borderWidth: 1,
                            borderColor: colorBordes(),
                            color: obtenerColorSelect(),
                            width: "90%",
                            height: 40,
                            borderRadius: 20,
                            fontFamily: "Prompt_400Regular",
                            fontSize: 13,
                          }}
                          keyboardType="number-pad"
                          //bg={colorFondo()}
                          //borderColor={colorBordes()}
                          //color={obtenerColorSelect()}

                          value={valorPrecioUnitario}
                          onChangeText={(text) => {
                            ActualizarItemCotizacion(text);
                          }}
                        />
                      ) : (
                        <Box
                          justifyContent="center"
                          style={{
                            backgroundColor: "#ccc",
                            paddingLeft: 10,
                            borderWidth: 1,
                            borderColor: colorBordes(),
                            color: "#000",
                            width: "90%",
                            height: 40,
                            borderRadius: 20,
                            fontFamily: "Prompt_400Regular",
                            fontSize: 13,
                          }}
                        >
                          {/* <Text style={[QuodomDetalle.itemValorCampoCotInputSmall]}>Habilitación pendiente</Text> */}
                        </Box>
                      )}
                    </VStack>
                  </HStack>
                </View>
              </Box>
              <View
                style={{
                  borderLeftWidth: 0.9,
                  borderLeftColor: "white",
                  marginTop: 5,
                  marginBottom: 10,
                }}
              />
              <Box w="33%" borderWidth={debugBorder} pr="2">
                <HStack
                  w="100%"
                  pt="2"
                  pb={2}
                  space={1}
                  alignItems="center"
                  justifyContent="center"
                  style={{ paddingTop: "20%" }}
                >
                  <TouchableOpacity
                    style={StyleProfileScreen.button}
                    onPress={() => pickDoc()}
                  >
                    {userFoto !== null ? (
                      <Avatar.Image
                        source={{ uri: "data:image/png;base64," + userFoto }}
                        size={80}
                      />
                    ) : (
                      <Avatar.Image
                        source={require("../../assets/vendedor/subir_foto.png")}
                        size={80}
                      />
                    )}
                  </TouchableOpacity>
                  <VStack>
                    <Text
                      style={{
                        textAlign: "center",
                        padding: 0,
                        color: "#FFFFFF",
                        fontSize: 11,
                      }}
                    >
                      Subir foto{" "}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        padding: 0,
                        color: "#FFFFFF",
                        fontSize: 11,
                      }}
                    >
                      (opcional)
                    </Text>
                  </VStack>
                </HStack>
              </Box>
            </HStack>
          </Box>
        </View>
      )}

      {/* MODAL CONFIRMACION DUPLICAR */}
      <Modal
        isOpen={modalVisibleDuplicar}
        onClose={() => setModalVisibleDuplicar(false)}
      >
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Duplicar Producto
            </Text>
          </Modal.Header>
          <Modal.Body pt="5" pb="5">
            <Text style={QuodomDetalle.TextoModalEliminar}>
              Esta seguro de duplicar el producto{" "}
              <Text style={QuodomDetalle.TextoModalEliminarBold}>
                {props.nombreProducto}
              </Text>{" "}
              ?
            </Text>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "transparent",
                  padding: 10,
                  borderRadius: 5,
                  borderWidth: 1,
                }}
                onPress={() => DuplicarProducto()}
              >
                <Text style={QuodomDetalle.TextoModalButtonOK}>
                  Si, duplicar
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => setModalVisibleDuplicar(!modalVisibleDuplicar)}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Cancelar
                </Text>
              </TouchableOpacity>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <Modal isOpen={modal} onClose={() => setModal(false)} size="lg">
        <Modal.Content width={wp(95)}>
          <Modal.CloseButton />
          <Modal.Header>
            <Text style={QuodomDetalle.TextoTituloModalEliminar}>
              Agregar Imagen
            </Text>
          </Modal.Header>
          <Modal.Body>
            {userFoto !== null ? (
              <Center>
                <Image
                  source={{ uri: "data:image/png;base64," + userFoto }}
                  alt="."
                  size="xl"
                />
              </Center>
            ) : (
              <Center>
                <Text style={DetalleProductoQuodomstyle.textoFechaCotizado}>
                  Agrega la imagen de tus productos desde la camara o galeria
                </Text>
              </Center>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={3}>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => {
                  pickFromCamera();
                }}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Camara
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => {
                  pickFromGallery();
                }}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Galeria
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  alignItems: "center",
                  backgroundColor: "#706F9A",
                  padding: 10,
                  borderRadius: 5,
                }}
                onPress={() => {
                  setModal(false);
                }}
              >
                <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                  Cerrar
                </Text>
              </TouchableOpacity>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </View>
  );
};
const theme = {
  colors: {
    primary: "#006aff",
  },
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  inputStyle: {
    margin: 5,
  },
  modalView: {
    position: "absolute",
    bottom: 2,
    width: "100%",
    backgroundColor: "white",
  },
  modalButtonView: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
});
export default OfertasRenderItem;
