import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  ObtenerProductosAtributos,
  ModificarDetalleQuodom,
} from "../../../api";
import { Select, VStack, CheckIcon, Box } from "native-base";

const SelectAtributo = (props) => {
  ////console.log('PROPS', props);
  const [data, setData] = useState([]);
  let [service, setService] = useState(props.atributovalue);

  const colorFondo = (estado) => {
    if (estado) return "#2DAB66";
    else return "#fff";
  };

  const colorBordes = (estado) => {
    if (props.DP) {
      return "#45444C";
    } else {
      if (estado) return "#fff";
      else return "transparent";
    }
  };

  const obtenerColor = (estado) => {
    if (estado) return "#FFFFFF";
    else return "#000000";
  };

  const [dataInfo, setDataInfo] = React.useState({
    selectedItem: {},
    seleccionado: props.atributoNombre,
    Atributo: [],
  });

  async function onSelected(selected) {
    setService(selected);
    const token1 = await AsyncStorage.getItem("userToken");

    let nuevoValor = {};

    if (props.nroAtr == 1) {
      nuevoValor = {
        idquodom: props.idquodom,
        atributo1: selected,
        id: props.idlinea,
      };
    } else {
      nuevoValor = {
        idquodom: props.idquodom,
        atributo2: selected,
        id: props.idlinea,
      };
    }

    ////console.log('soy el nuevo valor', nuevoValor);

    ModificarDetalleQuodom(token1, props.idlinea, nuevoValor);

    props.funcionestado();
    props.valordelpicker(selected);
  }

  const loadAtributo = async () => {
    ////console.log('soy los props de SelectAtributo',props);
    const value = await AsyncStorage.getItem("userToken");
    const atrData = await ObtenerProductosAtributos(
      value,
      props.idproducto,
      props.atributoNombre
    );
    ////console.log(atrData);
    if (!atrData || atrData.length === 0) {
      let generico = {
        valoratributo: "No aplica",
        id: 3210,
      };
      atrData.push(generico);
    }
    setData(atrData);

    /*  var dataparseada = await Color.map(function (item) {
            return {
                Name: item.valoratributo,
                Value: item.valoratributo,
                Code: item.id,
                Id: item.id
            };
        });

        setDataInfo({
            ...dataInfo,
            Atributo: dataparseada,
            seleccionado: props.atributovalue
        }); */
  };

  useEffect(() => {
    setService(props.atributovalue);
    loadAtributo();
  }, []);

  return (
    <Box width={"80%"}>
      <select
        style={{ lineHeight: "1", width: "80%", fontSize: "11px" }}
        id="ddlatributo"
        class="form-select"
        value={service}
        onChange={(e) => onSelected(e.target.value)}
      >
        <option value="0">Seleccionar</option>
        {data && data !== undefined
          ? data.map((ctr, index) => {
              return (
                <option key={index} value={ctr.valoratributo}>
                  {ctr.valoratributo}
                </option>
              );
            })
          : "No hay atributos"}
      </select>
    </Box>
  );
};

export default SelectAtributo;
