import React, { useEffect, useState } from "react";
import {
  VStack,
  HStack,
  Text,
  Center,
  useToast,
  Input,
  Icon,
  Button,
} from "native-base";
import {
  View,
  TouchableOpacity,
  ActivityIndicator,
  Keyboard,
} from "react-native";
import quodomTheme from "../../constants/Theme";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { GuardarUsuario } from "../../api";
import { wp } from "../../styles/responsive";
import { MaterialIcons, Ionicons } from "@expo/vector-icons";
import {
  QuodomDetalle,
  StylePerfil,
  StyleSigInScreen,
} from "../../styles/StyleQuodom";

import { useNavigation } from "@react-navigation/native";

const CambiarPassScreen = () => {
  const [pass, setPass] = useState([]);
  const [secure, setSecure] = useState(true);
  const [passOK, setPassOK] = useState(true);
  const [passmin, setPassmin] = useState(true);
  const [changeOK, setChangeOK] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const navigation = useNavigation();

  const toast = useToast();
  const id = "id_toast";

  const check = (val) => {
    if (val !== null) {
      if (val.length < 6) {
        setPassmin(false);
      } else {
        setPassmin(true);
      }

      if (pass.pass1 !== pass.pass2) {
        setPassOK(false);
      } else {
        setPassOK(true);
      }
    }
  };

  const passChange1 = (val) => {
    setPass({
      ...pass,
      pass1: val,
    });
  };

  const passChange2 = (val) => {
    setPass({
      ...pass,
      pass2: val,
    });
  };

  const SaveHandle = async () => {
    setisLoading(true);
    setChangeOK(false);
    Keyboard.dismiss();

    if (pass.pass1 !== pass.pass2) {
      setPassOK(false);
    } else {
      setPassOK(true);
    }

    //console.log(pass.pass1, pass.pass2, passOK, passmin);
    if (
      pass.pass1 === undefined ||
      pass.pass1 === "" ||
      pass.pass2 === undefined ||
      pass.pass2 === "" ||
      !passOK ||
      !passmin
    ) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Atención",
          status: "warning",
          description:
            "Revise que las contraseñas cumplan con el requerimiento de 6 caracteres y sean iguales.",
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }

      setisLoading(false);
      return;
    }

    const token = await AsyncStorage.getItem("userToken");
    const deviceId = await AsyncStorage.getItem("userPushToken");

    let userpass = {
      password: pass.pass1,
      deviceId: deviceId,
    };

    const Res = await GuardarUsuario(token, userpass);

    //SL res == false es error muestro el message
    if (!Res.res) {
      if (!toast.isActive(id)) {
        toast.show({
          id,
          title: "Algo salió mal",
          status: "error",
          description: Res.message,
          placement: "bottom",
          style: { backgroundColor: "#2ECC71" },
        });
      }
      return;
    }
    setPass({
      ...pass,
      pass1: "",
      pass2: "",
    });
    setChangeOK(true);
    setisLoading(false);
    return;
  };

  useEffect(() => {
    //console.log("Entre al useEffect CambiarPassScreen.js");
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <HStack
        bg={quodomTheme.COLORS["DEFAULT"]}
        px={2}
        py={5}
        mb="1"
        justifyContent="space-between"
        alignItems="center"
        shadow={2}
      >
        <HStack space={4} alignItems="center">
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <HStack space={2} alignItems="center">
              <Ionicons name="arrow-back-sharp" size={25} color="#45444C" />
              <Text style={QuodomDetalle.textoNavigate}>Seguridad</Text>
            </HStack>
          </TouchableOpacity>
        </HStack>
      </HStack>

      <HStack w={wp(100)} flex={1} backgroundColor="#F2F2F2">
        <VStack style={StylePerfil.infoContainer} space={2}>
          <HStack mt="5" justifyContent="flex-start">
            <Text style={StylePerfil.textoTitulo}>Cambiar contraseña</Text>
          </HStack>

          <VStack
            bg="#fff"
            shadow="2"
            p="3"
            mt="2"
            borderRadius="2"
            space={5}
            justifyContent="flex-start"
            w={wp(94)}
          >
            <Text style={StylePerfil.labelMon13}>Nueva Contraseña</Text>
            <Input
              mt="-5"
              variant="underlined"
              style={StylePerfil.textbox}
              value={pass.pass1}
              onChangeText={(val) => passChange1(val)}
              type={secure ? "password" : "text"}
              onEndEditing={() => check(pass.pass1)}
            />

            <Text style={StylePerfil.labelMon13}>Repetir Contraseña</Text>
            <Input
              mt="-5"
              variant="underlined"
              style={StylePerfil.textbox}
              value={pass.pass2}
              onChangeText={(val) => passChange2(val)}
              type={secure ? "password" : "text"}
              InputRightElement={
                <Icon
                  as={
                    <MaterialIcons
                      name={secure ? "visibility-off" : "visibility"}
                    />
                  }
                  size={7}
                  mr="2"
                  color="muted.400"
                  onPress={() => setSecure(!secure)}
                />
              }
              onEndEditing={() => check(pass.pass2)}
            />
            {!passmin ? (
              <Text mt="-3" style={StyleSigInScreen.textoErrorPassmin}>
                La contraseña no cumple con el requerimiento.
              </Text>
            ) : null}

            {!passOK ? (
              <Text mt="-3" style={StyleSigInScreen.textoErrorPassmin}>
                Las contraseñas no coinciden.
              </Text>
            ) : null}

            <Text style={StylePerfil.labelMon13}>
              Tu contraseña deber tener 6 caracteres como mínimo. No utilices tu
              nombre, apellido o correo.
            </Text>
            <Text mt="-4" style={StylePerfil.labelMon12}>
              Nota: Al cambiar la contraseña se cerrara la sesion de los otros
              dispositivos vinculados.
            </Text>
          </VStack>

          {changeOK ? (
            <HStack
              bg="#2ECC71"
              shadow="2"
              p="3"
              mt="2"
              borderRadius="2"
              space={2}
              justifyContent="center"
              alignItems="center"
              w={wp(94)}
            >
              <Ionicons
                name="checkmark-circle-outline"
                size={24}
                color="#fff"
              />
              <Text style={StyleSigInScreen.textoChangeOK}>
                La contraseña fue modificada con exito!
              </Text>
            </HStack>
          ) : null}

          {/*  {isLoading ? (
                        <Center mt='5'>
                            <ActivityIndicator size="large" color="#706F9A" />
                        </Center>
                    ) : null} */}
        </VStack>
      </HStack>

      <HStack
        pb="2"
        pl="2"
        pr="2"
        safeAreaBottom
        bg="#fff"
        shadow="3"
        p="3"
        mt="2"
        borderRadius="0"
        space={1}
        alignItem="center"
        justifyContent="center"
      >
        {isLoading ? (
          <Button
            style={StyleSigInScreen.btnLoginyPass}
            isLoading
            spinnerPlacement="end"
            width="90%"
            rounded="8"
          ></Button>
        ) : (
          <TouchableOpacity
            style={{
              alignItems: "center",
              backgroundColor: "#706F9A",
              padding: 10,
              borderRadius: 5,
              width: "90%",
            }}
            onPress={() => {
              SaveHandle();
            }}
          >
            <Text style={StylePerfil.TextoButtonGenerico}>
              Cambiar contraseña
            </Text>
          </TouchableOpacity>
        )}
      </HStack>
    </View>
  );
};

export default CambiarPassScreen;
