import React, { useState } from 'react';
import {
    Menu,
    Box,
    Pressable,
    Text,
    Center,
    View,
    Divider,
    HStack
} from "native-base";
import { StyleProfileScreen } from '../../styles/StyleQuodom';

import { MaterialIcons, Ionicons } from '@expo/vector-icons';


const MenuAccionesFoto = (props) => {

    return (
        <View>
            <Center flex={1}>
                <Box alignItems="flex-start">
                    <Menu borderRadius='8'
                        p='0'
                        borderWidth='0'
                        borderColor='#52575D'
                        shadow={5}
                        //w={wp(50)}
                        placement='bottom'
                        trigger={(triggerProps) => {
                            return (
                                <Pressable {...triggerProps}>
                                    {({ isHovered, isFocused, isPressed }) => {
                                        return (
                                            <Box
                                                p="0"
                                                rounded="8"
                                                style={{
                                                    transform: [
                                                        {
                                                            scale: isPressed ? 0.9 : 1,
                                                        },
                                                    ],
                                                }}
                                            >
                                                <MaterialIcons name="photo-camera" size={23} color="#DFD8C8" />

                                            </Box>
                                        )
                                    }
                                    }
                                </Pressable>
                            )
                        }}
                    >


                            <Menu.Item value='OTRO' onPress={() => props.eliminar()} p='3' borderRadius='3'>
                                <HStack space={2} alignItems='center'>
                                    <Ionicons name="close-circle-outline" size={23} color="#52575D" />
                                    <Text style={StyleProfileScreen.textMenuFiltros}>Eliminar imagen</Text>
                                </HStack>
                            </Menu.Item>
{/* 
                        <Menu.Item onPress={() => props.modificar()} borderWidth='0' borderColor='#725B91' borderRadius='5' mb='1' alignItems="center">
                            <Text style={StyleProfileScreen.textMenuDireccion}>Modificar</Text></Menu.Item>
                        <Divider></Divider>

                        <Menu.Item onPress={() => props.eliminar()} borderWidth='0' borderColor='#725B91' borderRadius='5' alignItems="center">
                            <Text style={StyleProfileScreen.textMenuDireccion}>Eliminar</Text></Menu.Item> */}

                    </Menu>
                </Box>

            </Center>
        </View>


    )
}

export default MenuAccionesFoto

