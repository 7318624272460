const API = "https://api.quodom.com.ar";
//const API = "http://192.168.0.113:3999"; //MARCOS para pruebas en local
//const API = "http://192.168.10.145:3999"; //SEBA
//const API = "http://192.168.1.59:3999"; //SEBA FORMAID
//const API = "http://192.168.0.173:3999";

const timeout = 15000;

const Error = (err) => {
  //console.log("ERROR", err);
  if (err.message === "Aborted") {
    return { res: false, message: "Compruebe su conexión a internet." };
  } else {
    return { res: false, message: err.message };
  }
};

export const LogInApi = async (userInfo) => {
  try {
    //console.log("-- Request Login Info user:");
    ////console.log(userInfo);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    var init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
      signal: controller.signal,
      timeout: timeout,
    };

    var response = await fetch(API + "/users/signin", init);
    clearTimeout(id);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    return { res: false, message: err.message };
  }
};

export const ForgotPassApi = async (userInfo) => {
  try {
    var init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    };

    var response = await fetch(API + "/users/reset", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    return { res: false, message: err.message };
  }
};

export const getUsers = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getUsersUser = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/user", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getUsersVendor = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/vendor", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getMovLiquidaciones = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/mov_liquidaciones/", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const updateUser = async (token, data, id) => {
  try {
    //console.log(data, id);
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/users/" + id, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const SignUpApi = async (userInfoSign) => {
  //console.log(JSON.stringify(userInfoSign));
  try {
    var init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfoSign),
    };

    var response = await fetch(API + "/users/signup", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDataUsuario = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/current", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDataUsuarioConFoto = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/currentFoto", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerMisQuodoms = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/quodom/misquodom", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDetalleQuodoms = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/quodom_lines/" + idquodom, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CrearQuodom = async (token, paramdescripcion, iddireccion) => {
  try {
    var data = { descripcion: paramdescripcion, iddireccion: iddireccion };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/quodom/create", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const EliminarQuodom = async (token, idquodom) => {
  try {
    //console.log("Eliminar Quodom Id:", idquodom);
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/quodom/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ObtenerCategorias = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/categorias", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerSubCategorias = async (token, catPadre) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/categorias/Sub/" + catPadre, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosCat = async (token, catPadre) => {
  try {
    ////console.log('ENTRE A BUSCAR PRODUCTOS CON ID: ' + catPadre);
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/productos/categoria/" + catPadre, init);
    //console.log("SALGO PRODUCTOS CON ID: " + catPadre);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosAtributos = async (
  token,
  idproducto,
  nombreatributo
) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/quodom_lines/atributos?idproducto=" +
        idproducto +
        "&nombreatributo=" +
        nombreatributo,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ObtenerProductosAtributosVendedor = async (
  token,
  idproducto,
  nombreatributo
) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/cotizacioneslines/atributos/vendedor?idproducto=" +
        idproducto +
        "&nombreatributo=" +
        nombreatributo,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const GuardarUsuario = async (token, userinfo) => {
  //console.log(userinfo);
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(userinfo),
    };

    var response = await fetch(API + "/users/", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CambiarFotoProfile = async (token, userinfo) => {
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(userinfo),
    };

    var response = await fetch(API + "/users/cambiarFoto/" + userinfo.id, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerCategoriasUsuario = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/users/categorias", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerZonasUsuario = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/users/zonas", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerZonas = async (localidad) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "bearer " + token
      },
    };
    var response = await fetch(API + "/zonas/loc/" + localidad, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProvincias = async () => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "bearer " + token
      },
    };
    var response = await fetch(API + "/provincias", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerLocalidad = async (provincia, tipo) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "bearer " + token
      },
    };

    var response = await fetch(
      API + "/localidades/prov?idprovincia=" + provincia + "&tipo=" + tipo,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const deleteCategoria = async (token, idcategoria) => {
  try {
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/user_categoria/" + idcategoria, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const deleteZona = async (token, idzona) => {
  try {
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/user_zonas/" + idzona, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const AsociarCategoria = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/user_categoria/create", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const AsociarZona = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/user_zonas/create", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ModificarDetalleQuodom = async (token, idquodomline, data) => {
  try {
    ////console.log('DATAAAAAAAAAAAAAAAAA', data);
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/quodom_lines/" + idquodomline, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const EliminarDetalleQuodom = async (token, idlinea) => {
  try {
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/quodom_lines/" + idlinea, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const InsertarDetalleQuodom = async (token, data) => {
  try {
    ////console.log('DATA', data);
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/quodom_lines/add/", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const setearInfoDispositivoLogin = async (infodispositivo, token) => {
  ////console.log("ENTRE A SETEAR LOS DATOS DEL DEVICE", infodispositivo);
  try {
    var init = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(infodispositivo),
    };
    var response = await fetch(API + "/users_logs/add", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    return { res: false, message: err.message };
  }
};
export const ObtenerListaOfertas = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/listaCotizaciones", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDireccionesUsuario = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/users/dire", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ModificarQuodom = async (token, idquodom, data) => {
  ////console.log("ModQuodom");
  ////console.log(token);
  ////console.log(data);
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/quodom/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ModificarQuodomACotizado = async (token, idquodom, data) => {
  ////console.log("ModQuodom");
  ////console.log(token);
  ////console.log(data);
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/quodom/cot/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ModificarACotizado = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/cotizacionesheaders/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerQuodom = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/quodom/vista/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const SendQuodom = async (token, idquodom, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/quodom/enviar/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerOfertasItems = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/ofertas/" + idquodom, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const CrearCabCotizacionVenta = async (token, data) => {
  try {
    //var data = { estado: 'CREADO', idquodom: idquodom, usuariovendedor: userid };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/cotizacionesheaders/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CrearLineasCotizacionVenta = async (token, data) => {
  try {
    // var data = { idquodomline: idquodomline, idquodom: idquodom, idcotizacion: idcotizacion };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacioneslines/create", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ActualizarLineaCotizacionVenta = async (
  token,
  idquodom,
  idquodomline,
  idcotizacion,
  preciounitario,
  envio,
  userid,
  vend_atributo1,
  vend_atributo2
) => {
  try {
    var data = {
      idquodomline: idquodomline,
      idquodom: idquodom,
      idcotizacion: idcotizacion,
      preciounitario: preciounitario,
      envio: envio,
      usuarioVendedor: userid,
      atributo1: vend_atributo1,
      atributo2: vend_atributo2,
    };
    //console.log("DATAAA:", data);
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacioneslines/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ActualizarLineaCotizacionVentaFoto = async (
  token,
  idquodom,
  idquodomline,
  idcotizacion,
  marca,
  preciounitario,
  envio,
  userid,
  foto,
  vend_atributo1,
  vend_atributo2
) => {
  try {
    var data = {
      idquodomline: idquodomline,
      idquodom: idquodom,
      idcotizacion: idcotizacion,
      marca: marca,
      preciounitario: preciounitario,
      envio: envio,
      usuarioVendedor: userid,
      imagendelvendedor: foto,
      atributo1: vend_atributo1,
      atributo2: vend_atributo2,
    };
    // //console.log(data);
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacioneslines/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const ObtenerDireccionesDefault = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/users/direcciondefault", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDatosDireccion = async (token, iddireccion) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/user_direcciones/" + iddireccion, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const CrearOperNotificacion = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    //console.log("te llamé");

    var response = await fetch(API + "/oper_notificaciones/create", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDetalleProductoCotizado = async (
  token,
  idquodom,
  idquodomline
) => {
  ////console.log('_API idquodom: ', idquodom, ' idquodomline: ', idquodomline);
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    ////console.log(API + '/cotizacioneslines/?idq=' + idquodom + '&idql=' + idquodomline);
    var response = await fetch(
      API + "/cotizacioneslines/?idq=" + idquodom + "&idql=" + idquodomline,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CrearDireccion = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/user_direcciones/create", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CrearDireccionPublic = async (data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "bearer " + token
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/user_direcciones/createPublic", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const deleteDireccion = async (token, iddireccion) => {
  try {
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/user_direcciones/" + iddireccion, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const dirPrincipal = async (token, iddireccion, userid) => {
  try {
    var data = { iddireccion: iddireccion, userid: userid };

    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(
      API + "/user_direcciones/prin/" + iddireccion,
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.text();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ModDireccion = async (token, data) => {
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(
      API + "/user_direcciones/" + data.iddireccion,
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.text();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const IgnorarQuodom = async (token, idquodom) => {
  try {
    var data = { idQuodom: idquodom };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/ignorar_quodom_vendedor/create", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const DeshacerIgnorarQuodom = async (token, idquodom) => {
  try {
    var init = {
      method: "DELETE",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(
      API + "/ignorar_quodom_vendedor/" + idquodom,
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerQLCotizados = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/quodom_lines/cotizado/" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const seleccionProductoComprador = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacioneslines/seleccion/", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const FinalizarQuodom = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacionesheaders/finalizar/", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerResumenVendido = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/cotizacioneslines/resumen/?idq=" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerResumenVendedor = async (token, idquodom, idvendor) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/cotizacioneslines/resumen/vendedor?idq=" +
        idquodom +
        "&idvendor=" +
        idvendor,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerInfoVendedor = async (token, idvendor, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/users/infoVendedor/?idv=" + idvendor + "&idq=" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosID = async (token, id) => {
  try {
    ////console.log('ENTRE A BUSCAR PRODUCTOS CON ID: ' + id);
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/productos/" + id, init);
    ////console.log('SALGO PRODUCTOS CON ID: ' + id);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log("error", err);
    return { res: false, message: err.message };
  }
};

export const UltimoQuodom = async (token) => {
  try {
    var data = { descripcion: "Nombre Quodom" };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/quodom/getLastQuodom", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosCatQuodom = async (token, quodom, catPadre) => {
  try {
    ////console.log('ENTRE A BUSCAR PRODUCTOS CON ID: ' + catPadre, '-', quodom);
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    ////console.log(API + '/productos/categoriaQ/'+quodom +'/' + catPadre);
    var response = await fetch(
      API + "/productos/categoriaQ/" + quodom + "/" + catPadre,
      init
    );
    ////console.log('SALGO PRODUCTOS CON ID: ' + catPadre);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerInfoComprador = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users/infoComprador/" + idquodom, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerOfertasItemsVendido = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/ofertas/vendido/" + idquodom, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const Buscar = async (token, text) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    let tipo = "p";
    var response = await fetch(
      API + "/busqueda/?b=" + text + "&t=" + tipo,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerResumenPago = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/cotizacioneslines/resumenpago/?idq=" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ArchivarCH = async (token, idquodom, archivar) => {
  try {
    var data = { archivado: archivar, idquodom: idquodom };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/cotizacionesheaders/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getPorcById = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/quodom/porccompletado/" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerCotQudomProv = async (token, idquodom, idproveedor) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/cotizacionesheaders/quodom/vendedor?idq=" +
        idquodom +
        "&idvendor=" +
        idproveedor,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ActualizarLineaCotizacionVenta2 = async (token, data) => {
  try {
    // var data = { idquodomline: idquodomline, idquodom: idquodom, idcotizacion: idcotizacion, preciounitario: preciounitario, envio: envio, usuarioVendedor: userid, atributo1: vend_atributo1, atributo2: vend_atributo2 };
    ////console.log('DATAAA:', data);
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacioneslines/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
export const CrearCabCotizacionVenta2 = async (token, idquodom) => {
  try {
    var data = { estado: "CREADO", idquodom: idquodom };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/cotizacionesheaders/cou", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CrearOperNotificacionQuodom = async (
  token,
  tiponotificacion,
  idquodom,
  fecha,
  titulo,
  texto
) => {
  try {
    var data = {
      tiponotificacion: tiponotificacion,
      idquodom: idquodom,
      fecha: fecha,
      titulo: titulo,
      texto: texto,
    };
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/oper_notificaciones/sendQuodom", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDispositivosVinculados = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/users_logs", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerUsuarioVinculado = async (token, data) => {
  ////console.log('token', token)
  ////console.log('DATA', data)
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/users/vinculado", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const updateDesvincular = async (token, data) => {
  try {
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/users_logs/" + data.id, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerNotificaciones = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/oper_notificaciones", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerCountNotificaciones = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/oper_notificaciones/count", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getQuodomCreados = async (token) => {
  try {
    //var data = { descripcion: 'Nombre Quodom' };
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/quodom/getQuodomCreados", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerListaOfertasArchivadas = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/listaCotizaciones/archivadas", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const UpdateNotificaciones = async (token, data, id) => {
  try {
    //console.log(data);
    var init = {
      method: "PUT",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };

    var response = await fetch(API + "/oper_notificaciones/" + id, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CerrarQuodom = async (token, idquodom, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/quodom/cerrar/" + idquodom, init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerCotizacion = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/listaCotizaciones/cotizacion/" + idquodom,
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getDistancia = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(
      API + "/cotizacionesheaders/getDistancia/",
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CalificarComprador = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/cotizacionesheaders/calificar/", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const Pago = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(API + "/pago/Checkout", init);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const Checkpayment = async (tokenId) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //"Authorization": "bearer " + token
      },
    };
    var response = await fetch(API + "/pago/Checkpayment/" + tokenId, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const UpdateNotificacionesAll = async (token) => {
  try {
    //console.log("entre a marcar leidas");
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/oper_notificaciones/marcarLeidaAll",
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosCatQuodomBuscar = async (
  token,
  quodom,
  catPadre,
  buscar
) => {
  try {
    ////console.log('ENTRE A BUSCAR PRODUCTOS CON ID: ' + catPadre, '-', quodom);
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    ////console.log(API + '/productos/categoriaQ/'+quodom +'/' + catPadre);
    var response = await fetch(
      API +
        "/productos/categoriaBuscar/" +
        quodom +
        "/" +
        catPadre +
        "/" +
        buscar,
      init
    );
    ////console.log('SALGO PRODUCTOS CON ID: ' + catPadre);
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const RepetirQuodom = async (token, data, idquodomOriginal) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(
      API + "/quodom/repetir/" + idquodomOriginal,
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getCalificacionesUsuario = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/calificar/total", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getMisCalificaciones = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/calificar/miscalificaciones", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getCalificacionesVendor = async (token, userId) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/calificar/" + userId, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerFactura = async (token, idcotizacion, usuariovendedor) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //  Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/cotizaciones_facturas/?idcotizacion=" +
        idcotizacion +
        "&usuariovendedor=" +
        usuariovendedor,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const UpdateFactura = async (token, factura) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(factura),
    };

    var response = await fetch(API + "/cotizaciones_facturas/create", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CheckCuitMobbex = async (token, cuit) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(cuit),
    };

    var response = await fetch(API + "/pago/CheckCuit", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerOfertasItemsEnviados = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/ofertas/enviado/" + idquodom, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerLocalidadPorID = async (token, id, tipo) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/localidades/" + id, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDireccionePorID = async (token, id) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/user_direcciones/" + id, init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDireccionesUsuarioQ = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/users/direccioncompra", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const getDistanciaChange = async (token, data) => {
  try {
    var init = {
      method: "POST",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
      body: JSON.stringify(data),
    };
    var response = await fetch(
      API + "/cotizacionesheaders/getDistanciaChange/",
      init
    );
    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerDistancia = async (token, idquodom) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API + "/cotizacionesheaders/getByIdQuodom/" + idquodom,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const CheckTransactionId = async (token, transactionId) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(
      API + "pago/CheckTransactionId?transactionId='" + transactionId,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const sendEmailInvoice = async (
  token,
  idcotizacion,
  usuariovendedor
) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(
      API +
        "/cotizaciones_facturas/enviarCorreo/?idcotizacion=" +
        idcotizacion +
        "&usuariovendedor=" +
        usuariovendedor,
      init
    );

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerProductosDestacados = async (token) => {
  try {
    ////console.log('ENTRE A BUSCAR PRODUCTOS CON ID: ' + catPadre);
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };
    var response = await fetch(API + "/productos/destacados/", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};

export const ObtenerMisQuodomsUltimos4 = async (token) => {
  try {
    var init = {
      method: "GET",
      cache: "no-store",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + token,
      },
    };

    var response = await fetch(API + "/quodom/misquodom4", init);

    if (response.status === 500) {
      //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
      return { res: false, message: "Error en el servidor." };
    } else {
      return await response.json();
    }
  } catch (err) {
    //console.log(err);
    return { res: false, message: err.message };
  }
};
