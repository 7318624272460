import { Animated, FlatList, StyleSheet, Text, View } from "react-native";
import React, { useRef, useState } from "react";
import SlideItem from "./SlideItem";
import PaginationVendedor from "./PaginationVendedor";
import { VStack, HStack } from "native-base";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import img1 from "../../../assets/slidesnew/slideVendedor1.jpg";
import img2 from "../../../assets/slidesnew/slideVendedor2.jpg";
import img3 from "../../../assets/slidesnew/slideVendedor3.jpg";
import img4 from "../../../assets/slidesnew/slideVendedor4.jpg";

const SliderVendedor = () => {
  const Slides = [
    {
      id: 1,
      img: require("../../../assets/slidesnew/slideVendedor1.jpg"),
    },
    {
      id: 2,
      img: require("../../../assets/slidesnew/slideVendedor2.jpg"),
    },
    {
      id: 3,
      img: require("../../../assets/slidesnew/slideVendedor3.jpg"),
    },
    {
      id: 4,
      img: require("../../../assets/slidesnew/slideVendedor4.jpg"),
    },
  ];
  const images = [
    "../../../assets/slidesnew/slideVendedor1.jpg",
    "../../../assets/slidesnew/slideVendedor2.jpg",
    "../../../assets/slidesnew/slideVendedor3.jpg",
    "../../../assets/slidesnew/slideVendedor4.jpg",
  ];

  return (
    <Slide>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${img1})` }}></div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${img2})` }}></div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${img3})` }}></div>
      </div>
      <div className="each-slide-effect">
        <div style={{ backgroundImage: `url(${img4})` }}></div>
      </div>
    </Slide>
  );
};

export default SliderVendedor;
