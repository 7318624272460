import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import {
  DetalleProductoQuodomstyle,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias } from "../../styles/general";

import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { hp, wp, moderateScale } from "../../styles/responsive";

import {
  Box,
  Center,
  HStack,
  Stack,
  VStack,
  Button,
  FlatList,
  Divider,
} from "native-base";

const ResumenPagoItem = (props) => {
  useEffect(() => {
    //console.log("Entre al useEffect ResumenPagoItem.js");
  }, []);

  return (
    <View>
      <HStack pt="2" pb="2" borderWidth={0}>
        <HStack justifyContent="flex-start" space={2}>
          <VStack justifyContent="center" w="40%" borderWidth={0}>
            <Text style={DetalleProductoQuodomstyle.textoTituloCotizadoResumen}>
              <Text
                style={DetalleProductoQuodomstyle.textoTituloCotizadoResumen2}
              >
                {props.cantidad}x
              </Text>{" "}
              {props.nombreProducto}
            </Text>
          </VStack>

          <View
            style={{
              borderLeftWidth: 1,
              borderLeftColor: "#4D4D4D",
            }}
          />

          <VStack justifyContent="center" borderWidth={0} w="58%">
            {props.envio ? (
              <HStack alignItems="center" space={1}>
                <Text style={QuodomDetalle.TextoPrecioResumen}>
                  ${props.preciounitario}
                </Text>
                <HStack
                  space={1}
                  bg="#fff"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    textAlign: "center",
                    padding: 3,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 5,
                  }}
                >
                  <MaterialCommunityIcons
                    name="truck-outline"
                    size={15}
                    color="#4D4D4D"
                  />
                  <Text
                    style={{
                      color: "#4D4D4D",
                      fontSize: 11,
                      fontFamily: "Prompt_300Light",
                    }}
                  >
                    Envio Incluido
                  </Text>
                </HStack>
              </HStack>
            ) : (
              <HStack alignItems="center" space={1}>
                <Text style={QuodomDetalle.TextoPrecioResumen}>
                  ${props.preciounitario}
                </Text>
                <HStack
                  space={1}
                  bg="#fff"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    textAlign: "center",
                    padding: 3,
                    borderWidth: 1,
                    borderColor: "#4D4D4D",
                    shadowRadius: 0,
                    shadowOpacity: 0,
                    elevation: 0,
                    borderRadius: 5,
                  }}
                >
                  <MaterialIcons name="storefront" size={15} color="#4D4D4D" />
                  <Text
                    style={{
                      color: "#4D4D4D",
                      fontSize: 11,
                      fontFamily: "Prompt_300Light",
                    }}
                  >
                    Retiro en tienda
                  </Text>
                </HStack>
              </HStack>
            )}
          </VStack>
        </HStack>
      </HStack>
    </View>
  );
};
export default ResumenPagoItem;
