import React, { useState } from "react";
import { Switch } from "react-native";
import {
  Menu,
  Box,
  Pressable,
  Text,
  Center,
  View,
  Divider,
  HStack,
  Input,
} from "native-base";

import { StyleProfileScreen } from "../../styles/StyleQuodom";

import { MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import { hp, moderateScale, wp } from "../../styles/responsive";

const FiltrosSeleccionProveedor = (props) => {
  const [expanded, setExpanded] = React.useState(true);

  const handlePress = () => setExpanded(!expanded);

  return (
    <View>
      <Center flex={1}>
        <Box alignItems="flex-start">
          <Menu
            borderRadius="8"
            closeOnSelect={false}
            //bg='#fff'
            p="0"
            borderWidth="0"
            borderColor="#45444C"
            shadow={5}
            //w={wp(50)}
            placement="bottom left"
            trigger={(triggerProps) => {
              return (
                <Pressable {...triggerProps}>
                  {({ isPressed }) => {
                    return (
                      <Box
                        p="0"
                        rounded="0"
                        style={{
                          transform: [
                            {
                              scale: isPressed ? 0.95 : 1,
                            },
                          ],
                        }}
                      >
                        <HStack
                          space={1}
                          alignItems="center"
                          pt="1"
                          pb="1"
                          pl="3"
                          pr="3"
                          style={{
                            textAlign: "center",
                            borderWidth: 1,
                            borderColor: "#45444C",
                            shadowRadius: 0,
                            shadowOpacity: 0,
                            elevation: 0,
                            borderRadius: 20,
                          }}
                        >
                          <AntDesign name="filter" size={20} color="#45444C" />
                          <Text
                            style={{
                              color: "#45444C",
                              fontSize: moderateScale(8),
                              fontFamily: "Montserrat_400Regular",
                            }}
                          >
                            Filtros
                          </Text>
                          {/* <MaterialCommunityIcons name="filter-menu-outline" size={moderateScale(17)} color='#45444C' /> */}
                        </HStack>
                      </Box>
                    );
                  }}
                </Pressable>
              );
            }}
          >
            <Menu.OptionGroup title="Envios" defaultValue="TODOS" type="radio">
              <Menu.ItemOption
                value="TODOS"
                onPress={() => props.filtrar(0, "ENVIO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>Todos</Text>
              </Menu.ItemOption>
              <Menu.ItemOption
                value="CON"
                onPress={() => props.filtrar(1, "ENVIO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Envio Incluido
                </Text>
              </Menu.ItemOption>
              <Menu.ItemOption
                value="SIN"
                onPress={() => props.filtrar(null, "ENVIO")}
                p="2"
                borderRadius="3"
              >
                <Text style={StyleProfileScreen.textMenuFiltros}>
                  Retira en tienda
                </Text>
              </Menu.ItemOption>
            </Menu.OptionGroup>
          </Menu>
        </Box>
      </Center>
    </View>
  );
};

export default FiltrosSeleccionProveedor;
