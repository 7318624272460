import React, { useEffect, useState, useRef } from "react";
import {
  TouchableOpacity,
  RefreshControl,
  View,
  StyleSheet,
  ActivityIndicator,
  Dimensions,
} from "react-native";
import {
  Center,
  HStack,
  Icon,
  Box,
  VStack,
  useToast,
  Link,
  Pressable,
  Text,
  FlatList,
  Image,
  Spacer,
} from "native-base";
import {
  Ionicons,
  MaterialCommunityIcons,
  AntDesign,
  MaterialIcons,
} from "@expo/vector-icons";
import {
  ObtenerListaOfertas,
  IgnorarQuodom,
  ArchivarCH,
  DeshacerIgnorarQuodom,
  ObtenerOfertasItemsEnviados,
} from "../../api";
import OfertasItem from "./OfertasItem";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";

import {
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
} from "@expo-google-fonts/prompt";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { hp, moderateScale, WIDTH, wp } from "../../styles/responsive";
import { colorVioleta } from "../../styles/general";
import quodomTheme from "../../constants/Theme";
import { QuodomDetalle, QuodomList_Card } from "../../styles/StyleQuodom";
import Loader from "../../components/Loader";
import FiltrosCotizaciones from "./FiltrosCotizaciones";

import { SwipeListView } from "react-native-swipe-list-view";
import { Button, Snackbar } from "react-native-paper";

const OfertasList = (props) => {
  const mounted = useRef(true);
  const route = useRoute();
  const flatListRef = useRef();
  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
  });

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  const isFocused = useIsFocused();
  const [cotizaciones, setCotizaciones] = useState([]);
  const [oldcotizaciones, setoldcotizaciones] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingItem, setisLoadingItem] = useState(false);
  const [autoReload, setautoReload] = useState(4000);
  const [id, setId] = useState();
  const [visibleArchivar, setvisibleArchivar] = useState(false);
  const [visibleIgnorar, setvisibleIgnorar] = useState(false);
  const [filtrado, setFiltrado] = useState(false);
  const WIDTH = Dimensions.get("window").width;
  const HEIGHT = Dimensions.get("window").height;

  const onDismissArchivar = () => setvisibleArchivar(false);
  const onDismissIgnorar = () => setvisibleIgnorar(false);

  const toast = useToast();

  const navigation = useNavigation();

  const getOfertas = async () => {
    ////console.log('rutass ',route);
    //console.log("Entre al getOfertas() OfertasList.js");
    try {
      const token = await AsyncStorage.getItem("userToken");
      const cotizaciones = await ObtenerListaOfertas(token);
      ////console.log('getOfertas', cotizaciones)
      if (mounted.current) {
        setCotizaciones(cotizaciones);
        setoldcotizaciones(cotizaciones);
        setisLoadingItem(false);
        setisLoading(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  const onRefresh = React.useCallback(() => {
    //console.log("Entre al onRefresh() OfertasList.js");
    setRefreshing(true);
    getOfertas().then(() => {
      setFiltrado(false);
      setRefreshing(false);
    });
  }, []);

  useEffect(() => {
    mounted.current = true;
    setisLoadingItem(true);
    //console.log("Entre al useEffect de OfertasList.js");
    getOfertas();
    /*   clearInterval(interval);
      //SL REVISAR ESTO PORQUE SIGUE HACIENDO LA LLAMADA AUNQUE NO ESTE EN LA SCREEN
      const interval = setInterval(() => {
        if (isFocused) {
          //console.log('si estoy deberia resfrescar');
          getOfertas();
          
        } else {
          //console.log('no estoy no deberia hacer nada');
          clearInterval(interval)
        }
      }, autoReload) */
    //return () => clearInterval(interval)
    return () => (mounted.current = false);
  }, [isFocused]);

  const filtrar = (valor, tipo) => {
    ////console.log('valor:', valor, tipo);
    setFiltrado(true);
    let cot = oldcotizaciones;
    ////console.log('COT', cot)
    if (tipo === "VENC") {
      if (valor === 4) {
        setCotizaciones(cot);
      } else if (valor === 1) {
        const newData = cot.filter(
          (item) =>
            (item.diasparavencimientoaceptacion === 1 ||
              item.diasparavencimientoaceptacion === 0) &&
            item.estado === "CREADO"
        );
        setCotizaciones(newData);
      } else {
        const newData = cot.filter(
          (item) =>
            item.diasparavencimientoaceptacion === valor &&
            item.estado === "CREADO"
        );
        setCotizaciones(newData);
      }
    }

    if (tipo === "ESTADO") {
      if (valor === "TODOS") {
        setCotizaciones(cot);
      } else {
        const newData = cot.filter((item) => item.estado === valor);
        setCotizaciones(newData);
      }
    }

    if (tipo === "ORDEN") {
      ////console.log('SOY EL ORDEN')
      if (valor === 0) {
        setCotizaciones(cot);
      } else {
        if (valor === 1) {
          //const newData = cot.sort((a, b) => new Date(a.fechavencimientoenvio).getTime() > new Date(b.fechavencimientoenvio).getTime());
          ////console.log('OPCION 1');
          ////console.log('COT', cot)
          setCotizaciones(cot);
        }

        if (valor === 2) {
          ////console.log('COT LIMPIA',cot)
          const newData = cot.sort(function (x, y) {
            var firstDate = new Date(x.fechacreacion),
              SecondDate = new Date(y.fechacreacion);

            if (firstDate < SecondDate) return -1;
            if (firstDate > SecondDate) return 1;
            return 0;
          });
          ////console.log('COT 2', newData);
          setCotizaciones(newData);
        }
      }
    }
  };

  async function Ignorar(idquodom) {
    const token = await AsyncStorage.getItem("userToken");
    IgnorarQuodom(token, idquodom);
  }

  async function DeshacerIgnorar(idquodom) {
    const token = await AsyncStorage.getItem("userToken");
    DeshacerIgnorarQuodom(token, idquodom);
  }

  async function Archivar(idquodom, archivar) {
    //clearInterval(interval)
    const token = await AsyncStorage.getItem("userToken");
    ArchivarCH(token, idquodom, archivar);
  }

  const EmptyList = ({}) => (
    <View style={{ marginVertical: 20, alignItems: "center" }}>
      {filtrado ? (
        <Box
          w="95%"
          bgColor="#EAEEF7"
          rounded={5}
          borderWidth={0}
          shadow={2}
          shadowRadius={8}
        >
          <HStack
            w="100%"
            justifyContent="flex-start"
            alignItems="center"
            space={3}
            p="5"
            borderWidth={0}
          >
            <AntDesign name="exclamationcircleo" size={24} color="black" />
            <Text
              w="90%"
              adjustsFontSizeToFit={true}
              numberOfLines={2}
              style={{
                fontSize: 14,
                fontFamily: "Montserrat_400Regular",
                color: "#000",
              }}
            >
              No tienes cotizaciones con esos filtros.
            </Text>
          </HStack>
        </Box>
      ) : (
        <Box
          w="95%"
          bgColor="#EAEEF7"
          rounded={5}
          borderWidth={0}
          shadow={2}
          shadowRadius={8}
        >
          <HStack
            w="100%"
            justifyContent="flex-start"
            alignItems="center"
            space={3}
            p="5"
            borderWidth={0}
          >
            <AntDesign name="exclamationcircleo" size={24} color="black" />
            <Text
              w="90%"
              adjustsFontSizeToFit={true}
              numberOfLines={2}
              style={{
                fontSize: 14,
                fontFamily: "Montserrat_400Regular",
                color: "#000",
              }}
            >
              Aún no tienes cotizaciones para tus categorías y zonas. Elegí
              categorías y zonas nuevas desde tu perfil.
            </Text>
          </HStack>
        </Box>
      )}
    </View>
  );

  const Header = ({}) => (
    <Pressable
      onPress={() => {
        navigation.navigate("OfertasListArchivadas");
      }}
    >
      {({ isPressed }) => {
        return (
          <Box
            p="0"
            rounded="0"
            style={{
              transform: [
                {
                  scale: isPressed ? 0.98 : 1,
                },
              ],
            }}
          >
            <HStack ml="3" mr="3" mb="3" mt="2">
              <Box width="100%">
                <HStack ml="1" space={2} alignItems="center">
                  <MaterialCommunityIcons
                    name="archive-arrow-down-outline"
                    size={20}
                    color="#e8e6df"
                  />
                  <Text
                    style={{
                      fontSize: moderateScale(8),
                      fontFamily: "Montserrat_400Regular",
                      color: "#e8e6df",
                    }}
                  >
                    Archivados
                  </Text>
                </HStack>
              </Box>
            </HStack>
          </Box>
        );
      }}
    </Pressable>
  );

  const Footer = ({}) => <Center h={hp(20)}></Center>;

  const HandleArchivar = (rowKey) => {
    setId(rowKey);
    setvisibleArchivar(true);
    //setoldcotizaciones(cotizaciones);
    Archivar(rowKey, 1);
    const newData = [...cotizaciones];
    const prevIndex = cotizaciones.findIndex(
      (item) => item.idquodom === rowKey
    );
    newData.splice(prevIndex, 1);
    setCotizaciones(newData);
  };

  async function HandleDeshacer() {
    if (oldcotizaciones.length === 0) {
      ////console.log('TODO MAL');
      getOfertas();
    } else {
      setCotizaciones(oldcotizaciones);
    }
    Archivar(id, 0);
  }

  async function HandleDeshacerIgnorar(idquodom) {
    if (oldcotizaciones.length === 0) {
      ////console.log('TODO MAL');
      getOfertas();
    } else {
      setCotizaciones(oldcotizaciones);
    }

    DeshacerIgnorar(id);
  }

  const HandleIgnorar = (rowKey) => {
    setId(rowKey);
    setvisibleIgnorar(true);
    Ignorar(rowKey);
    const newData = [...cotizaciones];
    const prevIndex = cotizaciones.findIndex(
      (item) => item.idquodom === rowKey
    );
    newData.splice(prevIndex, 1);
    setCotizaciones(newData);
  };

  const renderItem = (data) => (
    <OfertasItem cotizacion={data.item} isLoadingItem={isLoadingItem} />
  );

  const obtenerColor = (estado) => {
    if (estado === "VENDISTE" || estado === "COTIZADO" || estado === "CERRADO")
      return "#454e7a";
    else return "#c41f06";
  };

  const renderHiddenItem = (data) => (
    <HStack
      style={{
        alignItems: "center",
        backgroundColor: obtenerColor(data.item.estado),
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginLeft: 15,
        marginRight: 16,
        marginBottom: 5,
        marginTop: 5,
        borderRadius: 5,
      }}
    >
      {data.item.estado === "VENDISTE" ||
      data.item.estado === "COTIZADO" ||
      data.item.estado === "CERRADO" ? (
        <TouchableOpacity
          style={[styles.backRightBtn, styles.backRightBtnLeft]}
          onPress={() => HandleArchivar(data.item.idquodom)}
        >
          <Center>
            <Icon
              mb="1"
              as={<MaterialCommunityIcons name="archive-arrow-down-outline" />}
              color="#fff"
              size="6"
            />
            <Text style={styles.backTextWhite}>Archivar</Text>
          </Center>
        </TouchableOpacity>
      ) : null}
      {data.item.estado === "CREADO" ? (
        <TouchableOpacity
          style={[styles.backRightBtn, styles.backRightBtnRight]}
          onPress={() => HandleIgnorar(data.item.idquodom)}
        >
          <Center>
            <Icon
              mb="1"
              as={<MaterialCommunityIcons name="archive-clock" color="#fff" />}
              color="#fff"
              size="6"
            />
            <Text style={styles.backTextWhite}>Ignorar</Text>
          </Center>
        </TouchableOpacity>
      ) : null}
    </HStack>
  );

  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: "#F6EE5D", marginBottom: 0 }}>
        <TouchableOpacity
          style={{ marginLeft: "10px", marginTop: "10px" }}
          onPress={() => navigation.toggleDrawer()}
        >
          <Icon
            size="8"
            as={<MaterialIcons name="menu" />}
            color={quodomTheme.COLORS["COLORVIOLETA"]}
          />
        </TouchableOpacity>

        <Center>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Image
              source={require("../../assets/INICIO-16.png")}
              alt="Quodom."
              alignItems="center"
              marginBottom={"2%"}
              style={{ width: wp(10), height: wp(3) }}
            />
          </TouchableOpacity>
        </Center>

        {isLoadingItem ? (
          <View
            style={{
              Flex: 1,
              zIndex: 100,
              position: "absolute",
              top: 120,
              bottom: 0,
              width: "100%",
              backgroundColor: "#706f9a",
            }}
          >
            <Center flex="1">
              <ActivityIndicator size="large" color="#fff" />
            </Center>
          </View>
        ) : (
          <View
            style={{
              Flex: 1,
              zIndex: 100,
              position: "absolute",
              top: 120,
              bottom: 0,
              width: "100%",
              borderWidth: 0,
              backgroundColor: "#706f9a",
            }}
          >
            <VStack>
              <Center
                style={{
                  backgroundColor: "#706f9a",
                }}
              >
                <HStack width={"60%"} paddingTop={"2%"} paddingBottom={"1%"}>
                  <Text style={QuodomDetalle.textoTituloMisCotizacion}>
                    MIS COTIZACIONES
                  </Text>
                  <Spacer width={"40%"}></Spacer>
                  <FiltrosCotizaciones
                    visible={visible}
                    closeMenu={closeMenu}
                    openMenu={openMenu}
                    filtrar={filtrar}
                  />
                </HStack>

                <HStack width={"60%"}>
                  <FlatList
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                      />
                    }
                    ref={flatListRef}
                    keyboardShouldPersistTaps="always"
                    data={cotizaciones}
                    style={QuodomList_Card.gridView}
                    onEndReached={() => {
                      //console.log("-------------------list ended");
                    }}
                    initialNumToRender={6}
                    horizontal={false}
                    contentContainerStyle={{ flexGrow: 1 }}
                    numColumns="1"
                    snapToAlignment="center"
                    //ListEmptyComponent={(<NodoQuodomCrear actualizarestado={cambiarState} />)}
                    //ListFooterComponent={renderFooter}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.idquodom.toString()}
                  />
                </HStack>
              </Center>
            </VStack>
          </View>
        )}
      </View>
    );
  }
};

const styles = StyleSheet.create({
  itemButtons: {
    color: "#8395a7",
    marginRight: 0,
    textAlign: "left",
    padding: 0,
    marginEnd: 10,
    color: "#FFFFFF",
    fontSize: 13,
    borderWidth: 1,
    borderColor: "#FFFFFF",
    shadowRadius: 0,
    FowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
    width: WIDTH * 0.25,
  },
  item: {
    color: "#8395a7",
    marginRight: 0,
    textAlign: "left",
    padding: 0,
    width: WIDTH * 0.4,
    color: "#FFFFFF",
    fontSize: 16,
    shadowRadius: 0,
    shadowOpacity: 0,
    elevation: 0,
    borderRadius: 5,
    marginBottom: 0,
  },

  backTextWhite: {
    //color: '#FFF',
    color: "#fff",
    fontSize: moderateScale(12),

    //borderWidth: 1,
    // height: hp(13)
  },

  rowBack: {
    alignItems: "center",
    backgroundColor: "#454e7a",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 15,
    marginRight: 16,
    marginBottom: 5,
    marginTop: 5,
    borderRadius: 5,
  },
  backRightBtn: {
    alignItems: "center",
    bottom: 0,
    justifyContent: "center",
    position: "absolute",
    top: 0,
    width: 75,
  },
  backRightBtnLeft: {
    backgroundColor: "#454e7a",
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  backRightBtnRight: {
    backgroundColor: "#c41f06",
    right: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
});

export default OfertasList;
