import React, { useEffect, useState } from "react";
import {
  View,
  Image,
  Text,
  Pressable,
  Modal,
  TextInput,
  TouchableOpacity,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import {
  DetalleProductoQuodomstyle,
  QuodomList_Card,
  QuodomDetalle,
} from "../../styles/StyleQuodom";
import { basico, estilosCategorias, ModalStyle } from "../../styles/general";
import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";

import { Box, HStack, VStack } from "native-base";

const OfertasRenderItemEnviado = (props) => {
  const comprador_nombreAtributo1 = props.comprador_nombreAtributo1;
  const comprador_nombreAtributo2 = props.comprador_nombreAtributo2;
  const vendedor_nombreAtributo1 = props.vendedor_nombreAtributo1;
  const vendedor_nombreAtributo2 = props.vendedor_nombreAtributo2;

  //console.log(props.vendedor_nombreAtributo1, props.vendedor_nombreAtributo2);
  const atr1_comprador =
    comprador_nombreAtributo1 !== "" && comprador_nombreAtributo1 !== null;
  const atr2_comprador =
    comprador_nombreAtributo2 !== "" && comprador_nombreAtributo2 !== null;

  const atr1_vendor =
    vendedor_nombreAtributo1 !== "" && vendedor_nombreAtributo1 != null;
  const atr2_vendor =
    vendedor_nombreAtributo2 !== "" && vendedor_nombreAtributo2 != null;

  function capitalize(string) {
    if (string !== "" && string !== null)
      try {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      } catch (err) {
        return;
      }
  }

  const CortarFecha = (texto) => {
    if (texto == null) {
      return texto;
    } else {
      return (
        texto.substr(8, 2) + "/" + texto.substr(5, 2) + "/" + texto.substr(0, 4)
      );
    }
  };

  return (
    <HStack flex={1} mb="2" justifyContent="center">
      <Box
        w="52%"
        roundedTopLeft={8}
        roundedBottomLeft={8}
        bgColor="#706F9A"
        shadow={2}
        shadowRadius={8}
        elevation={5}
      >
        <HStack space={0} alignItems="center">
          <VStack>
            {props.imagendelvendedor !== null ? (
              <Image
                source={{
                  uri: "data:image/png;base64," + props.imagendelvendedor,
                }}
                alt="."
                style={estilosCategorias.ImagenDetalleQuodom}
              />
            ) : (
              <Image
                source={{
                  uri: props.imagen,
                }}
                style={estilosCategorias.ImagenDetalleQuodom}
              />
            )}
          </VStack>
          <VStack
            style={{ paddingTop: 1, paddingLeft: 5, paddingRight: 8 }}
            w="65%"
          >
            <Text style={DetalleProductoQuodomstyle.textoTituloCotizado}>
              {props.nombreproducto}
            </Text>
          </VStack>
        </HStack>

        <HStack pt="2" pl="3" pb="4">
          <VStack space={2.5}>
            {props.enviovendedor ? (
              <HStack
                space={1}
                bg="transparent"
                alignItems="center"
                style={{
                  textAlign: "center",
                  padding: 3,
                  borderWidth: 1,
                  borderColor: "#fff",
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 5,
                }}
              >
                <MaterialCommunityIcons
                  name="truck-outline"
                  size={18}
                  color="#fff"
                />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  Enviar
                </Text>
              </HStack>
            ) : (
              <HStack
                space={1}
                bg="transparent"
                alignItems="center"
                style={{
                  textAlign: "center",
                  padding: 3,
                  borderWidth: 1,
                  borderColor: "#fff",
                  shadowRadius: 0,
                  shadowOpacity: 0,
                  elevation: 0,
                  borderRadius: 5,
                }}
              >
                <MaterialIcons name="storefront" size={18} color="#fff" />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 13,
                  }}
                >
                  Retira en tienda
                </Text>
              </HStack>
            )}

            <Text style={DetalleProductoQuodomstyle.textoPrecio}>
              Precio{" "}
              {props.preciounitario.toLocaleString("es-AR", {
                style: "currency",
                currency: "ARS",
              })}
            </Text>
          </VStack>
        </HStack>
      </Box>

      <Box
        w="48%"
        bg="#fff"
        roundedTopRight={8}
        roundedBottomRight={8}
        shadow={2}
        shadowRadius={8}
        justifyContent="center"
      >
        <VStack alignItems="center" space={0.2} pl="1">
          <HStack w="95%" pb="1">
            <Text
              style={{ fontSize: 13, fontFamily: "Montserrat_600SemiBold" }}
            >
              Caracteristicas
            </Text>
          </HStack>

          <HStack w="95%">
            <Text style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}>
              Cantidad:
              <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                {" "}
                {props.cantidad}
              </Text>
            </Text>
          </HStack>

          {atr1_comprador ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.comprador_nombreAtributo1)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.comprador_atributo1}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr2_comprador ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.comprador_nombreAtributo2)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.comprador_atributo2}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr1_vendor ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.vendedor_nombreAtributo1)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.vendedor_atributo1}
                </Text>
              </Text>
            </HStack>
          ) : null}

          {atr2_vendor ? (
            <HStack w="95%">
              <Text
                textAlign="center"
                style={{ fontSize: 13, fontFamily: "Montserrat_300Light" }}
              >
                {capitalize(props.vendedor_nombreAtributo2)}:{" "}
                <Text style={{ fontSize: 13, fontFamily: "Prompt_400Regular" }}>
                  {" "}
                  {props.vendedor_atributo2}
                </Text>
              </Text>
            </HStack>
          ) : null}
        </VStack>
      </Box>
    </HStack>
  );
};
export default OfertasRenderItemEnviado;
