import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  RefreshControl,
  TouchableOpacity,
  ActivityIndicator,
  StyleSheet,
} from "react-native";
import {
  ObtenerQLCotizados,
  ObtenerDatosDireccion,
  FinalizarQuodom,
  ObtenerQuodom,
} from "../../api";
import {
  HStack,
  NativeBaseProvider,
  VStack,
  Center,
  FlatList,
  Divider,
  Button,
  Box,
  Image,
  Modal,
} from "native-base";
import {
  useNavigation,
  useIsFocused,
  useRoute,
} from "@react-navigation/native";
import { colorFondoGeneral } from "../../styles/general";
import { QuodomDetalle } from "../../styles/StyleQuodom";

import AsyncStorage from "@react-native-async-storage/async-storage";
import quodomTheme from "../../constants/Theme";
import {
  Ionicons,
  SimpleLineIcons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Loader from "../Loader";
import { moderateScale, wp } from "../../styles/responsive";
import DetalleProductoCotizado from "./DetalleProductoCotizado";
import { AnimatedFAB } from "react-native-paper";
import Moment from "moment";

const DetalleQuodomCotizado = (props) => {
  const mounted = useRef(true);

  const isFocused = useIsFocused();

  const route = useRoute();
  const navigation = useNavigation();
  const { vengode } = route.params;

  const [fechaenvio, setfechaenvio] = useState("");
  const [iddireccion, setiddireccion] = useState(0);
  const [nombreQuodom, setNombreQuodom] = useState("");
  const [idquodom, setidquodomState] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsDireccion, setDireccion] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [isLoadingAdentro, setisLoadingAdentro] = useState(true);
  const [isFinalizando, setIsFinalizando] = useState(false);
  const [isExtended, setIsExtended] = useState(true);

  ////console.log('iddireccion', iddireccion);
  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  function cortarDireccion(texto) {
    if (texto.length > 35) {
      return texto.slice(0, 35) + " ...";
    } else {
      return texto;
    }
  }

  const onRefresh = React.useCallback(() => {
    //console.log("entre al onRefresh de DetalleQuodomsCotizado.js");
    setRefreshing(true);
    wait(2000).then(() => setRefreshing(false));
  }, []);

  const Finalizar = async () => {
    setIsFinalizando(true);
    const token = await AsyncStorage.getItem("userToken");
    let data = { idquodom: idquodom };
    const res = await FinalizarQuodom(token, data);

    if (res) {
      setIsFinalizando(false);
    }
    setModalVisible(!modalVisible);
    //SL aca deberia ir a la pasarela de pago
    navigation.navigate("ResumenQuodomFinalizado", {
      idquodom: idquodom,
      nombreQuodom: nombreQuodom,
      vengode: vengode,
      fechaenvio: fechaenvio,
    });
  };

  const LeerDatos = async () => {
    const tokenLeer = await AsyncStorage.getItem("userToken");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const postsData = await ObtenerQLCotizados(tokenLeer, q.id);
    if (mounted.current) {
      ////console.log('--- postsData ORIGINAL', postsData)
      setPosts(postsData);
    }
  };

  const DatosDireccion = async () => {
    const tokenDir = await AsyncStorage.getItem("userToken");
    const valor = await AsyncStorage.getItem("quodomSeleccionada");
    const q = JSON.parse(valor);
    const datosDireccion = await ObtenerDatosDireccion(tokenDir, q.iddireccion);
    if (mounted.current) {
      let direccion = cortarDireccion(
        datosDireccion.alias +
          " " +
          datosDireccion.direccion +
          " " +
          datosDireccion.localidad
      );

      setDireccion("Enviar a " + direccion);
      //setDireccion('Enviar a ' + datosDireccion.alias + ' ' + datosDireccion.direccion + ' ' + datosDireccion.localidad)
      setisLoading(false);
      setisLoadingAdentro(false);
    }
  };

  const cambiarState = () => {
    setisLoadingAdentro(true);
    //console.log("Refresh cambiarState DetalleQuodomsCotizado.js");
    setRefreshing((state) => !state);
  };

  const validarEstado = async () => {
    const token = await AsyncStorage.getItem("userToken");
    const postsData = await ObtenerQuodom(token, idquodom);
    ////console.log('POSTTT', postsData)
    if (postsData.estado === "FINALIZADO") {
      navigation.navigate("ResumenQuodomFinalizado", {
        idquodom: idquodom,
        nombreQuodom: nombreQuodom,
      });
    } else {
      navigation.navigate("ResumenPago", {
        idquodom: idquodom,
        nombreQuodom: nombreQuodom,
        vengode: vengode,
      });
    }
  };

  const MarcarSeleccionado = (Item) => {
    ////console.log('--------------------------------entre al DetalleQuodomCotizado con iD', Item)
    ////console.log('--- DetalleQuodomCotizado posts', posts);
    const newData = posts.map((item) => {
      if (item.QLine === Item) {
        ////console.log('---!!!!!!!!!!!!!!! lo encontre y vale',item.QLine , item.cantidadSeleccionados )

        if (item.cantidadSeleccionados === "1") {
          item.cantidadSeleccionados = "0";
        } else {
          item.cantidadSeleccionados = "1";
        }
        return item;
      }
      return item;
    });
    ////console.log('--- DetalleQuodomCotizado newData', newData);
    setPosts(newData);
  };

  useEffect(() => {
    mounted.current = true;
    //console.log("Entre al useEffect DetalleQuodomsCotizado.js", vengode);
    AsyncStorage.getItem("quodomSeleccionada").then((valor) => {
      const q = JSON.parse(valor);
      setidquodomState(q.id);
      setNombreQuodom(q.descripcion);
      setfechaenvio(q.fechaenvio);
      setiddireccion(q.iddireccion);
      setisLoading(false);
    });
    LeerDatos();
    DatosDireccion();
    return () => (mounted.current = false);
  }, [refreshing, route]);

  let animateFrom;
  let style;
  const fabStyle = { [animateFrom]: 16 };

  function nombreQuodomEdit(texto) {
    if (texto.length > 18) {
      return texto.slice(0, 18) + " ...";
    } else {
      return texto;
    }
  }

  const onScroll = ({ nativeEvent }) => {
    ////console.log('isExtended Detalle', isExtended)
    const currentScrollPosition =
      Math.floor(nativeEvent?.contentOffset?.y) ?? 0;

    setIsExtended(currentScrollPosition <= 0);
    //return (currentScrollPosition <= 0)
  };

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: "#F6EE5D", marginBottom: 0 }}>
        <View
          style={{
            Flex: 1,
            zIndex: 100,
            position: "absolute",
            top: 10,
            bottom: 0,
            width: "100%",
            borderWidth: 0,
            backgroundColor: "#F6EE5D",
          }}
        >
          <VStack>
            <Center
              style={{
                backgroundColor: "#F6EE5D",
              }}
            >
              <HStack width={"60%"} paddingTop={"2%"} paddingBottom={"1%"}>
                <TouchableOpacity onPress={() => navigation.goBack()}>
                  <HStack space={2} alignItems="center" pt="3">
                    <Ionicons
                      name="arrow-back-sharp"
                      size={25}
                      color="#45444C"
                    />
                    <Text style={QuodomDetalle.textoTituloChico}>
                      Mis Quodoms Cotizados
                    </Text>
                  </HStack>
                </TouchableOpacity>
              </HStack>
              <HStack width={"60%"} paddingBottom={"1%"}>
                <HStack space={2} alignItems="center" pt="3">
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.textoTitulo}
                  >
                    {nombreQuodomEdit(nombreQuodom)}
                  </Text>
                </HStack>
              </HStack>
              <HStack
                style={{ paddingLeft: 5, paddingTop: 0 }}
                justifyContent="space-between"
                alignItems="center"
                overflow="hidden"
              >
                <VStack justifyContent="center">
                  <HStack space={6}>
                    <Center>
                      <Image
                        source={require("../../assets/1.png")}
                        //size={wp(15)}
                        size={63}
                        alt="Enviado"
                      ></Image>
                      <Text style={QuodomDetalle.textoChico}>Enviado</Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/2.png")}
                        //size={wp(15)}
                        size={63}
                        alt="Disponibles"
                      ></Image>
                      <Text style={QuodomDetalle.textoChico}>Disponibles</Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/3i.png")}
                        //size={wp(15)}
                        size={63}
                        alt="Elegí y comprá"
                      ></Image>
                      <Text style={QuodomDetalle.textoChico}>
                        Elegí y comprá
                      </Text>
                    </Center>

                    <Center>
                      <Image
                        source={require("../../assets/4e.png")}
                        //size={wp(15)}
                        size={63}
                        alt="Recibí o retirá"
                      ></Image>
                      <Text style={QuodomDetalle.textoChico}>
                        Recibí o retirá
                      </Text>
                    </Center>
                  </HStack>
                </VStack>
              </HStack>
              <HStack width={"60%"} paddingBottom={"1%"}>
                <HStack space={1} alignItems="center">
                  <SimpleLineIcons
                    name="location-pin"
                    size={moderateScale(13)}
                    color="#45444C"
                  />
                  <Text
                    adjustsFontSizeToFit={true}
                    numberOfLines={1}
                    style={QuodomDetalle.textoEnviarA}
                  >
                    {postsDireccion}
                  </Text>
                </HStack>
              </HStack>
              <HStack width={"60%"} paddingBottom={"1%"}>
                <Text
                  adjustsFontSizeToFit={true}
                  numberOfLines={1}
                  style={QuodomDetalle.textoEnviarA}
                >
                  Pedido enviado el {Moment(fechaenvio).format("DD/MM/YYYY")}
                </Text>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    backgroundColor: "transparent",
                    padding: 10,
                    borderRadius: 5,
                    borderWidth: 1,
                  }}
                  onPress={() => validarEstado()}
                >
                  <Text style={QuodomDetalle.TextoModalButtonOK}>
                    Revisar y Pagar
                  </Text>
                </TouchableOpacity>
              </HStack>
              <Divider bg="#45444C" />
              <HStack>
                <VStack>
                  <Text style={QuodomDetalle.textoTituloSeleccionarProveedor}>
                    Seleccióna un proveedor para cada producto:
                  </Text>
                </VStack>
              </HStack>
              <HStack width={"60%"}>
                {isLoadingAdentro ? (
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: colorFondoGeneral,
                      alignContent: "center",
                    }}
                  >
                    <Center flex="1" mt="20">
                      <ActivityIndicator size="large" color="#706F9A" h="30%" />
                    </Center>
                  </View>
                ) : (
                  <FlatList
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshing}
                        onRefresh={onRefresh}
                      />
                    }
                    //itemDimension={100}
                    data={posts}
                    //style={QuodomDetalle.gridView}
                    // spacing={20}
                    onScroll={onScroll}
                    ListFooterComponent={<Center h="200"></Center>}
                    renderItem={({ item }) => (
                      <DetalleProductoCotizado
                        //detalleProducto={item.detalleProducto}
                        nombreProducto={item.nombreProducto}
                        //marca={item.marca}
                        //unidad={item.unidad}
                        cantidad={item.cantidad}
                        imagen={item.imagen}
                        refreshImagen={item.refreshImagen}
                        //atributosFaltantes={item.atributosFaltantes}
                        idproducto={item.idproducto}
                        //nombreCategoria={item.nombreCategoria}
                        id={item.QLine}
                        idquodom={item.idquodom}
                        nombreQuodom={nombreQuodom}
                        cantidadCotizado={item.cantidadCotizado}
                        //actualizarestado={cambiarState}
                        cantidadSeleccionados={item.cantidadSeleccionados}
                        marcarSeleccionado={MarcarSeleccionado}
                        atributo1={item.atributo1}
                        atributo2={item.atributo2}
                        nombreAtributo1={item.nombreAtributo1}
                        nombreAtributo2={item.nombreAtributo2}
                        cantidadEnvio={item.cantidadEnvio}
                      />
                    )}
                    keyExtractor={(item) => item.QLine.toString()}
                  />
                )}
              </HStack>
            </Center>
          </VStack>
          <Modal isOpen={modalVisible} onClose={() => setModalVisible(false)}>
            <Modal.Content width={wp(95)}>
              {isFinalizando ? null : <Modal.CloseButton />}
              <Modal.Header>
                {isFinalizando ? (
                  <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                    Espere por favor
                  </Text>
                ) : (
                  <Text style={QuodomDetalle.TextoTituloModalEliminar}>
                    Atención
                  </Text>
                )}
              </Modal.Header>
              <Modal.Body pt="5" pb="5">
                {isFinalizando ? (
                  <HStack
                    style={{ paddingTop: 15, paddingEnd: 15 }}
                    justifyContent="space-between"
                    alignItems="center"
                    overflow="hidden"
                  >
                    <HStack space={2} alignItems="center">
                      <ActivityIndicator color="#706F9A" />
                      <Text style={QuodomDetalle.TextoModalEliminar}>
                        Finalizando pedido...
                      </Text>
                    </HStack>
                  </HStack>
                ) : (
                  <Text style={QuodomDetalle.TextoModalEliminar}>
                    Esta seguro de finalizar el pedido y proceder al pago? Una
                    vez finalizado no podra seleccionar otro proveedor.
                  </Text>
                )}
              </Modal.Body>
              <Modal.Footer>
                {isFinalizando ? null : (
                  <Button.Group space={2}>
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: "transparent",
                        padding: 10,
                        borderRadius: 5,
                        borderWidth: 1,
                      }}
                      onPress={() => Finalizar()}
                    >
                      <Text style={QuodomDetalle.TextoModalButtonOK}>
                        Si, Finalizar
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        alignItems: "center",
                        backgroundColor: "#706F9A",
                        padding: 10,
                        borderRadius: 5,
                      }}
                      onPress={() => setModalVisible(!modalVisible)}
                    >
                      <Text style={QuodomDetalle.TextoModalButtonCancelar}>
                        Cancelar
                      </Text>
                    </TouchableOpacity>
                  </Button.Group>
                )}
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  fabStyle: {
    bottom: 16,
    right: 16,
    position: "absolute",
  },
});

export default DetalleQuodomCotizado;
