import React, { useEffect, useState, useRef } from "react";
import {
  View,
  StyleSheet,
  Text,
  RefreshControl,
  ActivityIndicator,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import {
  useIsFocused,
  useRoute,
  useNavigation,
} from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  NativeBaseProvider,
  Center,
  Icon,
  HStack,
  FlatList,
} from "native-base";
import NodoQuodomLinea from "../../components/MisQuodoms/NodoQuodomLinea";
import { ObtenerMisQuodoms, getQuodomCreados } from "../../api";
import { QuodomList_Card } from "../../styles/StyleQuodom";
import AppBar from "../../components/AppBar";
import {
  useFonts,
  Prompt_300Light,
  Prompt_400Regular,
  Prompt_500Medium,
} from "@expo-google-fonts/prompt";
import Loader from "../../components/Loader";
import NodoQuodomCrearLinea from "../../components/MisQuodoms/NodoQuodomCrearLinea";
//import MenuAcciones2 from '../../components/MisQuodoms/MenuAcciones2';
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import quodomTheme from "../../constants/Theme";
import FiltrosQuodoms from "./FiltrosQuodoms";
const ListaMisQuodoms = ({ route, props }) => {
  //const route = useRoute();
  const navigation = useNavigation();
  const isFocused = useIsFocused();

  let [fontsLoaded] = useFonts({
    Prompt_300Light,
    Prompt_400Regular,
    Prompt_500Medium,
  });

  const mounted = useRef(true);

  const flatListRef = useRef();

  const toTop = () => {
    // use current
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
  };

  const [isLoading, setisLoading] = useState(true);
  const [abrirMenu, setabrirMenu] = useState(false);
  const [volverMenu, setVolverMenu] = useState("");
  const [actListaSub, setactListaSub] = useState(0);
  const [actNombreSub, setactNombreSub] = useState("");
  const [refreshing, setRefreshing] = useState(false);
  const [ultimoQuodom, setUltimoQuodom] = useState("");
  const [ultimoQuodom2, setUltimoQuodom2] = useState("");
  const [isLoadingAdentro, setisLoadingAdentro] = useState(true);
  const WIDTH = Dimensions.get("window").width;
  const HEIGHT = Dimensions.get("window").height;
  const [filtrado, setFiltrado] = useState(false);
  /*  setVolverMenu(navigation.getParam('volver'));
  setactListaSub(navigation.getParam('idcategoria'));
  setactNombreSub(navigation.getParam('nombrecategoria'));
*/

  const wait = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  };

  /* const onRefresh = React.useCallboldcotizacionesack(async () => {
    await LeerDatos();
  }, []); */

  const onRefresh = React.useCallback(() => {
    //console.log("Entre al onRefresh() ListaMisQuodoms.js");
    setRefreshing(true);
    //setisLoadingAdentro(true);
    LeerDatos().then(() => {
      setRefreshing(false);
      //setisLoadingAdentro(false);
    });
  }, []);

  let [posts, setPosts] = useState([]);
  let [oldposts, setoldPosts] = useState([]);
  const LeerDatos = async () => {
    //console.log("Entre al LeerDatos() ListaMisQuodoms.js");

    let ultimoq = await AsyncStorage.getItem("UltimoQuodom");
    setUltimoQuodom(ultimoq);
    //console.log("este ultimo q", ultimoq);
    let value = await AsyncStorage.getItem("userToken");
    let postsData = await ObtenerMisQuodoms(value);
    const newData = postsData
      .filter((item) => item.estado === "CREADO")
      .filter((item) => item.id === ultimoQuodom);
    if (newData.length === 0) {
      setUltimoQuodom("");
    } else {
      setUltimoQuodom(q);
    }
    const newData2 = postsData.filter((item) => item.estado === "CREADO");
    //console.log("nueva data", newData2);
    if (newData2.length === 0) {
      setUltimoQuodom("");
    } else {
      setUltimoQuodom(newData2[0].id);
    }

    //SL Alta negrada, pero asi tengo un item mas para hacer el card de crear.... algun dia, cuando sepa de esto lo arreglo.
    postsData.push("CREAR");
    //SL Alta negrada END
    if (mounted.current) {
      ////console.log('POST', postsData)
      setPosts(postsData);
      setoldPosts(postsData);
      //SL Espero para que termine el render del item del Flatlist
      /* wait(200).then(() => {
        setisLoadingAdentro(false);
      }); */

      //setisLoadingAdentro(false)
    }
  };

  const Setear = async () => {
    if (mounted.current) {
      const value = await AsyncStorage.getItem("userToken");
      const Qs = await getQuodomCreados(value);
      if (Qs.res) {
        setUltimoQuodom(Qs.data[0].id);
      } else {
        setUltimoQuodom("");
      }
      ////console.log('Entre al Setear() ListaMisQuodoms.js');
      let volverA = await AsyncStorage.getItem("volverA");
      let idcategoriaLP = await AsyncStorage.getItem("catPadre");
      let nombrecategoriaLP = await AsyncStorage.getItem("catPadreNombre");
      setVolverMenu(volverA);
      setactListaSub(idcategoriaLP);
      setactNombreSub(nombrecategoriaLP);
      setisLoading(false);
      //SL Espero para que termine el render del item del Flatlist
      wait(300).then(() => {
        setisLoadingAdentro(false);
      });
    }
  };

  useEffect(() => {
    //setisLoading(true);
    //setisLoadingAdentro(true);
    mounted.current = true;
    //console.log(
    //  "Entre al useEffect ListaMisQuodoms.js isFocused:",
    //  isFocused,
    //  "mounted:",
    //  mounted.current,
    //  "isLoading:",
    //  isLoading
    // );

    if (isFocused) {
      LeerDatos();
      Setear();
    }

    return () => (mounted.current = false);
  }, [isFocused]);

  const onPressFab = () => {
    navigation.navigate("QuodomNuevo", { estado: cambiarState });
  };

  const cambiarState = () => {
    //console.log("Refresh cambiarState ListaMisQuodoms.js");
    setRefreshing((state) => !state);
    wait(2000).then(() => setRefreshing(false));
  };

  const AbrirMenu = (newState) => {
    setabrirMenu(newState);
  };
  const renderHeader = () => {
    if (ultimoQuodom2 === "") {
      return <NodoQuodomCrearLinea />;
    } else {
      return null;
    }
  };

  const filtrar = (valor, tipo) => {
    ////console.log('valor:', valor, tipo);
    setFiltrado(true);
    let q = oldposts;
    ////console.log('COT', cot)
    if (tipo === "VENC") {
      if (valor === 4) {
        setPosts(q);
      } else if (valor === 1) {
        const newData = q.filter(
          (item) =>
            (item.diasparavencimientoaceptacion === 1 ||
              item.diasparavencimientoaceptacion === 0) &&
            item.estado === "CREADO"
        );
        setPosts(newData);
      } else {
        const newData = q.filter(
          (item) =>
            item.diasparavencimientoaceptacion === valor &&
            item.estado === "CREADO"
        );
        setPosts(newData);
      }
    }

    if (tipo === "ESTADO") {
      if (valor === "TODOS") {
        setPosts(q);
      } else {
        const newData = q.filter((item) => item.estado === valor);
        setPosts(newData);
      }
    }

    if (tipo === "ORDEN") {
      ////console.log('SOY EL ORDEN')
      if (valor === 0) {
        setPosts(q);
      } else {
        if (valor === 1) {
          //const newData = cot.sort((a, b) => new Date(a.fechavencimientoenvio).getTime() > new Date(b.fechavencimientoenvio).getTime());
          ////console.log('OPCION 1');
          ////console.log('COT', cot)
          setPosts(q);
        }

        if (valor === 2) {
          ////console.log('COT LIMPIA',cot)
          const newData = q.sort(function (x, y) {
            var firstDate = new Date(x.fechacreacion),
              SecondDate = new Date(y.fechacreacion);

            if (firstDate < SecondDate) return -1;
            if (firstDate > SecondDate) return 1;
            return 0;
          });
          ////console.log('COT 2', newData);
          setPosts(newData);
        }
      }
    }
  };

  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  if (isLoading || !fontsLoaded) {
    return <Loader />;
  } else {
    return (
      <View style={{ flex: 1, backgroundColor: "#706f9a", marginBottom: 0 }}>
        <TouchableOpacity
          style={{ marginLeft: "10px", marginTop: "10px" }}
          onPress={() => navigation.toggleDrawer()}
        >
          <Icon
            size="8"
            as={<MaterialIcons name="menu" />}
            color={quodomTheme.COLORS["DEFAULT"]}
          />
        </TouchableOpacity>

        <View
          style={{
            backgroundColor: "#706f9a",
            height: HEIGHT,
            width: WIDTH,
            alignItems: "center",
            justifyContent: "flex-start",
            zIndex: 1,
          }}
        >
          <View
            style={{
              backgroundColor: "#706f9a",
              height: HEIGHT,
              width: WIDTH,
              alignItems: "center",
              justifyContent: "center",
              justifyContent: "flex-start",
            }}
          >
            <Text
              style={{
                paddingTop: 18,

                fontSize: 17,
                color: "#FFFFFF",
              }}
            >
              MIS QUODOMS
            </Text>
          </View>
        </View>

        {isLoadingAdentro ? (
          <View
            style={{
              Flex: 1,
              zIndex: 100,
              position: "absolute",
              top: 120,
              bottom: 0,
              width: "100%",
            }}
          >
            <Center flex="1">
              <ActivityIndicator size="large" color="#fff" />
            </Center>
          </View>
        ) : (
          <View
            style={{
              Flex: 1,
              zIndex: 100,
              position: "absolute",
              top: 120,
              bottom: 0,
              width: "100%",
              borderWidth: 0,
            }}
          >
            <Center
              style={{
                backgroundColor: "#706f9a",
              }}
            >
              <HStack width={"40%"}>
                <FlatList
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshing}
                      onRefresh={onRefresh}
                    />
                  }
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  ref={flatListRef}
                  keyboardShouldPersistTaps="always"
                  data={posts}
                  style={QuodomList_Card.gridView}
                  onEndReached={() => {
                    //console.log("-------------------list ended");
                  }}
                  horizontal={false}
                  contentContainerStyle={{ flexGrow: 1 }}
                  numColumns="1"
                  snapToAlignment="center"
                  //ListEmptyComponent={(<NodoQuodomCrear actualizarestado={cambiarState} />)}
                  //ListFooterComponent={renderFooter}
                  ListHeaderComponent={renderHeader}
                  renderItem={({ item }) => (
                    <NodoQuodomLinea
                      fechavencimientoenvio={item.fechavencimientoenvio}
                      descripcion={item.descripcion}
                      porccompletado={item.porccompletado}
                      estado={item.estado}
                      cantproductos={item.cantproductos}
                      createdAt={item.createdAt}
                      diasparavencimientoenvio={item.diasparavencimientoenvio}
                      id={item.id}
                      iddireccion={item.iddireccion}
                      diasparavencimientoaceptacion={
                        item.diasparavencimientoaceptacion
                      }
                      actualizarestado={onRefresh}
                      nro={item.nro}
                      estadoCalc={item.EstadoCalc}
                      fechapago={item.FechaPago}
                      fechaenvio={item.fechaenvio}
                      ultimoQ={ultimoQuodom}
                      //abrirMenu={}
                    ></NodoQuodomLinea>
                  )}
                  keyExtractor={({ id }, index) => id}
                />
              </HStack>
            </Center>
          </View>
        )}
      </View>
    );
  }
};

export default ListaMisQuodoms;
